import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import '../../assets/styles/upgrade/UpgradeGameModal.css';
import ArrowLeft from '../../assets/images/upgrade/Arrow 1.png';
import gnom_bad from '../../assets/images/upgrade/gnom_bad.png';
import plusDolar from '../../assets/images/upgrade/plusDolar.png';
import minusDolar from '../../assets/images/upgrade/minusDolar.png';
import { useUser } from '../../contexts/UserContext';
import { useTranslation } from 'react-i18next';

const UpgradeGameModalProgress = ({ status, onClose, isVisible, onRefresh  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { user, updateUser } = useUser();
    const [statusMoment, setStatusMoment] = useState('');

    const { t } = useTranslation();

    useEffect(() => {
        if (isVisible) {
          setTimeout(() => setIsOpen(true), 50);
        } else {
          setIsOpen(false);
        }
      }, [isVisible]);

    const buyButton = async (status) => {

        if(status === "4") {
            // handleClose();
            handleRefresh();

        }
        else {
            handleClose();
        }
    }

    
    const handleClose = () => {
        setIsOpen(false);
        // Delay before calling onClose to allow closing animation to play
        setTimeout(onClose, 500);
    };

    const handleRefresh = () => {
        if (onRefresh) {
            console.log("Calling onRefresh from UpgradeGameModal");
            handleClose();
            // Use setTimeout to ensure handleClose completes before onRefresh is called
            setTimeout(() => {
                onRefresh();
            }, 350); // Match the delay used in handleClose
        }
    }
    
    
    const renderRewardIcon = (status) => {
        console.log('status2243124   ', status);
        if(status === "1" || status === "0" || status === "3" || status === "2" ) {
            return (
                <div className='modal-task-icon-con-bad-gnom'>
                    <img src={gnom_bad}></img>
                </div>
            );
        }
        else if (status === '4') {
            return (
                <div className='modal-task-icon-con-two' style={{padding: 0}}>
                    <img className='next-rank-img' style={backgroundStyle}></img>
                    <span className='next-rank-text'></span>
                </div>
            );
        }
        
    }

    const getTitle = (status) => {
        if(status === '0' || status === '1' || status === '2' || status === '3' ) {

            const validStatuses = ['0', '1', '2', '3'];
            if (validStatuses.includes(status)) {
                return t(`UpgradePage.Bad.${status}.titleOne`);
            }

        }
        else if(status === "4") {
            return `${t('UpgradePage.NextRank.titleOne')}`;
            
            
        }
    }
    const getTitleTwo = (status) => {
        if(status === '0' || status === '1' || status === '2' || status === '3' ) {
            return ``;
        }
    
        
        else if(status === "4") {
            if(user.rank.next_rank === null) {
                return `${t('UpgradePage.NextRank.titleTwo')} ${user.stage.id} ${t('UpgradePage.NextRank.titieThree')} `;
            }
            else {
                return `${t('UpgradePage.NextRank.titleTwo')} ${user.rank.next_rank} ${t('UpgradePage.NextRank.titleThreePlus')} `;

            }

        }
    }
    const getDescription = (status) => {
        if(status === '0' || status === '1' || status === '2' || status === '3' ) {

            const validStatuses = ['0', '1', '2', '3'];
            if (validStatuses.includes(status)) {
                return t(`UpgradePage.Bad.${status}.description`);
            }

        }
        else if("4") {
            return `${t('UpgradePage.NextRank.description')}`;

        }
    }
    const getBtnText = (status) => {
        if(status === '1') {
        return `${t('UpgradePage.btnContent.next')}`
        }
        else if("4") {
            return `${t('UpgradePage.btnContent.nextRank')}`;

        }
    }

    const memoizedTitle = useMemo(() => getTitle(status), [status]);
    const memoizedTitleTwo = useMemo(() => getTitleTwo(status), [status]);
    const memoizedDescription = useMemo(() => getDescription(status), [status]);

    const getBackgroundImage = (rankId, stageId) => {
        if(status === '4') {
            if(user.rank.next_rank === null){
                return require(`../../assets/images/upgrade/ModalBackground/${user.rank.id + 1}_${user.stage.drink + 1}.webp`);
            }
            else {
                return require(`../../assets/images/upgrade/ModalBackground/${user.rank.next_rank}_.webp`);
            }
            
        }
        else {
            return require(`../../assets/images/upgrade/ModalBackground/${rankId}_${user.stage.drink}.webp`);
        }
    };

    const backgroundStyle = status === 4 
    ? { backgroundColor: `url(${getBackgroundImage(user.rank.id, user.stage.drink, )})` }  // Стиль для status 4
    : {
        backgroundImage: `url(${getBackgroundImage(user.rank.id, user.stage.drink)})`,
      };


      useEffect(() => {
        console.log('--user.stage.id: ', user.stage.id);
        console.log('--user.rank.id: ', user.rank.id);
      })

    return (
        <div className={`modal-task-section-bck ${isOpen ? 'open' : ''}`}>
            <div className={`modal-task-section ${isOpen ? 'open' : ''}`}>
                <div className='modal-task-container' style={backgroundStyle}>
                    {renderRewardIcon(status)}

                    <p className='modal-task-title'>{memoizedTitle}</p>

                    <div className='modal-task-title-two'>
                        {memoizedTitleTwo}
                    </div>

                    <p className='modal-task-description'>{memoizedDescription}</p>

                    <button className='modal-task-buy-button' onClick={() => buyButton(status)}>
                        {getBtnText(status)}
                    </button>

                    <div className='modal-task-back-btn-section'>
                        <img src={ArrowLeft} alt='<' />
                        <button onClick={handleClose}>{t("LevelsPage.CloseModalText")}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpgradeGameModalProgress;