import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import '../../assets/styles/upgrade/UpgradeGameModal.css'
import { getBackgroundImage, getActualPickaxe, getActualDrink } from '../../contexts/UpgradeContext';
import chestIcon from '../../assets/images/upgrade/chest.png';
import ArrowLeft from '../../assets/images/upgrade/Arrow 1.png'
import keyIcon from '../../assets/images/upgrade/key.png';
import gnomIcon from '../../assets/images/upgrade/gnom.png';
import jailIcon from '../../assets/images/upgrade/prison.png';
import mini_energy from '../../assets/images/upgrade/icon_mini_energy.png';
import mini_pickaxe from '../../assets/images/upgrade/icon_mini_pickaxe.png';
import gnom_bad from '../../assets/images/upgrade/gnom_bad.png'
import minusDolar from '../../assets/images/upgrade/minusDolar.png'
import plusDolar from '../../assets/images/upgrade/plusDolar.png'
import { useUser } from '../../contexts/UserContext';
import { API_URLS } from '../../config/config';
import { useTranslation } from 'react-i18next';
import tickIcon from '../../assets/images/upgrade/Tick.png';
import UpgradeInviteModal from './UpgradeInviteModal';



import { useAudio } from "../../contexts/AudioContext";
import { useSound } from '../../utils/soundUtils'; // Импортируем наш хук

import defaultButtonClick from '../../assets/sounds/menu-button-click.mp3';
import BuyClick from '../../assets/sounds/upgrade/Buy01.mp3';
import ChestOpen from '../../assets/sounds/upgrade/ChestOpen.mp3';
import winMoney from '../../assets/sounds/upgrade/winMoney.mp3';
import loseMoney from '../../assets/sounds/upgrade/winMoney.mp3';




const UpgradeGameModal = ({ task, stageId, onClose, globalRefreshData, userStage, preloadedData, gameDataId, userlvl, stageInstrument, stageDrink, rankDataId }) => {
    const { t } = useTranslation();
    const { user, updateUser } = useUser();

    const [isOpen, setIsOpen] = useState(false);

    const [isOpenIviteModal, setisOpenIviteModal] = useState(false);

    const [taskStatus, setTaskStatus] = useState(task?.status);
    const [taskType, setTaskType] = useState(task?.type);
    const [buttonText, setButtonText] = useState('');
    const [error, setError] = useState(null);
    const [updatedTask, setUpdatedTask] = useState(task);
    const [taskRewardType, setTaskRewardType] = useState(task?.rewards[0]?.reward_type);

    const [stageInstrumentId, setStageInstrument] = useState(stageInstrument);
    const [stageDrinkId, setStageDrink] = useState(stageDrink);

    const [animateIcon, setAnimateIcon] = useState(true);
    const [animateTitle, setAnimateTitle] = useState(true);
    const [animateTitleTwo, setAnimateTitleTwo] = useState(true);
    const [animateDescription, setAnimateDescription] = useState(true);
    const [animateButton, setAnimateButton] = useState(true);

    const [firstStart, setFirstStart] = useState(true);

    //для звука
    const [updateData, setUpdateData] = useState(false);
    const [playVolum, setPlayVolum] = useState(true);

    const userId = user.user_id;

    //звук
    const { generalVolume, effectsVolume } = useAudio();
    const { initializeAudioContext, loadSound, playSound } = useSound({ generalVolume, effectsVolume });
    const audioInitializedRef = useRef(false);

    useEffect(() => {
        const handleInteraction = async () => {
          if (!audioInitializedRef.current) {
            await initializeAudioContext();
            await loadSound(defaultButtonClick, 'clickDefault');
            await loadSound(BuyClick, 'Buy');
            await loadSound(BuyClick, 'Jail');
            await loadSound(winMoney, 'OpenChestMoneyPlus');
            await loadSound(BuyClick, 'OpenChestMoneyMinus');
            await loadSound(BuyClick, 'OpenChestGnom');
            await loadSound(BuyClick, 'OpenChestKey');
            await loadSound(ChestOpen, 'ChestOpen');
            audioInitializedRef.current = true;
          }
        };
    
        document.addEventListener('touchstart', handleInteraction, { once: true });
        document.addEventListener('mousedown', handleInteraction, { once: true });
    
        return () => {
          document.removeEventListener('touchstart', handleInteraction);
          document.removeEventListener('mousedown', handleInteraction);
        };
      }, [initializeAudioContext, loadSound]);

    const AnimationError = () => {
        setAnimateIcon(false);
        setAnimateTitle(false);
        setAnimateTitleTwo(false);
        setAnimateDescription(false);
        setAnimateButton(true);

        setTimeout(runAnimations, 500);

    }
    const closeIviteModal = () => {
        setTimeout(() => {
            setisOpenIviteModal(false);
        }, 500);
        
    }


    const runAnimations = () => {
        setTimeout(() => setAnimateIcon(true), 150);
        setTimeout(() => setAnimateTitle(true), 200);
        setTimeout(() => setAnimateTitleTwo(true), 200);
        setTimeout(() => setAnimateDescription(true), 200);
        setTimeout(() => setAnimateButton(true), 0);
    };

    const updateContent = (newTask, animate = false) => {
        const shouldAnimate = firstStart || animate && (taskType === 'buy chest' && newTask.status === 'In progress');

        // if(updateData === true) {
        //         if(newTask.rewards[0]?.reward_type === 1 ){
        //             console.log("VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV");
        //     }
        // }

        if (firstStart || animate && (newTask.type === 'buy chest' && taskStatus === 'In progress')) {
            setAnimateIcon(false);
            setAnimateTitle(false);
            setAnimateTitleTwo(false);
            setAnimateDescription(false);
            setAnimateButton(true);
        }
        

        setTimeout(() => {
            setIsOpen(true);
            setTaskStatus(newTask.status);
            setTaskType(newTask.type);
            setTaskRewardType(newTask.rewards[0]?.reward_type);
            updateButtonText(newTask.status, newTask.type, newTask.rewards[0]?.reward_type);
            setUpdatedTask(newTask);

            if (firstStart || animate && (newTask.type === 'buy chest' && newTask.status === 'In progress')) {
                setTimeout(() => setAnimateIcon(true), 150);
                setTimeout(() => setAnimateTitle(true), 200);
                setTimeout(() => setAnimateTitleTwo(true), 200);
                setTimeout(() => setAnimateDescription(true), 200);
                setTimeout(() => setAnimateButton(true), 0);
            }

            if (firstStart) {
                setFirstStart(false);
            }
        }, shouldAnimate ? 500 : 0);
    };

    useEffect(() => {
        console.log('----------------');
        console.log('task: ', task);
        console.log('stageId: ', stageId);
        console.log('stageInstrument: ', stageInstrument);
        console.log('stageDrink: ', stageDrink);
        console.log('----------------');

        const backgroundImage = getBackgroundImage(rankDataId, stageId);

        // Обновляем фоновое изображение для ::before
        const upgradeSection = document.querySelector('.modal-task-section');
        if (upgradeSection) {
            upgradeSection.style.setProperty('--background-image', `url(${backgroundImage})`);
        }

        if (task) {
            setTaskType(task.type);
            setTaskStatus(task.status);
            updateContent(task, false);
        } else {
            setIsOpen(false);
        }
    }, [task]);

    

    const handleRefresh = () => {
        if (globalRefreshData) {
            console.log("Calling onRefresh from UpgradeGameModal");
            globalRefreshData();
        }
    };

    const updateButtonText = (status, type, rewardType) => {
        if (status === 'Claimed') {
            setButtonText(t('UpgradePage.btnContent.claimed'));
        }
        else if (status === 'Not claimed') {
            setButtonText(t('UpgradePage.btnContent.not_claimed'));
        }
        else if (status === 'In progress') {
            if (type === 'subscribe to channel') {
                setButtonText(t('UpgradePage.btnContent.sub_channel'));
            }
            else if (type === 'invite friend') {
                setButtonText(t('UpgradePage.btnContent.inviteFrends'));
            }
            else {
                setButtonText(t('UpgradePage.btnContent.buy', { IdPrice: updatedTask.price }));
            }
        } else if (status === 'No Money') {
            setButtonText(t('UpgradePage.btnContent.back'));
        } else if (status === 'PrisonError') {
            setButtonText(t('UpgradePage.UpgradeModal.PrisonError.ButtonText'));
        } else if (status === 'Unavailable') {
            setButtonText(t('UpgradePage.btnContent.back'));
        } else {
            setButtonText('vay, a yak?');
        }
    };

    const closeAnimation = () => {
            setAnimateIcon(false);
            setAnimateTitle(false);
            setAnimateTitleTwo(false);
            setAnimateDescription(false);
            setAnimateButton(true);
    }

    const buyButton = async () => {

        

        let button_text = `${t('UpgradePage.btnContent.claimed')}`;
        let button_text2 = `${t('UpgradePage.btnContent.back')}`;

        if (buttonText === button_text || buttonText === button_text2) {
            playSound('clickDefault');
            handleClose();
            return;
        }

        if (taskType === 'buy chest' && taskStatus === 'In progress') {
            playSound('ChestOpen');
            setAnimateIcon(false);
            setAnimateTitle(false);
            setAnimateTitleTwo(false);
            setAnimateDescription(false);
            setAnimateButton(true);
        }
        setTimeout(async () => {
            try {
                setError(null);
                const headers = {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'ngrok-skip-browser-warning': 'true'
                };
                let response;

                if (taskStatus === 'Not claimed') {
                    response = await axios.post(API_URLS.CLAIM_TASK, { taskId: updatedTask.id }, { headers });
                    if (response.data.message === 'ok') {
                        setUpdateData(true);
                        setTaskStatus('Claimed');
                        updateButtonText('Claimed', updatedTask.type, taskRewardType);
                    }
                } 
                else if (taskStatus === 'In progress') {
                    console.log("tutu vork");
                    if (updatedTask.type === 'subscribe to channel') {
                        response = await axios.post(API_URLS.BUY_TASK, { taskId: task.id }, { headers });
                        if (response.data.message === 'You completed the task. Now claim it' && response.data.task_status === 'Not claimed') {
                            setUpdateData(true);
                            setTaskStatus('Not claimed');
                            updateButtonText('Not claimed');
                        } else {
                            let link = task.text;
                            // Проверяем, находимся ли мы в среде Telegram WebApp
                            if (window.Telegram && window.Telegram.WebApp) {
                                // Используем метод Telegram для открытия ссылок
                                window.Telegram.WebApp.openTelegramLink(link);
                            } else {
                                // Для других сред используем стандартный метод
                                window.open(link, '_blank');
                            }
                        }
                    } 

                    else if (updatedTask.type === "invite friend") {
                        response = await axios.post(API_URLS.BUY_TASK, { taskId: task.id }, { headers });
                        if (response.data.message === 'You completed the task. Now claim it') {
                            setUpdateData(true);
                            setTaskStatus('Not claimed');
                            updateButtonText('Not claimed');
                            setisOpenIviteModal(true);

                            setTimeout(() => {
                                if (window.Telegram && window.Telegram.WebApp) {
                                
                                    const title = document.querySelector('meta[property="og:title"]')?.content || "";
                                    const description = document.querySelector('meta[property="og:description"]')?.content || "";
                                    const imagePath = document.querySelector('meta[property="og:image"]')?.content || "";
                                    const fullImageUrl = new URL(imagePath, window.location.origin).href;
                                    
                                    const titleTwo = '**Реферальна система підключена!**'; // текст жирным через Markdown
                                    const customDes = `Тепер ти отримуватимеш винагороду за кожен рівень який досягне твій друг, з кожним досягнутим рівнем винагорода за реферала сумується.\n\nЯкщо ваш реферал дійде до 10 рівня, ви отримаєте загалом 5.5 GToken, що дорівнює приблизно 55$ `;
                                    const message = `\n\n${title}\n\n\n${description}`;
                                
                                    const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(preloadedData.refLink)}&text=${encodeURIComponent(message)}`;
                                    window.Telegram.WebApp.openTelegramLink(shareUrl);
                                }
                            }, 2500)
                            
                            
                        } 
                        else {
                            // setisOpenIviteModal(true);
                            if (window.Telegram && window.Telegram.WebApp) {
                                const title = document.querySelector('meta[property="og:title"]')?.content || "";
                                const description = document.querySelector('meta[property="og:description"]')?.content || "";
                                const imagePath = document.querySelector('meta[property="og:image"]')?.content || "";
                                const fullImageUrl = new URL(imagePath, window.location.origin).href;
                                
                                const titleTwo = '**Реферальна система підключена!**'; // текст жирным через Markdown
                                const customDes = `Тепер ти отримуватимеш винагороду за кожен рівень який досягне твій друг, з кожним досягнутим рівнем винагорода за реферала сумується.\n\nЯкщо ваш реферал дійде до 10 рівня, ви отримаєте загалом 5.5 GToken, що дорівнює приблизно 55$ `;
                                const message = `\n\n${title}\n\n\n${description}`;
                            
                                const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(preloadedData.refLink)}&text=${encodeURIComponent(message)}`;
                                window.Telegram.WebApp.openTelegramLink(shareUrl);
                            }
                        }
                    } 
                    else {
                        try {
                            response = await axios.post(API_URLS.BUY_TASK, { taskId: updatedTask.id }, { headers });
                            if (response.data.message === 'You completed the task. Now claim it') {
                                if(taskType === 'buy chest') {
                                    response = await axios.post(API_URLS.CLAIM_TASK, { taskId: updatedTask.id }, { headers });
                                    if (response.data.message === 'ok') {
                                        setUpdateData(true);
                                        setTaskStatus('Claimed');
                                        updateButtonText('Claimed', updatedTask.type, taskRewardType);
                                    }
                                }
                                else {
                                    setUpdateData(true);
                                    setTaskStatus('Not claimed');
                                    updateButtonText('Not claimed', updatedTask.type, taskRewardType);
                                    console.log("Task completed and ready to be claimed");
                                }
                            } 
                            
                            else {
                                // setTaskType('No Money');
                                // setButtonText('No Money')
                            }
                        } catch (error) {

                            if (error.response && error.response.status === 403) {
                                
                                console.log('error.response.data.result', error.response.data.message);
                                if(error.response.data.message === "You can't buy tasks while prisoning") {
                                    AnimationError();
                                    console.log("===================GGGG");
                                    
                                    setTimeout(() => {
                                        setTaskStatus('PrisonError');
                                        setButtonText(t('UpgradePage.btnContent.back'));
                                    }, 700); // Задержка 700 миллисекунд
                                }
                                else {
                                    
                                    AnimationError();
                                    
    
                                    setTimeout(() => {
                                        setTaskStatus('No Money');
                                        setButtonText(t('UpgradePage.btnContent.back'));
                                    }, 700); // Задержка 700 миллисекунд
                                }

                                
                            } else {
                                console.error("An error occurred:", error);
                            }
                        }
                    }
                } else {
                    response = await axios.post(API_URLS.BUY_TASK, { taskId: updatedTask.id }, { headers });
                    if (response.data.message === 'You completed the task. Now claim it' && response.data.task_status === 'Not claimed') {
                        setUpdateData(true);
                        setTaskStatus('Not claimed');
                        updateButtonText('Not claimed', updatedTask.type, taskRewardType);
                    } else if (response.data.message === 'Task already done') {
                        setButtonText('Claimed');
                    }
                }

                if (response.data.task) {
                    console.log('Новые данные задачи:', response.data.task);
                    setTaskType(response.data.task.type);
                    setTaskStatus(response.data.task.status);
                    updateContent(response.data.task, true);
                } 
                else if (taskType === 'buy chest' && taskStatus === 'In progress') {
                    setTimeout(() => setAnimateIcon(true), 100);
                    setTimeout(() => setAnimateTitle(true), 200);
                    setTimeout(() => setAnimateTitleTwo(true), 200);
                    setTimeout(() => setAnimateDescription(true), 200);
                    setTimeout(() => setAnimateButton(true), 100);
                }

                const userResponse = await axios.get(API_URLS.GET_USER_INFO, { params: { userId }, headers });
                if (typeof userResponse.data === 'object' && userResponse.data.info) {
                    updateUser({ ...userResponse.data.info });
                }

            } catch (error) {
                console.error('Error during task operation:', error);
                setError('Произошла ошибка при выполнении операции. Пожалуйста, попробуйте еще раз.');
                if (updatedTask.type === 'buy chest' && updatedTask.status === 'In progress') {
                    setTimeout(() => setAnimateIcon(true), 100);
                    setTimeout(() => setAnimateTitle(true), 200);
                    setTimeout(() => setAnimateTitleTwo(true), 200);
                    setTimeout(() => setAnimateDescription(true), 200);
                    setTimeout(() => setAnimateButton(true), 100);
                }
            }
        }, updatedTask.type === 'buy chest' && updatedTask.status === 'In progress' ? 800 : 0);
    };

    const handleClose = () => {
        playSound('clickDefault');
        
        if(updateData === true) {
            setIsOpen(false);
        setTimeout(() => {
            handleRefresh();
            onClose();
        }, 600);
        }
        else {
            setIsOpen(false);
            setTimeout(() => {
                onClose();
                
            }, 600);
        }

        

    };

    const renderRewardIcon = () => {
        const reward = updatedTask?.rewards[0];

        if (taskStatus === 'No Money' || taskStatus === 'PrisonError' ) {
            if(playVolum === true) {
                playSound('NoMoney');
                setPlayVolum(false);
             }
            return (
                <div className='modal-task-icon-con-bad-gnom'>
                    <img src={gnom_bad} alt="Bad Gnom" />
                </div>
            );
        }

        if (updatedTask.type === 'buy chest' && (taskStatus === 'Not claimed' || taskStatus === 'Claimed')) {
            if (taskRewardType === '1') {
                if(playVolum === true) {
                    if(reward.amount < 0) {
                        playSound('OpenChestMoneyMinus');
                        setPlayVolum(false);
                    }
                    else {
                        playSound('OpenChestMoneyPlus');
                        setPlayVolum(false);
                    }
                }
                
                return (
                    <div className='modal-task-icon-con-two'>
                        {taskStatus === 'Claimed' && (
                            <img
                                className='completed-icon'
                                src={tickIcon}
                                alt="Completed"
                                style={{
                                    zIndex: '2',
                                    position: 'absolute',
                                    right: '9px',
                                    top: '9px',
                                    width: 'auto',
                                    height: '17px',
                                    filter: "drop-shadow(0px 0px 5px rgb(255, 199, 0))"
                                }}
                            />
                        )}
                        {/* <div className={`reward-gold ${reward.amount < 0 ? 'negative' : 'positive'}`}>{reward.amount}$</div> */}
                        <img
                            src={reward.amount < 0 ? minusDolar : plusDolar}
                            alt={reward.amount < 0 ? 'Minus Dollar' : 'Plus Dollar'}
                        />
                    </div>
                );
            } 
            else if (taskRewardType === '5') {
                if(playVolum === true) {
                   playSound('OpenChestKey');
                   setPlayVolum(false);
                }
                return (
                    <div className='modal-task-icon-con'>
                        {taskStatus === 'Claimed' && (
                            <img
                                className='completed-icon'
                                src={tickIcon}
                                alt="Completed"
                                style={{
                                    zIndex: '2',
                                    position: 'absolute',
                                    right: '9px',
                                    top: '9px',
                                    width: 'auto',
                                    height: '17px',
                                    filter: "drop-shadow(0px 0px 5px rgb(255, 199, 0))"
                                }}
                            />
                        )}
                        <img className='key' src={keyIcon} alt={updatedTask.name} />
                    </div>
                );
            } 
            else if (taskRewardType === '6') {
                if(playVolum === true) {
                    playSound('OpenChestGnom');
                    setPlayVolum(false);
                 }
                return (
                    <div className='modal-task-icon-con'>
                        {taskStatus === 'Claimed' && (
                            <img
                                className='completed-icon'
                                src={tickIcon}
                                alt="Completed"
                                style={{
                                    zIndex: '2',
                                    position: 'absolute',
                                    right: '9px',
                                    top: '9px',
                                    width: 'auto',
                                    height: '17px',
                                    filter: "drop-shadow(0px 0px 5px rgb(255, 199, 0))"
                                }}
                            />
                        )}
                        <img className='gnom-mod' src={gnomIcon} alt={updatedTask.name} />
                    </div>
                );
            } 
            else if (taskRewardType === '7') {
                if(playVolum === true) {
                    playSound('Jail');
                    setPlayVolum(false);
                 }
                return (
                    
                    <div className='modal-task-icon-con' style={{ padding: '0' }}>
                        {taskStatus === 'Claimed' && (
                            <img
                                className='completed-icon'
                                src={tickIcon}
                                alt="Completed"
                                style={{
                                    zIndex: '2',
                                    position: 'absolute',
                                    right: '9px',
                                    top: '9px',
                                    width: 'auto',
                                    height: '17px',
                                    filter: "drop-shadow(0px 0px 5px rgb(255, 199, 0))"
                                }}
                            />
                        )}
                        <img className='jail-icon' style={{ width: "100%", height: "100%" }} src={jailIcon} alt={updatedTask.name} />
                    </div>
                );
            }
        }

        if (updatedTask.type === 'buy chest' && (taskStatus === 'Unavailable' || taskStatus === 'In progress' || taskStatus === 'Expired')) {
            return (
                <div className='modal-task-icon-con-chest'>
                    <img className='gnom-moda' src={chestIcon} alt={updatedTask.name} />
                </div>
            );
        }

        if (taskRewardType === '2' || taskRewardType === '4') {
            const icon = taskRewardType === '2' ?
                // getPickaxeIcon(user.rank.id, reward.id, stageId, stageInstrumentId) :
                // getEnergyIcon(user.rank.id, reward.id, stageId, stageDrinkId);
                getActualPickaxe(userlvl, stageInstrument, reward.id) :
                getActualDrink(userlvl, stageDrink, reward.id)
            return (
                <div className='modal-task-icon-con'>
                    {taskStatus === 'Claimed' && (
                        <img
                            className='completed-icon'
                            src={tickIcon}
                            alt="Completed"
                            style={{
                                zIndex: '2',
                                position: 'absolute',
                                right: '9px',
                                top: '9px',
                                width: 'auto',
                                height: '17px',
                                filter: "drop-shadow(0px 0px 5px rgb(255, 199, 0))"
                            }}
                        />
                    )}
                    <img src={icon} alt={updatedTask.name} />
                </div>
            );
        }

        return null;
    };

    // const getPickaxeIcon = (rankId, rewardId, stageId, stageInstrumentId) => {
    //     if (rewardId < '1000') {
    //         return require(`../../assets/images/upgrade/pickaxe/pickaxe_${rankId}_${stageInstrumentId}.webp`);
    //     }
    //     else {
    //         return require(`../../assets/images/upgrade/pickaxe/pickaxe_${rankId}_${rewardId}.webp`);
    //     }
    // };

    // const getEnergyIcon = (rankId, rewardId, stageId, stageDrink) => {
    //     if (rewardId < '1000') {
    //         return require(`../../assets/images/upgrade/energy/energy_${rankId}_${stageDrink}.webp`);
    //     }
    //     else {
    //         return require(`../../assets/images/upgrade/energy/energy_${rankId}_${rewardId}.webp`);
    //     }
    // };

    const getTitle = (task, status, rewardType, stageId, stageInstrument, stageDrink) => {
        var rankID = user.rank.id;
        var rewardId = task.rewards[0].id;

        if (taskStatus === 'No Money') {
            // return t('UpgradePage.InsufficientFundsModal.titleOne');
            return t('UpgradePage.UpgradeModal.NoMoneyBuyTask.Title');

        }

        if(taskStatus === 'PrisonError') {
            return t('UpgradePage.UpgradeModal.PrisonError.Title');
        }

        if (task.type === 'buy chest') {
            if (status === 'Unavailable' || status === 'In progress' || status === 'Expired') {
                return t("UpgradePage.Cheast.titleOne");
            }

            if (rewardType === "1" && (status === 'Not claimed' || status === 'Claimed')) {
                return task.rewards[0].amount < 0 ? t("UpgradePage.CheastMoneyMinus.titleOne") : t('UpgradePage.CheastMoneyPlus.titleOne');
            }

            if (rewardType === "5" && (status === 'Not claimed' || status === 'Claimed')) {
                return t('UpgradePage.CheastNextKey.titleOne');
            }

            if (rewardType === "6" && (status === 'Not claimed' || status === 'Claimed')) {
                return t("UpgradePage.CheastGnom.titleOne");
            }

            if (rewardType === "7" && (status === 'Not claimed' || status === 'Claimed')) {
                return t("UpgradePage.CheastPrison.titleOne");
            }
        }

        if (rewardType === "2") {
            let key = rewardId < '1000' ? `UpgradePage.Pickaxe.pickaxe_${rankID}_${stageInstrument}` : `UpgradePage.Pickaxe.pickaxe_${rankID}_${rewardId}`;
            let translatedObject = t(key, { returnObjects: true });
            return translatedObject.title || key;
        }

        if (rewardType === "4") {
            let key = rewardId < '1000' ? `UpgradePage.Energy.energy_${rankID}_${stageDrink}` : `UpgradePage.Energy.energy_${rankID}_${rewardId}`;
            let translatedObject = t(key, { returnObjects: true });
            return translatedObject.title || key;
        }

        return "";
    };

    const getDescription = (task, status, rewardType, stageId, stageInstrument, stageDrink) => {
        var rankID = user.rank.id;
        var rewardId = task.rewards[0].id;

        if (status === 'No Money') {
            // return `${t('UpgradePage.UpgradeModal.NoMoney.Description')}`;
            return t('UpgradePage.UpgradeModal.NoMoneyBuyTask.Description', { IdDsc: task.price })
        }

        if(status === 'PrisonError') {
            return t('UpgradePage.UpgradeModal.PrisonError.Description');
        }

        if (task.type === 'buy chest') {
            if (status === 'Unavailable' || status === 'In progress' || status === 'Expired') {
                return t("UpgradePage.Cheast.description");
            }
            if ((status === 'Not claimed' || status === 'Claimed') && rewardType === '1') {
                return task.price > 0
                    ? t('UpgradePage.CheastMoneyPlus.description')
                    : t('UpgradePage.CheastMoneyMinus.description');
            }
            if (rewardType === '5') {
                return t('UpgradePage.CheastNextKey.description');
            }
            if (rewardType === '6') {
                return t('UpgradePage.CheastGnom.description');
            }
            if (rewardType === '7') {
                return t('UpgradePage.CheastPrison.description');
            }
        }
        if (rewardType === "2") {
            let key = rewardId < '1000' ? `UpgradePage.Pickaxe.pickaxe_${rankID}_${stageInstrument}` : `UpgradePage.Pickaxe.pickaxe_${rankID}_${rewardId}`;
            let translatedObject = t(key, { returnObjects: true });
            return translatedObject.dsc || key;
        }
        if (rewardType === "4") {
            let key = rewardId < '1000' ? `UpgradePage.Energy.energy_${rankID}_${stageDrink}` : `UpgradePage.Energy.energy_${rankID}_${rewardId}`;
            let translatedObject = t(key, { returnObjects: true });
            return translatedObject.dsc || key;
        }
    }

    const getTitleTwo = (task, status, rewardType) => {
        const reward = task.rewards[0];
        const prefix = rewardType === '1' && reward.amount < 0 ? '' : '+';

        if (taskStatus === 'No Money') {
            return t('UpgradePage.UpgradeModal.NoMoneyBuyTask.TitleTwo');

        }

        if (taskStatus === 'PrisonError') {
            return t('UpgradePage.UpgradeModal.PrisonError.TitleTwo');
        }

        if (rewardType === '4') {
            return (
                <div className='title-two-con'>
                    <p className=''>{t('UpgradePage.Universal.energyTitleTwo', { sumPlus: reward.amount })}</p>
                    {/* <p className=''>+{`${reward.amount} ${t("UpgradePage.Universal.energyTitleTwo")}`}</p> */}
                    <img className='img-mini' src={mini_energy} alt='' />
                </div>
            );
        }

        if (rewardType === '2') {
            return (
                <div className='title-two-con'>
                    <p className=''>{t('UpgradePage.Universal.pickaxeTitleTwo', { sumPlus: reward.amount })}</p>
                    {/* <p className=''>+{`${reward.amount} ${t("UpgradePage.Universal.pickaxeTitleTwo")}`}</p> */}
                    <img className='img-mini' src={mini_pickaxe} alt='' />
                </div>
            );
        }

        const plusminusbalik = prefix === '+'
        
            // ? `${t("UpgradePage.CheastMoneyPlus.titleTwo")} ${reward.amount} ${t("UpgradePage.Universal.goldTitleTwo")}`
            // : `${t("UpgradePage.CheastMoneyMinus.titleTwo")} ${reward.amount} ${t("UpgradePage.Universal.goldTitleTwo")}`;
            ? `${t('UpgradePage.CheastMoneyPlus.titleTwo', { sumMoney: reward.amount })}`
            : `${t('UpgradePage.CheastMoneyMinus.titleTwo', { sumMoney: reward.amount })}`;

        if (status === 'Claimed' || status === 'Not claimed') {
            switch (rewardType) {
                case '1':
                    return plusminusbalik;
                case '3':
                    return `+${reward.amount} g-token`;
                case '5':
                    return t("UpgradePage.CheastNextKey.titleTwo");
                case '6':
                    return `+${reward.amount} ${t("UpgradePage.CheastGnom.titleTwo")}`;
                case '7':
                    return `${reward.amount} ${t("UpgradePage.CheastPrison.titleTwo")}`;
                default:
                    return `+${reward.amount} ${reward.name}`;
            }
        }
    };

    const getTitleTwoColor = (reward, status) => {
        if (!reward) return 'white';

        if (reward.reward_type === '1') {
            return reward.amount < 0 ? 'red' : 'green';
        }

        return 'white';
    };



    const memoizedTitle = useMemo(() => getTitle(updatedTask, taskStatus, taskRewardType, stageId, stageInstrument, stageDrink), [updatedTask, taskStatus, taskRewardType, stageId, stageInstrument, stageDrink]);
    const memoizedDescription = useMemo(() => getDescription(updatedTask, taskStatus, taskRewardType, stageId, stageInstrument, stageDrink), [updatedTask, taskStatus, taskRewardType, stageId, stageInstrument, stageDrink]);
    const memoizedTitleTwo = useMemo(() => getTitleTwo(updatedTask, taskStatus, taskRewardType, stageId, stageInstrument, stageDrink), [updatedTask, taskStatus, taskRewardType, stageId, stageInstrument, stageDrink]);
    const memoizedTitleTwoColor = useMemo(() => getTitleTwoColor(updatedTask.rewards[0], taskStatus), [updatedTask.rewards, taskStatus]);



    if (!updatedTask) return null;


    

    const backgroundStyle = {
        backgroundImage: `url(${getBackgroundImage(rankDataId, stageId)})`,
    };


    return (
        <>
        <div className={`modal-task-section-bck ${isOpen ? 'open' : ''}`}>
            <div className={`modal-task-section ${isOpen ? 'open' : ''}`}>


                <div className='modal-task-container'>
                    <div className={`modal-task-icon fade-element ${animateIcon ? 'visible' : ''}`}>
                        {renderRewardIcon()}
                    </div>
                    <p className={`modal-task-title fade-element ${animateTitle ? 'visible' : ''}`}>
                        {memoizedTitle}
                    </p>
                    <div className={`modal-task-title-two fade-element ${animateTitleTwo ? 'visible' : ''}`}
                        >
                        {memoizedTitleTwo}
                    </div>
                    <p className={`modal-task-description fade-element ${animateDescription ? 'visible' : ''}`}>
                        {memoizedDescription}
                    </p>
                    <button className={`modal-task-buy-button fade-element ${animateButton ? 'visible' : ''}`}
                        onClick={buyButton}>
                        {buttonText}
                    </button>
                    <div className='modal-task-back-btn-section'>
                        <img src={ArrowLeft} alt='<' />
                        <button onClick={handleClose}>{t("LevelsPage.CloseModalText")}</button>
                    </div>
                </div>
            </div>
        </div>

        {isOpenIviteModal && (
            <UpgradeInviteModal 
                closeIviteModal={closeIviteModal} 
            />
)}
        </>


    );
};

export default UpgradeGameModal;