import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { debounce } from 'lodash';
import "../../assets/styles/games/game_core3.css";
import { useAudio } from "../../contexts/AudioContext";
import { API_URLS } from "../../config/config";
import { ReactComponent as IconCoin } from "../../assets/icons/coin.svg";
import { useUser } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import GameIconLL from "../../assets/images/Games/ui_graph_person.jpg";
import TopRightImage from "../../assets/icons/gnom.webp";
import TradeWarningModal from "../../components/TradeWarningModal";
import TelegramButton from "./tgbtn";
import modalBackground from "../../assets/images/fairy-modal-background.png";
import clickSoundMp3 from "../../assets/sounds/menu-button-click.mp3";
import moneySoundMp3 from "../../assets/sounds/bonus-money-sound.mp3";
import fiascoSoundMp3 from "../../assets/sounds/fiasco.mp3";

const styles = {
  gameWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
  },
  gameContainer: {
    width: "100%",
    maxWidth: "500px",
    backgroundColor: "white",
    overflow: "hidden",
  },
  gameHeader: {
    padding: "15px",
    backgroundColor: "#4a90e2",
    color: "white",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "center",
  },
  canvas: {
    width: "100%",
    height: "auto",
    maxHeight: "300px",
  },
  controlsContainer: {
    padding: "15px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  statusText: {
    margin: "5px 0",
    fontSize: "14px",
    color: "white",
  },
  finalMessage: {
    color: "#e74c3c",
    fontWeight: "bold",
  },
  input: {
    width: "100%",
    padding: "5px",
    color: "white",
    fontSize: "18px",
    backgroundColor: "transparent",
    borderRadius: "4px",
    outline: "none",
  },
  whiteText: {
    color: "white",
  },
};

const Notification = ({ message, isVisible, onClose }) => {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        color: "white",
        padding: "20px",
        borderRadius: "10px",
        zIndex: 1000,
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "24px",
      }}
    >
      {message}
    </div>
  );
};

const CoreGameThree = ({ tgUserId }) => {
  const [columns, setColumns] = useState(6);
  const [rows, setRows] = useState(6);
  const [currentValue, setCurrentValue] = useState(1);
  const [isRunning, setIsRunning] = useState(false);
  const [growthProgress, setGrowthProgress] = useState(0);
  const [showFinalMessage, setShowFinalMessage] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [gameStatus, setGameStatus] = useState("wait");
  const [waitTime, setWaitTime] = useState(0);
  const [sessionId, setSessionId] = useState(null);
  const [error, setError] = useState(null);
  const [betMessage, setBetMessage] = useState("");
  const [pickupMessage, setPickupMessage] = useState("");
  const [endPosition, setEndPosition] = useState({ x: 0, y: 0 });
  const [hasPickedUpBet, setHasPickedUpBet] = useState(false);
  const [fallProgress, setFallProgress] = useState(0);
  const [isGrowthComplete, setIsGrowthComplete] = useState(false);
  const [betAmount, setBetAmount] = useState("");
  const [betId, setBetId] = useState(null);
  const [currentBet, setCurrentBet] = useState("");
  const [betResult, setBetResult] = useState(null);
  const [gamePhase, setGamePhase] = useState("bet");
  const [displayedBetAmount, setDisplayedBetAmount] = useState("");
  const [betDisplayed, setBetDisplayed] = useState(false);
  const [finalValue, setFinalValue] = useState(null);
  const [betPlaced, setBetPlaced] = useState(false);
  const canvasRef = useRef(null);
  const wsRef = useRef(null);
  const imageRef = useRef(null);
  const topRightImageRef = useRef(null);
  const betMessageRef = useRef("");
  const { user, updateUser } = useUser();
  //const [localBalance, setLocalBalance] = useState(user.g_token);
  const [gameResult, setGameResult] = useState(null);
  const [winNotification, setWinNotification] = useState({
    isVisible: false,
    message: "",
  });
  const [betConfirmation, setBetConfirmation] = useState({
    isVisible: false,
    message: "",
  });
  const { t } = useTranslation();
  const [modalState, setModalState] = useState({
    isOpen: false,
    message: "",
    link: "",
  });
  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);
  const moneyAudioBufferRef = useRef(null);
  const fiascoAudioBufferRef = useRef(null);

  const playSound = useCallback(() => {
    if (
      isAudioInitialized &&
      audioContextRef.current &&
      clickAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  const playMoneySound = useCallback(() => {
    if (
      isAudioInitialized &&
      audioContextRef.current &&
      moneyAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = moneyAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  const playFiascoSound = useCallback(() => {
    if (isAudioInitialized && audioContextRef.current && fiascoAudioBufferRef.current) {
        const source = audioContextRef.current.createBufferSource();
        source.buffer = fiascoAudioBufferRef.current;
        
        const gainNode = audioContextRef.current.createGain();
        gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
        
        source.connect(gainNode);
        gainNode.connect(audioContextRef.current.destination);
        
        source.start(0);
    }
}, [isAudioInitialized, effectsVolume, generalVolume]);


  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const clickResponse = await fetch(clickSoundMp3);
          const clickArrayBuffer = await clickResponse.arrayBuffer();
          clickAudioBufferRef.current =
            await audioContextRef.current.decodeAudioData(clickArrayBuffer);

          const moneyResponse = await fetch(moneySoundMp3);
          const moneyArrayBuffer = await moneyResponse.arrayBuffer();
          moneyAudioBufferRef.current =
            await audioContextRef.current.decodeAudioData(moneyArrayBuffer);

            const fiascoResponse = await fetch(fiascoSoundMp3);
            const fiascoArrayBuffer = await fiascoResponse.arrayBuffer();
            fiascoAudioBufferRef.current = await audioContextRef.current.decodeAudioData(fiascoArrayBuffer);

          setIsAudioInitialized(true);
        } catch (error) {
        }
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const maxCoefficient = 6;
  const minCoefficient = 0;

  useEffect(() => {
    const img = new Image();
    img.src = GameIconLL;
    img.onload = () => {
      imageRef.current = img;
    };

    const topRightImg = new Image();
    topRightImg.src = TopRightImage;
    topRightImg.onload = () => {
      topRightImageRef.current = topRightImg;
    };
  }, []);

  const debouncedSetBetAmount = useCallback(
    debounce((value) => {
      setBetAmount(value);
    }, 100),
    []
  );
  
  const handleInputFocus = () => {
    const menuElement = document.querySelector(".menu");
    if (menuElement) {
      menuElement.classList.remove("menu");
      menuElement.classList.add("menu-hidden");
    }
    const tradeButton = document.querySelector(".trade-button");
    if (tradeButton) {
      tradeButton.classList.remove("trade-button");
      tradeButton.classList.add("trade-button-hidden");
    }
  };

  const handleInputBlur = () => {
    const menuElement = document.querySelector(".menu-hidden");
    if (menuElement) {
      menuElement.classList.remove("menu-hidden");
      menuElement.classList.add("menu");
    }
    const tradeButton = document.querySelector(".trade-button-hidden");
    if (tradeButton) {
      tradeButton.classList.remove("trade-button-hidden");
      tradeButton.classList.add("trade-button");
    }
  };
  
  // const handleBetAmountChange = useCallback((e) => {
  //   const value = e.target.value.replace(/[^0-9.]/g, '');
  //   if (!/^\d*\.?\d*$/.test(value)) return;
  //   if (value.length > 6) return;
    
  //   const parts = value.split('.');
  //   if (parts[1] && parts[1].length > 5) return;

  //   setBetAmount(value);
  //   debouncedSetBetAmount(value);
  // }, [debouncedSetBetAmount]);

  const handleBetAmountChange = useCallback((e) => {
    let value = e.target.value;
    
    // Дозволяємо введення "." або "," для десяткових чисел
    if (value.includes(',')) {
        value = value.replace(',', '.');
    }

    // Спеціальна обробка для введення "0."
    if (value === '.') {
        setBetAmount('0.');
        debouncedSetBetAmount('0.');
        return;
    }

    // Перевірка на валідний формат числа
    if (!/^\d*\.?\d*$/.test(value)) {
        return;
    }

    // Обмеження на довжину цілої частини
    const [whole, decimal] = value.split('.');
    if (whole.length > 6) return;

    // Обмеження на довжину десяткової частини
    if (decimal && decimal.length > 2) {
        value = `${whole}.${decimal.slice(0, 2)}`;
    }

    // Перевірка на нуль на початку, але дозволяємо "0." для десяткових чисел
    if (value.length > 1 && value[0] === '0' && value[1] !== '.') {
        value = value.slice(1);
    }

    setBetAmount(value);
    debouncedSetBetAmount(value);
}, [debouncedSetBetAmount]);

  const resetGameState = useCallback(() => {
    setIsRunning(false);
    setShowFinalMessage(false);
    setCurrentValue(1);
    setGrowthProgress(0);
    setIsGrowthComplete(false);
    setFallProgress(0);
    setHasPickedUpBet(false);
    setBetId(null);
    setError(null);
    setBetPlaced(false);
    setCurrentBet(betAmount);
    betMessageRef.current = "";
    setBetMessage("");
  }, []);

  const connectWebSocket = useCallback(() => {
    try {
      const wsUrl = "wss://" + API_URLS.WEBS_GAME3_CONNECT;

      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        return;
      }

      wsRef.current = new WebSocket(wsUrl);

      const timeoutId = setTimeout(() => {
        if (
          wsRef.current &&
          wsRef.current.readyState === WebSocket.CONNECTING
        ) {
          wsRef.current.close();
          setIsConnected(false);
          setError(t("GamePage.Game3.ConnectError"));
        }
      }, 10000);

      wsRef.current.onopen = (event) => {
        clearTimeout(timeoutId);
        setIsConnected(true);
        setError(null);
      };

      wsRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);

        switch (data.status) {
          case "wait":
            setGameStatus("wait");
            setWaitTime(data.second);
            setSessionId(data.session_id);
            setPickupMessage("");
            setFinalValue(null);
            resetGameState();
            break;
          case "sterted":
            setGameStatus("started");
            setCurrentValue(data.startnumber || 1);
            setGrowthProgress(0);
            setIsRunning(true);
            setShowFinalMessage(false);
            if (betPlaced) {
              setBetMessage(betMessageRef.current);
            }
            break;
          case "prosed":
            setGameStatus("prosed");
            setCurrentValue(data.current);
            setGrowthProgress(Math.min(data.current / maxCoefficient, 1));
            if (betPlaced) {
              setBetMessage(betMessageRef.current);
            }
            break;
          case "ended":
            setGameStatus("ended");
            setIsRunning(false);
            setCurrentValue(data.number);
            setFinalValue(data.number);
            setShowFinalMessage(true);
            setIsGrowthComplete(true);
            setBetMessage("");
            setBetAmount("");
            setDisplayedBetAmount("-");
            setGameResult(null);
            betMessageRef.current = "";
            break;
          default:
        }
      };

      wsRef.current.onclose = (event) => {
        clearTimeout(timeoutId);
        setIsConnected(false);
        setTimeout(connectWebSocket, 5000);
      };

      wsRef.current.onerror = (error) => {
        clearTimeout(timeoutId);
      };
    } catch (error) {}
  }, [resetGameState, t, maxCoefficient]);

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [connectWebSocket]);

  useEffect(() => {
    if (isGrowthComplete) {
      const fallInterval = setInterval(() => {
        setFallProgress((prev) => {
          const newValue = prev + 0.02;
          if (newValue >= 1) {
            clearInterval(fallInterval);
            setShowFinalMessage(true);
            return 1;
          }
          return newValue;
        });
      }, 20);

      return () => clearInterval(fallInterval);
    }
  }, [isGrowthComplete]);

  useEffect(() => {
    const drawGraph = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      const width = canvas.width;
      const height = canvas.height;

      const leftPadding = 25;
      const bottomPadding = 20;
      const rightPadding = 13;
      const topPadding = 12;

      const gridWidth = width - leftPadding - rightPadding;
      const gridHeight = height - topPadding - bottomPadding;

      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = "white";
      ctx.strokeStyle = "#ffffff";
      ctx.lineWidth = 2;

      const cellWidth = gridWidth / columns;
      const cellHeight = gridHeight / rows;

      for (let i = 0; i <= columns; i++) {
        const x = leftPadding + i * cellWidth;
        ctx.beginPath();
        ctx.moveTo(x, topPadding);
        ctx.lineTo(x, height - bottomPadding);
        ctx.stroke();
      }

      for (let i = 0; i <= rows; i++) {
        const y = topPadding + i * cellHeight;
        ctx.beginPath();
        ctx.moveTo(leftPadding, y);
        ctx.lineTo(width - rightPadding, y);
        ctx.stroke();
      }

      ctx.strokeStyle = "#ffae63";
      ctx.lineWidth = 3;
      ctx.beginPath();
      ctx.moveTo(leftPadding, height - bottomPadding);

      const curveFunction = (x) => {
        const progress = x / gridWidth;
        const scaledValue =
          (currentValue - minCoefficient) / (maxCoefficient - minCoefficient);
        const y = Math.pow(progress, 0.6) * scaledValue * gridHeight;
        return height - bottomPadding - y;
      };

      const maxX = gridWidth * growthProgress;

      for (let x = 0; x <= maxX; x += 2) {
        const y = curveFunction(x);
        ctx.lineTo(leftPadding + x, y);
      }

      ctx.stroke();

      if (imageRef.current) {
        const imgWidth = 30;
        const imgHeight = 30;
        let endX, endY;

        if (growthProgress > 0) {
          endX = leftPadding + maxX;
          endY = curveFunction(maxX);
        } else {
          endX = leftPadding;
          endY = height - bottomPadding;
        }

        setEndPosition({ x: endX, y: endY });

        const angle =
          growthProgress > 0
            ? Math.atan2(endY - (height - bottomPadding), endX - leftPadding) -
              Math.PI / 2
            : 0;

        const fallDistance = 70;
        const horizontalShift = 12;

        const fallOffsetY = fallProgress * fallDistance;
        const fallOffsetX =
          Math.sin((fallProgress * Math.PI) / 2) * horizontalShift;

        const rotationAngle = angle + (fallProgress * Math.PI) / 4;

        ctx.save();
        ctx.translate(endX + fallOffsetX, endY + fallOffsetY);
        ctx.rotate(rotationAngle);
        ctx.rotate(Math.PI * 0.4);
        ctx.drawImage(
          imageRef.current,
          -imgWidth / 2,
          -imgHeight / 1.1,
          imgWidth,
          imgHeight
        );

        if (
          gameStatus === "started" ||
          gameStatus === "prosed" ||
          gameStatus === "ended"
        ) {
          ctx.rotate(-rotationAngle - Math.PI * 0.4);
          ctx.font = "bold 22px Oswald";
          ctx.textAlign = "center";

          const gradient = ctx.createLinearGradient(-50, 0, 50, 0);
          gradient.addColorStop(0, "#ffc700");
          gradient.addColorStop(1, "#ffae63");

          ctx.fillStyle = gradient;

          ctx.shadowColor = isGrowthComplete ? "red" : "green";
          ctx.shadowBlur = 10;

          ctx.fillText(`${currentValue.toFixed(2)}x`, 0, imgHeight / 2 + 5);

          ctx.shadowColor = "transparent";
          ctx.shadowBlur = 0;
        }

        ctx.restore();
      }

      ctx.font = "bold 32px Arial";
      ctx.textAlign = "center";
      ctx.textBaseline = "top";

      if (isGrowthComplete) {
        ctx.fillStyle = "red";
      } else {
        ctx.fillStyle = "green";
      }

      ctx.fillStyle = "white";
      ctx.font = "12px Arial";
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      for (let i = 0; i <= columns; i++) {
        const value = `${i}.0X`;
        const x = leftPadding + i * cellWidth;
        ctx.fillText(value, x, height - bottomPadding + 5);
      }
      ctx.textAlign = "right";
      ctx.textBaseline = "middle";
      for (let i = 0; i <= rows; i++) {
        const value = 5 * (rows - i);
        const y = topPadding + i * cellHeight;

        if (i === 6) {
          ctx.fillStyle = "rgba(255, 255, 255, 0)";
        } else {
          ctx.fillStyle = "white";
        }

        ctx.fillText(`${value}s`, leftPadding - 5, y);
      }

      if (topRightImageRef.current) {
        const imgWidth = 30;
        const imgHeight = 40;
        const cellX = width - rightPadding - cellWidth;
        const cellY = topPadding;
        const x = cellX + (cellWidth - imgWidth) / 2;
        const y = cellY + (cellHeight - imgHeight) / 2;
        ctx.drawImage(topRightImageRef.current, x, y, imgWidth, imgHeight);
      }
    };

    drawGraph();
  }, [
    gameStatus,
    columns,
    rows,
    currentValue,
    growthProgress,
    fallProgress,
    isGrowthComplete,
    minCoefficient,
    maxCoefficient,
  ]);

  const startAnimation = () => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify({ action: "start_game" }));
    } else {
    }
  };

  const handleGameAction = async () => {
    if (!isConnected) {
      return;
    }
    playSound();
    if (gameStatus === "wait") {
      if (!betAmount || parseFloat(betAmount) <= 0) {
        return;
      }
      try {
        const response = await axios.post(API_URLS.REACT_APP_GAME3_MAKEBET, {
          tg_user_id: user.user_id,
          bet: parseFloat(betAmount),
          session_id: sessionId,
        });
        if (response.data.status === "done") {
          localStorage.setItem("gameBetId", response.data.bet_id);
          setError(null);
          const message = t("GamePage.Game3.BetConfirmation", {
            amount: betAmount,
          });
          betMessageRef.current = message;
          setPickupMessage("");
          setGameResult(null);
          setDisplayedBetAmount(betAmount);
          setGamePhase("bet");
          setBetPlaced(true);
          const updatedBalance = user.g_token - betAmount;
          updateUser({ g_token: updatedBalance });
          startAnimation();
        } else if (response.data.status === "error") {
          if (response.data.error_code === "552") {
            updateModalState(t("GamePage.SomethingWrong"), true);
          } else if (response.data.error_code === "551") {
            updateModalState(t("GamePage.NotEnoughBalance"), true);
          }
        }
      } catch (error) {
        updateModalState(t("GamePage.UnexpectedError"), true);
      }
    } else if (gameStatus === "started" || gameStatus === "prosed") {
      const storedBetId = localStorage.getItem("gameBetId");
      if (storedBetId) {
        try {
          const response = await axios.post(API_URLS.REACT_APP_GAME3_TAKEBET, {
            bet_id: storedBetId,
          });
          if (response.data.status === "ok") {
            localStorage.removeItem("gameBetId");
            setHasPickedUpBet(true);
            const winAmount = parseFloat(response.data.win);
            const message = t("GamePage.Game3.Congratulations", {
              winAmount: winAmount,
              xValue: currentValue.toFixed(2),
            });
            updateModalState(message, true);
            setGameResult({
              isWin: true,
              oldBalance: user.g_token,
              newBalance: parseFloat(user.g_token) + winAmount,
              winAmount: winAmount,
            });
            setBetMessage("");
            betMessageRef.current = "";
            setError(null);
            const updatedBalance = user.g_token + winAmount;
            updateUser({ g_token: updatedBalance });
            playMoneySound();
          } else {
          }
        } catch (error) {}
      } else {
      }
    }
  };

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.onEvent('viewportChanged', () => {
        window.scrollTo(0, 0);
      });
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (gameStatus === "ended" && !hasPickedUpBet) {
      playFiascoSound();
      setBetMessage("");
      setBetDisplayed(false);
      setBetAmount("");
      setDisplayedBetAmount(0);
      setGameResult(null);
      betMessageRef.current = "";
      localStorage.removeItem("gameBetId");
    }
  }, [gameStatus, hasPickedUpBet, t]);

  const ArrowRight = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="5" y1="12" x2="19" y2="12"></line>
      <polyline points="12 5 19 12 12 19"></polyline>
    </svg>
  );
  const updateModalState = (message, isOpen = true, link = "") => {
    setModalState({ isOpen, message, link });
  };

  useEffect(() => {
    if (gameStatus === "ended" && finalValue !== null) {
      let message;
      if (hasPickedUpBet) {
        message = t("GamePage.Game3.Victory", { value: finalValue.toFixed(2) });
      } else {
        message = t("GamePage.Game3.Defeat", { value: finalValue.toFixed(2) });
      }
      updateModalState(message);
    }
  }, [gameStatus, finalValue, hasPickedUpBet, t]);

  const getButtonStyle = () => {
    if (
      (gameStatus === "started" || gameStatus === "prosed") &&
      !hasPickedUpBet
    ) {
      return {
        background:
          "linear-gradient(105.63deg, #4CAF50 -30.5%, #45a049 66.66%)",
        boxShadow: "0 0 5px #4CAF50, inset 0 0 5px #45a049",
      };
    }
    return {};
  };



  const formatBalanceWithSixDecimals = (balance) => {
    return Number(balance).toFixed(2);
  };

  return (
    <div className="h-full w-full flex flex-col justify-between items-center">
    <div style={styles.gameWrapper}>
      <div className="flex flex-row justify-between" style={{ width: "100%" }}>
        <div className="bet-container flex flex-col w-1/2 justify-end">
          <p className="my-bet-text">
            {gameResult
              ? gameResult.isWin
                ? t("GamePage.Game3.YourWinning")
                : t("GamePage.Game3.YourLoss")
              : t("GamePage.Game3.YourBet")}
          </p>
          <div className="earning-game-my-bet">
            <div className="earning-content-game">
              <div className="earning-icon-container-game">
                <IconCoin className="earning-icon-small-game" />
                <p className="token-game" style={styles.whiteText}>
                  Token
                </p>
              </div>
              <div className="earning-text-container-game">
                <p
                  className="earning-text-game"
                  style={{
                    ...styles.whiteText,
                    color: gameResult
                      ? gameResult.isWin
                        ? "#15FF00"
                        : "#FF0000"
                      : "white",
                  }}
                >
                  {gameResult
                    ? gameResult.isWin
                      ? `+${gameResult.winAmount.toFixed(2)}`
                      : `-${gameResult.lossAmount.toFixed(2)}`
                    : displayedBetAmount || "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="cont-game-3plz">
          <div className="earning-game1">
            <div className="earning-content-game">
              <div className="earning-icon-container-game1">
                <div className="text-font-size">-5.1x</div>
              </div>
              <ArrowRight />
              <div className="earning-text-container-game1">
                <div className="gnome-container">
                  <img src={TopRightImage} alt="Gnome" className="gnome-icon" />
                  <div className="bonus bonus-bottom">100</div>
                </div>
              </div>
            </div>
          </div>
          <div className="earning-game1">
            <div className="earning-content-game">
              <div className="earning-icon-container-game1">
                <div className="text-font-size">-5.5x</div>
              </div>
              <ArrowRight />
              <div className="earning-text-container-game1">
                <div className="gnome-container">
                  <img src={TopRightImage} alt="Gnome" className="gnome-icon" />
                  <div className="bonus bonus-bottom">500</div>
                </div>
              </div>
            </div>
          </div>
          <div className="earning-game1">
            <div className="earning-content-game">
              <div className="earning-icon-container-game1">
                <div className="text-font-size">-6.0x</div>
              </div>
              <ArrowRight />
              <div className="earning-text-container-game1">
                <div className="gnome-container">
                  <img src={TopRightImage} alt="Gnome" className="gnome-icon" />
                  <div className="bonus bonus-bottom">1000</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="game-container" style={{ position: "relative" }}>
        <canvas
            ref={canvasRef}
            width={350}
            height={350}
            style={styles.canvas}
        />
      </div>

      <Notification
        message={winNotification.message}
        isVisible={winNotification.isVisible}
        onClose={() => setWinNotification({ isVisible: false, message: "" })}
      />

      <TradeWarningModal
        key={modalState.isOpen ? "open" : "closed"}
        modalState={modalState}
        background={modalBackground}
        onClose={() => updateModalState("", false)}
      />
    </div>
    <div className="game3-buttom-list">
      <TelegramButton
          className="game3-button"
          style={getButtonStyle()}
          onClick={() => handleGameAction()}
          disabled={!isConnected || gameStatus === "ended" || (betId && gameStatus !== "started" && gameStatus !== "prosed")}
      >
        {!isConnected
            ? t("GamePage.Game3.Connect")
            : gameStatus === "wait"
                ? `${t("GamePage.Game3.StartGame")} (${waitTime.toFixed(1)}s)`
                : (gameStatus === "started" || gameStatus === "prosed") &&
                !hasPickedUpBet
                    ? t("GamePage.Game3.Takethebet")
                    : t("GamePage.Game3.Inprogress")}
      </TelegramButton>
        <div className="earning-game3">
          <div className="earning-content-game">
            <div className="earning-icon-container-game">
              <IconCoin className="earning-icon-small-game" />
              <p className="token-game" style={styles.whiteText}>
                Token
              </p>
            </div>
            <div className="earning-text-container-game">
              <p className="earning-text-game" style={styles.whiteText}>
                {" "}
                {formatBalanceWithSixDecimals(user.g_token)}
              </p>
            </div>
          </div>
        </div>
        <div className="earning-game3">
          <div className="earning-content-game">
            <div className="earning-icon-container-game1">
              <IconCoin className="earning-icon-small-game" />
            </div>
            <div className="earning-content-game">
              <div className="earning-text-container-game">
                <div style={{ position: 'relative', width: '100%' }}>
                <input
    type="text"
    inputMode="decimal"
    value={betAmount}
    onChange={handleBetAmountChange}
    onFocus={handleInputFocus}
    onBlur={handleInputBlur}
    placeholder="0.001"
    style={{
        ...styles.input,
        opacity: gameStatus !== "wait" ? '0.7' : '1'
    }}
    disabled={gameStatus !== "wait"}
    autoComplete="off"
    autoCorrect="off"
    spellCheck="false"
/>
                  {gameStatus !== "wait" && (
                      <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 'inherit',
                            pointerEvents: 'none'
                          }}
                      >
                        <span style={{ fontSize: '18px' }}>🔒</span>
                      </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
  );
};

export default CoreGameThree;