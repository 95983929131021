import React, { useState, useEffect } from "react";
import "../assets/styles/copiedMessageModal.css";
const Toast = ({ message, duration = 1500, copyIconMessage }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    if (isVisible) {
      setAnimationClass("show");
    } else {
      setAnimationClass("hide");
    }
  }, [isVisible]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  if (!isVisible && animationClass !== "hide") return null;

  return (
    <div className={`copied-message-modal ${animationClass}`}>
      <div className="flex flex-col w-1/5 justify-center items-center">
        <img
          src={copyIconMessage}
          className="copy-icon-message"
          alt="Copy Icon"
        />
      </div>
      <div className="flex flex-col w-5/6 justify-center items-center">
        <p className="copy-message-text">{message}</p>
      </div>
    </div>
  );
};

export default Toast;
