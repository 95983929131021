// preloadFunctions.js
// icons
import iconCoin from "../assets/icons/coin.webp";
import iconDolar from "../assets/icons/dolar.webp";
import iconUsdt from "../assets/icons/usdt.webp";
import iconSettings from "../assets/icons/settings.webp";
import iconSwap from "../assets/icons/swap.webp";
import iconCopy from "../assets/icons/copy.webp";
import iconGnom from "../assets/icons/gnom.webp";
import iconNotification from "../assets/icons/notification.webp";
import iconVip from "../assets/icons/vip.webp";
import iconArrow from "../assets/icons/arrow.webp";
import iconCrown from "../assets/icons/crown.webp";
import iconChecked from "../assets/icons/checked.webp";
import iconLevelsCheck from "../assets/icons/levels-check.webp";
import iconLevelsLock from "../assets/icons/levels-lock.webp";
import iconArrowLeft from "../assets/icons/arrow-left.png";//need webp
import iconFile from "../assets/icons/file.webp";
import iconModerationSuccess from "../assets/icons/moderation-success.webp";
import iconModerationInProgress from "../assets/icons/moderation-in-progress.webp";
import iconModerationFail from "../assets/icons/moderation-fail.webp";
import iconAdWatchRedirect from "../assets/icons/ad-watch-only.webp";
import iconAdWatchOnly from "../assets/icons/ad-watch-redirect.webp";
import iconBin from "../assets/icons/bin.webp";

import modalBackground from "../assets/images/fairy-modal-background.png";

//navbar
import logo from "../assets/images/logo.png";

//socials-images
import socialBackground from "../assets/images/Socials/background-socials.webp";

//partners-images
import partnersBackground from "../assets/images/Partners/background-partners.webp";

//games-images
import gamesBackground from "../assets/images/Games/background-games.webp";
import gameIconOne from "../assets/icons/pocket.webp";

//referral-images
import referralBackground from "../assets/images/Refferals/background-refferals.webp";

//trade-images
import tradeBackground from "../assets/images/Trade/background-trade.webp";

//rules-images
import rulesBackground from "../assets/images/Rules/background-rules.webp";

//levels-images
import levelsBackground from "../assets/images/Levels/background.webp";

//page1
import tempPage1Back from "../assets/images/background-page1.png";


import genderMale from "../assets/images/gender-male.webp";
import genderFemale from "../assets/images/gender-female.webp";

import { API_URLS } from "../config/config";
import axios from "axios";

// Функция для предзагрузки изображения
const preloadImage = (src, name) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(src);
    };
    img.onerror = reject;
    img.src = src;
  });
};

export const preloadFullImgProject = async () => {
  const allImages = {
    navbar: { logo: logo, iconSettings: iconSettings,  genderMale: genderMale, genderFemale: genderFemale, iconCrown:iconCrown },
    main:{
      rewardBackground:modalBackground,
      iconAdWatchOnly:iconAdWatchOnly,
      iconAdWatchRedirect:iconAdWatchRedirect
    },
    socials: { 
      background: socialBackground,
      iconArrow:iconArrow,
      iconChecked:iconChecked,
      iconDolar:iconDolar
     },
    partners: { background: partnersBackground },
    rules: { background: rulesBackground },
    referral: {
      background: referralBackground,
      genderMale: genderMale,
      genderFemale: genderFemale,
      iconCoin: iconCoin,
      iconNotification: iconNotification,
    },
    trade: {
      background: tradeBackground,
      copyIconMessage: iconNotification,
      iconCoin: iconCoin,
      iconDolar: iconDolar,
      iconUsdt: iconUsdt,
      iconCopy: iconCopy,
      swapIcon: iconSwap,
      genderMale: genderMale,
      genderFemale: genderFemale,
      iconGnom: iconGnom,
      iconVip: iconVip,
      iconArrow:iconArrow
    },
    games:{
      background: gamesBackground,
      gameicon: gameIconOne
    },
    levels:{
      background:levelsBackground,
      iconLevelsCheck:iconLevelsCheck,
      iconLevelsLock:iconLevelsLock,
      iconArrowLeft:iconArrowLeft
    }
  };

  const preloadPromises = Object.entries(allImages).flatMap(
    ([pageName, images]) =>
      Object.entries(images).map(([imgName, src]) =>
        preloadImage(src, `${pageName}_${imgName}`)
      )
  );

  await Promise.all(preloadPromises);

  return allImages;
};

const loadAdverts = async (userId) => {
  try {
    const data = {
      userId: userId,
    };

    const response = await axios.post(API_URLS.GET_ALL_ACTIVE_BANNER_ADVERTS_ENDPOINT, data);
   
    if (Array.isArray(response.data.active_banner_adverts)) {
      return {
        adverts: response.data.active_banner_adverts.map((advert) => ({
          id: advert.id,
          file_path: advert.file_path,
          url: advert.url,
          name: advert.name,
          g_tokens_per_day: advert.g_tokens_per_day
        })),
        total_g_tokens: response.data.total_g_tokens
      };

    }
    return { adverts: [], total_g_tokens: 0 };
  } catch (error) {
    return { adverts: [], total_g_tokens: 0 };
  }
};
const loadSocialsData = async (userId) => {
  try {
    const response = await axios.post(API_URLS.GET_USER_SOCIALS, {
      userId: userId,
    });
    if (Array.isArray(response.data.tasks)) {
      return response.data.tasks.map((task) => ({
        id:task.id,
        name: task.name,
        amount: task.amount,
        link: task.link,
        photo: task.photo,
        isCompleted:task.is_completed
      }));
    }
  } catch (error) {
    return [];
  }
};
const loadAdsets = async (userId) => {
  try {
    const response = await axios.post(API_URLS.GET_USER_ADSETS, {
      userId: userId,
    });

    if (Array.isArray(response.data.adsets)) {
      return  response.data.adsets.map((adset) => ({
        id: adset.id,
        name: adset.name,
        current_clicks_number: adset.current_clicks_number,
        display_duration: adset.display_duration,
        fullscreen: adset.fullscreen,
        banner: adset.banner,
        is_active:adset.is_active,
        g_tokens_per_day: adset.g_tokens_per_day,
        is_verified: adset.is_verified,
        relevant_regions: adset.relevant_regions,
        total_active_duration: adset.total_active_duration,
        last_started_at: adset.last_started_at,
        will_be_displayed_until: adset.will_be_displayed_until,
      }));
    }
  } catch (error) {
    return [];
  }
};
const loadreferralData = async (userId) => {
  try {
    const response = await axios.get(API_URLS.GET_USER_REFERRALS, {
      params: { userId: userId },
      headers: {
        Accept: "application/json",
        "ngrok-skip-browser-warning": "true",
      },
    });
    if (Array.isArray(response.data.referrals)) {
      return response.data.referrals.map((ref) => ({
        tg_username: ref.tg_username,
        gender: ref.gender,
        rank: ref.rank,
        referral_reward: ref.referral_reward,
      }));
    }
  } catch (error) {
    return [];
  }
};

const loadReffLink = async (userId) => {
  try {
    const response = await axios.post(API_URLS.GET_FREN_LINK, {
      userId: userId,
    });
    return response.data.link;
  } catch (error) {
    return [];
  }
};

const loadRegions = async () => {
  try {
    const response = await axios.get(API_URLS.GET_REGIONS, {
      headers: {
        Accept: "application/json",
        "ngrok-skip-browser-warning": "true",
      },
    });
    if (Array.isArray(response.data.regions)) {
      return response.data.regions.map((region) => ({
        id: region.id,
        name: region.name,
        code: region.code
      }));
    }
  } catch (error) {
    return [];
  }
};
const loadPartnersData = async (userId) => {
  try {
    const response = await axios.post(API_URLS.GET_USER_PARTNERS, {
      userId: userId,
    });
    if (Array.isArray(response.data.tasks)) {
      return response.data.tasks.map((task) => ({
        id:task.id,
        name: task.name,
        amount: task.amount,
        link: task.link,
        photo: task.photo,
        action: task.button_type,
        isCompleted:task.is_completed
      }));
    }
  } catch (error) {
    return [];
  }
};

const loadTransactionsData = async (userId) => {
  try {
    const response = await axios.post(API_URLS.EXECUTE_TRANSACTIONS, {
      userId: userId,
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const loadTransaferFeeData = async () => {
  try {
    const response = await axios.get(API_URLS.GET_TRANSFER_FEE, {
      headers: {
        Accept: "application/json",
        "ngrok-skip-browser-warning": "true",
      },
    });
    if (response.data.transfer_fee) {
      return response.data.transfer_fee;
    } else {
      throw new Error("TRANSFER Unexpected response structure from backend");
    }
  } catch (error) {
  }
};
export const preloadAllData = async (setProgress, userId) => {
  const totalSteps = 4; 
  let completedSteps = 0;

  const updateProgress = () => {
    completedSteps++;
    const progress = Math.round((completedSteps / totalSteps) * 100);
    setProgress(progress);
  };

  try {
    const navbarData = await loadAdverts(userId);
    updateProgress();

    const socialsData = await loadSocialsData(userId);
    updateProgress();

    const partnersData = await loadPartnersData(userId);
    updateProgress();

    const referralData = await loadreferralData(userId);
    updateProgress();

    const referralLinkData = await loadReffLink(userId);
    updateProgress();

    const transferFeeData = await loadTransaferFeeData();
    updateProgress();

    const transactionsData = await loadTransactionsData(userId);
    updateProgress();

    const adSetsData = await loadAdsets(userId);
    updateProgress();

    const regions= await loadRegions();
    updateProgress();

    const allImages = await preloadFullImgProject();
    updateProgress();

    return {
      navbar: {
        adverts: navbarData,
        settingsIcon: allImages.navbar.iconSettings,
        genderMale: allImages.navbar.genderMale,
        genderFemale: allImages.navbar.genderFemale,
        iconCrown:allImages.navbar.iconCrown
      },
      main: {
        modalBackground: allImages.main.rewardBackground,
        iconAdWatchOnly: allImages.main.iconAdWatchOnly,
        iconAdWatchRedirect: allImages.main.iconAdWatchRedirect,
        iconGnom: iconGnom
      },
      socials: {
        data: socialsData,
        backgroundImage: allImages.socials.background,
        iconArrow:allImages.socials.iconArrow,
        iconChecked:allImages.socials.iconChecked,
        iconDolar:allImages.socials.iconDolar
      },
      rules: {
        backgroundImage: allImages.rules.background,
      },
      partners: {
        data: partnersData,
        backgroundImage: allImages.partners.background,
      },
      referral: {
        data: referralData,
        refLink:referralLinkData,
        backgroundImage: allImages.referral.background,
        genderMale: allImages.referral.genderMale,
        genderFemale: allImages.referral.genderFemale,
        iconCoin: allImages.referral.iconCoin,
        iconNotification: iconNotification,
      },
      trade: {
        background: allImages.trade.background,
        data: {
          transferFee: transferFeeData,
          transactions: {
            swaps: transactionsData.swaps || [],
            transfers: transactionsData.transfers || [],
          },
        },
        iconCoin: iconCoin,
        iconDolar: iconDolar,
        iconUsdt: iconUsdt,
        iconGnom: iconGnom,
        iconCopy: iconCopy,
        genderMale: genderMale,
        genderFemale: genderFemale,
        iconSwap: iconSwap,
        iconVip: iconVip,
        iconArrow:iconArrow,
        modalBackground:modalBackground,
        iconNotification: iconNotification,
      },
      adOffice: {
        adsets: adSetsData,
        regions:regions,
        background: allImages.trade.background,
        iconArrow:iconArrow,
        iconCoin: iconCoin,
        iconFile:iconFile,
        iconModerationSuccess:iconModerationSuccess,
        iconModerationInProgress:iconModerationInProgress,
        iconModerationFail:iconModerationFail,
        iconArrowLeft:iconArrowLeft,
        modalBackground:modalBackground,
        iconBin:iconBin
      },
      games:{
        background:gamesBackground,
        gameicon: gameIconOne
      },
      levels:{
        background:allImages.levels.background,
        iconLevelsCheck:allImages.levels.iconLevelsCheck,
        iconLevelsLock:allImages.levels.iconLevelsLock,
        iconArrowLeft:allImages.levels.iconArrowLeft,
        backgroundMain:tempPage1Back
      }
    };
  } catch (error) {
    throw error;
  }
};