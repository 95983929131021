// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.animated-content {
    transition: opacity 0.3s ease-in-out;
}

.animated-content.fade-out {
    opacity: 0;
}

.animated-content.fade-in {
    opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/upgrade/AnimatedText.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd","sourcesContent":[".animated-content {\r\n    transition: opacity 0.3s ease-in-out;\r\n}\r\n\r\n.animated-content.fade-out {\r\n    opacity: 0;\r\n}\r\n\r\n.animated-content.fade-in {\r\n    opacity: 1;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
