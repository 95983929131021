import React, { useEffect, useRef, useState, useCallback } from "react";
import "../../assets/styles/games/game_modal.css";
import iconArrowLeft from "../../assets/icons/arrow-left.png";
import { useTranslation } from "react-i18next";
import { useAudio } from "../../contexts/AudioContext";
import clickSoundMp3 from "../../assets/sounds/menu-button-click.mp3";

const GameHelpModal = ({
  isOpen,
  onClose,
  levelImageUrl,
  gameName,
  gameLevel,
  gameRules,
}) => {
  const modalRef = useRef(null);
  const [animationClass, setAnimationClass] = useState("");
  const { t } = useTranslation();
  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setAnimationClass("slide-in");
      document.body.classList.add("modal-open", "slide-in");
      document.body.classList.remove("slide-out");
      document.addEventListener("mousedown", handleOverlayClick);
    } else {
      setAnimationClass("slide-out");
      document.body.classList.remove("modal-open", "slide-in");
      document.body.classList.add("slide-out");
      document.removeEventListener("mousedown", handleOverlayClick);
    }

    return () => {
      document.body.classList.remove("slide-in", "slide-out");
      document.removeEventListener("mousedown", handleOverlayClick);
    };
  }, [isOpen]);

  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const clickResponse = await fetch(clickSoundMp3);
          const clickArrayBuffer = await clickResponse.arrayBuffer();
          const clickAudioBuffer =
            await audioContextRef.current.decodeAudioData(clickArrayBuffer);

          clickAudioBufferRef.current = clickAudioBuffer;

          setIsAudioInitialized(true);
        } catch (error) {}
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    if (
      isAudioInitialized &&
      audioContextRef.current &&
      clickAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  const getImageUrl = (imagePath) => {
    try {
      return require(`../../${imagePath}`);
    } catch (err) {
      return null;
    }
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const handleCloseClick = () => {
    playSound();
    onClose();
  };

  const imageUrl = getImageUrl(levelImageUrl);

  return (
    <div
      className={`about-level-modal-overlay ${isOpen ? "active" : ""}`}
      onClick={handleOverlayClick}
    >
      <div
        ref={modalRef}
        className={`about-level-modal ${animationClass} current-level-modal`}
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="about-level-modal-content">
          <div className="w-full flex flex-row">
            <button
              className="level-modal-close-button"
              onClick={handleCloseClick}
            >
              <img src={iconArrowLeft} alt="<" />
              <p className="level-modal-close-text">
                {t("GamePage.GameModal.Back")}
              </p>
            </button>
          </div>
          <div className="game-modal-main">
            <div className="game-modal-cont-text">
              <p className="game-modal-text text-rules">
                {t("GamePage.GameModal.GameRule")}
              </p>
              <div className="flex-1 min-w-0 flex justify-start">
                <p className="ref-nick">
                  <span className="ref-lvl ml-1 w-10 flex items-center justify-center">
                    LVL {gameLevel}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="game-modal-name">
            <p>{gameName}</p>
          </div>
          <div className="game-modal-rules">
            <p>{gameRules}</p>
          </div>
          <div className="game-section-btn">
            <button onClick={handleCloseClick}>
              {t("GamePage.GameModal.Next")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameHelpModal;
