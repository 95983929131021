import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  } from "react";
  import TelegramButton from "./tgbtn";
  import axios from "axios";
  import { debounce } from 'lodash';
  import TradeViewChart from "react-crypto-chart";
  import "../../assets/styles/games/game_core4.css";
  import { useAudio } from "../../contexts/AudioContext";
  import { ReactComponent as IconCoin } from "../../assets/icons/coin.svg";
  import { useTranslation } from "react-i18next";
  import PocketoptionSmal from "../../assets/icons/pocketoption.webp";
  import { useUser } from "../../contexts/UserContext";
  import { API_URLS } from "../../config/config";
  import PartnersModal from "../../components/games/PartnersModal";
  import TradeWarningModal from "../../components/TradeWarningModal";
  import modalBackground from "../../assets/images/fairy-modal-background.png";
  import iconArrowLeft from "../../assets/icons/arrow-left.png";
  import clickSoundMp3 from "../../assets/sounds/menu-button-click.mp3";
  import moneySoundMp3 from "../../assets/sounds/bonus-money-sound.mp3";
  import fiascoSoundMp3 from "../../assets/sounds/fiasco.mp3";
  
  function Four() {
  const [chartColor] = useState("#1E53E5");
  const [betAmount, setBetAmount] = useState("");
  const { user, updateUser } = useUser();
  const [localBalance, setLocalBalance] = useState(user.g_token);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [gameResult, setGameResult] = useState(null);
  const [btcUsdtPrice, setBtcUsdtPrice] = useState("0.00");
  const [timer, setTimer] = useState(180);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [isAdModalOpen, setIsAdModalOpen] = useState(false);
  const [chartDimensions, setChartDimensions] = useState(null);
  const [modalState, setModalState] = useState({
    isOpen: false,
    message: "",
    link: "",
  });
  const [exchangeLinks, setExchangeLinks] = useState({});
  const [isLinksLoading, setIsLinksLoading] = useState(true);
  const [showBetPlaced, setShowBetPlaced] = useState(false);
  const [betStartPrice, setBetStartPrice] = useState(null);
  const [betEndPrice, setBetEndPrice] = useState(null);
  const [userBet, setUserBet] = useState(null);
  const [betDirection, setBetDirection] = useState(null);
  const [betResult, setBetResult] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(null);
  const timerRef = useRef(null);
  const [startPrice, setStartPrice] = useState(null);
  const [priceColor, setPriceColor] = useState("white");
  const { t } = useTranslation();
  const wsRef = useRef(null);
  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);
  const moneyAudioBufferRef = useRef(null);
  const fiascoAudioBufferRef = useRef(null);
  
  const playSound = useCallback(() => {
    if (
        isAudioInitialized &&
        audioContextRef.current &&
        clickAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;
  
      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
  
      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);
  
      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);
  
  const playMoneySound = useCallback(() => {
    if (
        isAudioInitialized &&
        audioContextRef.current &&
        moneyAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = moneyAudioBufferRef.current;
  
      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
  
      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);
  
      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);
  const playFiascoSound = useCallback(() => {
    if (isAudioInitialized && audioContextRef.current && fiascoAudioBufferRef.current) {
        const source = audioContextRef.current.createBufferSource();
        source.buffer = fiascoAudioBufferRef.current;
        
        const gainNode = audioContextRef.current.createGain();
        gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
        
        source.connect(gainNode);
        gainNode.connect(audioContextRef.current.destination);
        
        source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);
  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();
  
          const clickResponse = await fetch(clickSoundMp3);
          const clickArrayBuffer = await clickResponse.arrayBuffer();
          clickAudioBufferRef.current =
              await audioContextRef.current.decodeAudioData(clickArrayBuffer);
  
          const moneyResponse = await fetch(moneySoundMp3);
          const moneyArrayBuffer = await moneyResponse.arrayBuffer();
          moneyAudioBufferRef.current =
              await audioContextRef.current.decodeAudioData(moneyArrayBuffer);
  
              const fiascoResponse = await fetch(fiascoSoundMp3);
                const fiascoArrayBuffer = await fiascoResponse.arrayBuffer();
                fiascoAudioBufferRef.current = await audioContextRef.current.decodeAudioData(fiascoArrayBuffer);
  
          setIsAudioInitialized(true);
        } catch (error) {
        }
      }
    };
  
    initializeAudio();
  }, [isAudioInitialized]);
  
  useEffect(() => {
    const fetchExchangeLinks = async () => {
      setIsLinksLoading(true);
      try {
        const response = await axios.post(API_URLS.REACT_APP_GAME4_LINKSS);
        if (response.data && typeof response.data === "object") {
          setExchangeLinks(response.data);
        } else {
        }
      } catch (error) {
      } finally {
        setIsLinksLoading(false);
      }
    };
  
    fetchExchangeLinks();
  }, []);
  
  const saveGameDataToLocalStorage = (data) => {
    localStorage.setItem('game4Data', JSON.stringify(data));
  };
  
  const getGameDataFromLocalStorage = () => {
    const data = localStorage.getItem('game4Data');
    return data ? JSON.parse(data) : null;
  };
  
  const clearGameDataFromLocalStorage = () => {
    localStorage.removeItem('game4Data');
  };
  
  
  const ArrowUp = ({ color }) => (
      <svg
          width="10"
          height="9"
          viewBox="0 0 10 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9 8.5L9 0.5M9 0.5L1 0.5M9 0.5L1 8.5" stroke={color} />
      </svg>
  );
  
  const ArrowDown = ({ color }) => (
      <svg
          width="10"
          height="9"
          viewBox="0 0 10 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 8.5H9M9 8.5V0.5M9 8.5L1 0.5" stroke={color} />
      </svg>
  );
  
  const getBetColor = () => {
    switch (betDirection) {
      case "up":
        return "#15FF00";
      case "down":
        return "#FF0000";
      default:
        return "white";
    }
  };
  
  const handlePocketoptionClick = useCallback(() => {
    playSound();
    setIsAdModalOpen(true);
  }, [playSound]);
  
  const getEndPriceColor = () => {
    if (!betEndPrice || !betStartPrice) return "white";
    return parseFloat(betEndPrice) > parseFloat(betStartPrice)
        ? "#15FF00"
        : "#FF0000";
  };
  
  const EndPriceArrow = () => {
    if (!betEndPrice || !betStartPrice) return null;
    const color = getEndPriceColor();
    return parseFloat(betEndPrice) > parseFloat(betStartPrice) ? (
        <ArrowUp color={color} />
    ) : (
        <ArrowDown color={color} />
    );
  };
  
  const updateModalState = (message, isOpen = true, link = "") => {
    setModalState({ isOpen, message, link });
  };
  
  
  const styles = useMemo(
      () => ({
        input: {
          width: "100%",
          padding: "5px",
          color: "white",
          fontSize: "18px",
          backgroundColor: "transparent",
          borderRadius: "4px",
          outline: "none",
        },
        whiteText: {
          color: "white",
        },
        resultText: {
          color: "white",
          textAlign: "center",
          marginTop: "10px",
        },
        timerText: {
          color: "white",
          textAlign: "center",
          fontSize: "24px",
          marginTop: "10px",
        },
        betPlacedMessage: {
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          color: "white",
          padding: "20px",
          borderRadius: "10px",
          zIndex: 1000,
          transition: "opacity 0.5s ease-in-out",
        },
        priceColumns: {
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
          padding: "0 10px",
        },
        priceColumn: {
          flex: 1,
          textAlign: "center",
          color: "white",
        },
        currentPrice: {
          color: priceColor,
          transition: "color 0.3s ease",
        },
      }),
      [priceColor]
  );
  
  const debouncedSetBetAmount = useCallback(
    debounce((value) => {
      setBetAmount(value);
    }, 100),
    []
  );
  
  const handleInputFocus = () => {
    const menuElement = document.querySelector(".menu");
    if (menuElement) {
      menuElement.classList.remove("menu");
      menuElement.classList.add("menu-hidden");
    }
    const tradeButton = document.querySelector(".trade-button");
    if (tradeButton) {
      tradeButton.classList.remove("trade-button");
      tradeButton.classList.add("trade-button-hidden");
    }
  };
  
  const handleInputBlur = () => {
    const menuElement = document.querySelector(".menu-hidden");
    if (menuElement) {
      menuElement.classList.remove("menu-hidden");
      menuElement.classList.add("menu");
    }
    const tradeButton = document.querySelector(".trade-button-hidden");
    if (tradeButton) {
      tradeButton.classList.remove("trade-button-hidden");
      tradeButton.classList.add("trade-button");
    }
  };
  
  
  const handleBetChange = useCallback((e) => {
    let value = e.target.value;
    
    if (value.includes(',')) {
        value = value.replace(',', '.');
    }
  
    if (value === '.') {
        setBetAmount('0.');
        debouncedSetBetAmount('0.');
        return;
    }
  
    if (!/^\d*\.?\d*$/.test(value)) {
        return;
    }
  
    const [whole, decimal] = value.split('.');
    if (whole.length > 6) return;
  
    if (decimal && decimal.length > 2) {
        value = `${whole}.${decimal.slice(0, 2)}`;
    }
  
    if (value.length > 1 && value[0] === '0' && value[1] !== '.') {
        value = value.slice(1);
    }
  
    setBetAmount(value);
    debouncedSetBetAmount(value);
  }, [debouncedSetBetAmount]);
  
  // const handleBetChange = useCallback((e) => {
  //   const value = e.target.value.replace(/[^0-9.]/g, '');
  //   if (!/^\d*\.?\d*$/.test(value)) return;
  //   if (value.length > 6) return;
    
  //   const parts = value.split('.');
  //   if (parts[1] && parts[1].length > 5) return;
  
  //   setBetAmount(value);
  //   debouncedSetBetAmount(value);
  // }, [debouncedSetBetAmount]);
  
  useEffect(() => {
    const setInitialChartDimensions = () => {
      const height = window.innerHeight;
      let chartHeight, chartWidth;
  
      if (height <= 667) {
        chartHeight = '20vh';
      } else if (height <= 844) {
        chartHeight = '30vh';
      } else if (height <= 926) {
        chartHeight = '35vh';
      } else {
        chartHeight = '40vh';
      }
  
      const aspectRatio = window.innerWidth / window.innerHeight;
      if (aspectRatio < 0.5) {
        chartWidth = '95vw';
      } else if (aspectRatio < 0.75) {
        chartWidth = '90vw';
      } else {
        chartWidth = '85vw';
      }
  
      setChartDimensions({ height: chartHeight, width: chartWidth });
    };
  
    setInitialChartDimensions();
  
    window.addEventListener('orientationchange', setInitialChartDimensions);
  
    return () => {
      window.removeEventListener('orientationchange', setInitialChartDimensions);
    };
  }, []);
  
  const closeWebSocket = useCallback(() => {
    if (wsRef.current) {
      wsRef.current.close();
    }
  }, []);
  
  const stopTimer = useCallback(() => {
    setIsTimerRunning(false);
    if (timerRef.current) {
      cancelAnimationFrame(timerRef.current);
      timerRef.current = null;
    }
    setTimer(180);
  }, []);
  
  const waitForSocketConnection = (socket, callback) => {
    setTimeout(() => {
      if (socket.readyState === WebSocket.OPEN) {
        callback();
      } else {
        waitForSocketConnection(socket, callback);
      }
    }, 100);
  };
  const startTimer = useCallback((initialTime = 180) => {
    // Очищаємо попередній таймер
    if (timerRef.current) {
      cancelAnimationFrame(timerRef.current);
      timerRef.current = null;
    }
    
    setTimer(initialTime);
    setIsTimerRunning(true);
  
    const startTime = Date.now();
    const updateTimer = () => {
      const elapsedTime = (Date.now() - startTime) / 1000;
      const remainingTime = Math.max(initialTime - elapsedTime, 0);
      
      if (remainingTime <= 0) {
        setIsTimerRunning(false);
        if (timerRef.current) {
          cancelAnimationFrame(timerRef.current);
          timerRef.current = null;
        }
        return;
      }
      
      setTimer(remainingTime);
      timerRef.current = requestAnimationFrame(updateTimer);
    };
  
    timerRef.current = requestAnimationFrame(updateTimer);
  }, []);

  
  const checkLastGame = useCallback(async (userId) => {
    try {
      const response = await axios.post(API_URLS.REACT_APP_GAME4_GET_LAST_GAME, {
        tg_user_id: userId
      });

      if (response.data.status === "ok" && response.data.is_exist) {
        const resultMessage = response.data.is_win
          ? t("GamePage.Game4.ResultMessage.Win", {
              winAmount: (Number(response.data.bet) * 2).toFixed(2),
            })
          : t("GamePage.Game4.ResultMessage.Lose", {
              winAmount: response.data.bet,
            });
            const resultAmount = response.data.is_win
            ? `+${(response.data.bet * 2).toFixed(2)}`
            : `-${response.data.bet.toFixed(2)}`;
            setBetResult(response.data.is_win ? "win" : "lose");
            setUserBet(resultAmount);
            
            const finalPrice = response.data.btcend;
            setBetEndPrice(finalPrice);

        updateModalState(resultMessage, true);

        // Play appropriate sound
        if (response.data.is_win) {
          playMoneySound();
        } else {
          playFiascoSound();
        }
        clearGameDataFromLocalStorage();
      }
    } catch (error) {
      console.error('Error checking last game:', error);
    }
  }, [t, playMoneySound, playFiascoSound]);

  
  
  useEffect(() => {
    const checkGameStatus = async () => {
      try {
        const response = await axios.post(API_URLS.REACT_APP_GAME4_CHECK, {
          tg_user_id: user.user_id
        });
  
        if (response.data.status === "ok") {
          if (response.data.is_exist === false) {
            console.log("AAA");
            await checkLastGame(user.user_id);
            return;
          }
          const gameData = getGameDataFromLocalStorage();
  
          if (gameData) {
            setIsRequestPending(true);
            setBetDirection(gameData.betDirection);
            setBetStartPrice(gameData.startPrice);
            setUserBet(gameData.betAmount);
  
            if (gameData.g_token) {
              updateUser({ g_token: gameData.g_token });
            }
  
            const remainingTime = Math.max(180 - (Date.now() - gameData.startTime) / 1000, 0);
            if (remainingTime > 0) {
              setTimer(remainingTime);
              setIsTimerRunning(true);
              startTimer(remainingTime);
  
              const personalGameUrl = `wss://${API_URLS.GAME4_PERSONAL_GAME}/${user.user_id}/0/up/`;
  
              const createWebSocketConnection = (retriesLeft = 8) => {
                if (wsRef.current) {
                  wsRef.current.close();
                }
  
                wsRef.current = new WebSocket(personalGameUrl);
  
                wsRef.current.onmessage = (event) => {
                  const personalGameResponse = JSON.parse(event.data);
  
                  if (personalGameResponse.status === "wait") {
                    setTimer(personalGameResponse.second);
                    setIsTimerRunning(true);
                    startTimer(personalGameResponse.second);
                  }
                  else if (personalGameResponse.status === "result") {
                    // Зупиняємо таймер
                    setIsTimerRunning(false);
                    if (timerRef.current) {
                      cancelAnimationFrame(timerRef.current);
                      timerRef.current = null;
                    }
                    
                    const originalBet = Number(gameData.betAmount);
                    const isWin = personalGameResponse.is_win;
                    setBetResult(isWin ? "win" : "lose");
  
                    const finalPrice = personalGameResponse.new;
                    setBetEndPrice(finalPrice);
  
                    const resultAmount = isWin
                      ? `+${(originalBet * 2).toFixed(2)}`
                      : `-${originalBet.toFixed(2)}`;
                    setUserBet(resultAmount);
  
                    // Оновлюємо баланс користувача
                    updateUser({ g_token: personalGameResponse.new_g_token });
  
                    // Показуємо модальне вікно з результатом
                    const resultMessage = isWin
                      ? t("GamePage.Game4.ResultMessage.Win", {
                          winAmount: (originalBet * 2).toFixed(2),
                        })
                      : t("GamePage.Game4.ResultMessage.Lose", {
                          winAmount: originalBet.toFixed(2),
                        });
                    updateModalState(resultMessage, true);
  
                    // Програємо відповідний звук
                    if (isWin) {
                      playMoneySound();
                    } else {
                      playFiascoSound();
                    }
  
                    // Розблоковуємо кнопки
                    setIsLoading(false);
                    setIsRequestPending(false);
  
                    // Очищаємо дані гри
                    //clearGameDataFromLocalStorage();
  
                    // Закриваємо WebSocket
                    if (wsRef.current) {
                      wsRef.current.close();
                      wsRef.current = null;
                    }
                  }
                };
  
                wsRef.current.onerror = (error) => {
                  console.error('WebSocket error:', error);
                  setIsLoading(false);
                  setIsRequestPending(false);
                };
  
                wsRef.current.onclose = () => {
                  if (retriesLeft > 0) {
                    setTimeout(() => {
                      createWebSocketConnection(retriesLeft - 1);
                    }, 1000);
                  } else {
                    setIsLoading(false);
                    setIsRequestPending(false);
                  }
                };
              };
  
              createWebSocketConnection();
            } else {
              clearGameDataFromLocalStorage();
              setIsLoading(false);
              setIsRequestPending(false);
            }
          }
        }
      } catch (error) {
        console.error('Error checking game status:', error);
        setIsLoading(false);
        setIsRequestPending(false);
      }
    };
  
    checkGameStatus();
  }, [updateUser, t, startTimer, playMoneySound, playFiascoSound]);
  
  
  
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        cancelAnimationFrame(timerRef.current);
        timerRef.current = null;
      }
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }
    };
  }, []);
  
  useEffect(() => {
  }, [currentPrice]);
  
  useEffect(() => {
    let timeout;
    if (showBetPlaced) {
      timeout = setTimeout(() => {
        setShowBetPlaced(false);
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [showBetPlaced]);
  
  useEffect(() => {
    const savedGameData = getGameDataFromLocalStorage();
    if (savedGameData) {
      setBetDirection(savedGameData.betDirection);
      setBetStartPrice(savedGameData.startPrice);
      setUserBet(savedGameData.betAmount);
      if (savedGameData.betResult) {
        setBetResult(savedGameData.betResult);
      }
      if (savedGameData.endPrice) {
        setBetEndPrice(savedGameData.endPrice);
      }
    }
  }, []);
  
  const placeBet = useCallback(async (isUp) => {
    playSound();
    let currentBtcPrice;
    if (isRequestPending || !betAmount || betAmount.trim() === "") {
      return;
    }
    setError(null);
    setGameResult(null);
    setIsLoading(true);
    setIsRequestPending(true);
  
    try {
      const response = await axios.post(API_URLS.REACT_APP_GAME4_GETBTCPRICE, {
        tg_user_id: user.user_id,
      });
      currentBtcPrice = response.data.cost;
      setCurrentPrice(currentBtcPrice);
  
      const direction = isUp ? 'up' : 'down';
      setBetDirection(direction);
      setBetStartPrice(currentBtcPrice);
      setBetEndPrice(null);
      setBetResult(null); // Скидаємо попередній результат
      setUserBet(betAmount);
  
      setPriceColor("white");
      startTimer();
  
      const bet = parseFloat(betAmount);
      const newBalance = user.g_token - bet;
  
      updateUser({ g_token: newBalance.toFixed(2) });
  
      saveGameDataToLocalStorage({
        betAmount: bet,
        betDirection: direction,
        startPrice: currentBtcPrice,
        startTime: Date.now(),
        g_token: newBalance.toFixed(2)
      });
  
      if (wsRef.current) {
        wsRef.current.close();
      }
  
      const personalGameUrl = `wss://${API_URLS.GAME4_PERSONAL_GAME}/${user.user_id}/${bet}/${direction}/`;
  
      wsRef.current = new WebSocket(personalGameUrl);
      
      const handleWebSocketMessage = (event) => {
        try {
          const response = JSON.parse(event.data);
          
          switch(response.status) {
            case "wait":
              if (!isTimerRunning) {
                setTimer(response.second);
                setIsTimerRunning(true);
                startTimer(response.second);
              }
              break;
            
            case "process":
              setCurrentPrice(response.current_price);
              break;
            
            case "result":
              stopTimer(); // Зупиняємо таймер
              
              const gameData = getGameDataFromLocalStorage();
              const originalBet = gameData ? Number(gameData.betAmount) : bet;
      
              const finalPrice = response.new;
              setBetEndPrice(finalPrice);
      
              const isWin = response.is_win;
              setBetResult(isWin ? "win" : "lose");
      
              const resultAmount = isWin
                ? `+${(originalBet * 2).toFixed(2)}`
                : `-${originalBet.toFixed(2)}`;
              setUserBet(resultAmount);
      
              updateUser({ g_token: response.new_g_token });
      
              if (isWin) {
                playMoneySound();
              } else {
                playFiascoSound();
              }
      
              const resultMessage = isWin
                ? t("GamePage.Game4.ResultMessage.Win", {
                    winAmount: (originalBet * 2).toFixed(2),
                  })
                : t("GamePage.Game4.ResultMessage.Lose", {
                    winAmount: originalBet.toFixed(2),
                  });
              updateModalState(resultMessage, true);
      
              clearGameDataFromLocalStorage();
              setIsLoading(false);
              setIsRequestPending(false);
      
              // Очищаємо WebSocket після отримання результату
              if (wsRef.current) {
                wsRef.current.close();
                wsRef.current = null;
              }
              break;
            
            default:
              break;
          }
        } catch (error) {
          console.error('Error processing WebSocket message:', error);
        }
      };

      wsRef.current.onopen = () => {
        wsRef.current.send(JSON.stringify({ 
          action: "start",
          bet: bet,
          direction: direction 
        }));
      };
  
      wsRef.current.onmessage = handleWebSocketMessage;
  
      wsRef.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        setIsLoading(false);
        setIsRequestPending(false);
      };
  
      wsRef.current.onclose = () => {
        if (!wsRef.current) return;
        
        setTimeout(() => {
          if (wsRef.current) {
            wsRef.current = new WebSocket(personalGameUrl);
            wsRef.current.onmessage = handleWebSocketMessage;
          }
        }, 1000);
      };
  
    } catch (error) {
      console.error('Error placing bet:', error);
      setIsLoading(false);
      setIsRequestPending(false);
      setError(t("GamePage.Game4.Error.PlaceBet"));
    }
  }, [
    playSound,
    betAmount,
    isRequestPending,
    user.user_id,
    user.g_token,
    updateUser,
    startTimer,
    t,
    playMoneySound,
    playFiascoSound
  ]);
  
  // Функція для обробки результату гри
  const handleGameResult = useCallback((response) => {
    if (response.is_win) {
      // Обробка виграшу
      const winAmount = parseFloat(response.win_amount);
      updateUser({ g_token: response.new_balance });
      playMoneySound();
      updateModalState(t("GamePage.Game4.Win", { amount: winAmount }), true);
    } else {
      // Обробка програшу
      playFiascoSound();
      updateModalState(t("GamePage.Game4.Lose"), true);
    }
    
    setBetEndPrice(response.final_price);
    setIsTimerRunning(false);
    setIsLoading(false);
    setIsRequestPending(false);
    clearGameDataFromLocalStorage();
  }, [updateUser, playMoneySound, updateModalState, t]);
  
  const updatePriceColor = useCallback(() => {
    if (startPrice && btcUsdtPrice) {
      if (parseFloat(btcUsdtPrice) > parseFloat(startPrice)) {
        setPriceColor("green");
      } else if (parseFloat(btcUsdtPrice) < parseFloat(startPrice)) {
        setPriceColor("red");
      } else {
        setPriceColor("white");
      }
    }
  }, [startPrice, btcUsdtPrice]);
  
  useEffect(() => {
    updatePriceColor();
  }, [btcUsdtPrice, updatePriceColor]);
  
    useEffect(() => {
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.onEvent('viewportChanged', () => {
          window.scrollTo(0, 0);
        });
      }
    }, []);
  
    useEffect(() => {
      const handleResize = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      };
  
      handleResize();
      window.addEventListener('resize', handleResize);
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
  const memoizedTradeViewChart = useMemo(() => {
    if (!chartDimensions) return null;
  
    return (
        <TradeViewChart
            containerStyle={{
              height: chartDimensions.height,
              width: chartDimensions.width,
              backgroundColor: "black",
              marginBottom: "10px",
            }}
            pair="BTCUSDT"
            chartLayout={{
              priceScale: {
                scaleMargins: {
                  top: 0.001,
                  bottom: 0.01,
                },
              },
              grid: {
                vertLines: {
                  color: "rgba(70, 130, 180, 0.1)",
                  style: 1,
                  visible: true,
                },
                horzLines: {
                  color: "rgba(70, 130, 180, 0.1)",
                  style: 1,
                  visible: true,
                },
              },
            }}
            candleStickConfig={{
              upColor: chartColor,
              downColor: "#880808",
              borderUpColor: chartColor,
              borderDownColor: "#880808",
              wickUpColor: chartColor,
              wickDownColor: "#880808",
            }}
            onPriceUpdate={(price) => setBtcUsdtPrice(price.toFixed(2))}
        />
    );
  }, [chartColor, chartDimensions]);
  
  if (!chartDimensions) return null;
  
  const formatBalanceWithSixDecimals = (balance) => {
    return Number(balance).toFixed(2);
  };
  
  return (
      <div className="graph_game_cont">
        <PartnersModal
            iconArrowLeft={iconArrowLeft}
            background={modalBackground}
            isOpen={isAdModalOpen}
            onClose={() => setIsAdModalOpen(false)}
            exchangeLinks={exchangeLinks}
            isLinksLoading={isLinksLoading}
        />
        <div className="cont-game-4">
          <div className="earning-game">
            <div className="earning-content-game">
              <div className="earning-icon-container-game">
                <IconCoin className="earning-icon-small-game4" />
                <p className="token-game" style={styles.whiteText}>
                  Token
                </p>
              </div>
              <div className="earning-text-container-game">
                <p className="earning-text-game" style={styles.whiteText}>
                  {formatBalanceWithSixDecimals(user.g_token)}
                </p>
              </div>
            </div>
          </div>
          <div className="earning-game">
            <div className="earning-content-game">
              <div className="earning-icon-container-game2">
                <p className="earning-text-game" style={styles.whiteText}>
                  {isTimerRunning ? timer.toFixed(1) : "180.0"}
                </p>
              </div>
              <div className="earning-text-container-game">
                <p className="earning-text-game" style={styles.whiteText}>
                  BTC/USDT
                </p>
              </div>
            </div>
          </div>
        </div>
  
        <div className="graph-cont">
          <div className="chart_wrapper">{memoizedTradeViewChart}</div>
        </div>
        <div className="flex w-full justify-between flex-col items-center">
        <div className="game4-buttom-list2 mb-2">
          <div className="flex flex-col justify-end w-1/3">
            <p className="bet-label">{t("GamePage.Game4.Bet")}</p>
            <div className="earning-game-b-price">
              <div className="earning-content-game">
                <div className="earning-text-container-game flex flex-row">
                  <p
                      className="earning-text-game mr-1"
                      style={{
                        ...styles.whiteText,
                        color: getBetColor(),
                      }}
                  >
                    {betStartPrice || "-"}
                  </p>
                  {betDirection === "up" && <ArrowUp color={getBetColor()} />}
                  {betDirection === "down" && <ArrowDown color={getBetColor()} />}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-end  w-1/3">
            <p className="bet-label">{t("GamePage.Game4.Ending")}</p>
            <div className="earning-game-b-price">
              <div className="earning-content-game">
                <div className="earning-text-container-game">
                  <p
                      className="earning-text-game mr-1"
                      style={{
                        ...styles.whiteText,
                        color: getEndPriceColor(),
                      }}
                  >
                    {betEndPrice || "-"}
                  </p>
                  <EndPriceArrow />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-end  w-1/4">
            <p className="bet-label"></p>
            <div className="earning-game-b-price">
              <div className="earning-content-game">
                <div className="earning-icon-container-game ml-3">
                  <IconCoin className="earning-icon-small-game4" />
                  <p className="token-game" style={styles.whiteText}>
                    Token
                  </p>
                </div>
                <div className="earning-text-container-game">
                  {/* <p
        className="earning-text-game bet-res-text"
        style={{
            ...styles.whiteText,
            color: betResult === "win" ? "#15FF00" : betResult === "lose" ? "#FF0000" : "white",
        }}
    >
        {betResult
            ? betResult === "win"
                ? `+${(parseFloat(userBet)).toFixed(2)}`
                : `-${parseFloat(userBet).toFixed(2)}`
            : userBet || "-"}
    </p> */}
                  <p
                      className="earning-text-game bet-res-text"
                      style={{
                        ...styles.whiteText,
                        color: betResult === "win" ? "#15FF00" : betResult === "lose" ? "#FF0000" : "white",
                      }}
                  >
                    {betResult
                        ? userBet
                        : userBet || "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start  w-12">
            <div
                className="earning-game-b-price"
                onClick={handlePocketoptionClick}
            >
              <img
                  src={PocketoptionSmal}
                  alt="CryptoPocket"
                  className="pocken-image"
              />
            </div>
          </div>
        </div>
        <div className="game4-buttom-list">
          <div className="earning-game">
            <div className="earning-content-game">
              <div className="earning-icon-container-game1">
                <IconCoin className="earning-icon-small-game4" />
              </div>
              <div className="earning-content-game">
                <div className="earning-text-container-game">
                  <input
                      type="text"
                      inputMode="decimal"
                      placeholder="0.001"
                      style={styles.input}
                      value={betAmount}
                      onChange={handleBetChange}
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      disabled={isRequestPending}
                  />
                </div>
              </div>
            </div>
          </div>
          <TelegramButton
              className="game-button-up"
              onClick={() => placeBet(true)}
              onTouchStart={() => placeBet(true)}
              disabled={isLoading || isRequestPending}
          >
            {t("GamePage.Game4.Up")}
          </TelegramButton>
          <TelegramButton
              className="game-button-down"
              onClick={() => placeBet(false)}
              onTouchStart={() => placeBet(false)}
              disabled={isLoading || isRequestPending}
          >
            {t("GamePage.Game4.Down")}
          </TelegramButton>
        </div>
        </div>
        {error && (
            <div
                className="error-message"
                style={{ color: "red", textAlign: "center", marginTop: "10px" }}
            >
              {error}
            </div>
        )}
        {showBetPlaced && (
            <div
                style={{
                  ...styles.betPlacedMessage,
                  opacity: showBetPlaced ? 1 : 0,
                }}
            >
              {t("GamePage.Game4.BetMade")}
            </div>
        )}
        <TradeWarningModal
            key={modalState.isOpen ? "open" : "closed"}
            modalState={modalState}
            background={modalBackground}
            onClose={() => updateModalState("", false)}
            exchangeLinks={exchangeLinks}
        />
      </div>
  );
  }
  
  export default React.memo(Four);