import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "../contexts/UserContext";
import { API_URLS } from "../config/config";
import { useAudio } from "../contexts/AudioContext";
import axios from "axios";
import clickSoundMp3 from "../assets/sounds/menu-button-click.mp3";
import "../assets/styles/rules.css";
const Rules = ({ preloadedData }) => {
  const { t } = useTranslation();
  const [openSection, setOpenSection] = useState(null);
  const [openSubsection, setOpenSubsection] = useState(null);
  const [sections, setSections] = useState([]);
  const { user } = useUser();
  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);
  const updateLastActive = async (userId) => {
    try {
      await axios.post(API_URLS.UPDATE_LAST_ACTIVE, {
        userId: userId,
      });
    } catch (error) {}
  };

  useEffect(() => {
    updateLastActive(user.user_id);
  }, [user.user_id]);

  useEffect(() => {
    const rulesData = t("RulesPage", { returnObjects: true });
    const dynamicSections = Object.keys(rulesData).filter((key) =>
      key.startsWith("Paragraph")
    );
    setSections(dynamicSections);
  }, [t]);

  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const [clickResponse] = await Promise.all([fetch(clickSoundMp3)]);

          const [clickArrayBuffer] = await Promise.all([
            clickResponse.arrayBuffer(),
          ]);

          const [clickAudioBuffer] = await Promise.all([
            audioContextRef.current.decodeAudioData(clickArrayBuffer),
          ]);

          clickAudioBufferRef.current = clickAudioBuffer;

          setIsAudioInitialized(true);
        } catch (error) {
          console.error("Error initializing audio:", error);
        }
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    if (
      isAudioInitialized &&
      audioContextRef.current &&
      clickAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  const toggleSection = (sectionKey) => {
    playSound();
    setOpenSection(openSection === sectionKey ? null : sectionKey);
    setOpenSubsection(null);
  };

  const toggleSubsection = (subsectionKey) => {
    playSound();
    setOpenSubsection(openSubsection === subsectionKey ? null : subsectionKey);
  };

  const renderContent = (content) => {
    if (typeof content === "string") {
      return <p className="whitespace-pre-wrap">{content}</p>;
    } else if (content && typeof content === "object") {
      return (
        <div>
          {/* Main text with line breaks */}
          {content.MainText && (
            <div className="mb-4">
              {content.MainText.split('\n').map((line, index) => (
                <p key={index} className="whitespace-pre-wrap mb-2">{line}</p>
              ))}
            </div>
          )}

          {/* List title with line breaks */}
          {content.ListTitle && (
            <div className="mb-4">
              {content.ListTitle.split('\n').map((line, index) => (
                <p key={index} className="whitespace-pre-wrap mb-2">{line}</p>
              ))}
            </div>
          )}

          {/* Regular ListItems array */}
          {content.ListItems && (
            <div className="ml-2 mb-4">
              {content.ListItems.map((item, index) => (
                <div key={index} className="flex items-start mb-2">
                  <span className="mr-2">•</span>
                  <p className="whitespace-pre-wrap">{item}</p>
                </div>
              ))}
            </div>
          )}

          {/* ListItem1, ListItem2, etc. format */}
          {!content.ListItems && (
            <div className="ml-2">
              {Object.entries(content)
                .filter(([key]) => key.startsWith('ListItem'))
                .map(([key, value]) => (
                  <div key={key} className="flex items-start mb-2">
                    <span className="mr-2">•</span>
                    <p className="whitespace-pre-wrap">{value}</p>
                  </div>
                ))}
            </div>
          )}

          {/* Image rendering */}
          {content.Image && (
            <img
              src={content.Image.src}
              alt={content.Image.alt}
              width={content.Image.width}
              height={content.Image.height}
              className="my-4"
            />
          )}

          {/* Table section */}
          {content.TableIntro && <p>{content.TableIntro}</p>}
          {content.Table && (
            <table className="w-full border-collapse overflow-hidden rounded-lg">
              <thead>
                <tr>
                  {content.Table[0].map((header, index) => (
                    <th
                      key={index}
                      scope="col"
                      className={`p-1 table-text border border-lightgray ${
                        index === 0 ? "rounded-tl-lg" : ""
                      } ${
                        index === content.Table[0].length - 1
                          ? "rounded-tr-lg"
                          : ""
                      }`}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {content.Table.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td
                        key={cellIndex}
                        className={`p-1 table-text border border-lightgray ${
                          rowIndex === content.Table.length - 2 &&
                          cellIndex === 0
                            ? "rounded-bl-lg"
                            : ""
                        } ${
                          rowIndex === content.Table.length - 2 &&
                          cellIndex === row.length - 1
                            ? "rounded-br-lg"
                            : ""
                        }`}
                      >
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {content.TableOutro && <p>{content.TableOutro}</p>}

          {/* Mechanics and Strategy section */}
          {content.Mechanics && content.Strategy && (
            <div className="grid grid-cols-2 gap-6 mt-4">
              <div className="mechanics-section">
                <h3 className="text-white mb-2">{content.MechanicsTitle}</h3>
                <div className="mechanics-content">
                  {content.Mechanics.map((item, index) => (
                    <div key={index} className="flex items-start mb-2">
                      <span className="mr-2">•</span>
                      <p className="whitespace-pre-wrap">{item}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="strategy-section">
                <h3 className="text-white mb-2">{content.StrategyTitle}</h3>
                <div className="strategy-content">
                  {content.Strategy.map((item, index) => (
                    <div key={index} className="flex items-start mb-2">
                      <span className="mr-2">•</span>
                      <p className="whitespace-pre-wrap">{item}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Explanation section */}
          {content.Explanation && (
            <div>
              <h4 className="font-bold mt-4">{content.Explanation.Title}</h4>
              <ul className="list-disc pl-5">
                {content.Explanation.Items.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}

          {/* Conclusion and additional info */}
          {content.Conclusion && <p className="mt-4">{content.Conclusion}</p>}
          {content.AdditionalInfo && (
            <ul className="list-disc pl-5 mt-4">
              {content.AdditionalInfo.map((info, index) => (
                <li key={index}>{info}</li>
              ))}
            </ul>
          )}

          {/* Sub lists section */}
          {content.SubLists &&
            content.SubLists.map((subList, subIndex) => (
              <div key={subIndex} className="mt-4">
                {subList.ListTitle && (
                  <h4 className="font-medium">{subList.ListTitle}</h4>
                )}
                {subList.MainText && <p>{subList.MainText}</p>}
                <ul className="list-disc pl-5">
                  {subList.ListItems.map((item, itemIndex) => (
                    <li key={itemIndex}>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
        </div>
      );
    }
    return null;
};
  const renderSubsections = (sectionKey) => {
    const sectionData = t(`RulesPage.${sectionKey}`, { returnObjects: true });
    const subsections = Object.keys(sectionData).filter(
      (key) => key.startsWith("Clause") && !key.endsWith("Text")
    );

    return subsections.map((subsectionKey, index) => (
      <div key={subsectionKey} className="w-full">
        <h2 id={`accordion-collapse-heading-${sectionKey}-${index}`}>
          <button
            type="button"
            className="flex items-center justify-between w-full p-3 font-medium rtl:text-right text-white border border-t-0 border-l-0 border-r-0 border-gray-200 dark:text-white gap-3"
            onClick={() => toggleSubsection(`${sectionKey}.${subsectionKey}`)}
            aria-expanded={openSubsection === `${sectionKey}.${subsectionKey}`}
            aria-controls={`accordion-collapse-body-${sectionKey}-${index}`}
          >
            <span className="subtitle">{sectionData[subsectionKey]}</span>
            <svg
              data-accordion-icon
              className={`w-3 h-3 transform ${
                openSubsection === `${sectionKey}.${subsectionKey}`
                  ? "rotate-180"
                  : ""
              } shrink-0`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              width="19"
              height="8"
              viewBox="0 0 19 8"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M18 1L9.5 7L1 0.999999"
              />
            </svg>
          </button>
        </h2>
        <div
          id={`accordion-collapse-body-${sectionKey}-${index}`}
          className={`transition-all duration-500 ease-in-out overflow-hidden ${
            openSubsection === `${sectionKey}.${subsectionKey}`
              ? "max-h-[5000px] opacity-100"
              : "max-h-0 opacity-0"
          }`}
          aria-labelledby={`accordion-collapse-heading-${sectionKey}-${index}`}
        >
          <div className="p-1 rules-text w-full">
            {renderContent(sectionData[`${subsectionKey}Text`])}
          </div>
        </div>
      </div>
    ));
  };
  return (
    <div
      id="rules"
      style={{ backgroundImage: `url(${preloadedData.backgroundImage})` }}
    >
      <div className="h-14 flex items-center justify-center">
        <p className="title">
          {t("RulesPage.Title")}
          <span className="game-name"> {t("RulesPage.GameName")}</span>
        </p>
      </div>
      <div className="accordion-container">
        <div id="accordion-collapse" data-accordion="collapse">
          {sections.map((sectionKey, index) => (
            <div key={sectionKey}>
              <h2 id={`accordion-collapse-heading-${index}`}>
                <button
                  type="button"
                  className="flex items-center justify-between w-full p-3 font-medium rtl:text-right text-white border border-t-0 border-l-0 border-r-0 border-gray-200 dark:text-white gap-3"
                  onClick={() => toggleSection(sectionKey)}
                  aria-expanded={openSection === sectionKey}
                  aria-controls={`accordion-collapse-body-${index}`}
                >
                  <span className="subtitle">
                    {t(`RulesPage.${sectionKey}.Title`)}
                  </span>
                  <svg
                    data-accordion-icon
                    className={`w-3 h-3 transform ${
                      openSection === sectionKey ? "rotate-180" : ""
                    } shrink-0`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    width="19"
                    height="8"
                    viewBox="0 0 19 8"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 1L9.5 7L1 0.999999"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id={`accordion-collapse-body-${index}`}
                className={`transition-all duration-500 ease-in-out overflow-hidden ${
                  openSection === sectionKey
                    ? "max-h-[5000px] opacity-100"
                    : "max-h-0 opacity-0"
                }`}
                aria-labelledby={`accordion-collapse-heading-${index}`}
              >
                <div className="p-1 rules-text">
                  {renderSubsections(sectionKey)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Rules;
