import React, { useState, useCallback, useEffect, useRef } from "react";
import { API_URLS } from "../config/config";
import axios from "axios";
import TradeWarningModal from "../components/TradeWarningModal";
import RequestModal from "../components/RequestModal";
import NumericInput from "../components/NumericInput";
import LoadingDots from "../components/LoadingDots";
import Toast from "../components/CopiedMessageModal";
import { useUser } from "../contexts/UserContext";
import formatBalance from "../utils/formatBalance";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAudio } from "../contexts/AudioContext";
import clickSoundMp3 from "../assets/sounds/menu-button-click.mp3";
import moneySoundMp3 from "../assets/sounds/transaction okey.mp3";
import "../assets/styles/trade.css";

const Trade = ({ preloadedData }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("conversion");
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [isConversionLoading, setIsConversionLoading] = useState(false);
  const [isBuySellLoading, setIsBuySellLoading] = useState(false);
  const [isGnomeLoading, setIsGnomeLoading] = useState(false);
  const { user, updateUser } = useUser();
  const timeoutRef = useRef(null);
  const [transactionsSwaps, setSwapsTransactions] = useState(
    preloadedData.data.transactions.swaps || []
  );
  const [transactionsTransfers, setTransfersTransactions] = useState(
    preloadedData.data.transactions.transfers || []
  );
  const [showToast, setShowToast] = useState(false);
  const [isInitialFetchDone, setIsInitialFetchDone] = useState(false);
  const [modalState, setModalState] = useState({
    isOpen: false,
    message: "",
    link: "",
  });

  const navigate = useNavigate();

  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);
  const moneyAudioBufferRef = useRef(null);

  // Exchange rates
  //gtoken/dolar
  const [gTokenToDollarRate, setGTokenToDollarRate] = useState(0);
  const [dollarToGTokenRate, setDollarToGTokenRate] = useState(0);

  //gtoken/gnom
  const [gTokenToGnomeRate, setGTokenToGnomeRate] = useState(0);
  const [gnomeToGTokenRate, setGnomeToGTokenRate] = useState(0);

  //gtoken/usdt
  const [gTokenToUsdtRate, setGTokokenToUsdtRate] = useState(0);
  const [usdtToGTokenRate, setUsdtToGTokenRate] = useState(0);

  const [vipRate, setVipRate] = useState(100);

  // Conversion tab state
  const [conversionFromAmount, setConversionFromAmount] = useState(0);
  const [conversionToAmount, setConversionToAmount] = useState(0);
  const [conversionFeePercentage, setConversionFeePercentage] = useState(0);
  const [conversionFeeAmount, setСonversionFeeAmount] = useState(0);
  const [isGTokenToDollar, setIsGTokenToDollar] = useState(false);
  const [dolarPrice, setDolarPrice] = useState(0);
  const [feeAmount, setFeeAmount] = useState(0);

  // Transfer tab state
  const [reciverId, setReciverId] = useState("");
  const [isTransferConfirmed, setIsTransferConfirmed] = useState(false);
  const [transferAmount, setTransferAmount] = useState(1);
  const [transferFeeAmount, setTransferFeeAmount] = useState(0);
  const [transferFeePercentage, setTransferFeePercentage] = useState(
    preloadedData.data.transferFee
  );
  const transferFeeTimeoutRef = useRef(null);

  // BuySell tab state
  const [buySellFromAmount, setBuySellFromAmount] = useState(1.1);
  const [buySellToAmount, setBuySellToAmount] = useState(0);
  const [isGTokenToUsdt, setIsGTokenToUsdt] = useState(false);
  const [usdtPrice, setUsdtPrice] = useState(0);

  const [makeRequestModal, setMakeRequestModal] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const modalRef = useRef(null);

  // GToken/Gnome swap state

  const [gnomeFromAmount, setGnomeFromAmount] = useState(1);
  const [gnomeToAmount, setGnomeToAmount] = useState(0);
  const [isGTokenToGnome, setIsGTokenToGnome] = useState(true);

  // Vip tab state
  const [vipAmount, setBuyVipAmount] = useState(100);
  const [showContactManagerButton, setShowContactManagerButton] =
    useState(false);

  const updateLastActive = async (userId) => {
    try {
      await axios.post(API_URLS.UPDATE_LAST_ACTIVE, {
        userId: userId,
      });
    } catch (error) {}
  };

  useEffect(() => {
    updateLastActive(user.user_id);
  }, [user.user_id]);

  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const [clickResponse, moneyResponse] = await Promise.all([
            fetch(clickSoundMp3),
            fetch(moneySoundMp3),
          ]);

          const [clickArrayBuffer, moneyArrayBuffer] = await Promise.all([
            clickResponse.arrayBuffer(),
            moneyResponse.arrayBuffer(),
          ]);

          const [clickAudioBuffer, moneyAudioBuffer] = await Promise.all([
            audioContextRef.current.decodeAudioData(clickArrayBuffer),
            audioContextRef.current.decodeAudioData(moneyArrayBuffer),
          ]);

          clickAudioBufferRef.current = clickAudioBuffer;
          moneyAudioBufferRef.current = moneyAudioBuffer;

          setIsAudioInitialized(true);
        } catch (error) {}
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    if (
      isAudioInitialized &&
      audioContextRef.current &&
      clickAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  const playMoneySound = useCallback(() => {
    if (
      isAudioInitialized &&
      audioContextRef.current &&
      moneyAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = moneyAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  useEffect(() => {
    const tradeElement = document.getElementById("trade");
    if (makeRequestModal) {
      setAnimationClass("slide-in");
      document.body.classList.add("slide-in");
      document.body.classList.remove("slide-out");
      tradeElement.classList.add("disable-trade");
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      setAnimationClass("slide-out");
      document.body.classList.add("slide-out");
      document.body.classList.remove("slide-in");
      tradeElement.classList.remove("disable-trade");
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.body.classList.remove("slide-in", "slide-out");
      tradeElement.classList.remove("disable-trade");
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [makeRequestModal]);

  useEffect(() => {
    setShowContactManagerButton(user.is_vip);
  }, [user.is_vip]);

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeMakeRequestModal();
    }
  };

  const loadTransactionsData = async () => {
    try {
      const response = await axios.post(API_URLS.EXECUTE_TRANSACTIONS, {
        userId: user.user_id,
      });
      setSwapsTransactions(response.data.swaps || []);
      setTransfersTransactions(response.data.transfers || []);
    } catch (error) {}
  };

  useEffect(() => {
    const initialAmount = 1;
    const calculatedFee = parseFloat(
      (initialAmount * (transferFeePercentage / 100)).toFixed(6)
    );
    setTransferFeeAmount(calculatedFee);
  }, [transferFeePercentage]);

  useEffect(() => {
    const fetchInitialRates = async () => {
      try {
        const gTokenToDollarResponse = await axios.post(
          `${API_URLS.GET_EXCHANGER_RATE}`,
          {
            asset1Id: 1,
            asset2Id: 2,
            amount1: 1,
          }
        );

        setDollarToGTokenRate(
          parseFloat(gTokenToDollarResponse.data.exchange_rate)
        );
        setGTokenToDollarRate(
          1 / parseFloat(gTokenToDollarResponse.data.exchange_rate)
        );
        setConversionFromAmount(gTokenToDollarResponse.data.amount_2);
        setConversionToAmount(gTokenToDollarResponse.data.amount_1);
        setDolarPrice(gTokenToDollarResponse.data.amount_2);
        setConversionFeePercentage(
          parseFloat(gTokenToDollarResponse.data.fee_percentage)
        );
        setFeeAmount(gTokenToDollarResponse.data.fee_amount);

        const gTokenToGnomeResponse = await axios.post(
          `${API_URLS.GET_EXCHANGER_RATE}`,
          {
            asset1Id: 1,
            asset2Id: 3,
            amount1: 6,
          }
        );

        const gTokenToGnomeRate = parseFloat(
          gTokenToGnomeResponse.data.exchange_rate
        );
        setGTokenToGnomeRate(gTokenToGnomeRate);
        setGnomeToGTokenRate(1 / gTokenToGnomeRate);
        setGnomeFromAmount(6);
        setGnomeToAmount(gTokenToGnomeResponse.data.amount_2);

        const usdToGTockenResponse = await axios.post(
          `${API_URLS.GET_EXCHANGER_RATE}`,
          {
            asset1Id: 1,
            asset2Id: 4,
            amount1: 1,
          }
        );

        setGTokokenToUsdtRate(
          1 / parseFloat(usdToGTockenResponse.data.exchange_rate)
        );
        setUsdtToGTokenRate(
          parseFloat(usdToGTockenResponse.data.exchange_rate)
        );
        setBuySellFromAmount(usdToGTockenResponse.data.amount_2);
        setBuySellToAmount(usdToGTockenResponse.data.amount_1);
        setUsdtPrice(usdToGTockenResponse.data.amount_2);
        setIsInitialFetchDone(true);
      } catch (error) {}
    };

    fetchInitialRates();
  }, []);

  useEffect(() => {
    if (conversionFromAmount && feeAmount) {
      setСonversionFeeAmount(feeAmount * conversionFromAmount);
    }
  }, [conversionFromAmount, feeAmount]);

  const toggleAccordion = async () => {
    if (!isAccordionOpen) {
      await loadTransactionsData();
    }
    setIsAccordionOpen(!isAccordionOpen);
  };
  const getFeeIconClass = (icon) => {
    if (icon === preloadedData.iconCoin) return "fee-coin-icon";
    if (icon === preloadedData.iconDolar) return "fee-dollar-icon";
    return "";
  };

  const debouncedApiCall = useCallback(
    (endpoint, data, loadingState) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      loadingState(true);
      timeoutRef.current = setTimeout(async () => {
        try {
          const response = await axios.post(endpoint, data);
          if (endpoint === `${API_URLS.GET_EXCHANGER_RATE}`) {
            if (data.asset1Id === 1 && data.asset2Id === 2) {
              // GToken to Dollar
              setConversionToAmount(parseFloat(response.data.amount_2));
              setСonversionFeeAmount(parseFloat(response.data.fee_amount));
              setGTokenToDollarRate(parseFloat(response.data.exchange_rate));
              setConversionFeePercentage(
                parseFloat(response.data.fee_percentage)
              );
            } else if (data.asset1Id === 2 && data.asset2Id === 1) {
              // Dollar to GToken
              setConversionToAmount(parseFloat(response.data.amount_2));
              setСonversionFeeAmount(parseFloat(response.data.fee_amount));
              setDollarToGTokenRate(parseFloat(response.data.exchange_rate));
              setConversionFeePercentage(
                parseFloat(response.data.fee_percentage)
              );
            } else if (data.asset1Id === 1 && data.asset2Id === 3) {
              // GToken to Gnome
              setGnomeToAmount(parseFloat(response.data.amount_2));
              setGTokenToGnomeRate(parseFloat(response.data.exchange_rate));
            } else if (data.asset1Id === 3 && data.asset2Id === 1) {
              // Gnome to GToken
              setGnomeToAmount(parseFloat(response.data.amount_2));
              setGnomeToGTokenRate(parseFloat(response.data.exchange_rate));
            } else if (data.asset1Id === 1 && data.asset2Id === 4) {
              // GToken to USDT
              setBuySellToAmount(parseFloat(response.data.amount_2));
              setGTokokenToUsdtRate(parseFloat(response.data.exchange_rate));
            } else if (data.asset1Id === 4 && data.asset2Id === 1) {
              // USDT to GToken
              setBuySellToAmount(parseFloat(response.data.amount_2));
              setUsdtToGTokenRate(parseFloat(response.data.exchange_rate));
            }

            if (response.data.userBalance) {
              updateUser({
                g_token: parseFloat(response.data.userBalance.gtoken || 0),
                gold_balance: parseFloat(response.data.userBalance.dollar || 0),
                usdt_balance: parseFloat(response.data.userBalance.usdt || 0),
                gnome_amount: parseFloat(response.data.userBalance.gnome || 0),
              });
            }
          }
        } catch (error) {
        } finally {
          loadingState(false);
        }
      }, 1000);
    },
    [updateUser]
  );

  const updateBuySellFromAmount = useCallback(
    (value) => {
      setBuySellFromAmount(value);
      const data = {
        asset1Id: isGTokenToUsdt ? 1 : 4,
        asset2Id: isGTokenToUsdt ? 4 : 1,
        amount1: parseFloat(value),
      };
      debouncedApiCall(
        `${API_URLS.GET_EXCHANGER_RATE}`,
        data,
        setIsBuySellLoading
      );
    },
    [isGTokenToUsdt, debouncedApiCall]
  );
  const updateFromAmount = useCallback(
    (value) => {
      setConversionFromAmount(value);
      const data = {
        asset1Id: isGTokenToDollar ? 1 : 2,
        asset2Id: isGTokenToDollar ? 2 : 1,
        amount1: parseFloat(value),
      };
      debouncedApiCall(
        `${API_URLS.GET_EXCHANGER_RATE}`,
        data,
        setIsConversionLoading
      );
    },
    [isGTokenToDollar, debouncedApiCall]
  );

  const updateGnomeFromAmount = useCallback(
    (value) => {
      setGnomeFromAmount(value);
      const data = {
        asset1Id: isGTokenToGnome ? 1 : 3,
        asset2Id: isGTokenToGnome ? 3 : 1,
        amount1: parseFloat(value),
      };

      debouncedApiCall(
        `${API_URLS.GET_EXCHANGER_RATE}`,
        data,
        setIsGnomeLoading
      );
    },
    [isGTokenToGnome, debouncedApiCall]
  );

  const handleSwap = useCallback(async () => {
    playSound();
    setIsGTokenToDollar(!isGTokenToDollar);
    const newFromAmount = isGTokenToDollar ? dolarPrice : 1;
    setConversionFromAmount(newFromAmount);

    const data = {
      asset1Id: isGTokenToDollar ? 2 : 1,
      asset2Id: isGTokenToDollar ? 1 : 2,
      amount1: newFromAmount,
    };

    try {
      setIsConversionLoading(true);
      const response = await axios.post(`${API_URLS.GET_EXCHANGER_RATE}`, data);
      setConversionToAmount(parseFloat(response.data.amount_2));
      setСonversionFeeAmount(parseFloat(response.data.fee_amount));
      setConversionFeePercentage(parseFloat(response.data.fee_percentage));
      if (isGTokenToDollar) {
        setGTokenToDollarRate(parseFloat(response.data.exchange_rate));
      } else {
        setDollarToGTokenRate(parseFloat(response.data.exchange_rate));
      }
    } catch (error) {
    } finally {
      setIsConversionLoading(false);
    }
  }, [isGTokenToDollar, playSound, dolarPrice]);

  const handleGnomeSwap = useCallback(async () => {
    playSound();
    setIsGTokenToGnome(!isGTokenToGnome);
    const newFromAmount = isGTokenToGnome ? 1 : 6;
    setGnomeFromAmount(newFromAmount);

    const data = {
      asset1Id: isGTokenToGnome ? 3 : 1,
      asset2Id: isGTokenToGnome ? 1 : 3,
      amount1: newFromAmount,
    };

    try {
      setIsGnomeLoading(true);
      const response = await axios.post(`${API_URLS.GET_EXCHANGER_RATE}`, data);
      setGnomeToAmount(parseFloat(response.data.amount_2));

      if (isGTokenToGnome) {
        setGnomeToGTokenRate(parseFloat(response.data.exchange_rate));
      } else {
        setGTokenToGnomeRate(parseFloat(response.data.exchange_rate));
      }
    } catch (error) {
    } finally {
      setIsGnomeLoading(false);
    }
  }, [isGTokenToGnome, playSound]);

  const handleBuySellSwap = useCallback(async () => {
    playSound();
    setIsGTokenToUsdt(!isGTokenToUsdt);
    const newFromAmount = isGTokenToUsdt ? usdtPrice : 1.1;
    setBuySellFromAmount(newFromAmount);

    const data = {
      asset1Id: isGTokenToUsdt ? 4 : 1,
      asset2Id: isGTokenToUsdt ? 1 : 4,
      amount1: newFromAmount,
    };

    try {
      setIsBuySellLoading(true);
      const response = await axios.post(`${API_URLS.GET_EXCHANGER_RATE}`, data);
      setBuySellToAmount(parseFloat(response.data.amount_2));
      if (isGTokenToUsdt) {
        setGTokokenToUsdtRate(parseFloat(response.data.exchange_rate));
      } else {
        setUsdtToGTokenRate(parseFloat(response.data.exchange_rate));
      }
    } catch (error) {
    } finally {
      setIsBuySellLoading(false);
    }
  }, [isGTokenToUsdt, playSound, usdtPrice]);

  const handleTransferAmountChange = (value) => {
    setTransferAmount(value);

    if (transferFeeTimeoutRef.current) {
      clearTimeout(transferFeeTimeoutRef.current);
    }

    transferFeeTimeoutRef.current = setTimeout(() => {
      const amount = parseFloat(value);
      if (!isNaN(amount)) {
        const calculatedFee = parseFloat(
          (amount * (transferFeePercentage / 100)).toFixed(6)
        );
        setTransferFeeAmount(calculatedFee);
      }
    }, 300);
  };

  const handleTransferInputBlur = () => {
    handleInputBlur();
    if (transferFeeTimeoutRef.current) {
      clearTimeout(transferFeeTimeoutRef.current);
    }
    transferFeeTimeoutRef.current = setTimeout(() => {
      const amount = parseFloat(transferAmount);
      if (!isNaN(amount)) {
        const calculatedFee = parseFloat(
          (amount * (transferFeePercentage / 100)).toFixed(6)
        );
        setTransferFeeAmount(calculatedFee);
      }
    }, 1000);
  };

  const handleVipAmountChange = (value) => {
    if (/^\d*\.?\d*$/.test(value)) {
      setBuyVipAmount(value);
    }
  };

  const updateModalState = (message, isOpen = true, link = "") => {
    setModalState({ isOpen, message, link });
  };
  const handleExchangeClick = async () => {
    playSound();
    try {
      const data = {
        userId: user.user_id,
        asset1Id: isGTokenToDollar ? 1 : 2,
        asset2Id: isGTokenToDollar ? 2 : 1,
        amount1: parseFloat(conversionFromAmount),
        amount2: parseFloat(conversionToAmount),
        fee: parseFloat(conversionFeeAmount),
      };

      const response = await axios.post(`${API_URLS.EXECUTE_SWAP}`, data);

      if (response.data.result === "ok") {
        playMoneySound();
        updateUser({
          g_token: response.data.new_user_g_token_balance,
          gold_balance: response.data.new_user_gold_balance,
          gnome_amount: response.data.new_user_gnome_balance,
        });
        setConversionFromAmount(isGTokenToDollar ? 100000 : 1);
        updateFromAmount(conversionFromAmount);
      } else {
        let message = "";
        switch (response.data.result) {
          case "not enough balance":
            message = t("TradePage.NotEnoughBalanceError");
            break;
          case "g_tokens swap limit on rank exceeded":
            message = t("TradePage.GTokenLimitError");
            break;
          default:
            message = t("TradePage.UnexpectedSwapResult");
        }
        updateModalState(message, true);
      }
    } catch (error) {
      updateModalState(t("TradePage.UnexpectedSwapResult"), true);
    }
  };

  function copyToClipboard(text) {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setShowToast(true);
          setTimeout(() => setShowToast(false), 1500);
        })
        .catch((err) => {});
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        setShowToast(true);
        setTimeout(() => setShowToast(false), 1500);
      } catch (err) {}
      document.body.removeChild(textArea);
    }
  }

  const handleBuySellClick = async () => {
    playSound();
    try {
      if (!isGTokenToUsdt) {
        try {
          const data = {
            userId: user.user_id,
            amount: parseFloat(buySellFromAmount).toFixed(1),
          };
          const gtokenToUsdtResponse = await axios.post(
            `${API_URLS.CRYPTO_TRANSFER}`,
            data
          );

          if (gtokenToUsdtResponse.data.ok === true) {
            playMoneySound();
            let currentBalance = user.g_token;
            const updatedBalance =
              currentBalance - gtokenToUsdtResponse.data.g_token_to_remove;
            updateUser({
              g_token: updatedBalance,
            });
          } else {
            let message = "";
            switch (gtokenToUsdtResponse.data.error) {
              case "the user is not a player of Golden Rush":
                message = t("TradePage.UserIsNotPlayer");
                break;
              case "user's level is too small to request USDT payouts, should be at least 7":
                message = t("TradePage.LevelIsToSmall");
                break;
              default:
                break;
            }
            updateModalState(message, true);
          }
        } catch (error) {
          let message = "";
          let link = "";
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            switch (error.response.data.error) {
              case "the minimum amount is 1.1 USDT":
                message = t("TradePage.UsdtMinimumAmount");
                break;
              case "insufficient G-tokens for this transfer":
                message = t("TradePage.InsufficientTokens");
                break;
              case "insufficient funds on app's balance":
                message = t("TradePage.InsufficientTokens");
                break;
              case "user is not registered in CryptoPay":
                message = t("TradePage.NotRegistredToCryptoPay");
                link = error.response.data.registration_link;
                break;
              case "database error":
                message = t("TradePage.DatabseError");
                break;
              default:
                message = t("TradePage.UnexpectedBuySellResult");
            }
          } else {
            message = t("TradePage.UnexpectedBuySellResult");
          }
          updateModalState(message, true, link);
        }
      } else {
        try {
          const data = {
            userId: user.user_id,
            amount: parseFloat(buySellFromAmount).toFixed(1),
          };
          const usdtToGtokenResponse = await axios.post(
            `${API_URLS.CREATE_INVOICE}`,
            data
          );

          if (usdtToGtokenResponse.data.ok === true) {
            playMoneySound();
            updateModalState(
              t("TradePage.InvoiceCreated"),
              true,
              usdtToGtokenResponse.data.invoice.payment_links.mini_app
            );
          } else {
            let message = "";
            switch (usdtToGtokenResponse.data.error) {
              case "there is another unsettled invoice for this user at the moment":
                message = t("TradePage.UserHaveActiveInvoice");
                break;
              case "the user is not a player of Golden Rush":
                message = t("TradePage.UserIsNotPlayer");
                break;
              default:
                message = t("TradePage.UnexpectedBuySellResult");
                break;
            }
            updateModalState(message, true);
          }
        } catch (error) {
          let message = t("AdOfficePage.UnexpectedBuySellResult");
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            switch (error.response.data.error) {
              case "there is another unsettled invoice for this user at the moment":
                message = t("TradePage.UserHaveActiveInvoice");
                break;
              case "the user is not a player of Golden Rush":
                message = t("TradePage.UserIsNotPlayer");
                break;
              default:
                message = t("TradePage.UnexpectedBuySellResult");
                break;
            }
          }
          updateModalState(message, true);
        }
      }
    } catch (error) {
      updateModalState(t("TradePage.UnexpectedBuySellResult"), true);
    }
  };

  const handleCheckClick = async () => {
    playSound();
    try {
      const data = {
        userId: user.user_id,
      };
      const response = await axios.post(`${API_URLS.CHECK_INVOICE}`, data);

      if (response.data.ok === true && response.data.status === "pending") {
        updateModalState(t("TradePage.PayActiveInvoice"), true);
      } else if (
        response.data.ok === true &&
        response.data.status === "success"
      ) {
        playMoneySound();
        updateUser({
          g_token: response.data.new_g_token_balance,
        });
      } else if (response.data.ok === false) {
        let message = "";
        switch (response.data.error) {
          case "no user's active unsettled invoices found":
            message = t("TradePage.NoActivePriviousTransaction");
            break;
          case "transaction failed, please try again or contact support":
            message = t("TradePage.InvoiceCreated");
            break;
          case "can't find invoice data":
            message = t("TradePage.InvoiceCreated");
            break;
          default:
            message = t("TradePage.UnexpectedBuySellResult");
        }
        updateModalState(message, true);
      }
    } catch (error) {
      let message = t("AdOfficePage.EnableUnexpectedError");
      if (error.response && error.response.data && error.response.data.error) {
        switch (error.response.data.error) {
          case "no user's active unsettled invoices found":
            message = t("TradePage.NoActivePriviousTransaction");
            break;
          case "transaction failed, please try again or contact support":
            message = t("TradePage.InvoiceCreated");
            break;
          case "can't find invoice data":
            message = t("TradePage.InvoiceCreated");
            break;
          default:
            message = t("TradePage.UnexpectedBuySellResult");
        }
      }
      updateModalState(message, true);
    }
  };

  const handleByVipClick = async () => {
    playSound();
    try {
      const data = {
        userId: user.user_id,
      };

      const response = await axios.post(`${API_URLS.BY_VIP}`, data);

      if (response.data.result === "ok") {
        playMoneySound();
        updateUser({
          g_token: response.data.new_g_token_balance,
          is_vip: true,
        });
        setShowContactManagerButton(true);
        setConversionFromAmount(1);
        updateFromAmount(1);
      } else {
        let message = "";
        switch (response.data.result) {
          case "not enough balance":
            message = t("TradePage.NotEnoughBalanceError");
            break;
          case "g_tokens swap limit on rank exceeded":
            message = t("TradePage.GTokenLimitError");
            break;
          case "user is already vip":
            message = t("TradePage.UserArledyVip");
            break;
          default:
            message = t("TradePage.UnexpectedByVipResult");
        }
        updateModalState(message, true);
      }
    } catch (error) {
      let message = t("AdOfficePage.UnexpectedByVipResult");
      if (error.response && error.response.data && error.response.data.error) {
        switch (error.response.data.error) {
          case "not enough balance":
            message = t("TradePage.NotEnoughBalanceError");
            break;
          case "g_tokens swap limit on rank exceeded":
            message = t("TradePage.GTokenLimitError");
            break;
          case "user is already vip":
            message = t("TradePage.UserArledyVip");
            break;
          default:
            message = t("TradePage.UnexpectedByVipResult");
        }
      }
      updateModalState(message, true);
    }
  };

  const handleGnomeSwapClick = async () => {
    playSound();
    try {
      const data = {
        userId: user.user_id,
        asset1Id: isGTokenToGnome ? 1 : 3,
        asset2Id: isGTokenToGnome ? 3 : 1,
        amount1: parseFloat(gnomeFromAmount),
        amount2: parseFloat(gnomeToAmount),
        fee: 0,
      };

      const response = await axios.post(`${API_URLS.EXECUTE_SWAP}`, data);

      if (response.data.result === "ok") {
        playMoneySound();
        updateUser({
          g_token: response.data.new_user_g_token_balance,
          gold_balance: response.data.new_user_gold_balance,
          gnome_amount: response.data.new_user_gnome_balance,
        });
        setGnomeFromAmount(isGTokenToGnome ? 6 : 1);
        updateGnomeFromAmount(isGTokenToGnome ? 6 : 1);
      } else {
        let message = "";
        switch (response.data.result) {
          case "not enough balance":
            message = t("TradePage.NotEnoughBalanceError");
            break;
          case "g_tokens swap limit on rank exceeded":
            message = t("TradePage.GTokenLimitError");
            break;
          default:
            message = t("TradePage.UnexpectedSwapResult");
        }
        updateModalState(message, true);
      }
    } catch (error) {
      let message = t("AdOfficePage.UnexpectedSwapResult");
      if (error.response && error.response.data && error.response.data.error) {
        switch (error.response.data.error) {
          case "not enough balance":
            message = t("TradePage.NotEnoughBalanceError");
            break;
          case "g_tokens swap limit on rank exceeded":
            message = t("TradePage.GTokenLimitError");
            break;
          default:
            message = t("TradePage.UnexpectedSwapResult");
        }
      }
      updateModalState(message, true);
    }
  };

  const handleTransferClick = async () => {
    playSound();
    try {
      const transferAmountValue = parseFloat(transferAmount);
      const transferFeeAmountValue = parseFloat(transferFeeAmount);

      const data = {
        user1Id: user.user_id,
        user2Id: parseFloat(reciverId),
        assetId: 1,
        amount: transferAmountValue,
        fee: transferFeeAmountValue,
      };

      const response = await axios.post(API_URLS.EXECUTE_TRANSFER, data);

      if (response.data.result === "ok") {
        playMoneySound();
        updateUser({
          g_token: response.data.new_sender_balance,
        });
        setTransferAmount(1);
        handleTransferAmountChange(1);
        setIsTransferConfirmed(true);
      } else {
        let message = "";
        switch (response.data.result) {
          case "not enough balance":
            message = t("TradePage.NotEnoughBalanceError");
            break;
          case "g_tokens swap limit on rank exceeded":
            message = t("TradePage.GTokenLimitError");
            break;
          case "user does not exist":
            message = t("TradePage.UserDoesNotExistError");
            break;
          case "cannot transfer as sender and receiver are the same user":
            message = t("TradePage.SenderIsUserError");
            break;
          default:
            message = t("TradePage.UnexpectedTransferResult");
        }
        updateModalState(message, true);
      }
    } catch (error) {
      updateModalState(t("TradePage.UnexpectedTransferResult"), true);
      let message = t("TradePage.UnexpectedTransferResult");
      if (error.response && error.response.data && error.response.data.error) {
        switch (error.response.data.error) {
          case "not enough balance":
            message = t("TradePage.NotEnoughBalanceError");
            break;
          case "g_tokens swap limit on rank exceeded":
            message = t("TradePage.GTokenLimitError");
            break;
          case "user does not exist":
            message = t("TradePage.UserDoesNotExistError");
            break;
          case "cannot transfer as sender and receiver are the same user":
            message = t("TradePage.SenderIsUserError");
            break;
          default:
            message = t("TradePage.UnexpectedTransferResult");
        }
      }
      updateModalState(message, true);
    }
  };

  const openTelegramChat = () => {
    const telegramUsername = "Golden_Rush_Manager";

    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openTelegramLink(
        `https://t.me/${telegramUsername}`
      );
    } else {
      window.open(`https://t.me/${telegramUsername}`, "_blank");
    }
  };

  const handleInputFocus = () => {
    const menuElement = document.querySelector(".menu");
    if (menuElement) {
      menuElement.classList.remove("menu");
      menuElement.classList.add("menu-hidden");
    }
    const tradeButton = document.querySelector(".trade-button");
    if (tradeButton) {
      tradeButton.classList.remove("trade-button");
      tradeButton.classList.add("trade-button-hidden");
    }
    const myIdElemeny = document.getElementById('myid');
    if (myIdElemeny) {
      myIdElemeny.classList.add("myid-hidden");
    }
    setTimeout(() => {
      const inputElement = document.querySelector(".reciver-input");
      if (inputElement) {
        inputElement.focus();
      }
    }, 100);
  };

  const handleInputBlur = () => {
    const menuElement = document.querySelector(".menu-hidden");
    if (menuElement) {
      menuElement.classList.remove("menu-hidden");
      menuElement.classList.add("menu");
    }
    const tradeButton = document.querySelector(".trade-button-hidden");
    if (tradeButton) {
      tradeButton.classList.remove("trade-button-hidden");
      tradeButton.classList.add("trade-button");
    }
    const myIdElemeny = document.getElementById('myid');
    if (myIdElemeny) {
      myIdElemeny.classList.remove("myid-hidden");
    }
  };

  const handleReciverIdChange = (e) => {
    setReciverId(e.target.value);
  };

  const getTransactionType = (transaction) => {
    if (transaction.type === "transfer") return "transfer";
    if (transaction.asset1Id === 4 && transaction.asset2Id === 1)
      return "donation";
    return "exchange";
  };

  const getAssetIcon = (assetId) => {
    switch (assetId) {
      case 1:
        return { icon: preloadedData.iconCoin, class: "asset-icon-gtoken" };
      case 2:
        return { icon: preloadedData.iconDolar, class: "asset-icon-dollar" };
      case 3:
        return { icon: preloadedData.iconGnom, class: "asset-icon-gnome" };
      case 4:
        return { icon: preloadedData.iconUsdt, class: "asset-icon-usdt" };
      default:
        return { icon: preloadedData.iconCoin, class: "asset-icon-gtoken" };
    }
  };

  const closeMakeRequestModal = () => {
    setMakeRequestModal(false);
  };

  const openMakeRequestModal = () => {
    playSound();
    const menuElement = document.querySelector(".menu-hidden");
    if (menuElement) {
      menuElement.classList.remove("menu-hidden");
      menuElement.classList.add("menu");
    }
    const tradeButton = document.querySelector(".trade-button-hidden");
    if (tradeButton) {
      tradeButton.classList.remove("trade-button-hidden");
      tradeButton.classList.add("trade-button");
    }
    setMakeRequestModal(true);
  };

  const renderAssetIcon = (assetId) => {
    const { icon, class: iconClass } = getAssetIcon(assetId);
    return (
      <div className="asset-icon-container mr-1">
        <img src={icon} className={iconClass} alt="Asset Icon" />
        {assetId === 1 && <p className="token">Token</p>}
      </div>
    );
  };

  const handleAddOfficeClick = () => {
    playSound();
    navigate("/adoffice");
  };

  const renderTransaction = (transaction, index) => {
    const transactionType = getTransactionType(transaction);

    return (
      <li className="transaction-item" key={index}>
        <div className="flex items-center">
          {transactionType !== "transfer" && (
            <>
              <img
                className="transaction-item-img"
                src={
                  user.gender === 0
                    ? preloadedData.genderMale
                    : preloadedData.genderFemale
                }
                alt="Gender"
              />
              <div className="flex-1 min-w-0 flex justify-start">
                <p className="transaction-item-nick">{user.username}</p>
              </div>
            </>
          )}
          {transactionType === "transfer" && (
            <>
              <img
                className="transaction-item-img"
                src={
                  transaction.receiver_gender === 0
                    ? preloadedData.genderMale
                    : preloadedData.genderFemale
                }
                alt="Sender Gender"
              />
              <div className="flex-1 min-w-0 flex justify-start">
                <p className="transaction-item-nick">
                  {transaction.receiver_username}
                </p>
              </div>
            </>
          )}
        </div>
        <div className="transaction-item-data">
          <div className="w-4/6"></div>
          {transactionType !== "transfer" && (
            <div className="transaction-item-data-money-container">
              <div className="transaction-item-data-money-from">
                <p className="transfer-type">
                  {transactionType === "transfer"
                    ? t("TradePage.TransactionTypeTransfer")
                    : transactionType === "donation"
                    ? t("TradePage.TransactionTypeDonat")
                    : t("TradePage.TransactionTypeExchange")}
                </p>
                <div className="flex flex-row items-center">
                  {renderAssetIcon(transaction.asset_1)}
                  <p className="transfer-money-text">
                    {formatBalance(transaction.amount_1)}
                  </p>
                </div>
              </div>
              <div className="w-10 flex justify-center items-center flex-row">
                <img
                  src={preloadedData.iconArrow}
                  className="-rotate-90 w-2 h-2"
                  alt="Arrow"
                />
              </div>
              <div className="transaction-item-data-money-to">
                <div className="flex flex-row items-center">
                  {renderAssetIcon(transaction.asset_2)}
                  <p className="transfer-money-text">
                    {formatBalance(transaction.amount_2)}
                  </p>
                </div>
              </div>
            </div>
          )}
          {transactionType === "transfer" && (
            <div className="transaction-item-data-money-container">
              <div className="transaction-item-data-money-from">
                <p className="transfer-type">
                  {transactionType === "transfer"
                    ? t("TradePage.TransactionTypeTransfer")
                    : transactionType === "donation"
                    ? t("TradePage.TransactionTypeDonat")
                    : t("TradePage.TransactionTypeExchange")}
                </p>
                <div className="flex flex-row items-center">
                  {renderAssetIcon(transaction.asset)}
                  <p className="transfer-money-text">
                    {formatBalance(transaction.amount)}
                  </p>
                </div>
              </div>
              <div className="w-10 flex justify-center items-center flex-row">
                <img
                  src={preloadedData.iconArrow}
                  className="-rotate-90 w-2 h-2"
                  alt="Arrow"
                />
              </div>
              <div className="transaction-reciver-input-container">
                <input
                  type="text"
                  value={transaction.user_2}
                  className="transaction-reciver-input"
                  readOnly
                />
              </div>
            </div>
          )}
        </div>
      </li>
    );
  };

  const renderTransactions = () => {
    if (transactionsSwaps.length === 0 && transactionsTransfers.length === 0) {
      return (
        <p className="no-transactions-text">
          {" "}
          {t("TradePage.NoTransactionsText")}
        </p>
      );
    }

    const allTransactions = [
      ...transactionsSwaps.map((swap) => ({ ...swap, type: "swap" })),
      ...transactionsTransfers.map((transfer) => ({
        ...transfer,
        type: "transfer",
      })),
    ].sort((a, b) => new Date(b.time) - new Date(a.time));

    return (
      <ul className="w-full">
        {allTransactions.map((transaction, index) =>
          renderTransaction(transaction, index)
        )}
      </ul>
    );
  };

  const renderFee = () => (
    <div className="fee">
      <p className="fee-text mr-2"> {t("TradePage.Fee")} </p>
      <div className="fee-amount-container">
        {isConversionLoading ? (
          <div className="loading-overlay">
            <LoadingDots />
          </div>
        ) : (
          <p className="fee-text mr-1">{conversionFeeAmount}</p>
        )}
      </div>
      <img
        src={
          isGTokenToDollar ? preloadedData.iconCoin : preloadedData.iconDolar
        }
        className={`${getFeeIconClass(
          isGTokenToDollar ? preloadedData.iconCoin : preloadedData.iconDolar
        )}`}
        alt="Fee Icon"
      />
    </div>
  );
  const renderExchangeItem = (
    amount,
    icon,
    label,
    onChange,
    onBlur,
    isLeftSide,
    style,
    min,
    max,
    showArrows = true,
    loadingState
  ) => {
    let iconClass = "swap-icon-gtoken";
    let step;

    if (icon === preloadedData.iconDolar) {
      iconClass = "swap-icon-dollar";
    } else if (icon === preloadedData.iconUsdt) {
      iconClass = "swap-icon-usdt";
    } else if (icon === preloadedData.iconGnom) {
      iconClass = "swap-icon-gnome";
    }

    if (icon === preloadedData.iconCoin) {
      step = 6;
    } else if (icon === preloadedData.iconGnom) {
      step = 1;
    } else {
      step = 1;
    }

    return (
      <div className="swap-item">
        <div className="swap-item-content">
          <div className="swap-item-icon-container">
            <img src={icon} className={iconClass} alt={`${label} Icon`} />
            <p className="token">{label}</p>
          </div>
          <div className="swap-item-text-container">
            <NumericInput
              value={loadingState && !isLeftSide ? "..." : amount}
              onChange={onChange}
              step={step}
              className={style}
              onFocus={handleInputFocus}
              onBlur={onBlur}
              min={min}
              max={max}
              showArrows={showArrows}
              readOnly={!isLeftSide || loadingState}
              isLoading={loadingState}
              showLoadingAnimation={!isLeftSide}
            />
          </div>
        </div>
      </div>
    );
  };
  const renderContent = () => {
    switch (activeTab) {
      case "conversion":
        return (
          <div className="w-full flex flex-col justify-center items-center mt-2">
            <div className="swap-container">
              {renderExchangeItem(
                conversionFromAmount,
                isGTokenToDollar
                  ? preloadedData.iconCoin
                  : preloadedData.iconDolar,
                isGTokenToDollar ? "Token" : "",
                updateFromAmount,
                handleInputBlur,
                true,
                "swap-item-input",
                0,
                null,
                false,
                false,
                false
              )}

              <div className="swap-button-container">
                <button onClick={handleSwap}>
                  <img
                    src={preloadedData.iconSwap}
                    className="swap-icon"
                    alt="Swap Icon"
                  />
                </button>
              </div>
              {renderExchangeItem(
                conversionToAmount,
                isGTokenToDollar
                  ? preloadedData.iconDolar
                  : preloadedData.iconCoin,
                isGTokenToDollar ? "" : "Token",
                updateFromAmount,
                handleInputBlur,
                false,
                "swap-item-input",
                0,
                null,
                false,
                isConversionLoading,
                true
              )}
            </div>
            {renderFee()}
            <div className="trade-accordion-wrapper">
              <div className="trade-accordion-container">
                <button
                  className="trade-accordion-header"
                  onClick={toggleAccordion}
                  aria-expanded={isAccordionOpen}
                >
                  <span className="trade-accordion-title">
                    {t("TradePage.SeeTransactions")}
                  </span>
                  <svg
                    className={`trade-accordion-icon ${
                      isAccordionOpen ? "rotate" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    width="11"
                    height="6"
                    viewBox="0 0 11 6"
                  >
                    <path
                      opacity="0.5"
                      d="M11 1L6 5L1 0.999999"
                      stroke="white"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              </div>

              <div
                className={`trade-accordion-content ${
                  isAccordionOpen ? "" : "closed"
                }`}
              >
                {renderTransactions()}
              </div>
            </div>
            <div
              className={`send-button-container mt-1 ${
                isAccordionOpen ? "accordion-open" : ""
              }`}
            >
              <button
                className="trade-send-button"
                onClick={handleExchangeClick}
              >
                {t("TradePage.ExchangeBtn")}
              </button>
            </div>
          </div>
        );
      case "transfer":
        return (
          <div className="transfer-container mt-2">
            <div className="receiver-container">
              <p className="reciver-title"> {t("TradePage.Receiver")}</p>
              <div className="reciver-input-container">
                <input
                  type="text"
                  value={reciverId}
                  onChange={handleReciverIdChange}
                  className="adress-input"
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  placeholder="Telegram ID"
                />
              </div>
            </div>
            <div className="transfer-money-container">
              <div className="recive-money">
                <div className="recive-money-content">
                  <div className="recive-money-icon-container">
                    <img
                      src={preloadedData.iconCoin}
                      className="swap-icon-gtoken"
                      alt="Coin Icon"
                    />
                    <p className="token">Token</p>
                  </div>
                  <div className="money-text-container">
                    <NumericInput
                      value={transferAmount}
                      onChange={handleTransferAmountChange}
                      className="swap-item-input"
                      onFocus={handleInputFocus}
                      onBlur={handleTransferInputBlur}
                      showArrows={false}
                    />
                  </div>
                </div>
              </div>
              <div className="transfer-send-button-container">
                <button
                  className="transfer-send-button"
                  onClick={handleTransferClick}
                >
                  {t("TradePage.SendBtn")}
                </button>
              </div>
            </div>
            <div className="fee">
              <p className="fee-text mr-2">{t("TradePage.Fee")} </p>
              <p className="fee-text mr-1">{transferFeeAmount}</p>
              <img
                src={preloadedData.iconCoin}
                className="transfer-fee-coin-icon"
                alt="Coin Icon"
              />
            </div>
            {isTransferConfirmed && (
              <div className="confirm-container">
                <div className="confirm">{t("TradePage.ConfirmMessage")}</div>
              </div>
            )}
            <div className="earnings-container">
              <div
                className="earning"
                id="myid"
                onClick={() => copyToClipboard(user.user_id.toString())}
              >
                <div className="earning-content">
                  <div className="earning-text-container">
                    <p className="earning-text">{user.user_id}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "buySell":
        return (
          <div className="w-full flex flex-col justify-center items-center mt-2">
            <div className="swap-container">
              {renderExchangeItem(
                buySellFromAmount,
                isGTokenToUsdt
                  ? preloadedData.iconCoin
                  : preloadedData.iconUsdt,
                isGTokenToUsdt ? "Token" : "",
                updateBuySellFromAmount,
                handleInputBlur,
                true,
                "swap-item-input",
                0,
                null,
                false,
                false,
                false
              )}
              <div className="swap-button-container">
                <button onClick={handleBuySellSwap} className="swap-button">
                  <img
                    src={preloadedData.iconSwap}
                    className="swap-icon"
                    alt="Swap Icon"
                  />
                </button>
              </div>
              {renderExchangeItem(
                buySellToAmount,
                isGTokenToUsdt
                  ? preloadedData.iconUsdt
                  : preloadedData.iconCoin,
                isGTokenToUsdt ? "" : "Token",
                updateBuySellFromAmount,
                handleInputBlur,
                false,
                "swap-item-input",
                0,
                null,
                false,
                isBuySellLoading,
                true
              )}
            </div>
            <div className="bu-sell-buttons-container mt-1">
              <button
                className="trade-send-button"
                onClick={openMakeRequestModal}
              >
                {t("TradePage.MakeRequestBtn")}
              </button>
              <button className="trade-send-button" onClick={handleCheckClick}>
                {t("TradePage.CheckInvoiceBtn")}
              </button>
            </div>
            <div className="swap-container mt-1">
              {renderExchangeItem(
                gnomeFromAmount,
                isGTokenToGnome
                  ? preloadedData.iconCoin
                  : preloadedData.iconGnom,
                isGTokenToGnome ? "Token" : "",
                updateGnomeFromAmount,
                handleInputBlur,
                true,
                "swap-item-input-with-arrows",
                0,
                9999999999999,
                true,
                false
              )}
              <div className="swap-button-container">
                <button onClick={handleGnomeSwap}>
                  <img
                    src={preloadedData.iconSwap}
                    className="swap-icon"
                    alt="Swap Icon"
                  />
                </button>
              </div>
              {renderExchangeItem(
                gnomeToAmount,
                isGTokenToGnome
                  ? preloadedData.iconGnom
                  : preloadedData.iconCoin,
                isGTokenToGnome ? "" : "Token",
                () => {},
                handleInputBlur,
                false,
                "swap-item-input-with-arrows",
                0,
                9999999999999,
                true,
                isGnomeLoading,
                true
              )}
            </div>
            <div className="send-button-container mt-1">
              <button
                className="trade-send-button"
                onClick={handleGnomeSwapClick}
              >
                {t("TradePage.ExchangeBtn")}
              </button>
            </div>
          </div>
        );
      case "vip":
        return (
          <div className="w-full flex flex-col justify-center items-center mt-2">
            <div className="vip-price-container">
              <div className="vip-item">
                <div className="vip-item-content">
                  <div className="vip-item-icon-container">
                    <img
                      src={preloadedData.iconCoin}
                      className="swap-icon-gtoken"
                      alt="Coin Icon"
                    />
                    <p className="token">Token</p>
                  </div>
                  <div className="vip-item-text-container">
                    {/* <NumericInput
                      value={vipAmount}
                      onChange={handleVipAmountChange}
                      className="swap-item-input"
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      showArrows={false}
                    /> */}
                    <p className="vip-text">{vipAmount}</p>
                  </div>
                </div>
              </div>
              <div className="vip-item">
                <div className="vip-item-content">
                  <div className="vip-item-icon-container">
                    <img
                      src={preloadedData.iconVip}
                      className="swap-icon-vip"
                      alt="VIP Icon"
                    />
                  </div>
                  <div className="vip-item-text-container">
                    <p className="vip-text">VIP</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="send-button-container mt-1">
              <button className="trade-send-button" onClick={handleByVipClick}>
                {t("TradePage.ExchangeBtn")}
              </button>
            </div>
            {showContactManagerButton && (
              <div className="send-button-container mt-1">
                <button
                  className="contact-meneger-button"
                  onClick={openTelegramChat}
                >
                  {t("TradePage.CallManager")}
                </button>
                <button
                  className="add-cabinet-button mt-1"
                  onClick={handleAddOfficeClick}
                >
                  {t("TradePage.AddOfficeButton")}
                </button>
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div
      id="trade"
      style={{ backgroundImage: `url(${preloadedData.background})` }}
    >
      <TradeWarningModal
        key={modalState.isOpen ? "open" : "closed"}
        modalState={modalState}
        background={preloadedData.modalBackground}
        onClose={() => updateModalState("", false)}
      />
      {showToast && (
        <Toast
          message={t("TradePage.CopiedMessage")}
          copyIconMessage={preloadedData.iconNotification}
        />
      )}
      <div className="balance-container">
        <div className="balance">
          <div className="trade-balance-content">
            <div className="trade-balance-icon-container">
              <img
                src={preloadedData.iconDolar}
                className="trade-balance-icon-dolar"
                alt="Dollar Icon"
              />
            </div>
            <div className="balance-text-container">
              <p className="balance-text ml-2">
                {formatBalance(user.gold_balance)}
              </p>
            </div>
          </div>
        </div>
        <div className="balance">
          <div className="trade-balance-content">
            <div className="trade-balance-icon-container">
              <img
                src={preloadedData.iconCoin}
                className="trade-balance-icon-coin"
                alt="Coin Icon"
              />
              <p className="token">Token</p>
            </div>
            <div className="balance-text-container">
              <p className="balance-text ml-2">{formatBalance(user.g_token)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="h-14 flex items-center justify-center">
        <p className="trader-title">{t("TradePage.Title")}</p>
      </div>

      <div className="trade-menu-container">
        <div className="trade-menu-button-row">
          <button
            className={`trade-menu-button small-trade-manu-button ${
              activeTab === "conversion" ? "active" : ""
            }`}
            onClick={() => {
              playSound();
              setActiveTab("conversion");
            }}
          >
            {t("TradePage.ConversionBtn")}
          </button>
          <button
            className={`trade-menu-button small-trade-manu-button ${
              activeTab === "transfer" ? "active" : ""
            }`}
            onClick={() => {
              playSound();
              setActiveTab("transfer");
            }}
          >
            {t("TradePage.TransferBtn")}
          </button>
        </div>
        <div className="trade-menu-button-row mt-1">
          <button
            className={`trade-menu-button small-trade-manu-button ${
              activeTab === "buySell" ? "active" : ""
            }`}
            onClick={() => {
              playSound();
              setActiveTab("buySell");
            }}
          >
            {t("TradePage.PurchaseSellBtn")}
          </button>
          <button
            className={`trade-menu-button small-trade-manu-button ${
              activeTab === "vip" ? "active" : ""
            }`}
            onClick={() => {
              playSound();
              setActiveTab("vip");
            }}
          >
            {t("TradePage.VipBtn")}
          </button>
        </div>
      </div>
      <div className="trade-content">{renderContent()}</div>
      <RequestModal
        isOpen={makeRequestModal}
        onClose={closeMakeRequestModal}
        sendAmount={buySellFromAmount}
        sendIcon={
          isGTokenToUsdt ? preloadedData.iconCoin : preloadedData.iconUsdt
        }
        receiveAmount={buySellToAmount}
        receiveIcon={
          isGTokenToUsdt ? preloadedData.iconUsdt : preloadedData.iconCoin
        }
        backgroundImage={preloadedData.background}
        onConfirm={handleBuySellClick}
      />
    </div>
  );
};

export default Trade;
