import React, { useEffect, useRef } from "react";
import iconCross from "../assets/icons/cross.webp";
import { useAudio } from "../contexts/AudioContext";
import { API_URLS } from "../config/config";
import "../assets/styles/creative.css";
const Creative = ({ mediaUrl, adFileName, fullFilePath, isVideo, onClose }) => {
  const mediaRef = useRef(null);
  const { pauseBackgroundMusic, resumeBackgroundMusic } = useAudio();
  const mediaSource = `${API_URLS.API_BASE_URL}${fullFilePath}`;
  useEffect(() => {
    pauseBackgroundMusic();

    return () => {
      resumeBackgroundMusic();
    };
  }, [adFileName, pauseBackgroundMusic, resumeBackgroundMusic]);

  useEffect(() => {
    const header = document.querySelector(".header");
    const menu = document.querySelector(".menu-container");

    if (header) header.style.display = "none";
    if (menu) menu.style.display = "none";

    return () => {
      if (header) header.style.display = "";
      if (menu) menu.style.display = "";
    };
  }, []);

  useEffect(() => {
    if (isVideo && mediaRef.current) {
      const playVideoWithSound = async () => {
        try {
          mediaRef.current.muted = false;
          await mediaRef.current.play();
        } catch (error) {
          console.log("Autoplay with sound failed:", error);
          mediaRef.current.muted = true;
          await mediaRef.current.play();
        }
      };

      playVideoWithSound();
    }
  }, [isVideo, mediaSource]);

  return (
    <div className="creative-viewer-fullscreen">
      <div className="creative-content-wrapper">
        {isVideo ? (
          <video
            key={mediaSource}
            ref={mediaRef}
            className="creative-content"
            autoPlay
            playsInline
            loop
          >
            <source src={mediaSource} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            ref={mediaRef}
            src={mediaSource}
            alt="Advertisement"
            className="creative-content"
          />
        )}
      </div>

      <div className="creative-controls">
        <button onClick={onClose} className="creative-skip-button">
          <img src={iconCross} alt="cross" className="creative-cross-icon" />
        </button>
      </div>
    </div>
  );
};

export default Creative;
