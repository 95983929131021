import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { UserProvider, useUser } from "./contexts/UserContext";
import { LanguageInitProvider } from "./contexts/LanguageInitContext";
import { RewardProvider } from "./contexts/RewardContext";
import { AudioProvider, useAudio } from "./contexts/AudioContext";
import TelegramInitializer from "./initializer/TelegramInitializer";
import BackendInitializer from "./initializer/BackendInitializer";
import LoadingScreen from "./utils/LoadingScreen";
import ProtectedRoute from "./route/ProtectedRoute";
import WelcomePage from "./pages/WelcomePage";
import PickGender from "./pages/PickGender";
import MainPage from "./pages/MainPage";
import PartnersPage from "./pages/PartnersPage";
import ReferalsPage from "./pages/Ref";
import Rules from "./pages/Rules";
import SocialsPage from "./pages/SocialsPage";
import TradePage from "./pages/Trade";
import AdOffice from "./pages/AdOffice";
import SettingsPage from "./pages/Settings";
import GameSelector from "./pages/GameSelector";
import Navbar from "./components/Navbar";
import Menu from "./components/Menu";
import useSetLanguage from "./hooks/useSetLanguage";
import { preloadAllData } from "./utils/preloadFunctions";
import ScrollManager from "./utils/ScrollManager";
import "./assets/styles/index.css";
import { SKIP_TELEGRAM_INIT, TEST_USER_ID } from "./config/config";
import LevelsPage from "./pages/LevelsPage";
import UpgradePageFix from "./pages/UpgradePageFix";
import { UpgradeProvider } from "./contexts/UpgradeContext";
import ScrollExample from "./components/upgrade/ScrollExample";

function App() {
  return (
    <UserProvider>
      <Router>
        <LanguageInitProvider>
          <RewardProvider>
            <AudioProvider>
              <ScrollManager>
                <div className="App">
                  <AppContent />
                </div>
              </ScrollManager>
            </AudioProvider>
          </RewardProvider>
        </LanguageInitProvider>
      </Router>
    </UserProvider>
  );
}

function AppContent() {
  const [userId, setUserId] = useState(
    SKIP_TELEGRAM_INIT ? TEST_USER_ID : null
  );
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [preloadedData, setPreloadedData] = useState(null);
  const [activePage, setActivePage] = useState("/");
  const [hasSeenWelcome, setHasSeenWelcome] = useState(false);
  const [isFirstInit, setIsFirstInit] = useState(false);
  const { user } = useUser();
  const {
    playBackgroundMusic,
    setVolumes,
    isInitialized: audioIsInitialized,
  } = useAudio();
  const location = useLocation();
  const navigate = useNavigate();
  useSetLanguage(user);

  useEffect(() => {
    if (user && isInitialized && audioIsInitialized) {
      setVolumes(user.general_volume, user.music_volume, user.effects_volume);
    }
  }, [user, setVolumes, isInitialized, audioIsInitialized]);

  const tryPlayMusic = useCallback(() => {
    playBackgroundMusic();
  }, [playBackgroundMusic]);

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();

      window.Telegram.WebApp.onEvent("viewportChanged", tryPlayMusic);
      window.Telegram.WebApp.onEvent("mainButtonClicked", tryPlayMusic);

      window.Telegram.WebApp.isVerticalSwipesEnabled = false;
      window.Telegram.WebApp.disableVerticalSwipes();
    }

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        tryPlayMusic();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("touchstart", tryPlayMusic, { once: true });
    document.addEventListener("click", tryPlayMusic, { once: true });

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("touchstart", tryPlayMusic);
      document.removeEventListener("click", tryPlayMusic);
    };
  }, [tryPlayMusic]);

  useEffect(() => {
    if (userId && !isInitialized) {
      const timer = setTimeout(() => {
        setIsInitialized(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [userId, isInitialized]);

  useEffect(() => {
    if (isInitialized && !preloadedData) {
      preloadAllData(setProgress, userId)
        .then((data) => {
          setPreloadedData(data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, [isInitialized, userId, preloadedData]);

  useEffect(() => {
    if (!isLoading && isInitialized && preloadedData && user) {
      if (!user.is_picked_gender) {
        setIsFirstInit(true);
        if (!hasSeenWelcome) {
          navigate("/welcome", { replace: true });
        } else {
          navigate("/pick-gender", { replace: true });
        }
      }
    }
  }, [isLoading, isInitialized, preloadedData, user, navigate, hasSeenWelcome]);

  const handleTelegramSuccess = useCallback(
    (id) => {
      setUserId(id);
      tryPlayMusic();
    },
    [tryPlayMusic]
  );

  const handleInitializationError = useCallback(() => {
    setIsLoading(false);
    setIsInitialized(true);
  }, []);

  const handleBackendInitializationComplete = useCallback(() => {
    setIsInitialized(true);
  }, []);

  const handlePageChange = useCallback(
    (path) => {
      setActivePage(path);
      navigate(path, { replace: true });
    },
    [navigate]
  );

  const handleContinueFromWelcome = useCallback(() => {
    setHasSeenWelcome(true);
    navigate("/pick-gender", { replace: true });
  }, [navigate]);

  if (isLoading || !isInitialized || !preloadedData) {
    return (
      <>
        {!SKIP_TELEGRAM_INIT && (
          <TelegramInitializer
            onSuccess={handleTelegramSuccess}
            onError={handleInitializationError}
          />
        )}
        {userId && !isInitialized && (
          <BackendInitializer
            userId={userId}
            onError={handleInitializationError}
            onComplete={handleBackendInitializationComplete}
          />
        )}
        <LoadingScreen progress={progress} />
      </>
    );
  }

  const showNavbarAndMenu = !["/welcome", "/pick-gender"].includes(
    location.pathname
  );

  return (
    <>
      {showNavbarAndMenu && (
        <Navbar
          onPageChange={handlePageChange}
          preloadedData={preloadedData.navbar}
        />
      )}
      <Routes>
        <Route
          path="/welcome"
          element={
            <WelcomePage
              preloadedData={preloadedData}
              onContinue={handleContinueFromWelcome}
            />
          }
        />

        <Route
          path="/pick-gender"
          element={<PickGender preloadedData={preloadedData} />}
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <MainPage
                preloadedData={preloadedData.main}
                isFristInit={isFirstInit}
                setIsFirstInit={setIsFirstInit}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pump"
          element={
            <ProtectedRoute>
              <UpgradeProvider>
                <UpgradePageFix
                  preloadedData={preloadedData.referral}
                  startRankId={user.rank.id}
                  startStageId={user.stage.id}
                />
              </UpgradeProvider>
            </ProtectedRoute>
          }
        />
        <Route path="/upgrade" element={<ScrollExample></ScrollExample>} />
        <Route
          path="/partners"
          element={
            <ProtectedRoute>
              <PartnersPage preloadedData={preloadedData.partners} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/socials"
          element={
            <ProtectedRoute>
              <SocialsPage preloadedData={preloadedData.socials} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/referral"
          element={
            <ProtectedRoute>
              <ReferalsPage preloadedData={preloadedData.referral} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rules"
          element={
            <ProtectedRoute>
              <Rules preloadedData={preloadedData.rules} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/games"
          element={
            <ProtectedRoute>
              <GameSelector preloadedData={preloadedData.games} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/trade"
          element={
            <ProtectedRoute>
              <TradePage preloadedData={preloadedData.trade} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/adoffice"
          element={
            <ProtectedRoute>
              <AdOffice preloadedData={preloadedData.adOffice} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/levels"
          element={
            <ProtectedRoute>
              <LevelsPage preloadedData={preloadedData.levels} />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {showNavbarAndMenu && (
        <Menu activePage={activePage} onMenuClick={handlePageChange} />
      )}
    </>
  );
}

export default App;
