import React, { useState, useEffect, useRef, useCallback } from "react";
import { ReactComponent as IconDolar } from "../assets/icons/dolar.svg";
import dolarIcon from '../assets/icons/dolar.svg';
import { ReactComponent as IconChecked } from "../assets/icons/checked.svg";
import "../assets/styles/page3.css";
import { API_URLS } from "../config/config";
import { useUser } from "../contexts/UserContext";
import { useAudio } from "../contexts/AudioContext";
import axios from "axios";
import { useTranslation } from "react-i18next";
import clickSoundMp3 from "../assets/sounds/menu-button-click.mp3";

const loadPartnersData = async (userId) => {
  try {
    const response = await axios.post(API_URLS.GET_USER_PARTNERS, {
      userId: userId,
    });
    if (Array.isArray(response.data.tasks)) {
      return response.data.tasks.map((task) => ({
        id: task.id,
        name: task.name,
        amount: task.amount,
        link: task.link,
        photo: task.photo,
        action: task.button_type,
        isCompleted: task.is_completed,
      }));
    }
  } catch (error) {
    return [];
  }
};

const Loader = ({ visible }) => (
  <div className={`loading-overlay-up-page ${visible ? "" : "hidden"}`}>
    <div className="loading-content">
      <div className="loading-spinner"></div>
    </div>
  </div>
);

const PartnersPage = ({ preloadedData }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [setCompletedTasks] = useState({});
  const [partnersData, setPartnersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);

  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const [clickResponse] = await Promise.all([fetch(clickSoundMp3)]);

          const [clickArrayBuffer] = await Promise.all([
            clickResponse.arrayBuffer(),
          ]);

          const [clickAudioBuffer] = await Promise.all([
            audioContextRef.current.decodeAudioData(clickArrayBuffer),
          ]);

          clickAudioBufferRef.current = clickAudioBuffer;

          setIsAudioInitialized(true);
        } catch (error) {}
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    if (
      isAudioInitialized &&
      audioContextRef.current &&
      clickAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  const updateLastActive = async (userId) => {
    try {
      await axios.post(API_URLS.UPDATE_LAST_ACTIVE, {
        userId: userId,
      });
    } catch (error) {}
  };

  useEffect(() => {
    updateLastActive(user.user_id);
  }, [user.user_id]);

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.user_id) {
        setIsLoading(true);
        const data = await loadPartnersData(user.user_id);
        setPartnersData(data);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user]);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleClick = async (link, partnerId) => {
    playSound();
    if (link) {
      window.open(link, "_blank");

      try {
        await delay(10000);

        const data = {
          userId: user.user_id,
          taskId: partnerId,
        };

        const response = await axios.post(API_URLS.CHECK_PARTNER_TASKS, data);
        if (
          response.data.result === "ok" ||
          response.data.result === "already completed task"
        ) {
          setCompletedTasks((prev) => ({ ...prev, [partnerId]: true }));
          const updatedData = await loadPartnersData(user.user_id);
          setPartnersData(updatedData);
        }
      } catch (error) {}
    }
  };
  const renderPartner = (partner, index) => {
    const isLongName = partner.name.length > 25;
    const imageUrl = `${API_URLS.API_BASE_URL}${partner.photo}`;
    return (
      <div
        className={`partner-container ${
          isLongName ? "partner-container-tall" : ""
        }`}
        key={index}
      >
        <div className="flex flex-row">
          <div className="flex items-center justify-center" style={{marginRight: "10px"}}>
            <div className="partner-img-container">
              <img className="img-partner" src={imageUrl} alt={partner.name} />
            </div>
          </div>
          <div className="flex flex-col w-25">
            <p
              className={`partner-name ${
                isLongName ? "partner-name-long" : ""
              }`}
            >
              {partner.name}
            </p>
            <div className="flex flex-row items-center  w-20 ">
              {/* <IconDolar /> */}

              <img
                src={dolarIcon}
                alt="dolar"
                className="socials-dolar-icon"
              />
              <p className="partner-award">+{partner.amount}</p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center w-30 h-30">
          {partner.isCompleted ? (
            <div className="completed-task">
              <IconChecked />
            </div>
          ) : (
            <button
              className="partner-action"
              onClick={() => handleClick(partner.link.url, partner.id)}
            >
              {partner.action}
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      id="page3"
      style={{ backgroundImage: `url(${preloadedData.backgroundImage})` }}
    >
      <Loader visible={isLoading} />
      <p className="title">{t("PartnersPage.Title")}</p>
      <div className="partners">
        {partnersData.map((partner, index) => renderPartner(partner, index))}
      </div>
    </div>
  );
};

export default PartnersPage;
