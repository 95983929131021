import React from 'react';
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";

const TelegramButton = ({
                            children,
                            className,
                            style,
                            onClick,
                            disabled
                        }) => {
    const handleClick = (e) => {
        e.preventDefault();
        if (!disabled && onClick) {
            onClick(e);
        }
    };

    return (
        <button
            className={className}
            style={{
                WebkitTouchCallout: 'none',
                WebkitUserSelect: 'none',
                userSelect: 'none',
                cursor: disabled ? 'not-allowed' : 'pointer',
                touchAction: 'manipulation',
                position: 'relative',
            }}
            onClick={handleClick}
            disabled={disabled}
        >
            {children}
            {disabled && (
                // <div
                //     style={{
                //         position: 'absolute',
                //         top: '50%',
                //         left: '50%',
                //         transform: 'translate(-50%, -50%)',
                //         backgroundColor: 'rgba(0, 0, 0, 0.7)',
                //         width: '100%',
                //         height: '100%',
                //         display: 'flex',
                //         justifyContent: 'center',
                //         alignItems: 'center',
                //         borderRadius: 'inherit'
                //     }}
                // >
                //     <span style={{ fontSize: '24px' }}>🔒</span>
                // </div>
                <div className="lock-button-con">        
            <LockIcon className="icon-lock" />
          </div>
            )}
        </button>
    );
};

export default TelegramButton;