import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import TelegramButton from "./tgbtn";
import "../../assets/styles/games/game_core5.css";
import { useUser } from "../../contexts/UserContext";
import { useAudio } from '../../contexts/AudioContext';
import { API_URLS } from "../../config/config";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconCoin } from "../../assets/icons/coin.svg";
import soccerFieldImage from '../../assets/images/Games/footboll.webp';
import soccerBallImage from '../../assets/images/Games/boll.webp';
import gnom1 from '../../assets/images/Games/gmoms/gmom1.webp';
import gnom2 from '../../assets/images/Games/gmoms/gmom2.webp';
import gnom3 from '../../assets/images/Games/gmoms/gmom5.webp';
import gnom4 from '../../assets/images/Games/gmoms/gmom4.webp';
import gnom5 from '../../assets/images/Games/gmoms/gmom3.webp';
import gnom6 from '../../assets/images/Games/gmoms/gmom6.webp';
import gnomtake from '../../assets/images/Games/gmoms/gmomtaker.webp';
import clickSoundMp3 from '../../assets/sounds/menu-button-click.mp3';
import winSoundMp3 from "../../assets/sounds/football-win-sound.mp3";
import loseSoundMp3 from "../../assets/sounds/football-lose-sound.mp3";
import startSoundMp3 from "../../assets/sounds/football-start-sound.mp3";
import kikSoundMp3 from "../../assets/sounds/ball-kik-sound.mp3";
function CoreGameFive() {
    const { user, updateUser } = useUser();
    const [ballPosition, setBallPosition] = useState({ x: 50, y: 10 });
    const [isKicking, setIsKicking] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [playerPositions, setPlayerPositions] = useState({
        line1: 0, line2: 0, line3: 0, line4: 0, line5: 0, line6: 0
    });
    const [customMessage, setCustomMessage] = useState(null);
    const [showGoalkeeper, setShowGoalkeeper] = useState(false);
    const [gnomeSize, setGnomeSize] = useState(40);
    const wsRef = useRef(null);
    const gameAreaRef = useRef(null);
    const [gameStarted, setGameStarted] = useState(false);
    const [sessionId, setSessionId] = useState(null);
    const [gameResult, setGameResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [gamePrice, setGamePrice] = useState(null);
    const HORIZONTAL_PADDING = 30;
    const { t } = useTranslation();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isWaitingResult, setIsWaitingResult] = useState(false);
    const [collidedPlayers, setCollidedPlayers] = useState([]);
    const lastCollidedPlayers = useRef([]);

    const { effectsVolume, generalVolume } = useAudio();
    const [isAudioInitialized, setIsAudioInitialized] = useState(false);
    const audioContextRef = useRef(null);
    const clickAudioBufferRef = useRef(null);
    const winAudioBufferRef = useRef(null);
    const loseAudioBufferRef = useRef(null);
    const startAudioBufferRef = useRef(null);
    const kikAudioBufferRef = useRef(null);

    const gnomeImages = {
        line1: gnom1,
        line2: gnom2,
        line3: gnom3,
        line4: gnom4,
        line5: gnom5,
        line6: gnom6,
    };

    const connectWebSocket = useCallback(() => {
        try {
                 const wsUrl = "wss://" + API_URLS.WEBS_GAME5_CONNECT;
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                return;
            }

            wsRef.current = new WebSocket(wsUrl);

            const timeoutId = setTimeout(() => {
                if (wsRef.current && wsRef.current.readyState === WebSocket.CONNECTING) {
                    wsRef.current.close();
                    setIsConnected(false);
                }
            }, 10000);

            wsRef.current.onopen = (event) => {
                clearTimeout(timeoutId);
                setIsConnected(true);
            };

            wsRef.current.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === "players") {
                    const newPositions = {};
                    data.data.forEach(([line, position]) => {
                        newPositions[line] = position;
                    });
                    setPlayerPositions(newPositions);
                } else if (data.type === "goalkeeper") {
                    setShowGoalkeeper(data.data);
                }
            };

            wsRef.current.onclose = (event) => {
                setIsConnected(false);
                setTimeout(connectWebSocket, 5000);
            };

            wsRef.current.onerror = (error) => {
            };
        } catch (error) {
        }
    }, []);

    useEffect(() => {
        connectWebSocket();

        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, [connectWebSocket]);

    const playSound = useCallback(() => {
        if (isAudioInitialized && audioContextRef.current && clickAudioBufferRef.current) {
          const source = audioContextRef.current.createBufferSource();
          source.buffer = clickAudioBufferRef.current;

          const gainNode = audioContextRef.current.createGain();
          gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

          source.connect(gainNode);
          gainNode.connect(audioContextRef.current.destination);

          source.start(0);
        }
      }, [isAudioInitialized, effectsVolume, generalVolume]);

      const playWinSound = useCallback(() => {
        if (isAudioInitialized && audioContextRef.current && winAudioBufferRef.current) {
          const source = audioContextRef.current.createBufferSource();
          source.buffer = winAudioBufferRef.current;

          const gainNode = audioContextRef.current.createGain();
          gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

          source.connect(gainNode);
          gainNode.connect(audioContextRef.current.destination);

          source.start(0);
        }
      }, [isAudioInitialized, effectsVolume, generalVolume]);

      const playLoseSound = useCallback(() => {
        if (isAudioInitialized && audioContextRef.current && loseAudioBufferRef.current) {
          const source = audioContextRef.current.createBufferSource();
          source.buffer = loseAudioBufferRef.current;

          const gainNode = audioContextRef.current.createGain();
          gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

          source.connect(gainNode);
          gainNode.connect(audioContextRef.current.destination);

          source.start(0);
        }
      }, [isAudioInitialized, effectsVolume, generalVolume]);

      const playStartSound = useCallback(() => {
        if (isAudioInitialized && audioContextRef.current && startAudioBufferRef.current) {
          const source = audioContextRef.current.createBufferSource();
          source.buffer = startAudioBufferRef.current;

          const gainNode = audioContextRef.current.createGain();
          gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

          source.connect(gainNode);
          gainNode.connect(audioContextRef.current.destination);

          source.start(0);
        }
      }, [isAudioInitialized, effectsVolume, generalVolume]);

      const kikSound = useCallback(() => {
        if (isAudioInitialized && audioContextRef.current && kikAudioBufferRef.current) {
          const source = audioContextRef.current.createBufferSource();
          source.buffer = kikAudioBufferRef.current;

          const gainNode = audioContextRef.current.createGain();
          gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

          source.connect(gainNode);
          gainNode.connect(audioContextRef.current.destination);

          source.start(0);
        }
      }, [isAudioInitialized, effectsVolume, generalVolume]);

      useEffect(() => {
        const initializeAudio = async () => {
          if (!isAudioInitialized) {
            try {
              const AudioContext = window.AudioContext || window.webkitAudioContext;
              audioContextRef.current = new AudioContext();

              const clickResponse = await fetch(clickSoundMp3);
              const clickArrayBuffer = await clickResponse.arrayBuffer();
              clickAudioBufferRef.current = await audioContextRef.current.decodeAudioData(clickArrayBuffer);

              const winResponse = await fetch(winSoundMp3);
              const winArrayBuffer = await winResponse.arrayBuffer();
              winAudioBufferRef.current = await audioContextRef.current.decodeAudioData(winArrayBuffer);

              const loseResponse = await fetch(loseSoundMp3);
              const loseArrayBuffer = await loseResponse.arrayBuffer();
              loseAudioBufferRef.current = await audioContextRef.current.decodeAudioData(loseArrayBuffer);

              const startResponse = await fetch(startSoundMp3);
              const startArrayBuffer = await startResponse.arrayBuffer();
              startAudioBufferRef.current = await audioContextRef.current.decodeAudioData(startArrayBuffer);

              const kikResponse = await fetch(kikSoundMp3);
              const kikArrayBuffer = await kikResponse.arrayBuffer();
              kikAudioBufferRef.current = await audioContextRef.current.decodeAudioData(kikArrayBuffer);

              setIsAudioInitialized(true);
            } catch (error) {
            }
          }
        };

        initializeAudio();
    }, [isAudioInitialized]);

    const fetchGamePrice = async () => {
        try {
            const response = await axios.post(API_URLS.POST_GAME5_MONEY, {
                tg_user_id: user.user_id
            });
            if (response.data.type === "ok") {
                setGamePrice(response.data.cost);
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchGamePrice();
    }, []);

    const startGame = async () => {
        setIsLoading(true);
        playStartSound();
        try {
            const response = await axios.post(API_URLS.REACT_APP_GAME5_START, {
                tg_user_id: user.user_id
            });
            if (response.data.type === "ok") {
                setSessionId(response.data.session_id);
                const newBalance = user.g_token - gamePrice;
                updateUser({ g_token: newBalance });
                setGameStarted(true);
                setGameResult(null);
                setCollidedPlayers([]);
                lastCollidedPlayers.current = null;
                setBallPosition({ x: 50, y: 15 });
            }
            else if (response.data.type === "error" && response.data.error_code === "551") {
                setCustomMessage(t("GamePage.Game5.Money"));
            }else {
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const endGame = useCallback(async (gameResult) => {
        try {
            const response = await axios.post(API_URLS.REACT_APP_GAME5_END, {
                tg_user_id: user.user_id,
                session_id: sessionId,
                is_win: gameResult
            });
            if (response.data.type === "ok") {
                setGameResult(gameResult);
                setGameStarted(false);
                setSessionId(null);
                updateUser({ g_token: response.data.new_g_token });
                setIsWaitingResult(false);
                if (gameResult === 1) {
                    playWinSound();
                } else if (gameResult === 0) {
                    playLoseSound();
                }
            }
        } catch (error) {
        }
    }, [
        user.user_id, 
        sessionId, 
        playWinSound, 
        playLoseSound,
        setGameResult,  // Додаємо setGameResult
        setGameStarted, // Додаємо setGameStarted
        setSessionId,   // Додаємо setSessionId
        setIsWaitingResult // Додаємо setIsWaitingResult
    ]);

    const handleKickOrStart = async () => {
        if (!user || !user.user_id) {
            return;
        }
        if (!gameStarted) {
            playSound();
            await startGame();
        } else {
            if (!isKicking && !isWaitingResult) {
                kikSound();
                setIsKicking(true);
                setIsWaitingResult(true);
                setBallPosition({ x: 50, y: 10 });
                setShowGoalkeeper(false);
                if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                    wsRef.current.send(JSON.stringify({ action: 'kick' }));
                }
            }
        }
    };

    const calculatePlayerPosition = useCallback((position) => {
        const adjustedPosition = ((position - 5) / 60) * 100;
        return Math.max(0, Math.min(100, (adjustedPosition * (screenWidth - (2 * HORIZONTAL_PADDING)) / screenWidth) + (HORIZONTAL_PADDING / screenWidth * 100)));
    }, [screenWidth, HORIZONTAL_PADDING]);

    const calculateBallSize = useMemo(() => {
        const initialSize = 20;
        const maxSize = 60;
        const size = initialSize + ((ballPosition.y / 100) * (maxSize - initialSize));
        return `${size}px`;
    }, [ballPosition.y]);

    const checkCollisions = useCallback(() => {
        const ballCenter = {
            x: ballPosition.x,
            y: ballPosition.y
        };
    
        const lines = [
            { y: 65, players: ['line3', 'line4'] },  
            { y: 78, players: ['line5', 'line6'] },
        ];
    
        let hasCollision = false;
    
        for (const line of lines) {
            // Збільшуємо вертикальну зону до 4
            if (Math.abs(ballCenter.y - line.y) <= 4) {
                for (const playerKey of line.players) {
                    const xPosition = playerPositions[playerKey];
                    const playerCenterX = calculatePlayerPosition(xPosition);
    
                    // Збільшуємо горизонтальну зону до 5
                    const dx = Math.abs(ballCenter.x - playerCenterX);
                    
                    if (dx <= 7) {
                        hasCollision = true;
                        if (!collidedPlayers.includes(playerKey)) {
                            setCollidedPlayers(prevPlayers => [...prevPlayers, playerKey]);
                            setShowGoalkeeper(true);
                        }
                        break;
                    }
                }
            }
    
            if (hasCollision) {
                break;
            }
        }
    
        return hasCollision;
    }, [
        ballPosition, 
        calculatePlayerPosition, 
        playerPositions, 
        collidedPlayers,
        setCollidedPlayers, 
        setShowGoalkeeper
    ]);
    
    useEffect(() => {
        if (isKicking) {
            setShowGoalkeeper(false);
        }
    }, [isKicking]);

    useEffect(() => {
        if (isKicking) {
            const moveInterval = setInterval(() => {
                setBallPosition(prev => {
                    const newY = prev.y + 2;
                    const newPosition = { x: prev.x, y: newY };
    
                    if (newY >= 90) {
                        clearInterval(moveInterval);
                        setIsKicking(false);
                        
                        const hasCollisions = collidedPlayers.length > 0;
                        const isGoalkeeperActive = showGoalkeeper;
                        const isWin = !hasCollisions && !isGoalkeeperActive;
                        
                        console.log('Game ended:', {
                            hasCollisions,
                            isGoalkeeperActive,
                            collidedPlayers,
                            isWin
                        });
                        
                        endGame(isWin ? 1 : 0);
                        return prev;
                    }
    
                    checkCollisions();
                    return newPosition;
                });
            }, 12);
    
            return () => clearInterval(moveInterval);
        }
    }, [isKicking, collidedPlayers, showGoalkeeper, endGame, checkCollisions]);

    useEffect(() => {
        if (gameStarted) {
            setCollidedPlayers([]);
            lastCollidedPlayers.current = [];
        }
    }, [gameStarted]);


        const calculateGnomeSize = useCallback(() => {
        if (gameAreaRef.current) {
            const { width, height } = gameAreaRef.current.getBoundingClientRect();
            const minDimension = Math.min(width, height);
            return Math.max(20, Math.min(60, minDimension * 0.15));
        }
        return 40;
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
            setGnomeSize(calculateGnomeSize());
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [calculateGnomeSize]);

    useEffect(() => {
    }, [gameStarted, sessionId]);

    const styles = useMemo(() => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            position: 'relative',
        },
        gameArea: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden',
            backgroundColor: '#4a8f29',
        },
        backgroundImage: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${soccerFieldImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        controlPanel: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'transparent', // Змінили на прозорий
            padding: '0 10px',
            zIndex: 100,
        },
        ball: {
            width: calculateBallSize,
            height: calculateBallSize,
            backgroundImage: `url(${soccerBallImage})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            position: 'absolute',
            left: `calc(${ballPosition.x}% - ${parseInt(calculateBallSize) / 2}px)`,
            bottom: `${ballPosition.y}%`,
            transition: 'bottom 0.016s linear, width 0.016s linear, height 0.016s linear',
        },
        player: {
            width: `${gnomeSize}px`,
            height: `${gnomeSize}px`,
            position: 'absolute',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            transition: 'left 1s ease-out, bottom 1s ease-out',
        },
        goalkeeper: {
            width: `${gnomeSize * 1.2}px`,
            height: `${gnomeSize * 1.2}px`,
            position: 'absolute',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            left: '50%',
            bottom: '88%',
            transform: 'translateX(-50%)',
            transition: 'left 0.3s ease-out, bottom 0.3s ease-out',
        },
    }), [ballPosition, calculateBallSize, gnomeSize]);

    const renderPlayers = useCallback(() => {
        const players = [];
        const lines = [
            { y: 65, players: ['line3', 'line4'] },  
            { y: 45, players: ['line5', 'line6'] },
        ];

        lines.forEach(line => {
            line.players.forEach((playerKey) => {
                const xPosition = playerPositions[playerKey];
                if (xPosition !== undefined) {
                    const adjustedPosition = calculatePlayerPosition(xPosition);
                    const isHighlighted = Array.isArray(collidedPlayers) && collidedPlayers.includes(playerKey);
                    players.push(
                        <div
                            key={playerKey}
                            style={{
                                ...styles.player,
                                left: `${adjustedPosition}%`,
                                bottom: `${line.y}%`,
                                backgroundImage: `url(${gnomeImages[playerKey]})`,
                                filter: isHighlighted ? 'drop-shadow(0 0 10px red)' : 'none',
                                transition: 'left 0.1s linear, bottom 0.1s linear, filter 0.3s ease-in-out',
                                position:"absolute"
                            }}
                        />
                    );
                }
            });
        });

        if (showGoalkeeper) {
            players.push(
                <div
                    key="goalkeeper"
                    style={{
                        ...styles.goalkeeper,
                        backgroundImage: `url(${gnomtake})`,
                    }}
                />
            );
        }

        return players;
    }, [playerPositions, calculatePlayerPosition, collidedPlayers, showGoalkeeper, styles.player, styles.goalkeeper, gnomeImages]);
    const renderCustomMessage = (message) => {
        if (message) {
            return (
                <div className="custom-message" style={{
                    position: 'absolute',
                    top: '20%',
                    left: '0',
                    right: '0',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(0, 128, 0, 1)',
                    padding: '20px',
                    width: '100%',
                    textAlign: 'center',
                    fontSize: 'clamp(24px, 5vw, 48px)',
                    fontWeight: 'bold',
                    color: 'white',
                    zIndex: 1000
                }}>
                    {message}
                </div>
            );
        }
        return null;
    };
    useEffect(() => {
        if (customMessage) {
            const timer = setTimeout(() => {
                setCustomMessage(null);
            }, 2500);

            return () => clearTimeout(timer);
        }
    }, [customMessage]);
    const renderGameResult = () => {
        if (gameResult !== null) {
            return (
                <div className="game-result" style={{
                    position: 'absolute',
                    top: '20%',
                    left: '0',
                    right: '0',
                    transform: 'translateY(-50%)',
                    backgroundColor: gameResult === 1 ? 'rgba(0, 128, 0, 1)' : 'rgba(205, 50, 50, 1)',
                    padding: '20px',
                    width: '100%',
                    textAlign: 'center',
                    fontSize: 'clamp(24px, 5vw, 48px)',
                    fontWeight: 'bold',
                    color: 'white',
                    zIndex: 1000
                }}>
                    {gameResult === 1 ? t("GamePage.Game5.Win") : t("GamePage.Game5.Lose")}
                </div>
            );
        }
        return null;
    };

    const formatBalance = (balance) => {
        return Number(balance).toFixed(2);
      };

    return (
        <div style={styles.container}>
            <div style={styles.gameArea} ref={gameAreaRef}>
                <div style={styles.backgroundImage} />
                <div style={styles.ball} />
                {renderPlayers()}
                {renderGameResult()}
                {renderCustomMessage(customMessage)}
            </div>
            <div style={styles.controlPanel}>
                <div className="game-buttom-list-five">
                <TelegramButton
                        className="game-button"
                        onClick={handleKickOrStart}
                        disabled={!isConnected || isKicking || isLoading || isWaitingResult}
                    >
                        {isLoading ? t("GamePage.Game5.Loading") :
                         (gameStarted ?
                            (isWaitingResult ? t("GamePage.Game5.WaitingResult") : t("GamePage.Game5.Hit")) :
                            `${t("GamePage.Game5.StartGame")} (${gamePrice}G)`)}
                </TelegramButton>
                    <div className="earning-game">
                        <div className="earning-content-game">
                                <IconCoin className="earning-icon-small-game" />
                            <div className="earning-text-container-game">
                                <p className="earning-text-game"> {formatBalance(user.g_token)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(CoreGameFive);
