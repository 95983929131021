import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from "axios";
import { useUser } from "../../contexts/UserContext";
import { API_URLS } from "../../config/config";
import { useAudio } from '../../contexts/AudioContext';
import "../../assets/styles/games/game_core1.css";
import TradeWarningModal from "../../components/TradeWarningModal";
import modalBackground from "../../assets/images/fairy-modal-background.png";
import gameIcon from "../../assets/icons/pocket.webp";
import gameGnome from "../../assets/icons/gnom.webp";
import gameCoin from "../../assets/icons/coin.webp";
import { ReactComponent as IconCoin } from "../../assets/icons/coin.svg";
import formatBalance from "../../utils/formatBalance";
import { useTranslation } from "react-i18next";
import backSound from "../../assets/sounds/game1-back.mp3";
import clickSoundMp3 from '../../assets/sounds/menu-button-click.mp3';
import shufflingTheCardsSound from '../../assets/sounds/shuffling-the-cards.mp3';
import flipCardSound from '../../assets/sounds/flip-card.mp3';
import moneySoundMp3 from "../../assets/sounds/bonus-money-sound.mp3";
import fiascoSoundMp3 from "../../assets/sounds/fiasco.mp3";

const ANIMATION_CONFIG   = {
    totalElements: 9,
    elements: [
        { type: '+', count: 4 },
        { type: '-', count: 4 },
        { type: '*', count: 1 }
    ]
};

const CoreGameOne = ({ handleBackgroundMusic, stopBackgroundMusic }) => {
    const [gameData, setGameData] = useState(null);
    const [flipped, setFlipped] = useState(Array(9).fill(false));
    const [shuffling, setShuffling] = useState(false);
    const [positions, setPositions] = useState(Array(9).fill(null));
    const [gameStarted, setGameStarted] = useState(false);
    const [openedCard, setOpenedCard] = useState(null);
    const [gamePrice, setGamePrice] = useState(null);
    const [gameResultData, setGamesResultData] = useState(Array(9).fill(null));
    const { user, updateUser } = useUser();
    const [gToken, setGToken] = useState(user.g_token);
    const containerRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const [showTempContent, setShowTempContent] = useState(false);
    const [tempResults, setTempResults] = useState(Array(9).fill(null));
    const { t } = useTranslation();
       const [elementConfig, setElementConfig] = useState({
        '+': 4,
        '-': 4,
        '*': 1
    });
    const [modalState, setModalState] = useState({
        isOpen: false,
        message: "",
        link: "",
      });
    const { changeBackgroundMusic, revertToDefaultMusic, activeMusic } = useAudio();

    const { effectsVolume, generalVolume } = useAudio();
    const [isAudioInitialized, setIsAudioInitialized] = useState(false);
    const audioContextRef = useRef(null);
    const clickAudioBufferRef = useRef(null);
    const flipAudioBufferRef = useRef(null);
    const [shuffleAudioBuffer, setShuffleAudioBuffer] = useState(null);
    const shuffleAudioRef = useRef(null);
    const shuffleGainNodeRef = useRef(null);
    const moneyAudioBufferRef = useRef(null);
    const fiascoAudioBufferRef = useRef(null);

    const playSound = useCallback(() => {
        if (isAudioInitialized && audioContextRef.current && clickAudioBufferRef.current) {
          const source = audioContextRef.current.createBufferSource();
          source.buffer = clickAudioBufferRef.current;
          
          const gainNode = audioContextRef.current.createGain();
          gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
          
          source.connect(gainNode);
          gainNode.connect(audioContextRef.current.destination);
          
          source.start(0);
        }
      }, [isAudioInitialized, effectsVolume, generalVolume]);

     
    const playFlipSound = useCallback(() => {
        if (isAudioInitialized && audioContextRef.current && flipAudioBufferRef.current) {
          const source = audioContextRef.current.createBufferSource();
          source.buffer = flipAudioBufferRef.current;
          
          const gainNode = audioContextRef.current.createGain();
          gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
          
          source.connect(gainNode);
          gainNode.connect(audioContextRef.current.destination);
          
          source.start(0);
        }
    }, [isAudioInitialized, effectsVolume, generalVolume]);

    const playMoneySound = useCallback(() => {
        if (isAudioInitialized && audioContextRef.current && moneyAudioBufferRef.current) {
          const source = audioContextRef.current.createBufferSource();
          source.buffer = moneyAudioBufferRef.current;
    
          const gainNode = audioContextRef.current.createGain();
          gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
    
          source.connect(gainNode);
          gainNode.connect(audioContextRef.current.destination);
    
          source.start(0);
        }
    }, [isAudioInitialized, effectsVolume, generalVolume]);

      const playShuffleSound = useCallback(() => {
        if (isAudioInitialized && audioContextRef.current && shuffleAudioBuffer) {
            const source = audioContextRef.current.createBufferSource();
            source.buffer = shuffleAudioBuffer;
            source.loop = true; 
            
            const gainNode = audioContextRef.current.createGain();
            gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
            
            source.connect(gainNode);
            gainNode.connect(audioContextRef.current.destination);
            
            shuffleAudioRef.current = source;
            shuffleGainNodeRef.current = gainNode;

        }
    }, [isAudioInitialized, shuffleAudioBuffer, effectsVolume, generalVolume]);

    const playFiascoSound = useCallback(() => {
        if (isAudioInitialized && audioContextRef.current && fiascoAudioBufferRef.current) {
            const source = audioContextRef.current.createBufferSource();
            source.buffer = fiascoAudioBufferRef.current;
            
            const gainNode = audioContextRef.current.createGain();
            gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);
            
            source.connect(gainNode);
            gainNode.connect(audioContextRef.current.destination);
            
            source.start(0);
        }
    }, [isAudioInitialized, effectsVolume, generalVolume]);

    const stopShuffleSound = useCallback(() => {
        if (shuffleAudioRef.current) {
            shuffleAudioRef.current.stop();
            shuffleAudioRef.current = null;
        }
    }, []);
    useEffect(() => {
        const initializeAudio = async () => {
          if (!isAudioInitialized) {
            try {
              const AudioContext = window.AudioContext || window.webkitAudioContext;
              audioContextRef.current = new AudioContext();

              const clickResponse = await fetch(clickSoundMp3);
              const clickArrayBuffer = await clickResponse.arrayBuffer();
              clickAudioBufferRef.current = await audioContextRef.current.decodeAudioData(clickArrayBuffer);

              const flipResponse = await fetch(flipCardSound);
              const flipArrayBuffer = await flipResponse.arrayBuffer();
              flipAudioBufferRef.current = await audioContextRef.current.decodeAudioData(flipArrayBuffer);

              const moneyResponse = await fetch(moneySoundMp3);
              const moneyArrayBuffer = await moneyResponse.arrayBuffer();
              moneyAudioBufferRef.current = await audioContextRef.current.decodeAudioData(moneyArrayBuffer);

              const shuffleResponse = await fetch(shufflingTheCardsSound);
              const shuffleArrayBuffer = await shuffleResponse.arrayBuffer();
              setShuffleAudioBuffer(await audioContextRef.current.decodeAudioData(shuffleArrayBuffer));

              const fiascoResponse = await fetch(fiascoSoundMp3);
              const fiascoArrayBuffer = await fiascoResponse.arrayBuffer();
              fiascoAudioBufferRef.current = await audioContextRef.current.decodeAudioData(fiascoArrayBuffer);
    
              setIsAudioInitialized(true);
            } catch (error) {
            }
          }
        };
    
        initializeAudio();
    }, [isAudioInitialized]);

    const updateModalState = (message, isOpen = true, link = "") => {
        setModalState({ isOpen, message, link });
    };

        useEffect(() => {
        const fetchGameData = async () => {
            try {
                const response = await axios.post(API_URLS.POST_GAME1_MONEY, {
                    headers: {
                        Accept: "application/json",
                        "ngrok-skip-browser-warning": "true",
                    },
                    tg_user_id: user.user_id
                });
                if (response.data.type === "error" && response.data.error_code === "553") {
                    return;
                }
                setGamePrice(response.data.cost);
                setLoading(false);
            } catch (err) {
                setError('Error fetching game data');
                setLoading(false);
            }
        };

        fetchGameData();
    }, []);

    const handleClick = async (index) => {
       
        if (gameStarted && !shuffling && !isAnimating && openedCard === null && index !== 4) {
            playFlipSound();
            const newFlipped = [...flipped];
            newFlipped[index] = true;
            setFlipped(newFlipped);
            setOpenedCard(index);

            try {
                if (!gameData || !gameData.id_game) {
                    return;
                }

                const response = await axios.post(API_URLS.POST_GAME1_RESULT, {
                    game_started_id: gameData.id_game
                });

                if (response.data && response.data.type === "ok") {
                    setGamesResultData(prevState => {
                        const newState = [...prevState];
                        newState[index] = response.data.data;
                        return newState;
                    });
                    
                    if (response.data.data === '+') {
                        playMoneySound(); // Win sound
                    } else if (response.data.data === '-') {
                        playFiascoSound(); // Loss sound
                    }

                    if (response.data.new_g_token !== undefined) {
                        setGToken(response.data.new_g_token);
                        updateUser({ g_token: response.data.new_g_token });
                    }
                }
            } catch (error) {
            }
        }
    };

    const getCardBackContent = (index) => {
        const getmoney = gamePrice + gamePrice;
        if (showTempContent) {
            const result = tempResults[index];
            if (result === '+') {
                return (
                    <div className="card-back positive">
                        {/* <IconCoin className="earning-icon-mega-small" /> */}
                        <img className="earning-icon-mega-small" src={gameCoin} alt="" />
                        <p>+{getmoney}</p>
                    </div>
                );
            } else if (result === '-') {
                return (
                    <div className="card-back negative">
                        {/* <IconCoin className="earning-icon-mega-small" /> */}
                        <img className="earning-icon-mega-small" src={gameCoin} alt="" />
                        <p>-{getmoney}</p>

                    </div>
                );
            } else if (result === '*') {
                return (
                    <div className="card-back gnome">
                        <img className="card-img-gnome" src={gameGnome} alt="" />
                    </div>
                );
            }
        } else if (gameResultData[index] !== null) {
            const result = gameResultData[index];
            if (result === '+') {
                return (
                    <div className="card-back positive">
                        {/* <IconCoin className="earning-icon-mega-small" /> */}
                        <img className="earning-icon-mega-small" src={gameCoin} alt="" />
                        <p>+{getmoney}</p>
                    </div>
                );
            } else if (result === '-') {
                return (
                    <div className="card-back negative">
                        {/* <IconCoin className="earning-icon-mega-small" /> */}
                        <img className="earning-icon-mega-small" src={gameCoin} alt="" />
                        <p>-{getmoney}</p>
                    </div>
                );
            } else if (result === '*') {
                return (
                    <div className="card-back gnome">
                        <img className="card-img-gnome" src={gameGnome} alt="" />
                    </div>
                );
            }
        }
        return (
            <div className="card-back default">
            </div>
        );
    };

    const getGridPosition = (index) => {
        const cardSize = (300 - 20) / 3;
        const row = Math.floor(index / 3);
        const col = index % 3;
        return { x: col * (cardSize + 10), y: row * (cardSize + 10), rotate: 0 };
    };

    useEffect(() => {
        setPositions(Array(9).fill(null).map((_, i) => getGridPosition(i)));
    }, []);

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const generateConfiguredResults = () => {
        let results = [];
        ANIMATION_CONFIG.elements.forEach(element => {
            results = results.concat(Array(element.count).fill(element.type));
        });
        while (results.length < ANIMATION_CONFIG.totalElements) {
            results.push(null);
        }
        return shuffleArray(results);
    };

    const shuffle = async () => {
        if (isAnimating) return;

        playSound();
        setIsAnimating(true);
        setShuffling(true);
        setGameStarted(true);
        setOpenedCard(null);
        setGamesResultData(Array(9).fill(null));
        setTempResults(generateConfiguredResults());
        setShowTempContent(true);
        playShuffleSound();

        try {
            const response = await axios.post(API_URLS.POST_GAME1_START, {
                tg_user_id: user.user_id
            });

            if (response.data.type === "error" && response.data.error_code === "551") {
                updateModalState(t("GamePage.NotEnoughBalance"), true);
                setShuffling(false);
                setGameStarted(false);
                setIsAnimating(false);
                setShowTempContent(false);
                return;
            }

            if (response.data) {
                setGameData(response.data);
                setGToken(response.data.new_g_token);
                updateUser({ g_token: response.data.new_g_token });
            }
        } catch (error) {
            setShuffling(false);
            setGameStarted(false);
            setIsAnimating(false);
            setShowTempContent(false);
            return;
        }
        if (shuffleAudioRef.current) {
            shuffleAudioRef.current.start();
        }

        setPositions(positions.map((pos, i) => ({
            x: 100,
            y: 100,
            rotate: i * 40,
            scale: 0.5
        })));
        setFlipped(Array(9).fill(true));

        setTimeout(() => {
            setPositions(positions.map((_, i) => ({
                ...getGridPosition(i),
                scale: 1,
                rotate: 360
            })));
        }, 1500);

        setTimeout(() => {
            const shuffledIndices = shuffleArray([...Array(9).keys()]);
            setPositions(shuffledIndices.map((i) => ({
                ...getGridPosition(i),
                scale: 1,
                rotate: 360
            })));
        }, 3000);

        setTimeout(() => {
            setPositions(positions.map((pos) => ({ ...pos, rotate: 0 })));
            setFlipped(Array(9).fill(false));
            setShuffling(false);
            setIsAnimating(false);
            setShowTempContent(false);
            stopShuffleSound(); 
        }, 4500);
    };
    return (
        <div className="game-wrapper">
            <TradeWarningModal
                    key={modalState.isOpen ? "open" : "closed"}
                    modalState={modalState}
                    background={modalBackground}
                    onClose={() => updateModalState("", false)}
                />
            <div className={`gameContainer-one ${shuffling ? 'shuffling' : ''}`} ref={containerRef}>
                {[...Array(9)].map((_, index) => (
                    <div
                        key={index}
                        className={`gameButton-main ${shuffling ? 'shuffle-animation' : ''} ${index === 4 ? 'invisible' : ''}`}
                        onClick={() => handleClick(index)}
                        style={{
                            transform: `translate(${positions[index]?.x}px, ${positions[index]?.y}px) 
                                            rotate(${positions[index]?.rotate || 0}deg) 
                                            scale(${positions[index]?.scale || 1})`,
                            visibility: index === 4 ? 'hidden' : 'visible',
                            pointerEvents: index === 4 ? 'none' : 'auto'
                        }}
                    >
                        <div className={`card-inner ${flipped[index] || shuffling ? 'is-flipped' : ''}`}>
                            <div className="card-front">
                                <img src={gameIcon} alt={`Game button ${index + 1}`} />
                            </div>
                            {getCardBackContent(index)}
                        </div>
                    </div>
                ))}
            </div>
            <div className="game-buttom-list">
                <button className="game-button" onClick={shuffle} disabled={isAnimating}>
                    {gameStarted ? `${t('GamePage.Game1.StartGame')} (${gamePrice}G)` : `${t('GamePage.Game1.StartGame')} (${gamePrice}G)`}
                </button>
                <div className="earning-game">
                    <div className="earning-content-game">
                        <div className="earning-icon-container-game">
                            <IconCoin className="earning-icon-small-game" />
                            <p className="token-game">Token</p>
                        </div>
                        <div className="earning-text-container-game">
                            <p className="earning-text-game">{gToken}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoreGameOne;