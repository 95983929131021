import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import backgroundSound from '../assets/sounds/background-sound.mp3';

const AudioContext = createContext();

export const AudioProvider = ({ children }) => {
  const [generalVolume, setGeneralVolume] = useState(0);
  const [musicVolume, setMusicVolume] = useState(0);
  const [effectsVolume, setEffectsVolume] = useState(100);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isVolumeSet, setIsVolumeSet] = useState(false);
  const [currentMusic, setCurrentMusic] = useState(backgroundSound);
  const [isGameMusic, setIsGameMusic] = useState(false);
  
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const gainNodeRef = useRef(null);
  const defaultSourceNodeRef = useRef(null);

  const initializeAudio = useCallback(async () => {
    if (!audioContextRef.current) {
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      audioContextRef.current = new AudioContext();
      
      try {
        await loadAndPlayMusic(backgroundSound, true);
        setIsInitialized(true);
        if (gainNodeRef.current) {
          gainNodeRef.current.gain.setValueAtTime(0, audioContextRef.current.currentTime);
        }
      } catch (error) {
      }
    }
  }, []);

  const changeBackgroundMusic = useCallback(async (newMusicSource, isGame = true) => {
    if (newMusicSource !== currentMusic || isGame !== isGameMusic) {
      try {
        if (isGame) {
          if (defaultSourceNodeRef.current) {
            defaultSourceNodeRef.current.stop();
          }
        } else {
          if (sourceNodeRef.current) {
            sourceNodeRef.current.stop();
          }
        }
        await loadAndPlayMusic(newMusicSource, !isGame);
        setIsGameMusic(isGame);
        setCurrentMusic(newMusicSource);
      } catch (error) {
        revertToDefaultMusic();
      }
    }
  }, [currentMusic, isGameMusic]);

  const revertToDefaultMusic = useCallback(() => {
    if (currentMusic !== backgroundSound || isGameMusic) {
      if (defaultSourceNodeRef.current && !defaultSourceNodeRef.current.isPlaying) {
        changeBackgroundMusic(backgroundSound, false);
      } else if (sourceNodeRef.current) {
        sourceNodeRef.current.stop();
        sourceNodeRef.current.disconnect();
      }
      setCurrentMusic(backgroundSound);
      setIsGameMusic(false);
    }
  }, [currentMusic, isGameMusic, changeBackgroundMusic]);


  const loadAndPlayMusic = async (musicSource, isDefault = false) => {
    const sourceRef = isDefault ? defaultSourceNodeRef : sourceNodeRef;
    if (sourceRef.current) {
      sourceRef.current.stop();
      sourceRef.current.disconnect();
    }

    const response = await fetch(musicSource);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
    
    sourceRef.current = audioContextRef.current.createBufferSource();
    sourceRef.current.buffer = audioBuffer;
    sourceRef.current.loop = true;
    sourceRef.current.isPlaying = true;
    
    if (!gainNodeRef.current) {
      gainNodeRef.current = audioContextRef.current.createGain();
      gainNodeRef.current.connect(audioContextRef.current.destination);
    }
    
    sourceRef.current.connect(gainNodeRef.current);
    sourceRef.current.start();
    sourceRef.current.onended = () => {
      sourceRef.current.isPlaying = false;
    };
  };

  const calculateVolume = useCallback((general, music) => {
    return (general / 100) * (music / 100);
  }, []);

  const setVolumes = useCallback((general, music, effects) => {
    setGeneralVolume(general);
    setMusicVolume(music);
    setEffectsVolume(effects);
    setIsVolumeSet(true);
    updateVolume();
  }, []);

  const updateVolume = useCallback(() => {
    if (gainNodeRef.current && isInitialized) {
      const volume = calculateVolume(generalVolume, musicVolume);
      const currentTime = audioContextRef.current.currentTime;
      gainNodeRef.current.gain.setValueAtTime(gainNodeRef.current.gain.value, currentTime);
      gainNodeRef.current.gain.linearRampToValueAtTime(volume, currentTime + 0.5);
    }
  }, [generalVolume, musicVolume, calculateVolume, isInitialized]);

  useEffect(() => {
    if (isInitialized) {
      updateVolume();
    }
  }, [generalVolume, musicVolume, updateVolume, isInitialized]);

  const playBackgroundMusic = useCallback(async () => {
    try {
      if (!audioContextRef.current) {
        await initializeAudio();
      }
      
      if (audioContextRef.current.state === 'suspended') {
        await audioContextRef.current.resume();
      }
      
      setIsPaused(false);
      
      if (isVolumeSet) {
        updateVolume();
      }
    } catch (error) {
    }
  }, [initializeAudio, updateVolume, isVolumeSet]);

  const pauseBackgroundMusic = useCallback(() => {
    if (audioContextRef.current && audioContextRef.current.state === 'running') {
      audioContextRef.current.suspend();
      setIsPaused(true);
    }
  }, []);

  const resumeBackgroundMusic = useCallback(() => {
    if (audioContextRef.current && audioContextRef.current.state === 'suspended') {
      audioContextRef.current.resume();
      setIsPaused(false);
    }
  }, []);

  useEffect(() => {
    if (isInitialized && isVolumeSet) {
      updateVolume();
    }
  }, [generalVolume, musicVolume, updateVolume, isInitialized, isVolumeSet]);

  return (
    <AudioContext.Provider value={{
      generalVolume,
      setGeneralVolume,
      musicVolume,
      setMusicVolume,
      effectsVolume,
      setEffectsVolume,
      playBackgroundMusic,
      pauseBackgroundMusic,
      resumeBackgroundMusic,
      updateVolume,
      setVolumes,
      calculateVolume,
      isInitialized,
      isPaused,
      changeBackgroundMusic,
      revertToDefaultMusic,
      currentMusic
    }}>
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = () => useContext(AudioContext);