import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Icon1 } from "../assets/icons/menu-first.svg";
import { ReactComponent as Icon2 } from "../assets/icons/menu-second.svg";
import { ReactComponent as Icon3 } from "../assets/icons/menu-thrid.svg";
import { ReactComponent as Icon4 } from "../assets/icons/menu-fourth.svg";
import { ReactComponent as Icon5 } from "../assets/icons/menu-fifth.svg";
import { useAudio } from "../contexts/AudioContext";
import "../assets/styles/menu.css";
import clickSoundMp3 from "../assets/sounds/menu-button-click.mp3";

const Menu = ({ activePage, onMenuClick }) => {
  const { t } = useTranslation();
  const { effectsVolume, generalVolume, revertToDefaultMusic } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const audioBufferRef = useRef(null);

  useEffect(() => {
    let audioInitialized = false;

    const initAudioContext = () => {
      if (!audioInitialized) {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        audioContextRef.current = new AudioContext();
        audioInitialized = true;
        
        // Завантажуємо звук після ініціалізації контексту
        loadSound();
      }
    };

    const loadSound = async () => {
      try {
        const response = await fetch(clickSoundMp3);
        const arrayBuffer = await response.arrayBuffer();
        if (audioContextRef.current) {
          audioBufferRef.current = await audioContextRef.current.decodeAudioData(arrayBuffer);
          setIsAudioInitialized(true);
        }
      } catch (error) {
        console.error("Error loading sound:", error);
      }
    };

    // Додаємо слухачі для ініціалізації аудіо після взаємодії користувача
    const userInteractionEvents = ['click', 'touchstart', 'keydown'];
    
    const handleUserInteraction = () => {
      initAudioContext();
      userInteractionEvents.forEach(event => 
        document.removeEventListener(event, handleUserInteraction)
      );
    };

    userInteractionEvents.forEach(event => 
      document.addEventListener(event, handleUserInteraction, { once: true })
    );

    return () => {
      userInteractionEvents.forEach(event => 
        document.removeEventListener(event, handleUserInteraction)
      );
    };
  }, []);

  const playSound = () => {
    if (isAudioInitialized && audioContextRef.current && audioBufferRef.current) {
      try {
        const source = audioContextRef.current.createBufferSource();
        source.buffer = audioBufferRef.current;

        const gainNode = audioContextRef.current.createGain();
        gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

        source.connect(gainNode);
        gainNode.connect(audioContextRef.current.destination);

        source.start(0);
      } catch (error) {
        console.debug('Error playing sound:', error);
      }
    }
  };

  const handleButtonClick = (path) => {
    playSound();
    revertToDefaultMusic();
    onMenuClick(path);

    // Безпечна перевірка наявності HapticFeedback
    const hapticFeedback = window.Telegram?.WebApp?.HapticFeedback;
    if (hapticFeedback?.impactOccurred) {
      try {
        hapticFeedback.impactOccurred("light");
      } catch (error) {
        console.debug('HapticFeedback not supported');
      }
    }
  };

  return (
    <div className="menu-container">
      <div className="menu">
        <button
          className={`menu-button ${activePage === "/" ? "active" : ""}`}
          onClick={() => handleButtonClick("/")}
        >
          <Icon1 className="icon" />
          {t("Menu.MineText")}
        </button>
        <div className="vertical-line"></div>
        <button
          className={`menu-button ${activePage === "/pump" ? "active" : ""}`}
          onClick={() => handleButtonClick("/pump")}
        >
          <Icon2 className="icon" />
          {t("Menu.UpgradeText")}
        </button>
        <div className="vertical-line"></div>
        <button
          className={`menu-button ${activePage === "/partners" ? "active" : ""}`}
          onClick={() => handleButtonClick("/partners")}
        >
          <Icon3 className="icon" />
          {t("Menu.PartnersText")}
        </button>
        <div className="vertical-line"></div>
        <button
          className={`menu-button ${activePage === "/socials" ? "active" : ""}`}
          onClick={() => handleButtonClick("/socials")}
        >
          <Icon4 className="icon" />
          {t("Menu.MediaText")}
        </button>
      </div>
      <button
        className={`trade-button ${activePage === "/trade" ? "active" : ""}`}
        onClick={() => handleButtonClick("/trade")}
      >
        <Icon5 className="icon" />
        <p className="button-text">{t("Menu.TraderText")}</p>
      </button>
    </div>
  );
};

export default Menu;