import React, { useState, useEffect, useCallback, useRef } from "react";
import "../assets/styles/page1.css";
import axios from "axios";
import { ReactComponent as IconEnergy } from "../assets/icons/energy.svg";
import { ReactComponent as IconPick1 } from "../assets/images/MainPage/pickaxe/1.svg";
import { ReactComponent as IconPick2 } from "../assets/images/MainPage/pickaxe/2.svg";
import { ReactComponent as IconPick3 } from "../assets/images/MainPage/pickaxe/3.svg";
import { ReactComponent as IconPick4 } from "../assets/images/MainPage/pickaxe/4.svg";
import { ReactComponent as IconDwarf } from "../assets/icons/dwarf.svg";
import { ReactComponent as IconDolar } from "../assets/icons/dolar.svg";
import { ReactComponent as IconCoin } from "../assets/icons/coin.svg";
import iconMiniGames from "../assets/icons/mini-games.webp";
import prison from "../assets/images/prison.webp";
import { useNavigate } from "react-router-dom";
import fairyGif from "../assets/images/fairy.gif";
import presentGif from "../assets/images/present.gif";
import { API_URLS } from "../config/config";

// **** Контексти ****
import { useUser } from "../contexts/UserContext";
import { useClick } from "../contexts/ClickContext"; // <-- тепер беремо handleMainButtonClick та saveClicksBeforeNavigation
import { useAudio } from "../contexts/AudioContext";
import { useReward } from "../contexts/RewardContext";

// **** Компоненти ****
import AnimatedTool from "./AnimatedTool";
import AnimatedFairy from "./AnimatedFairy";
import RewardModal from "../components/RewardModal";
import { useTranslation } from "react-i18next";
import formatBalance from "../utils/formatBalance";

// Звуки
import clickSoundMp3 from '../assets/sounds/sound-of-digging-with-a-pickaxe.mp3';
import buttonClickSoundMp3 from '../assets/sounds/menu-button-click.mp3';
import bonuseMoneySoundMp3 from '../assets/sounds/bonus-money-sound.mp3';
import fairyGiftSoundMp3 from '../assets/sounds/fairy-gift-click.mp3';


const MainPage = ({ preloadedData, isFristInit, setIsFirstInit }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // **** Контексти ****
  const { user, updateUser } = useUser();
  const { handleMainButtonClick, saveClicksBeforeNavigation } = useClick(); // <-- головні методи
  const { effectsVolume, generalVolume } = useAudio();
  const {
    nextRewardTime,
    setNextRewardTime,
    adType,
    toggleRewardType,
    rewardIntervalMinutes,
  } = useReward();

  // **** Стан і рефи для UI ****
  const [audioContext, setAudioContext] = useState(null);
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);

  const [isButtonClickAudioInitialized, setIsButtonClickAudioInitialized] = useState(false);
  const buttonClickAudioContextRef = useRef(null);
  const buttonClickAudioClickAudioBufferRef = useRef(null);

  const [isBonusMoneyAudioInitialized, setIsBonusMoneyAudioInitialized] = useState(false);
  const bonusMoneyAudioContextRef = useRef(null);
  const bonusMoneyAudioBufferRef = useRef(null);
  const bonusMoneyAudioSourceRef = useRef(null);

  const [isFairyGiftAudioInitialized, setIsFairyGiftAudioInitialized] = useState(false);
  const fairyGiftAudioContextRef = useRef(null);
  const fairyGiftAudioBufferRef = useRef(null);

  const [isButtonZoomed, setIsButtonZoomed] = useState(false);
  const [isPickVisible, setIsPickVisible] = useState(false);
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  const fadeTimeoutRef = useRef(null);

  const [isRewardVisible, setIsRewardVisible] = useState(false);
  const [adReward, setAdReward] = useState(null);
  const [adId, setAdId] = useState(null);
  const [adFilePath, setAdFilePath] = useState(null);
  const [adIsVideo, setAdIsVideo] = useState(null);
  const [adUrl, setAdUrl] = useState(null);
  const [adRewardType, setAdRewarType] = useState(null);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [randomMessage, setRandomMessage] = useState("");
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [isWait, setIsWait] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [blockedUntil, setBlockedUntil] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);

  // Для "анімації балансу"
  const [displayedBalance, setDisplayedBalance] = useState(0);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const animationRef = useRef(null);

  const pageRef = useRef(null);
  const currentEnergyRef = useRef(user.current_energy);

  const multiplier = user.click_multiplier; // за потреби

  // ---------------- Функції для отримання зображень ----------------
  const getIconByRank = () => {
    switch (user.rank.id) {
      case 1:
        return IconPick1;
      case 2:
        return IconPick2;
      case 3:
        return IconPick3;
      case 4:
        return IconPick4;
      default:
        return IconPick1;
    }
  };

  const getGenderImg = () => {
    try {
      let gen;
      if (user.gender === null) {
        gen = 1;
        return require(`../assets/images/MainPage/personage/${user.rank.id}_${gen}.webp`);
      } else {
        return require(`../assets/images/MainPage/personage/${user.rank.id}_${user.gender}.webp`);
      }
    } catch {
      return require(`../assets/images/MainPage/personage/1_1.webp`);
    }
  };

  const getPickaxeIcon = () => {
    try {
      return require(`../assets/images/MainPage/pickaxe/${user.rank.id}.png`);
    } catch {
      return require(`../assets/images/MainPage/pickaxe/1.png`);
    }
  };

  const getBackgroundImage = (rankId, stageId) => {
    try {
      return require(`../assets/images/upgrade/GameBackground/${rankId}_${stageId}.webp`);
    } catch {
      return require(`../assets/images/upgrade/GameBackground/1_1.webp`);
    }
  };

  const backgroundStyle = {
    backgroundImage: `url(${getBackgroundImage(user.rank.id, user.stage.id)})`,
  };

  // ---------------- Форматування часу ----------------
  const formatTime = (ms) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    return `${hours.toString().padStart(2, "0")}:${(minutes % 60)
      .toString()
      .padStart(2, "0")}:${(seconds % 60).toString().padStart(2, "0")}`;
  };

  // ---------------- Оновлення lastActive ----------------
  const updateLastActive = async (userId) => {
    try {
      await axios.post(API_URLS.UPDATE_LAST_ACTIVE, { userId });
      // console.log()
    } catch (error) {
      // ігноруємо
    }
  };

  useEffect(() => {
    updateLastActive(user.user_id);
  }, [user.user_id]);

  // ---------------- Перевірка блокування ----------------
  const checkBlockStatus = async () => {
    try {
      const userResponse = await axios.get(API_URLS.GET_USER_INFO, {
        params: { userId: user.user_id },
        headers: {
          Accept: "application/json",
          "ngrok-skip-browser-warning": "true",
        },
      });
      if (typeof userResponse.data === "object" && userResponse.data.info) {
        const userInfo = userResponse.data.info;
        updateUser({ ...userInfo });

        setIsBlocked(userInfo.is_blocked);
        if (userInfo.is_blocked && userInfo.blocked_until) {
          setBlockedUntil(new Date(userInfo.blocked_until));
        } else {
          setBlockedUntil(null);
        }
      } else {
        throw new Error("Unexpected response structure from backend for user data");
      }
    } catch (error) {
      // ігноруємо
    }
  };

  // ---------------- Ініціалізація звуків ----------------
  useEffect(() => {
    const initializeBonusMoneyAudio = async () => {
      if (!isBonusMoneyAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          bonusMoneyAudioContextRef.current = new AudioContext();

          const response = await fetch(bonuseMoneySoundMp3);
          const arrayBuffer = await response.arrayBuffer();
          const audioBuffer = await bonusMoneyAudioContextRef.current.decodeAudioData(arrayBuffer);

          bonusMoneyAudioBufferRef.current = audioBuffer;
          setIsBonusMoneyAudioInitialized(true);
        } catch (error) {
          // ігноруємо
        }
      }
    };
    initializeBonusMoneyAudio();
  }, [isBonusMoneyAudioInitialized]);

  useEffect(() => {
    const initializeFairyGiftAudio = async () => {
      if (!isFairyGiftAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          fairyGiftAudioContextRef.current = new AudioContext();

          const response = await fetch(fairyGiftSoundMp3);
          const arrayBuffer = await response.arrayBuffer();
          const audioBuffer = await fairyGiftAudioContextRef.current.decodeAudioData(arrayBuffer);

          fairyGiftAudioBufferRef.current = audioBuffer;
          setIsFairyGiftAudioInitialized(true);
        } catch (error) {
          // ігноруємо
        }
      }
    };
    initializeFairyGiftAudio();
  }, [isFairyGiftAudioInitialized]);

  // ---- Звук кліку (pickaxe) ----
  useEffect(() => {
    const initializeAudio = async () => {
      if (!audioContext) {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        const context = new AudioContext();
        setAudioContext(context);

        try {
          const response = await fetch(clickSoundMp3);
          const arrayBuffer = await response.arrayBuffer();
          const decodedAudioData = await context.decodeAudioData(arrayBuffer);
          setAudioBuffer(decodedAudioData);
          setIsAudioInitialized(true);
        } catch (error) {
          // ігноруємо
        }
      }
    };

    const handleInteraction = () => {
      if (!isAudioInitialized) {
        initializeAudio();
      }
    };

    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.onEvent("viewportChanged", handleInteraction);
    }
    document.addEventListener("touchstart", handleInteraction, { once: true });
    document.addEventListener("mousedown", handleInteraction, { once: true });

    return () => {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.offEvent("viewportChanged", handleInteraction);
      }
      document.removeEventListener("touchstart", handleInteraction);
      document.removeEventListener("mousedown", handleInteraction);
    };
  }, [isAudioInitialized, audioContext]);

  // ---- Звук кнопки (buttonClickSoundMp3) ----
  useEffect(() => {
    const initializeButtonClickAudio = async () => {
      if (!isButtonClickAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          buttonClickAudioContextRef.current = new AudioContext();

          const response = await fetch(buttonClickSoundMp3);
          const arrayBuffer = await response.arrayBuffer();
          const audioBuffer = await buttonClickAudioContextRef.current.decodeAudioData(arrayBuffer);

          buttonClickAudioClickAudioBufferRef.current = audioBuffer;
          setIsButtonClickAudioInitialized(true);
        } catch (error) {
          // ігноруємо
        }
      }
    };
    initializeButtonClickAudio();
  }, [isButtonClickAudioInitialized]);

  // ---------------- Методи відтворення аудіо ----------------
  const playButtonClickSound = useCallback(() => {
    if (
      isButtonClickAudioInitialized &&
      buttonClickAudioContextRef.current &&
      buttonClickAudioClickAudioBufferRef.current
    ) {
      const source = buttonClickAudioContextRef.current.createBufferSource();
      source.buffer = buttonClickAudioClickAudioBufferRef.current;

      const gainNode = buttonClickAudioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(buttonClickAudioContextRef.current.destination);
      source.start(0);
    }
  }, [isButtonClickAudioInitialized, effectsVolume, generalVolume]);

  const playSound = useCallback(() => {
    if (isAudioInitialized && audioContext && audioBuffer) {
      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;

      const gainNode = audioContext.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContext.destination);
      source.start(0);
    }
  }, [isAudioInitialized, audioContext, audioBuffer, effectsVolume, generalVolume]);

  const playFairyGiftSound = useCallback(() => {
    if (
      isFairyGiftAudioInitialized &&
      fairyGiftAudioContextRef.current &&
      fairyGiftAudioBufferRef.current
    ) {
      const source = fairyGiftAudioContextRef.current.createBufferSource();
      source.buffer = fairyGiftAudioBufferRef.current;

      const gainNode = fairyGiftAudioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(fairyGiftAudioContextRef.current.destination);
      source.start(0);
    }
  }, [isFairyGiftAudioInitialized, effectsVolume, generalVolume]);

  const playBonusMoneySound = useCallback(() => {
    if (
      isBonusMoneyAudioInitialized &&
      bonusMoneyAudioContextRef.current &&
      bonusMoneyAudioBufferRef.current
    ) {
      if (bonusMoneyAudioSourceRef.current) {
        bonusMoneyAudioSourceRef.current.stop();
      }
      const source = bonusMoneyAudioContextRef.current.createBufferSource();
      source.buffer = bonusMoneyAudioBufferRef.current;

      const gainNode = bonusMoneyAudioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(bonusMoneyAudioContextRef.current.destination);
      source.loop = true;
      source.start(0);

      bonusMoneyAudioSourceRef.current = source;
    }
  }, [isBonusMoneyAudioInitialized, effectsVolume, generalVolume]);

  const stopBonusMoneySound = useCallback(() => {
    if (bonusMoneyAudioSourceRef.current) {
      bonusMoneyAudioSourceRef.current.stop();
      bonusMoneyAudioSourceRef.current = null;
    }
  }, []);

  // ---------------- Анімація балансу (при першому відкритті) ----------------
  useEffect(() => {
    if (isFristInit === true) {
      setShouldAnimate(true);
    }
  }, [isFristInit]);

  useEffect(() => {
    if (shouldAnimate) {
      setDisplayedBalance(0);
      playBonusMoneySound();
      animateBalance();
    } else {
      setDisplayedBalance(user.gold_balance);
      stopBonusMoneySound();
    }
    setIsFirstInit(false);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      stopBonusMoneySound();
    };
  }, [shouldAnimate, user.gold_balance, playBonusMoneySound, stopBonusMoneySound, setIsFirstInit]);

  const animateBalance = () => {
    const start = performance.now();
    const duration = 2000;

    const step = (timestamp) => {
      const progress = Math.min((timestamp - start) / duration, 1);
      const currentValue = Math.floor(progress * user.gold_balance);
      setDisplayedBalance(currentValue);

      if (progress < 1) {
        animationRef.current = requestAnimationFrame(step);
      } else {
        setShouldAnimate(false);
        stopBonusMoneySound();
      }
    };
    animationRef.current = requestAnimationFrame(step);
  };

  // ---------------- Блокування користувача ----------------
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      checkBlockStatus();
      isFirstRender.current = false;
    }
  }, []);

  useEffect(() => {
    let intervalId;
    if (isBlocked && blockedUntil) {
      intervalId = setInterval(() => {
        const now = new Date();
        const timeLeft = blockedUntil.getTime() - now.getTime();

        if (timeLeft <= 0) {
          setIsBlocked(false);
          setBlockedUntil(null);
          clearInterval(intervalId);
        } else {
          setRemainingTime(timeLeft);
        }
      }, 1000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isBlocked, blockedUntil]);

  // ---------------- Прогрес-бар (золото / rank.gold_required) ----------------
  useEffect(() => {
    const calculateProgress = () => {
      if (user.rank && user.rank.gold_required) {
        const newProgressPercentage = Math.min(
          (user.gold_balance / user.rank.gold_required) * 100,
          100
        );
        setProgressPercentage(newProgressPercentage || 0);
      } else {
        setProgressPercentage(0);
      }
    };
    calculateProgress();
  }, [user.gold_balance, user.rank]);

  // ---------------- Час для феї/подарунка (nextRewardTime) ----------------
  useEffect(() => {
    const checkRewardTime = () => {
      const currentTime = Date.now();
      // console.log("==================")
      // console.log("==currentTime: ", currentTime);
      // console.log("==nextRewardTime: ", nextRewardTime)
      // console.log("==isModalVisible:", isModalVisible)
      if (currentTime >= nextRewardTime && !isModalVisible) {
        setIsRewardVisible(true);
      }
    };
    const intervalId = setInterval(checkRewardTime, 1000);
    return () => clearInterval(intervalId);
  }, [nextRewardTime, isModalVisible]);

  // ---------------- Натиск на фею/подарунок ----------------
  const handleRewardClick = useCallback(async () => {
    playFairyGiftSound();
    setIsRewardVisible(false);
    setIsModalVisible(true);

    try {
      const response = await axios.post(
        API_URLS.GET_RANDOM_FULLSCREEN_BANNER_ADVERTS_ENDPOINT,
        { userId: user.user_id }
      );
      if (response.status === 200) {
        setAdId(response.data.id);
        const isGnome = Math.random() < response.data.gnome_probability;
        let reward, rewardType;

        if (isGnome) {
          reward = response.data.view_gnome_reward;
          rewardType = 3;
        } else {
          reward = response.data.random_gold_reward;
          rewardType = 2;
        }
        setAdReward(reward);
        setAdRewarType(rewardType);
        const originalPath = response.data.file_path; // "./media/ads/video_2025-02-03_00-37-18.mp4"
        const newPath = originalPath.replace(/^\./, ''); // "media/ads/video_2025-02-03_00-37-18.mp4"
        setAdFilePath(newPath);
        setAdUrl(response.data.url);
        setAdIsVideo(response.data.is_video);
      }
    } catch (error) {
      // ігноруємо
    }
  }, [user.user_id, adType, playFairyGiftSound]);

  // ---------------- Регенерація енергії (можна теж винести в ClickProvider) ----------------
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (currentEnergyRef.current < user.energy) {
  //       const newEnergy = Math.min(
  //         currentEnergyRef.current + user.energy_regeneration,
  //         user.energy
  //       );
  //       currentEnergyRef.current = newEnergy;
  //       updateUser({
  //         ...user,
  //         current_energy: newEnergy,
  //       });
  //     }
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, [user, updateUser]);

  // useEffect(() => {
  //   currentEnergyRef.current = user.current_energy;
  // }, [user.current_energy]);

  // ---------------- Випадкове повідомлення при кліках ----------------
  const getRandomClickMessage = useCallback(() => {
    const resources = i18n.getResourceBundle(i18n.language, "translation");
    const clickMessages = resources?.clickMsg;
    if (!clickMessages) return null;

    const keys = Object.keys(clickMessages);
    if (keys.length === 0) return null;
    const randomKey = keys[Math.floor(Math.random() * keys.length)];
    return clickMessages[randomKey];
  }, [i18n]);

  // ---------------- Головна кнопка (візуальний клік + handleMainButtonClick) ----------------
  const onMainButtonClick = useCallback(
    (event) => {
      // 1) Якщо заблоковано чи немає енергії
      if (isBlocked) return;
      if (currentEnergyRef.current <= 0) return;

      // 2) Локальний звук
      playSound();

      // 3) Координати для анімації кирки
      if (pageRef.current) {
        const pageRect = pageRef.current.getBoundingClientRect();
        const x = event.clientX - pageRect.left;
        const y = event.clientY - pageRect.top;
        setClickPosition({ x, y });
      }

      // 4) Анімація кирки
      setIsPickVisible(true);
      setIsButtonZoomed(true);

      if (fadeTimeoutRef.current) {
        clearTimeout(fadeTimeoutRef.current);
      }
      fadeTimeoutRef.current = setTimeout(() => {
        setIsPickVisible(false);
        setIsButtonZoomed(false);
      }, 500);

      // 5) Випадкове повідомлення (за бажанням)
      if (Math.random() < 0.01) {
        const msg = getRandomClickMessage();
        if (msg) {
          setRandomMessage(msg);
          setIsMessageVisible(true);
          setTimeout(() => setIsMessageVisible(false), 30000);
        }
      }

      // 6) Виклик handleMainButtonClick з ClickContext
      handleMainButtonClick();
    },
    [isBlocked, playSound, getRandomClickMessage, handleMainButtonClick]
  );

  // Коли анімація кирки закінчилась
  const handlePickAnimationEnd = useCallback(() => {
    setIsPickVisible(false);
  }, []);

  // ---------------- Перед навігацією ----------------
  const handleNavigation = useCallback(
    async (path) => {
      // Викликаємо функцію з контексту, щоб відправити накопичені кліки
      await saveClicksBeforeNavigation();
      // Граємо звук кнопки
      playButtonClickSound();
      // Навігація
      navigate(path);
    },
    [saveClicksBeforeNavigation, playButtonClickSound, navigate]
  );

  const handleRulesClick = () => handleNavigation("/rules");
  const handleReferralClick = () => handleNavigation("/referral");
  const handleMiniGamesClick = () => handleNavigation("/games");
  const handleLevelsClick = () => handleNavigation("/levels");

  // ---------------- Закриття модалки (подарунка/феї) ----------------
  const closeModal = useCallback(() => {
    setIsModalVisible(false);
    const currentTime = Date.now();
    const newNextRewardTime = currentTime + rewardIntervalMinutes * 60 * 1000;
    setNextRewardTime(newNextRewardTime);
    toggleRewardType();
  }, [rewardIntervalMinutes, setNextRewardTime, toggleRewardType]);

  // ---------------- Рендер ----------------
  const IconPick = getIconByRank(user.rank.id);

  return (
    <div id="page1" ref={pageRef} style={backgroundStyle}>
      {/* Персонаж */}
      <img
        className="persona_vremenno"
        style={{ maxHeight: "290px", width: "auto" }}
        src={getGenderImg()}
        alt="persona"
      />

      {/* Анімована кирка */}
      <AnimatedTool
        isVisible={isPickVisible}
        onAnimationEnd={handlePickAnimationEnd}
        position={clickPosition}
        Icon={IconPick}
        showSparks={user.visual_effects}
      />

      {/* RewardModal (якщо потрібно) */}
      {isModalVisible && (
        <RewardModal
          onClose={closeModal}
          isOpen={isModalVisible}
          isModalOpen={isModalVisible}
          reward={adReward}
          adType={adType}
          rewardType={adRewardType}
          id={adId}
          filePath={adFilePath}
          url={adUrl}
          isVideo={adIsVideo}
          iconGnome={preloadedData.iconGnom}
          background={preloadedData.modalBackground}
          iconAdWatchOnly={preloadedData.iconAdWatchOnly}
          iconAdWatchRedirect={preloadedData.iconAdWatchRedirect}
        />
      )}

      {/* Верхні кнопки */}
      <div className="info-container">
        <button className="referral-button" onClick={handleReferralClick}>
          {t("MainPage.RefButton")}
        </button>
        <button className="rules-button" onClick={handleRulesClick}>
          {t("MainPage.RulesButton")}
        </button>
      </div>

      {/* Прогрес / Енергія */}
      <div className="game-progress">
        <div className="first-line">
          <div className="energy-container-start">
            <button className="level-button" onClick={handleLevelsClick}>
              Level {user.rank.id}/10
            </button>
          </div>
          <div className="main-progress-container">
            <div className="main-progress-bar">
              <div
                className="main-progress"
                style={{
                  width: `${isNaN(progressPercentage) ? 0 : progressPercentage}%`,
                }}
              >
                <div className="main-progress-fill"></div>
              </div>
            </div>
          </div>
          <div className="energy-container">
            <IconEnergy />
            <p className="energy ml-1">
            {user.current_energy}/{user.energy}
            </p>
          </div>
        </div>

        <div className="second-line">
          <div className="flex flex-col w-100">
            <div className="flex flex-row items-center justify-between">
              <div className="pick-icon-container">
                <img src={getPickaxeIcon()} alt="Pickaxe Icon" />
              </div>
              <p className="sore-text">+{user.click_multiplier}</p>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row w-30 content-between">
              <div className="flex flex-row items-center mr-2">
                <IconDwarf />
                <p className="sore-text">{user.gnome_amount}</p>
              </div>
              <div className="flex flex-row items-center">
                <IconCoin />
                <p className="sore-text">+{formatBalance(user.passive_income)}/d</p>
              </div>
            </div>
          </div>
        </div>

        {/* Фея або подарунок */}
        <div className="third-line">
          {!isBlocked && isRewardVisible && !isModalVisible && (
            adType === "fairy" ? (
              <AnimatedFairy gifSrc={fairyGif} onFairyClick={handleRewardClick} />
            ) : (
              <div className="gift-background">
                <button
                  className="gift-button"
                  onClick={handleRewardClick}
                  style={{ backgroundImage: `url(${presentGif})` }}
                ></button>
              </div>
            )
          )}
        </div>
      </div>

      {/* Головна кнопка клік */}
      <div className="button-container" style={{ position: "relative" }}>
        {isBlocked && (
          <div className="prison-overlay">
            <img src={prison} alt="Prison" className="prison-image" />
            <div className="timer">{formatTime(remainingTime)}</div>
          </div>
        )}

        <div className={`random-message-container ${isMessageVisible ? "visible" : ""}`}>
          <p className="random-message">{randomMessage}</p>
        </div>
        <button
          className={`main-button ${isButtonZoomed ? "zoomed" : ""}`}
          onClick={onMainButtonClick}
          disabled={isBlocked}
        />
      </div>

      {/* Кнопка "Міні-ігри" */}
      <div className="mini-games-container">
        <button onClick={handleMiniGamesClick} className="mini-games-button">
          <img src={iconMiniGames} alt="Minigames" className="mini-games-icon" />
          {t("MainPage.MiniGamesButton")}
        </button>
      </div>

      {/* Баланс та токени */}
      <div className="earnings-container">
        <div className="earning">
          <div className="earning-content">
            <div className="earning-icon-container">
              <IconDolar className="earning-icon" />
            </div>
            <div className="earning-text-container">
              <p className="earning-text">{displayedBalance}</p>
            </div>
          </div>
        </div>
        <div className="earning">
          <div className="earning-content">
            <div className="earning-icon-container">
              <IconCoin className="earning-icon-small" />
              <p className="token">Token</p>
            </div>
            <div className="earning-text-container">
              <p className="earning-text">{formatBalance(user.g_token)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
