import React, { useEffect, useState, useRef, useCallback } from "react";
import Slider from "react-slick";
import "../assets/styles/navbar.css";
import { useTranslation } from "react-i18next";
import { useUser } from "../contexts/UserContext";
import { API_URLS } from "../config/config";
import { useAudio } from "../contexts/AudioContext";
import axios from "axios";
import clickSoundMp3 from "../assets/sounds/menu-button-click.mp3";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const calculateAdWeights = (adverts, totalGTokens) => {
  return adverts.map((ad) => ({
    ...ad,
    weight: ad.g_tokens_per_day / totalGTokens,
  }));
};

const selectWeightedAds = (adverts, totalSlots) => {
  const weightedAdverts = [];
  adverts.forEach((ad) => {
    const slots = Math.round(ad.weight * totalSlots);
    for (let i = 0; i < slots; i++) {
      weightedAdverts.push({ ...ad, uniqueId: `${ad.id}-${i}` });
    }
  });

  while (weightedAdverts.length < totalSlots) {
    const randomAd = adverts[Math.floor(Math.random() * adverts.length)];
    weightedAdverts.push({
      ...randomAd,
      uniqueId: `${randomAd.id}-extra-${weightedAdverts.length}`,
    });
  }

  return shuffleArray(weightedAdverts.slice(0, totalSlots));
};

const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const Navbar = ({ onPageChange, preloadedData }) => {
  const { user } = useUser();
  const [adSlots, setAdSlots] = useState([]);
  const [currentSlotIndex, setCurrentSlotIndex] = useState(0);
  const [originalAdverts, setOriginalAdverts] = useState([]);
  const { t } = useTranslation();
  const { effectsVolume, generalVolume, revertToDefaultMusic } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);
  const sliderRef = useRef(null);
  const [totalSlots, setTotalSlots] = useState(0);
  useEffect(() => {
    if (preloadedData && preloadedData.adverts) {
      const { adverts: advertsData, total_g_tokens } = preloadedData.adverts;
      setTotalSlots(total_g_tokens);
      if (
        Array.isArray(advertsData) &&
        advertsData.length > 0 &&
        total_g_tokens
      ) {
        const advertsWithWeights = calculateAdWeights(
          advertsData,
          total_g_tokens
        );
        setOriginalAdverts(advertsWithWeights);

        const initialSlots = [
          selectWeightedAds(advertsWithWeights, total_g_tokens),
          selectWeightedAds(advertsWithWeights, total_g_tokens),
        ];
        setAdSlots(initialSlots);
      } else {
        setAdSlots([]);
      }
    } else {
    }
  }, [preloadedData]);

  const generateNextSlot = useCallback(() => {
    setAdSlots((prevSlots) => {
      const newSlot = selectWeightedAds(originalAdverts, totalSlots);
      return [...prevSlots.slice(1), newSlot];
    });
  }, [originalAdverts, totalSlots]);

  const handleSettingsClick = () => {
    playSound();
    revertToDefaultMusic();
    onPageChange("/settings");
  };

  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const [clickResponse] = await Promise.all([fetch(clickSoundMp3)]);

          const [clickArrayBuffer] = await Promise.all([
            clickResponse.arrayBuffer(),
          ]);

          const [clickAudioBuffer] = await Promise.all([
            audioContextRef.current.decodeAudioData(clickArrayBuffer),
          ]);

          clickAudioBufferRef.current = clickAudioBuffer;

          setIsAudioInitialized(true);
        } catch (error) {}
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    if (
      isAudioInitialized &&
      audioContextRef.current &&
      clickAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  const handleAdvertClick = async (advertId, url) => {
    try {
      const data = {
        userId: user.user_id,
      };
      const response = await axios.post(
        `${API_URLS.TRACK_BANNER_ADVERTS_ENDPOINT}${advertId}/`,
        data
      );

      if (response.data.result === "ok") {
        window.location.href = response.data.redirection_link;
      }
    } catch (error) {}
  };

  const renderAdvert = (advert) => {
    if (!advert) {
      return null;
    }
    const imageUrl = `${API_URLS.API_BASE_URL}${advert.file_path}`;
    return (
      <div key={advert.uniqueId} className="baner-item">
        <div
          onClick={() => handleAdvertClick(advert.id, advert.url)}
          style={{ cursor: "pointer" }}
        >
          <img
            src={imageUrl}
            alt={advert.name || "Advertisement"}
            className="baner-image"
          />
        </div>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: adSlots.length > 0 && adSlots[currentSlotIndex].length > 1,
    autoplaySpeed: 3000,
    arrows: false,
    afterChange: (current) => {
      if (current === 0 && sliderRef.current) {
        setCurrentSlotIndex((prevIndex) => (prevIndex + 1) % adSlots.length);
        generateNextSlot();
        sliderRef.current.slickGoTo(0);
      }
    },
  };

  return (
    <nav className="header">
      <div className="user-container">
        <div className="logo-container">
          <img
            className="logo"
            src={
              user.gender === 0
                ? preloadedData.genderMale
                : preloadedData.genderFemale
            }
            alt="logo"
          />
          {user.is_vip && (
            <div className="vip-indicator">
              <img
                src={preloadedData.iconCrown}
                alt="VIP Crown"
                className="vip-crown"
              />
              <span className="vip-indikator-text">VIP</span>
            </div>
          )}
        </div>
        <div className="ml-1">
          <p className="user-name">{user.username}</p>
        </div>
      </div>
      <div className="baners-carusel-container">
        {adSlots.length === 0 ? (
          <div className="no-baners">{t("Navbar.PlaceForAd")}</div>
        ) : (
          <Slider {...settings} ref={sliderRef}>
            {adSlots[currentSlotIndex].map(renderAdvert)}
          </Slider>
        )}
      </div>
      <button className="settings-button" onClick={handleSettingsClick}>
        <img
          src={preloadedData.settingsIcon}
          alt="Settings Icon"
          className="settings-icon"
        />
      </button>
    </nav>
  );
};

export default Navbar;
