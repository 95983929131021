import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import CopiedMessageModal from "../components/CopiedMessageModal";
import formatBalance from "../utils/formatBalance";
import { API_URLS } from "../config/config";
import axios from "axios";
import { useUser } from "../contexts/UserContext";
import { useAudio } from "../contexts/AudioContext";
import clickSoundMp3 from "../assets/sounds/menu-button-click.mp3";
import "../assets/styles/ref.css";

const Ref = ({ preloadedData }) => {
  const { t } = useTranslation();
  const [startY, setStartY] = useState(null);
  const [totalBalance, setTotalBalance] = useState(0);
  const [showCopiedModal] = useState(false);
  const { user } = useUser();
  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);

  const updateLastActive = async (userId) => {
    try {
      await axios.post(API_URLS.UPDATE_LAST_ACTIVE, {
        userId: userId,
      });
    } catch (error) {}
  };

  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const [clickResponse] = await Promise.all([fetch(clickSoundMp3)]);

          const [clickArrayBuffer] = await Promise.all([
            clickResponse.arrayBuffer(),
          ]);

          const [clickAudioBuffer] = await Promise.all([
            audioContextRef.current.decodeAudioData(clickArrayBuffer),
          ]);

          clickAudioBufferRef.current = clickAudioBuffer;

          setIsAudioInitialized(true);
        } catch (error) {}
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    if (
      isAudioInitialized &&
      audioContextRef.current &&
      clickAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  useEffect(() => {
    updateLastActive(user.user_id);
  }, [user.user_id]);

  useEffect(() => {
    const total = preloadedData.data.reduce(
      (acc, referral) => acc + referral.referral_reward,
      0
    );
    setTotalBalance(total);
  }, [preloadedData.data]);

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    if (!startY) return;
    const currentY = e.touches[0].clientY;
    const diff = startY - currentY;
    e.currentTarget.scrollTop += diff;
    setStartY(currentY);
  };

  const handleTouchEnd = () => {
    setStartY(null);
  };

  const handleInviteButtonClicked = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      playSound();
      const title =
        document.querySelector('meta[property="og:title"]')?.content || "";
      const description =
        document.querySelector('meta[property="og:description"]')?.content ||
        "";
      const message = `${title}\n\n${description}`;
      const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
        preloadedData.refLink
      )}&text=${encodeURIComponent(message)}`;
      window.Telegram.WebApp.openTelegramLink(shareUrl);
    }
  };

  const getFriendText = (count) => {
    if (count >= 11 && count <= 19) {
      return t("RefPage.FriendsText3");
    }

    const lastDigit = count % 10;
    if (lastDigit === 1) {
      return t("RefPage.FriendsText1");
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      return t("RefPage.FriendsText2");
    } else {
      return t("RefPage.FriendsText3");
    }
  };
  const renderReferral = (referral, index) => (
    <li className="referral-item" key={index}>
      <div className="flex items-center">
        <img
          className="ref-img"
          src={
            referral.gender === 0
              ? preloadedData.genderMale
              : preloadedData.genderFemale
          }
          alt="Gender"
        />
        <div className="flex-1 min-w-0 flex justify-start">
          <p className="ref-nick">
            {referral.tg_username}
            <span className="ref-lvl ml-1">LVL {referral.rank}</span>
          </p>
        </div>
        <div className="ref-balance">
          <div className="balance-text-container">
            <p className="ref-balance-text">
              {formatBalance(referral.referral_reward)}
            </p>
          </div>
          <div className="ref-balance-content">
            <div className="gtoken-icon-container">
              <img
                src={preloadedData.iconCoin}
                className="gtoken-icon"
                alt="Coin Icon"
              />
              <p className="token">Token</p>
            </div>
          </div>
        </div>
      </div>
    </li>
  );

  return (
    <div
      id="referral"
      style={{ backgroundImage: `url(${preloadedData.backgroundImage})` }}
    >
      {showCopiedModal && (
        <CopiedMessageModal
          message={t("RefPage.CopiedMessageModalText")}
          copyIconMessage={preloadedData.iconNotification}
        />
      )}
      <div className="flex items-center justify-center">
        <p className="ref-title">{t("RefPage.Title")}</p>
      </div>
      <div className="balance-div">
        <div className="ref-balance-content">
          <div className="gtoken-icon-container">
            <img
              src={preloadedData.iconCoin}
              className="gtoken-icon"
              alt="Coin Icon"
            />
            <p className="token">Token</p>
          </div>
          <div className="balance-text-container">
            <p className="ref-balance-text">{totalBalance}</p>
          </div>
        </div>
      </div>
      <div className="message-container">
        <p className="message">{t("RefPage.Message")}</p>
      </div>
      <div className="referrals">
        <div className="refferals-count">
          <p className="refferals-text">{preloadedData.data.length || 0}</p>
          <p className="refferals-text ml-1">
            {getFriendText(preloadedData.data.length || 0)}
          </p>
        </div>
        <div
          className="refferals-list"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <ul>
            {preloadedData.data.map((referral, index) =>
              renderReferral(referral, index)
            )}
          </ul>
        </div>
      </div>
      <div className="invite-button-container">
        <button className="invite-button" onClick={handleInviteButtonClicked}>
          {t("RefPage.InviteFriends")}
        </button>
      </div>
    </div>
  );
};

export default Ref;
