import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { debounce } from 'lodash';
import "../../assets/styles/games/game_core3.css";
import { useAudio } from "../../contexts/AudioContext";
import { API_URLS } from "../../config/config";
import { ReactComponent as IconCoin } from "../../assets/icons/coin.svg";
import { useUser } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import GameIconLL from "../../assets/images/Games/ui_graph_person.jpg";
import TopRightImage from "../../assets/icons/gnom.webp";
import TradeWarningModal from "../../components/TradeWarningModal";
import TelegramButton from "./tgbtn";
import modalBackground from "../../assets/images/fairy-modal-background.png";
import clickSoundMp3 from "../../assets/sounds/menu-button-click.mp3";
import moneySoundMp3 from "../../assets/sounds/bonus-money-sound.mp3";
import fiascoSoundMp3 from "../../assets/sounds/fiasco.mp3";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/right-arrow.svg";

const styles = {
  gameWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
  },
  gameContainer: {
    width: "100%",
    maxWidth: "500px",
    backgroundColor: "white",
    overflow: "hidden",
  },
  gameHeader: {
    padding: "15px",
    backgroundColor: "#4a90e2",
    color: "white",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "center",
  },
  canvas: {
    width: "100%",
    height: "auto",
    maxHeight: "280px",
  },
  controlsContainer: {
    padding: "15px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  statusText: {
    margin: "5px 0",
    fontSize: "14px",
    color: "white",
  },
  finalMessage: {
    color: "#e74c3c",
    fontWeight: "bold",
  },
  input: {
    width: "100%",
    padding: "5px",
    color: "white",
    fontSize: "18px",
    backgroundColor: "transparent",
    borderRadius: "4px",
    outline: "none",
  },
  whiteText: {
    color: "white",
  },
};

const Notification = ({ message, isVisible, onClose }) => {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        color: "white",
        padding: "20px",
        borderRadius: "10px",
        zIndex: 1000,
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "24px",
      }}
    >
      {message}
    </div>
  );
};

const CoreGameThree = ({ tgUserId }) => {
  const [columns, setColumns] = useState(6);
  const [rows, setRows] = useState(6);
  const [currentValue, setCurrentValue] = useState(1);
  const [isRunning, setIsRunning] = useState(false);
  const [growthProgress, setGrowthProgress] = useState(0);
  const [showFinalMessage, setShowFinalMessage] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [gameStatus, setGameStatus] = useState("wait");
  const [waitTime, setWaitTime] = useState(0);
  // const [sessionId, setSessionId] = useState(null);
  const sessionIdRef = useRef(null);
  const [error, setError] = useState(null);
  const [betMessage, setBetMessage] = useState("");
  const [pickupMessage, setPickupMessage] = useState("");
  const [endPosition, setEndPosition] = useState({ x: 0, y: 0 });
  const [hasPickedUpBet, setHasPickedUpBet] = useState(false);
  const [fallProgress, setFallProgress] = useState(0);
  const [isGrowthComplete, setIsGrowthComplete] = useState(false);
  const [betAmount, setBetAmount] = useState("");
  const [betId, setBetId] = useState(null);
  const [currentBet, setCurrentBet] = useState("");
  const [betResult, setBetResult] = useState(null);
  const [gamePhase, setGamePhase] = useState("bet");
  const [displayedBetAmount, setDisplayedBetAmount] = useState("");
  const [betDisplayed, setBetDisplayed] = useState(false);
  const [finalValue, setFinalValue] = useState(null);
  const [betPlaced, setBetPlaced] = useState(false);
  const canvasRef = useRef(null);
  const wsRef = useRef(null);
  const imageRef = useRef(null);
  const topRightImageRef = useRef(null);
  const betMessageRef = useRef("");
  const { user, updateUser } = useUser();
  //const [localBalance, setLocalBalance] = useState(user.g_token);
  const [gameResult, setGameResult] = useState(null);
  const [winNotification, setWinNotification] = useState({
    isVisible: false,
    message: "",
  });
  const [betConfirmation, setBetConfirmation] = useState({
    isVisible: false,
    message: "",
  });
  const { t } = useTranslation();
  const [modalState, setModalState] = useState({
    isOpen: false,
    message: "",
    link: "",
  });
  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);
  const moneyAudioBufferRef = useRef(null);
  const fiascoAudioBufferRef = useRef(null);

  const [wrapperHeight, setWrapperHeight] = useState(0);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current) {
      // Считаем высоту
      const height = wrapperRef.current.offsetHeight;
      setWrapperHeight(height);
      console.log('Высота .gameWrapper:', height);
    }
  }, []);

  const playSound = useCallback(() => {
    if (
      isAudioInitialized &&
      audioContextRef.current &&
      clickAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  const playMoneySound = useCallback(() => {
    if (
      isAudioInitialized &&
      audioContextRef.current &&
      moneyAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = moneyAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  const playFiascoSound = useCallback(() => {
    if (isAudioInitialized && audioContextRef.current && fiascoAudioBufferRef.current) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = fiascoAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);


  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const clickResponse = await fetch(clickSoundMp3);
          const clickArrayBuffer = await clickResponse.arrayBuffer();
          clickAudioBufferRef.current =
            await audioContextRef.current.decodeAudioData(clickArrayBuffer);

          const moneyResponse = await fetch(moneySoundMp3);
          const moneyArrayBuffer = await moneyResponse.arrayBuffer();
          moneyAudioBufferRef.current =
            await audioContextRef.current.decodeAudioData(moneyArrayBuffer);

          const fiascoResponse = await fetch(fiascoSoundMp3);
          const fiascoArrayBuffer = await fiascoResponse.arrayBuffer();
          fiascoAudioBufferRef.current = await audioContextRef.current.decodeAudioData(fiascoArrayBuffer);

          setIsAudioInitialized(true);
        } catch (error) {
        }
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const maxCoefficient = 6;
  const minCoefficient = 0;

  useEffect(() => {
    const img = new Image();
    img.src = GameIconLL;
    img.onload = () => {
      imageRef.current = img;
    };

    const topRightImg = new Image();
    topRightImg.src = TopRightImage;
    topRightImg.onload = () => {
      topRightImageRef.current = topRightImg;
    };
  }, []);

  const debouncedSetBetAmount = useCallback(
    debounce((value) => {
      setBetAmount(value);
    }, 100),
    []
  );

  const handleInputFocus = () => {
    const menuElement = document.querySelector(".menu");
    if (menuElement) {
      menuElement.classList.remove("menu");
      menuElement.classList.add("menu-hidden");
    }
    const tradeButton = document.querySelector(".trade-button");
    if (tradeButton) {
      tradeButton.classList.remove("trade-button");
      tradeButton.classList.add("trade-button-hidden");
    }
  };

  const handleInputBlur = () => {
    const menuElement = document.querySelector(".menu-hidden");
    if (menuElement) {
      menuElement.classList.remove("menu-hidden");
      menuElement.classList.add("menu");
    }
    const tradeButton = document.querySelector(".trade-button-hidden");
    if (tradeButton) {
      tradeButton.classList.remove("trade-button-hidden");
      tradeButton.classList.add("trade-button");
    }
  };

  // const handleBetAmountChange = useCallback((e) => {
  //   const value = e.target.value.replace(/[^0-9.]/g, '');
  //   if (!/^\d*\.?\d*$/.test(value)) return;
  //   if (value.length > 6) return;

  //   const parts = value.split('.');
  //   if (parts[1] && parts[1].length > 5) return;

  //   setBetAmount(value);
  //   debouncedSetBetAmount(value);
  // }, [debouncedSetBetAmount]);

  const handleBetAmountChange = useCallback((e) => {
    let value = e.target.value;

    // Дозволяємо введення "." або "," для десяткових чисел
    if (value.includes(',')) {
      value = value.replace(',', '.');
    }

    // Спеціальна обробка для введення "0."
    if (value === '.') {
      setBetAmount('0.');
      debouncedSetBetAmount('0.');
      return;
    }

    // Перевірка на валідний формат числа
    if (!/^\d*\.?\d*$/.test(value)) {
      return;
    }

    // Обмеження на довжину цілої частини
    const [whole, decimal] = value.split('.');
    if (whole.length > 6) return;

    // Обмеження на довжину десяткової частини
    if (decimal && decimal.length > 2) {
      value = `${whole}.${decimal.slice(0, 2)}`;
    }

    // Перевірка на нуль на початку, але дозволяємо "0." для десяткових чисел
    if (value.length > 1 && value[0] === '0' && value[1] !== '.') {
      value = value.slice(1);
    }

    setBetAmount(value);
    debouncedSetBetAmount(value);
  }, [debouncedSetBetAmount]);

  const resetGameState = useCallback(() => {
    setIsRunning(false);
    setShowFinalMessage(false);
    setCurrentValue(1);
    setGrowthProgress(0);
    setIsGrowthComplete(false);
    setFallProgress(0);
    setHasPickedUpBet(false);
    setBetId(null);
    setError(null);
    setBetPlaced(false);
    setCurrentBet(prevBetAmount => prevBetAmount); // Функциональное обновление
    betMessageRef.current = "";
    setBetMessage("");
  }, []);


  const reconnectAttemptsRef = useRef(0);

  const connectWebSocket = useCallback(() => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      console.log("WebSocket уже подключен");
      return;
    }

    const wsUrl = "wss://" + API_URLS.WEBS_GAME3_CONNECT;
    console.log("Подключение к WebSocket:", wsUrl);
    wsRef.current = new WebSocket(wsUrl);

    const timeoutId = setTimeout(() => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.CONNECTING) {
        wsRef.current.close();
        setIsConnected(false);
        setError(t("GamePage.Game3.ConnectError"));
      }
    }, 10000);

    wsRef.current.onopen = () => {
      clearTimeout(timeoutId);
      setIsConnected(true);
      setError(null);
      reconnectAttemptsRef.current = 0; // Сбросить счётчик попыток
      console.log("WebSocket подключен");
    };

    wsRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
  // console.log("Получены данные:", data);

  if (data.session_id) {
    sessionIdRef.current = data.session_id;
    // console.log("sessionId установлен:", data.session_id);
  } else {
    // console.warn("Получены данные без session_id");
  }

      switch (data.status) {
        case "wait":
          setGameStatus("wait");
          setWaitTime(data.second);
          // setSessionId(data.session_id);
          sessionIdRef.current = data.session_id;
          resetGameState();
          break;
        case "started":
          setGameStatus("started");
          setCurrentValue(data.startnumber || 1);
          setGrowthProgress(0);
          setIsRunning(true);
          setShowFinalMessage(false);
          break;
        case "prosed":
          setGameStatus("prosed");
          setCurrentValue(data.current);
          setGrowthProgress(Math.min(data.current / maxCoefficient, 1));
          break;
        case "ended":
          setGameStatus("ended");
          setIsRunning(false);
          setCurrentValue(data.number);
          setFinalValue(data.number);
          setShowFinalMessage(true);
          setIsGrowthComplete(true);
          setBetAmount("");
          setDisplayedBetAmount("-");
          setGameResult(null);
          break;
        default:
          console.warn("Неизвестный статус:", data.status);
      }
    };

    wsRef.current.onclose = () => {
      clearTimeout(timeoutId);
      setIsConnected(false);
      console.log("WebSocket закрыт");
      if (reconnectAttemptsRef.current < 5) {
        reconnectAttemptsRef.current += 1;
        console.log(`Попытка переподключения №${reconnectAttemptsRef.current}`);
        setTimeout(connectWebSocket, 5000);
      } else {
        setError(t("GamePage.Game3.MaxReconnectAttempts"));
        console.error("Достигнуто максимальное количество попыток переподключения");
      }
    };

    wsRef.current.onerror = (error) => {
      clearTimeout(timeoutId);
      console.error("Ошибка WebSocket:", error);
      wsRef.current.close();
    };
  }, [resetGameState, t, maxCoefficient]);

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
        console.log("WebSocket соединение закрыто при размонтировании");
      }
    };
  }, [connectWebSocket]);

  useEffect(() => {
    if (isGrowthComplete) {
      const fallInterval = setInterval(() => {
        setFallProgress((prev) => {
          const newValue = prev + 0.02;
          if (newValue >= 1) {
            clearInterval(fallInterval);
            setShowFinalMessage(true);
            return 1;
          }
          return newValue;
        });
      }, 20);

      return () => clearInterval(fallInterval);
    }
  }, [isGrowthComplete]);

  useEffect(() => {
    const drawGraph = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      const width = canvas.width;
      const height = canvas.height;

      const leftPadding = 25;
      const bottomPadding = 20;
      const rightPadding = 13;
      const topPadding = 12;

      const gridWidth = width - leftPadding - rightPadding;
      const gridHeight = height - topPadding - bottomPadding;

      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = "white";
      ctx.strokeStyle = "#ffffff";
      ctx.lineWidth = 2;

      const cellWidth = gridWidth / columns;
      const cellHeight = gridHeight / rows;

      for (let i = 0; i <= columns; i++) {
        const x = leftPadding + i * cellWidth;
        ctx.beginPath();
        ctx.moveTo(x, topPadding);
        ctx.lineTo(x, height - bottomPadding);
        ctx.stroke();
      }

      for (let i = 0; i <= rows; i++) {
        const y = topPadding + i * cellHeight;
        ctx.beginPath();
        ctx.moveTo(leftPadding, y);
        ctx.lineTo(width - rightPadding, y);
        ctx.stroke();
      }

      ctx.strokeStyle = "#ffae63";
      ctx.lineWidth = 3;
      ctx.beginPath();
      ctx.moveTo(leftPadding, height - bottomPadding);

      const curveFunction = (x) => {
        const progress = x / gridWidth;
        const scaledValue =
          (currentValue - minCoefficient) / (maxCoefficient - minCoefficient);
        const y = Math.pow(progress, 0.6) * scaledValue * gridHeight;
        return height - bottomPadding - y;
      };

      const maxX = gridWidth * growthProgress;

      for (let x = 0; x <= maxX; x += 2) {
        const y = curveFunction(x);
        ctx.lineTo(leftPadding + x, y);
      }

      ctx.stroke();

      if (imageRef.current) {
        const imgWidth = 30;
        const imgHeight = 30;
        let endX, endY;

        if (growthProgress > 0) {
          endX = leftPadding + maxX;
          endY = curveFunction(maxX);
        } else {
          endX = leftPadding;
          endY = height - bottomPadding;
        }

        setEndPosition({ x: endX, y: endY });

        const angle =
          growthProgress > 0
            ? Math.atan2(endY - (height - bottomPadding), endX - leftPadding) -
            Math.PI / 2
            : 0;

        const fallDistance = 70;
        const horizontalShift = 12;

        const fallOffsetY = fallProgress * fallDistance;
        const fallOffsetX =
          Math.sin((fallProgress * Math.PI) / 2) * horizontalShift;

        const rotationAngle = angle + (fallProgress * Math.PI) / 4;

        ctx.save();
        ctx.translate(endX + fallOffsetX, endY + fallOffsetY);
        ctx.rotate(rotationAngle);
        ctx.rotate(Math.PI * 0.4);
        ctx.drawImage(
          imageRef.current,
          -imgWidth / 2,
          -imgHeight / 1.1,
          imgWidth,
          imgHeight
        );

        if (
          gameStatus === "started" ||
          gameStatus === "prosed" ||
          gameStatus === "ended"
        ) {
          ctx.rotate(-rotationAngle - Math.PI * 0.4);
          ctx.font = "bold 22px Oswald";
          ctx.textAlign = "center";

          const gradient = ctx.createLinearGradient(-50, 0, 50, 0);
          gradient.addColorStop(0, "#ffc700");
          gradient.addColorStop(1, "#ffae63");

          ctx.fillStyle = gradient;

          ctx.shadowColor = isGrowthComplete ? "red" : "green";
          ctx.shadowBlur = 10;

          ctx.fillText(`${currentValue.toFixed(2)}x`, 0, imgHeight / 2 + 5);

          ctx.shadowColor = "transparent";
          ctx.shadowBlur = 0;
        }

        ctx.restore();
      }

      ctx.font = "bold 32px Arial";
      ctx.textAlign = "center";
      ctx.textBaseline = "top";

      if (isGrowthComplete) {
        ctx.fillStyle = "red";
      } else {
        ctx.fillStyle = "green";
      }

      ctx.fillStyle = "white";
      ctx.font = "12px Arial";
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      for (let i = 0; i <= columns; i++) {
        const value = `${i}.0X`;
        const x = leftPadding + i * cellWidth;
        ctx.fillText(value, x, height - bottomPadding + 5);
      }
      ctx.textAlign = "right";
      ctx.textBaseline = "middle";
      for (let i = 0; i <= rows; i++) {
        const value = 5 * (rows - i);
        const y = topPadding + i * cellHeight;

        if (i === 6) {
          ctx.fillStyle = "rgba(255, 255, 255, 0)";
        } else {
          ctx.fillStyle = "white";
        }

        ctx.fillText(`${value}s`, leftPadding - 5, y);
      }

      if (topRightImageRef.current) {
        const imgWidth = 30;
        const imgHeight = 40;
        const cellX = width - rightPadding - cellWidth;
        const cellY = topPadding;
        const x = cellX + (cellWidth - imgWidth) / 2;
        const y = cellY + (cellHeight - imgHeight) / 2;
        ctx.drawImage(topRightImageRef.current, x, y, imgWidth, imgHeight);
      }
    };

    drawGraph();
  }, [
    gameStatus,
    columns,
    rows,
    currentValue,
    growthProgress,
    fallProgress,
    isGrowthComplete,
    minCoefficient,
    maxCoefficient,
  ]);

  const startAnimation = () => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify({ action: "start_game" }));
    } else {
    }
  };

  const handleGameAction = async () => {
    console.log("handleGameAction вызван");
    console.log("isConnected: ", isConnected);
    if (!isConnected) {
      console.log("Не подключен к WebSocket");
      return;
    }
    playSound();
    // console.log("gameStatus: ", gameStatus);
    // console.log("betAmount: ", betAmount);
    // console.log("sessionIdRef.current: ", sessionIdRef.current);
  
    if (gameStatus === "wait") {
      if (!betAmount || parseFloat(betAmount) <= 0) {
        console.log("705 wait");
        return;
      }
      try {
        console.log("sessionId: ", sessionIdRef.current);
        if (!sessionIdRef.current) {
          console.error("sessionId отсутствует. Невозможно разместить ставку.");
          updateModalState(t("GamePage.SessionIdMissing"), true);
          return;
        }
        console.log("Отправка запроса на размещение ставки...");
        console.log("user.user_id: ", user.user_id);
        console.log("betAmount: ", parseFloat(betAmount));
        console.log("session_id: ", sessionIdRef.current);
        const response = await axios.post(API_URLS.REACT_APP_GAME3_MAKEBET, {
          tg_user_id: user.user_id,
          bet: parseFloat(betAmount),
          session_id: sessionIdRef.current,
        });
        console.log("response.data: ", response.data);
        if (response.data.status === "done") {
          console.log("response.data.bet_id: ", response.data.bet_id);
          localStorage.setItem("gameBetId", response.data.bet_id);
          setError(null);
          const message = t("GamePage.Game3.BetConfirmation", {
            amount: betAmount,
          });
          betMessageRef.current = message;
          setPickupMessage("");
          setGameResult(null);
          setDisplayedBetAmount(betAmount);
          setGamePhase("bet");
          setBetPlaced(true);
          const updatedBalance = user.g_token - parseFloat(betAmount);
          updateUser({ g_token: updatedBalance });
          startAnimation();
          console.log("Ставка успешно размещена и баланс обновлён.");
        } 
        else if (response.data.status === "error") {
          console.log("Ошибка при размещении ставки:", response.data);
          if (response.data.error_code === "552") {
            updateModalState(t("GamePage.SomethingWrong"), true);
          } else if (response.data.error_code === "551") {
            updateModalState(t("GamePage.NotEnoughBalance"), true);
          } else {
            updateModalState(t("GamePage.UnknownError"), true);
          }
        } else {
          console.warn("Неизвестный статус ответа:", response.data.status);
          updateModalState(t("GamePage.UnknownResponse"), true);
        }
      } catch (error) {
        console.error("Ошибка при размещении ставки:", error);
        updateModalState(t("GamePage.UnexpectedError"), true);
      }
    }

    else if (gameStatus === "started" || gameStatus === "prosed") {
      const storedBetId = localStorage.getItem("gameBetId");
      console.log("storedBetId: ", storedBetId);
      if (storedBetId) {
        try {

          const response = await axios.post(API_URLS.REACT_APP_GAME3_TAKEBET, {
            bet_id: storedBetId,
          });
          if (response.data.status === "ok") {
            localStorage.removeItem("gameBetId");
            setHasPickedUpBet(true);
            const winAmount = parseFloat(response.data.win);
            const message = t("GamePage.Game3.Congratulations", {
              winAmount: winAmount,
              // xValue: currentValue.toFixed(2),
              xValue: response.data.current_num.toFixed(2),
            });
            updateModalState(message, true);
            setGameResult({
              isWin: true,
              oldBalance: user.g_token,
              newBalance: parseFloat(user.g_token) + winAmount,
              winAmount: winAmount,
            });
            setBetMessage("");
            betMessageRef.current = "";
            setError(null);
            const updatedBalance = user.g_token + winAmount;
            updateUser({ g_token: updatedBalance });
            playMoneySound();
          }
          else 
          {
            console.log("response.data.status: ", response.data);
          }
        }
        catch (error) {
          console.log("else 2");
        }
      }
      else {
        console.log("else 3");
      }
    }
  };

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.onEvent('viewportChanged', () => {
        window.scrollTo(0, 0);
      });
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (gameStatus === "ended" && !hasPickedUpBet) {
      playFiascoSound();
      setBetMessage("");
      setBetDisplayed(false);
      setBetAmount("");
      setDisplayedBetAmount(0);
      setGameResult(null);
      betMessageRef.current = "";
      localStorage.removeItem("gameBetId");
    }
  }, [gameStatus, hasPickedUpBet, t]);

  const ArrowRight = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="5" y1="12" x2="19" y2="12"></line>
      <polyline points="12 5 19 12 12 19"></polyline>
    </svg>
  );
  const updateModalState = (message, isOpen = true, link = "") => {
    setModalState({ isOpen, message, link });
  };

  useEffect(() => {
    if (gameStatus === "ended" && finalValue !== null) {
      let message;
      if (hasPickedUpBet) {
        message = t("GamePage.Game3.Victory", { value: finalValue.toFixed(2) });
      } else {
        message = t("GamePage.Game3.Defeat", { value: finalValue.toFixed(2) });
      }
      updateModalState(message);
    }
  }, [gameStatus, finalValue, hasPickedUpBet, t]);

  const getButtonStyle = () => {
    if (
      (gameStatus === "started" || gameStatus === "prosed") &&
      !hasPickedUpBet
    ) {
      return {
        background:
          "linear-gradient(105.63deg, #4CAF50 -30.5%, #45a049 66.66%)",
        boxShadow: "0 0 5px #4CAF50, inset 0 0 5px #45a049",
      };
    }
    return {};
  };



  const formatBalanceWithSixDecimals = (balance) => {
    return Number(balance).toFixed(2);
  };

  return (
    <div className="con-game-3">
      <div className="gmon-info-con">
            <div className="gmon-info-card">
              <div className="gmon-info-size">-5.1x</div>
              <ArrowRight className="gmon-info-arrow" />
              {/* <ArrowRight className="gmon-info-arrow"/> */}
              <div className="gmon-info-card-con">
                <img src={TopRightImage} alt="Gnome" className="gmon-info-icon" />
                <div className="gmon-info-bonus">x100</div>
              </div>
            </div>

            <div className="gmon-info-card">
              <div className="gmon-info-size">-5.5x</div>
              <ArrowRight className="gmon-info-arrow" />
              {/* <ArrowRight className="gmon-info-arrow"/> */}
              <div className="gmon-info-card-con">
                <img src={TopRightImage} alt="Gnome" className="gmon-info-icon" />
                <div className="gmon-info-bonus">x500</div>
              </div>
            </div>

            <div className="gmon-info-card">
              <div className="gmon-info-size">-6.0x</div>
              <ArrowRight className="gmon-info-arrow" />
              {/* <ArrowRight className="gmon-info-arrow"/> */}
              <div className="gmon-info-card-con">
                <img src={TopRightImage} alt="Gnome" className="gmon-info-icon" />
                <div className="gmon-info-bonus">x1000</div>
              </div>
            </div>
            
          </div>
      <div className="gameWrapper" ref={wrapperRef}>
        
        <div className="game-container" style={{ position: "relative" }}>
          <canvas
            ref={canvasRef}
            width={Math.max(0, wrapperHeight - 10)} 
            height={Math.max(0, wrapperHeight - 10)}
            style={styles.canvas}
          />
        </div>

        <Notification
          message={winNotification.message}
          isVisible={winNotification.isVisible}
          onClose={() => setWinNotification({ isVisible: false, message: "" })}
        />

        <TradeWarningModal
          key={modalState.isOpen ? "open" : "closed"}
          modalState={modalState}
          background={modalBackground}
          onClose={() => updateModalState("", false)}
        />
      </div>

      <div className="con-buttons">
        <div className="con-info-btn-one">

          <div className="bet-container">
            <p className="my-bet-text">
              {gameResult
                ? gameResult.isWin
                  ? t("GamePage.Game3.YourWinning")
                  : t("GamePage.Game3.YourLoss")
                : t("GamePage.Game3.YourBet")}
            </p>
            <div className="earning-game-my-bet">
              <div className="earning-content-game">
                <IconCoin className="earning-icon-small-game" />
                <div className="earning-text-container-game">
                  <p
                    className="earning-text-game"
                    style={{
                      ...styles.whiteText,
                      color: gameResult
                        ? gameResult.isWin
                          ? "#15FF00"
                          : "#FF0000"
                        : "white",
                    }}
                  >
                    {gameResult
                      ? gameResult.isWin
                        ? `+${gameResult.winAmount.toFixed(2)}`
                        : `-${gameResult.lossAmount.toFixed(2)}`
                      : displayedBetAmount || "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="rate-inpt-sec">
            <p className="my-bet-text">{t("GamePage.Game3.EnterBid")}</p>

            <div style={{position: "relative"}}>
            {gameStatus !== "wait" && (
              <div className="lock-button-con">        
                  <LockIcon className="icon-lock" />
              </div>
              )}
              <div className="rate-inpt-con" style={{ background: gameStatus !== "wait" ? "unset" : undefined }}>
                <div className="rate-icon-con">
                  <IconCoin className="coin" />
                </div>
                <input
                  type="text"
                  inputMode="decimal"
                  value={betAmount}
                  onChange={handleBetAmountChange}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  placeholder="0.001"
                  disabled={gameStatus !== "wait"}
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="false"
                />

              </div>
            </div>


          </div>







          
        </div>
        <div className="game3-buttom-list">
          <div className="main-con-btn-game-three">
          <TelegramButton
            className="game3-button"
            style={getButtonStyle()}
            onClick={() => handleGameAction()}
            disabled={!isConnected || gameStatus === "ended" || (betId && gameStatus !== "started" && gameStatus !== "prosed")}
          >
            {!isConnected
              ? t("GamePage.Game3.Connect")
              : gameStatus === "wait"
                ? `${t("GamePage.Game3.StartGame")} (${waitTime.toFixed(1)}s)`
                : (gameStatus === "started" || gameStatus === "prosed") &&
                  !hasPickedUpBet
                  ? t("GamePage.Game3.Takethebet")
                  : t("GamePage.Game3.Inprogress")}
          </TelegramButton>

          

          </div>
          

          <div className="card-balanc-gg">

            <div className="earning-content-game">
              <IconCoin className="earning-icon-small-game" />
              <div className="earning-text-container-game">
                <p className="earning-text-game" style={styles.whiteText}>
                  {" "}
                  {formatBalanceWithSixDecimals(user.g_token)}
                </p>
              </div>
            </div>
          </div>


          
        </div>
      </div>


    </div>
  );
};

export default CoreGameThree;