import React, { useState, useEffect, useRef } from "react";
import { API_URLS } from "../config/config";
import { useUser } from "../contexts/UserContext";
import { useAudio } from "../contexts/AudioContext";
import axios from "axios";
import iconCross from "../assets/icons/cross.webp";
import "../assets/styles/adViewer.css";

const AdViewer = ({
  id,
  mediaUrl,
  rewardType,
  adFileName,
  goldReward,
  isVideo,
  onClose,
}) => {
  const [canSkip, setCanSkip] = useState(false);
  const [progress, setProgress] = useState(0);
  const [timeLeft, setTimeLeft] = useState(15);
  const [isClickable, setIsClickable] = useState(true);
  const [mediaSource, setMediaSource] = useState(
    `${API_URLS.API_BASE_URL}${adFileName}`
  );
  const [rewardClaimed, setRewardClaimed] = useState(0);
  const [isAdEnded, setIsAdEnded] = useState(false);
  const mediaRef = useRef(null);
  const intervalRef = useRef(null);
  const { user, updateUser } = useUser();
  const { pauseBackgroundMusic, resumeBackgroundMusic } = useAudio();

  useEffect(() => {
    pauseBackgroundMusic();

    return () => {
      resumeBackgroundMusic();
    };
  }, [adFileName, pauseBackgroundMusic, resumeBackgroundMusic]);

  useEffect(() => {
    const header = document.querySelector(".header");
    const menu = document.querySelector(".menu-container");

    if (header) header.style.display = "none";
    if (menu) menu.style.display = "none";

    intervalRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(intervalRef.current);
          setCanSkip(true);
          setIsAdEnded(true);
          if (rewardClaimed === 0) {
            claimReward(0.5);
          }
          return 0;
        }
        return prevTime - 1;
      });

      setProgress((prevProgress) => {
        const newProgress = prevProgress + 100 / 15;
        return newProgress > 100 ? 100 : newProgress;
      });
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
      if (header) header.style.display = "";
      if (menu) menu.style.display = "";
    };
  }, [rewardClaimed]);

  useEffect(() => {
    if (isVideo && mediaRef.current) {
      const playVideoWithSound = async () => {
        try {
          mediaRef.current.muted = false;
          await mediaRef.current.play();
        } catch (error) {
          mediaRef.current.muted = true;
          await mediaRef.current.play();
        }
      };

      playVideoWithSound();

      const updateVideoProgress = () => {
        if (mediaRef.current && !isNaN(mediaRef.current.duration)) {
          const videoProgress =
            (mediaRef.current.currentTime / mediaRef.current.duration) * 100;
          setProgress(videoProgress);
        }
      };

      const handleVideoEnded = async () => {
        setIsAdEnded(true);
        setCanSkip(true);
        if (rewardClaimed === 0) {
          await claimReward(0.5);
        }
      };

      mediaRef.current.addEventListener("timeupdate", updateVideoProgress);
      mediaRef.current.addEventListener("ended", handleVideoEnded);

      return () => {
        if (mediaRef.current) {
          mediaRef.current.removeEventListener(
            "timeupdate",
            updateVideoProgress
          );
          mediaRef.current.removeEventListener("ended", handleVideoEnded);
        }
      };
    }
  }, [isVideo, mediaSource, rewardClaimed]);

  const claimReward = async (multiplier) => {
    if (rewardClaimed === 2) return;

    try {
      const data = {
        userId: user.user_id,
        assetId: rewardType,
        rewardAmount: Math.floor(goldReward * multiplier),
      };

      const response = await axios.post(
        API_URLS.GIVE_ADVIEW_REWARD_ENDPOINT,
        data
      );

      if (response.data.result === "ok") {
        setRewardClaimed((prevState) => Math.min(prevState + 1, 2));
        updateUser({
          gold_balance: response.data.new_user_gold_balance,
          gnome_amount: response.data.new_user_gnome_balance,
        });
      }
    } catch (error) {}
  };

  const handleSkip = () => {
    if (canSkip) {
      onClose();
    }
  };

  const handleAdClick = async () => {
    if (isClickable) {
      if (isAdEnded) {
        if (rewardClaimed === 1) {
          await claimReward(0.5);
        }
        window.open(mediaUrl, "_blank");
      } else {
        if (rewardClaimed === 0) {
          await claimReward(1);
        }
        window.open(mediaUrl, "_blank");
      }
    }
  };

  return (
    <div className="ad-viewer-fullscreen">
      <div className="ad-content-wrapper" onClick={handleAdClick}>
        {isVideo ? (
          <video
            key={mediaSource}
            ref={mediaRef}
            className="ad-content"
            autoPlay
            playsInline
            muted
            preload="auto"
            loop={false}
          >
            <source src={mediaSource} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img
            ref={mediaRef}
            src={mediaSource}
            alt="Advertisement"
            className="ad-content"
          />
        )}
      </div>
      <div className="ad-controls">
        <div className="ad-timer-container">
          {!canSkip && <div className="ad-timer">Skip in {timeLeft}s</div>}
        </div>
        <div className="ad-button-container">
          <button
            onClick={handleSkip}
            className={`ad-skip-button ${!canSkip ? "disabled" : ""}`}
            disabled={!canSkip}
          >
            <img src={iconCross} alt="cross" className="ad-cross-icon" />
          </button>
        </div>
      </div>

      <div className="ad-progress-bar-container">
        <div className="ad-progress-bar">
          <div className="ad-progress-fill" style={{ width: `${progress}%` }} />
        </div>
      </div>
    </div>
  );
};

export default AdViewer;
