import React, { useEffect } from 'react';

const ScreenOrientationLock = () => {
  useEffect(() => {
    const lockOrientation = async () => {
      try {
        if (window.screen?.orientation?.lock) {
          await window.screen.orientation.lock('portrait');
        }
      } catch (err) {
        console.warn('Orientation lock failed:', err);
      }
    };

    const forcePortrait = () => {
      // Force portrait styling
      document.body.style.width = '100vw';
      document.body.style.height = '100vh';
      document.body.style.transform = 'none';
      document.body.style.position = 'fixed';
      document.body.style.overflow = 'hidden';
    };

    const handleFocus = (e) => {
      if (e.target.tagName === 'INPUT') {
        e.preventDefault();
        forcePortrait();
        
        const viewport = document.querySelector('meta[name="viewport"]');
        if (viewport) {
          viewport.setAttribute('content', 
            'width=device-width, initial-scale=1, maximum-scale=1, ' +
            'user-scalable=no, viewport-fit=cover, orientation=portrait'
          );
        }
        
        setTimeout(() => {
          e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
          forcePortrait(); // Reapply after scroll
        }, 100);
      }
    };

    const preventScaling = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    const handleOrientationChange = () => {
      forcePortrait();
    };

    // Initial setup
    lockOrientation();
    forcePortrait();

    // Event listeners
    window.addEventListener('orientationchange', handleOrientationChange);
    window.addEventListener('resize', forcePortrait);
    document.addEventListener('focus', handleFocus, true);
    document.addEventListener('touchstart', preventScaling, { passive: false });
    document.addEventListener('touchmove', preventScaling, { passive: false });

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
      window.removeEventListener('resize', forcePortrait);
      document.removeEventListener('focus', handleFocus, true);
      document.removeEventListener('touchstart', preventScaling);
      document.removeEventListener('touchmove', preventScaling);
    };
  }, []);

  return null;
};

export default ScreenOrientationLock;