import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";
import TradeViewChart from "react-crypto-chart";

import axios from "axios"; // Импортируем axios
import { API_URLS } from "../../config/config";
import { useUser } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";

import "../../assets/styles/games/game_core4_1.css";
import { ReactComponent as CoinIcon } from "../../assets/icons/coin.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import { ReactComponent as ArrowUp } from "../../assets/images/Games/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/Games/arrow-down.svg";
import PocketoptionSmal from "../../assets/icons/pocketoption.webp";

import Keyboard from "../../components/Keyboard";
import "../../assets/styles/copmonents/Modal/CustomKeyboard.css";

//modal
import PartnersModal from "../../components/games/PartnersModal";
import iconArrowLeft from "../../assets/icons/arrow-left.png";
import modalBackground from "../../assets/images/fairy-modal-background.png";

import TradeWarningModal from "../../components/TradeWarningModal";

function WebSocketTester() {
  const { user, updateUser } = useUser(); // Получаем user и updateUser из контекста
  const [bet, setBet] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [timerValue, setTimerValue] = useState(20); // Инициализация таймера на 20 секунд
  const [balance, setBalance] = useState(user.g_token || 0); // Добавляем состояние для баланса
  const [error, setError] = useState(""); // Состояние для ошибок
  const [initialBTCPrice, setInitialBTCPrice] = useState(null); // Состояние для первоначальной цены BTC
  const [newBTCPrice, setNewBTCPrice] = useState(null); // Состояние для новой цены BTC
  const [winBet, setwinBet] = useState(null); // Состояние для сумми победи
  const [userBetDirection, setUserBetDirection] = useState(null); // Направление ставки пользователя ('up' или 'down')
  const { t } = useTranslation();
  const wsRef = useRef(null);

  //кастом клава
  const [inputValue, setInputValue] = useState("");
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  const handleKeyPress = (key) => {
    if (key === "delete") {
      setBet(bet.slice(0, -1));
    } else {
      setBet(bet + key);
    }
  };

  //для модалкы партнеров
  const [exchangeLinks, setExchangeLinks] = useState({});
  const [isLinksLoading, setIsLinksLoading] = useState(true);
  const [isAdModalOpen, setIsAdModalOpen] = useState(false);
  
  //Для модалкы резуальтата
  const [modalState, setModalState] = useState({
    isOpen: false,
    message: "",
    link: "",
  });

  // Константа для кольору графіка
  const chartColor = "#1E53E5";

  // Стан для розмірів графіка
  const [chartDimensions, setChartDimensions] = React.useState(null);

  // Встановлення початкових розмірів графіка та обробка зміни орієнтації
  useEffect(() => {
    const setInitialChartDimensions = () => {
      const height = window.innerHeight;
      let chartHeight, chartWidth;

      if (height <= 667) {
        chartHeight = '20vh';
      } else if (height <= 844) {
        chartHeight = '30vh';
      } else if (height <= 926) {
        chartHeight = '35vh';
      } else {
        chartHeight = '40vh';
      }

      const aspectRatio = window.innerWidth / window.innerHeight;
      if (aspectRatio < 0.5) {
        chartWidth = '100%';
      } else if (aspectRatio < 0.75) {
        chartWidth = '100%';
      } else {
        chartWidth = '100%';
      }

      setChartDimensions({ height: chartHeight, width: chartWidth });
    };

    // Встановлення початкових розмірів при завантаженні компонента
    setInitialChartDimensions();

    // Додавання слухача події зміни орієнтації
    window.addEventListener('orientationchange', setInitialChartDimensions);

    // Очистка слухача подій при розмонтуванні компонента
    return () => {
      window.removeEventListener('orientationchange', setInitialChartDimensions);
    };
  }, []);

  // Мемоізація компонента TradeViewChart для оптимізації продуктивності
  const memoizedTradeViewChart = useMemo(() => {
    if (!chartDimensions) return null;

    return (
      <TradeViewChart
        containerStyle={{
          height: chartDimensions.height,
          width: chartDimensions.width,
          backgroundColor: "black",
        }}
        pair="BTCUSDT"
        chartLayout={{
          priceScale: {
            scaleMargins: {
              top: 0.001,
              bottom: 0.01,
            },
          },
          grid: {
            vertLines: {
              color: "rgba(70, 130, 180, 0.1)",
              style: 1,
              visible: true,
            },
            horzLines: {
              color: "rgba(70, 130, 180, 0.1)",
              style: 1,
              visible: true,
            },
          },
        }}
        candleStickConfig={{
          upColor: chartColor,
          downColor: "#880808",
          borderUpColor: chartColor,
          borderDownColor: "#880808",
          wickUpColor: chartColor,
          wickDownColor: "#880808",
        }}
      // Видаляємо onPriceUpdate, оскільки ціна не відображається
      />
    );
  }, [chartColor, chartDimensions]);


  // Обновление баланса при изменении user.g_token (если это возможно)
  useEffect(() => {
    setBalance(user.g_token || 0);
  }, [user.g_token]);



  // Функция для логирования сообщений в консоль
  const logMessage = useCallback((msg) => {
    console.log(msg);
  }, []);

  // Функция для установки WebSocket соединения
  const connectWebSocket = useCallback((direction, betAmount) => {
    // Проверяем, требуется ли валидация ставки
    if (direction !== "0" && (isNaN(betAmount) || betAmount <= 0)) {
      setError("Пожалуйста, введите корректную ставку (положительное число).");
      console.log("Пожалуйста, введите корректную ставку (положительное число).");
      return;
    }

    // Если direction "0", устанавливаем betAmount в 0
    const finalBetAmount = direction === "0" ? 0 : betAmount;

    const wsUrl = `wss://${API_URLS.GAME4_PERSONAL_GAME}/${user.user_id}/${finalBetAmount}/${direction}/`;

    console.log(`Попытка соединения с: ${wsUrl}`);

    // Закрываем предыдущее соединение, если оно существует
    if (wsRef.current) {
      console.log("Закрытие предыдущего WebSocket соединения.");
      wsRef.current.close();
    }

    // Создаём новое WebSocket соединение
    const ws = new WebSocket(wsUrl);
    wsRef.current = ws;

    ws.onopen = () => {
      setIsConnected(true);
      setTimerValue(20); // Сбрасываем таймер на 20 секунд при подключении
      const openMsg = "WebSocket соединение установлено.";
      console.log(openMsg);
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        const message = `Получено сообщение: ${JSON.stringify(data)}`;
        logMessage(message);

        if (data.status === "wait" && typeof data.second === "number") {
          setTimerValue(data.second);
          setInitialBTCPrice(data.startbtcprice);
          setBet(data.bet);
          setUserBetDirection(data.is_up);
        } else if (data.status === "result") {
          // Обработка сообщения с результатом
          setTimerValue(20); // Сброс таймера на 20 секунд
          const resultMessage = data.is_win
          ? t("GamePage.Game4.ResultMessage.Win", {
            winAmount: (Number(data.win_bet)).toFixed(2),
          })
          : t("GamePage.Game4.ResultMessage.Lose", {
            winAmount: data.win_bet,
          });

          updateModalState(resultMessage, true);

          // Обновляем баланс
          setBalance(data.new_g_token);
          updateUser({ g_token: data.new_g_token });

          // Устанавливаем новую цену BTC
          setNewBTCPrice(data.new);

          setwinBet(data.win_bet);

          // Обновляем первоначальную цену BTC, если требуется
          setInitialBTCPrice(data.old);

        } else if (data.status === "disconnect") {
          // Обработка сообщения о разрыве соединения (если требуется)
          setIsConnected(false);
          setTimerValue(20); // Сброс таймера на 20 секунд при разрыве
          logMessage("Сервер запросил разрыв соединения.");
          ws.close();
        }
      } catch (error) {
        const message = `Получено сообщение: ${event.data}`;
        logMessage(message);
        console.log("Ошибка парсинга сообщения:", error);
      }
    };

    ws.onerror = (error) => {
      const errorMsg = `WebSocket ошибка: ${error.message}`;
      console.error("WebSocket ошибка:", error);
      console.log(errorMsg);
    };

    ws.onclose = (event) => {
      setIsConnected(false);
      setTimerValue(20); // Сброс таймера на 20 секунд при разрыве соединения
      const closeMsg = `WebSocket соединение закрыто. Код: ${event.code}, Причина: ${event.reason}`;
      console.log(closeMsg);
    };
  }, [user.user_id, logMessage, updateUser]);

  // useEffect для получения данных последней игры при монтировании компонента
  // модалку тут показувати треба
  useEffect(() => {
    const fetchLastGame = async () => {
      try {
        const response = await axios.post(API_URLS.REACT_APP_GAME4_GET_LAST_GAME, {
          tg_user_id: user.user_id
        });
        console.log("graph/get_last_info_game response: ", response);

        // Проверяем, существует ли последняя игра
        if (response.data.is_exist) {

          setInitialBTCPrice(response.data.old);
          setNewBTCPrice(response.data.new);
          setwinBet(response.data.bet_win);
          setBet(response.data.bet);

          if (response.data.is_win) {
            if (response.data.old > response.data.new) {
              setUserBetDirection("up");
            } else if (response.data.old < response.data.new) {
              setUserBetDirection("down");
            }
          }
          else {
            if (response.data.old > response.data.new) {
              setUserBetDirection("down");
            } else if (response.data.old < response.data.new) {
              setUserBetDirection("up");
            }
          }

          const resultMessage = response.data.is_win
          ? t("GamePage.Game4.ResultMessage.Win", {
            winAmount: (Number(response.data.bet_win)).toFixed(2),
          })
          : t("GamePage.Game4.ResultMessage.Lose", {
            winAmount: response.data.bet_win,
          });

          updateModalState(resultMessage, true);

        }


      } catch (error) {
        console.error("fetchLastGame Ошибка при получении последней игры:", error);
      }
    };

    fetchLastGame();
  }, [user.user_id]);

  // useEffect для проверки, идет ли уже игра при монтировании компонента
  useEffect(() => {
    const checkLastGame = async () => {
      try {
        const response = await axios.post(API_URLS.REACT_APP_GAME4_CHECK, {
          tg_user_id: user.user_id
        });
        console.log("graph/is_user_in_game response: ", response);

        if (response.data && response.data.status === 'ok' && response.data.is_exist) {
          // Если игра уже идет, подключаемся к WebSocket
          console.log("Игра уже идет. Подключение к WebSocket...");
          connectWebSocket("0", 0); // direction "0" и betAmount=0
        }
      } catch (error) {
        console.error("Ошибка при проверке игры:", error);
      }
    };

    checkLastGame();
  }, [user.user_id, connectWebSocket]);

  // Функции для обработки нажатий кнопок
  const handleUpClick = async () => {
    console.log("Нажата кнопка Up");
    const betAmount = parseFloat(bet);
    if (isNaN(betAmount) || betAmount <= 0) {
      setError("Пожалуйста, введите корректную ставку (положительное число).");
      console.log("Пожалуйста, введите корректную ставку (положительное число).");
      return;
    }
    setError(""); // Сброс ошибки при корректном вводе

    try {
      // Устанавливаем направление действия
      setUserBetDirection('up');

      // Чистим NewPrice
      setNewBTCPrice(null);

      setwinBet(null);

      // Немедленно вычитаем сумму ставки из баланса и обновляем g_token
      setBalance(prevBalance => {
        const newBalance = prevBalance - betAmount;
        updateUser({ g_token: newBalance });
        return newBalance;
      });

      // Подключаемся к WebSocket и отправляем ставку
      connectWebSocket("up", betAmount);
    } catch (error) {
      console.error("Ошибка при обработке ставки Up:", error);
      setError("Ошибка при обработке ставки Up.");
    }
  };

  const handleDownClick = async () => {
    console.log("Нажата кнопка Down");
    const betAmount = parseFloat(bet);
    if (isNaN(betAmount) || betAmount <= 0) {
      setError("Пожалуйста, введите корректную ставку (положительное число).");
      console.log("Пожалуйста, введите корректную ставку (положительное число).");
      return;
    }
    setError(""); // Сброс ошибки при корректном вводе

    try {
      // Устанавливаем направление действия
      setUserBetDirection('down');
      // Чистим NewPrice
      setNewBTCPrice(null);
      setwinBet(null);
      // Немедленно вычитаем сумму ставки из баланса и обновляем g_token
      setBalance(prevBalance => {
        const newBalance = prevBalance - betAmount;
        updateUser({ g_token: newBalance });
        return newBalance;
      });

      // Подключаемся к WebSocket и отправляем ставку
      connectWebSocket("down", betAmount);
    } catch (error) {
      console.error("Ошибка при обработке ставки Down:", error);
      setError("Ошибка при обработке ставки Down.");
    }
  };

  //отримання партнерів для модалкі
  useEffect(() => {
    const fetchExchangeLinks = async () => {
      setIsLinksLoading(true);
      try {
        const response = await axios.post(API_URLS.REACT_APP_GAME4_LINKSS);
        if (response.data && typeof response.data === "object") {
          setExchangeLinks(response.data);
        } else {
        }
      } catch (error) {
      } finally {
        setIsLinksLoading(false);
      }
    };
  
    fetchExchangeLinks();
  }, []);

  // Очистка WebSocket соединения при размонтировании компонента
  useEffect(() => {
    return () => {
      if (wsRef.current) {
        console.log("Размонтирование компонента. Закрытие WebSocket соединения.");
        wsRef.current.close();
      }
    };
  }, []);

  // Обработчик изменения ставки с ограничением до двух десятичных знаков
  // const handleBetChange = (e) => {
  //   const value = e.target.value;
  //   if (/^\d+(\.\d{0,2})?$/.test(value) || value === "") {
  //     setBet(value);
  //     console.log(`Введена ставка: ${value}`);
  //   }
  // };

  const formatBalanceWithSixDecimals = (balance) => {
    return Number(balance).toFixed(2);
  };

  // для партнеров
  const handlePocketoptionClick = useCallback(() => {
    setIsAdModalOpen(true);
  }, []);

  // для результату
  const updateModalState = (message, isOpen = true, link = "") => {
    setModalState({ isOpen, message, link });
  };

  return (

    <>
    <Keyboard
        onKeyPress={handleKeyPress}
        onClose={() => setIsKeyboardVisible(false)}
        isVisible={isKeyboardVisible}
      />
      <div className="con-game-4">
      {/* Отображение ошибок */}
      {/* {error && <p style={{ color: "red" }}>{error}</p>} */}


      

      <PartnersModal
        iconArrowLeft={iconArrowLeft}
        background={modalBackground}
        isOpen={isAdModalOpen}
        onClose={() => setIsAdModalOpen(false)}
        exchangeLinks={exchangeLinks}
        isLinksLoading={isLinksLoading}
      />

      <TradeWarningModal
        key={modalState.isOpen ? "open" : "closed"}
        modalState={modalState}
        background={modalBackground}
        onClose={() => updateModalState("", false)}
        exchangeLinks={exchangeLinks}
      />


      <div className="info-con-up">
        <div className="user-balance">
          <CoinIcon className="coin" />
          <div className="quantity-balance-con">
            <p className="quantity-balance">{formatBalanceWithSixDecimals(balance)}</p>
          </div>
        </div>
        <div className="timer-con">
          <p className="timer-quantity">{timerValue}</p>
          <div className="con-txt">
            <p className="txt">BTC/USDT</p>
          </div>
        </div>
      </div>
      <div className="graph-cont">
        <div className="chart_wrapper">{memoizedTradeViewChart}</div>
      </div>
      <div className="info-con-bottom">
        <div className="info-con-one">
          <div className="initialBTCPrice-con">
            <p className="lb-initialBTCPrice">{t("GamePage.Game4.Bet")}</p>
            <div className="initialBTCPrice-card"
              style={{
                color: userBetDirection === 'up' ? 'rgb(50 255 0)' : userBetDirection === 'down' ? 'red' : '#fff'
              }}
            >
              <p className="initialBTCPrice-quantity">
                {initialBTCPrice !== null ? initialBTCPrice : "--"}
              </p>
              {
                userBetDirection === 'up'
                  ? <ArrowUp className="arrow-g4 up" />
                  : userBetDirection === 'down'
                    ? <ArrowDown className="arrow-g4 down" />
                    : null
              }
            </div>
          </div>

          <div className="newBTCPrice-con">
            <p className="lb-newBTCPrice">{t("GamePage.Game4.Ending")}</p>
            <div className="newBTCPrice-card"
              style={{
                color: newBTCPrice === null
                  ? '#fff'
                  : initialBTCPrice > newBTCPrice
                    ? 'red'
                    : 'rgb(50, 255, 0)',
              }}
            >
              <p className="newBTCPric-quantity">{newBTCPrice !== null ? newBTCPrice : "--"}</p>
              {
                newBTCPrice === null
                  ? ""
                  : initialBTCPrice > newBTCPrice
                    ? <ArrowDown className="arrow-g4 down" />
                    : initialBTCPrice < newBTCPrice
                      ? <ArrowUp className="arrow-g4 up" />
                      : null
              }
            </div>
          </div>
          <div className="win-sum">
            <CoinIcon className="coin" />
            <div className="win-sum-con">
              <p
                className="win-sum-qui"
                style={{
                  color: winBet === null ? '#fff' : (winBet < 0 ? 'rgba(198, 8, 8, 1)' : 'rgb(50, 255, 0)')
                }}
              >
                {winBet !== null ? winBet : "--"}
              </p>
            </div>
          </div>
          <div className="CryptoPocket-con" onClick={handlePocketoptionClick}>
            <img
              src={PocketoptionSmal}
              alt="CryptoPocket"
              className="CryptoPocket-img"
            />
          </div>
        </div>
        <div className="info-con-two">
          <div className="rate-inpt-sec">
          <div className="lock-button-con"
              style={{ display: isConnected ? 'flex' : 'none' }}
            >
              <LockIcon className="icon-lock" />
            </div>
          <div className="rate-inpt-con" style={{ background: isConnected ? 'unset' : '' }}>
            <CoinIcon className="coin" />
            <input
              type="text"
              id="bet"
              value={bet}
              // onChange={handleBetChange}
              placeholder="0.001"
              min="0.01"
              step="0.01"

              readOnly
              onFocus={() => setIsKeyboardVisible(true)}

            />
          </div>
          </div>
          

          <div className="game-button-up-con">
            <div className="lock-button-con"
              style={{ display: isConnected ? 'flex' : 'none' }}
            >
              <LockIcon className="icon-lock" />
            </div>
            <button
              type="button"
              onPointerDown={handleUpClick}
              disabled={isConnected}
              className="game-button-up-f"
            >{t("GamePage.Game4.Up")}</button>
          </div>

          <div className="game-button-down-con">
            <div className="lock-button-con"
              style={{ display: isConnected ? 'flex' : 'none' }}
            >
              <LockIcon className="icon-lock" />
            </div>
            <button
              type="button"
              onPointerDown={handleDownClick}
              disabled={isConnected}
              className="game-button-down-f"
            >{t("GamePage.Game4.Down")}</button>
          </div>

        </div>
      </div>
    </div>
    </>

    
  );
}

export default WebSocketTester;
