import React, { useRef, useEffect, useState } from 'react';

const ScrollExample = () => {
    const scrollContainerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState({ x: 627, y: 400 });
    let scrollTimeout = null;

    // Прокрутка к определённым координатам при монтировании компонента
    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({
                left: scrollPosition.x,
                top: scrollPosition.y,
                behavior: 'smooth', // Плавная прокрутка
            });
        }
    }, []);

    // Обработчик прокрутки для обновления координат
    useEffect(() => {
        const handleScroll = () => {
            if (scrollContainerRef.current) {
                if (scrollTimeout) {
                    clearTimeout(scrollTimeout);
                }

                scrollTimeout = setTimeout(() => {
                    const newScrollPosition = {
                        x: scrollContainerRef.current.scrollLeft,
                        y: scrollContainerRef.current.scrollTop,
                    };
                    setScrollPosition(newScrollPosition);
                    console.log('Scroll position:', newScrollPosition);
                }, 1000);
            }
        };

        const container = scrollContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        // Удаление обработчика при размонтировании компонента
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
            if (scrollTimeout) {
                clearTimeout(scrollTimeout);
            }
        };
    }, []);

    // return (
    //     <div
    //         ref={scrollContainerRef}
    //         style={{
    //             width: '100%',  // Ширина контейнера
    //             height: '300px', // Высота контейнера
    //             overflow: 'auto', // Разрешаем скролл
    //             border: '1px solid black' // Для наглядности
    //         }}
    //     >
    //         <div style={{ width: '1000px', height: '1000px', padding: '20px' }}>
    //             {/* Содержимое для скролла */}
    //             <div style={{ height: '200px', backgroundColor: 'lightcoral' }}>Block 1</div>
    //             <div style={{ height: '200px', backgroundColor: 'lightblue' }}>Block 2</div>
    //             <div style={{ height: '200px', backgroundColor: 'lightgreen' }}>Block 3</div>
    //             <div style={{ height: '200px', backgroundColor: 'lightyellow' }}>Block 4</div>
    //             <div style={{ height: '200px', backgroundColor: 'lightpink' }}>Block 5</div>
    //         </div>
    //     </div>
    // );

    return (
        <div
            ref={scrollContainerRef}
            style={{
                width: '100%',  // Ширина контейнера
                height: '300px', // Высота контейнера
                overflow: 'auto', // Разрешаем скролл
                border: '1px solid black' // Для наглядности
            }}
        >
            <div style={{ width: '1000px', height: '1000px', padding: '20px' }}>
                {/* Содержимое для скролла */}
                <div style={{ height: '200px', backgroundColor: 'lightcoral' }}>Block 1</div>
                <div style={{ height: '200px', backgroundColor: 'lightblue' }}>Block 2</div>
                <div style={{ height: '200px', backgroundColor: 'lightgreen' }}>Block 3</div>
                <div style={{ height: '200px', backgroundColor: 'lightyellow' }}>Block 4</div>
                <div style={{ height: '200px', backgroundColor: 'lightpink' }}>Block 5</div>
            </div>
        </div>
    );
};

export default ScrollExample;