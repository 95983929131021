// contexts/ClickContext.js

import React, {
    createContext,
    useContext,
    useRef,
    useState,
    useEffect,
    useCallback
} from "react";
import axios from "axios";
import { useUser } from "./UserContext";
import { API_URLS } from "../config/config";

const ClickContext = createContext(null);

export const ClickProvider = ({ children }) => {
    const { user, updateUser } = useUser();

    // Зберігаємо невідіслані кліки
    const fClicksRef = useRef(0);
    const fGoldRef = useRef(0);

    // Приклад — зберігаємо, чи ми "тречимо" кліки
    const [isTracking, setIsTracking] = useState(false);

    // ===================== A. Функції =====================

    // Функція для оновлення останньої активності
    const updateLastActive = async (userId) => {
        try {
            await axios.post(API_URLS.UPDATE_LAST_ACTIVE, { userId });
            console.log("Last active updated for user:", userId);
        } catch (error) {
            console.error("Error updating last active:", error);
        }
    };

    const detectAutoClicker = useCallback((/* data */) => {
        return false;
    }, []);

    const sendToBackend = useCallback(async (data) => {
        try {
            const response = await axios.post(API_URLS.ADD_COINS, data);
            return response.data;
        } catch (error) {
            console.error("sendToBackend error:", error);
            return null;
        }
    }, []);

    const analyzeAndSendData = useCallback(async () => {
        if (!user) return;
        if (fClicksRef.current === 0) return;

        const suspiciousActivity = detectAutoClicker();

        const dataToSend = {
            userId: user.user_id,
            totalClicks: fClicksRef.current,
            currentEnergy: user.current_energy,
            suspiciousActivity
        };

        try {
            const response = await sendToBackend(dataToSend);
            if (response && response.user_info) {
                console.log("Send----");
                const newBalance = response.user_info.gold_balance + fGoldRef.current;
                // updateUser({
                //     ...user,
                //     gold_balance: newBalance
                // });
            }

            console.log("check----");
            // Скидываем счетчики
            fClicksRef.current = 0;
            fGoldRef.current = 0;

            // Обновляем lastActive после успешной отправки
            await updateLastActive(user.user_id);
        } catch (error) {
            console.error("analyzeAndSendData error:", error);
        }
    }, [user, detectAutoClicker, updateUser, sendToBackend]);

    // ===================== Логіка регенерації енергії =====================
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!user) return;

            if (user.current_energy < user.energy) {
                const newEnergy = Math.min(
                    user.current_energy + user.energy_regeneration,
                    user.energy
                );
                updateUser({
                    ...user,
                    current_energy: newEnergy
                });
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [user, updateUser]);

    // ===================== B. Обробка кліку ззовні =====================
    const handleMainButtonClick = useCallback(() => {
        if (!user) return;
        if (user.current_energy <= 0) return;

        const newEnergy = user.current_energy - 1;
        const earnedGold = user.click_multiplier;
        const newGold = user.gold_balance + earnedGold;

        updateUser({
            ...user,
            current_energy: newEnergy,
            gold_balance: newGold
        });

        fClicksRef.current += 1;
        fGoldRef.current += earnedGold;
    }, [user, updateUser]);

    // ===================== C. Зберегти перед навігацією =====================
    const saveClicksBeforeNavigation = useCallback(async () => {
        await analyzeAndSendData();
    }, [analyzeAndSendData]);

    // ===================== D. Вмикаємо інтервал для відправки =====================
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (fClicksRef.current > 0) {
                analyzeAndSendData();
            }
        }, 1000);

        const handleBeforeUnload = () => {
            if (fClicksRef.current > 0) {
                analyzeAndSendData();
            }
        };
        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            clearInterval(intervalId);
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [analyzeAndSendData]);

    // ===================== E. Повертаємо контекст =====================
    const value = {
        handleMainButtonClick,
        saveClicksBeforeNavigation
    };

    return <ClickContext.Provider value={value}>{children}</ClickContext.Provider>;
};

// Хук доступу до контексту
export const useClick = () => useContext(ClickContext);
