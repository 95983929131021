import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "../contexts/UserContext";
import { useAudio } from "../contexts/AudioContext";
import { API_URLS } from "../config/config";
import axios from "axios";
import "../assets/styles/copmonents/Modal/addAdsetModalStyle.css";
import TradeWarningModal from "../components/TradeWarningModal";
import clickSoundMp3 from "../assets/sounds/menu-button-click.mp3";
const CustomRegionSelect = ({ options, onSelect, icon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const selectRef = useRef(null);
  const optionsRef = useRef(null);
  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);
  const { t } = useTranslation();

  const allOptions = [
    {
      id: "all",
      name: t("AdOfficePage.AdsetModal.AllRegionsName"),
      code: "ALL",
    },
    ...options,
  ];

  const toggleSelect = () => setIsOpen(!isOpen);

  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const [clickResponse] = await Promise.all([fetch(clickSoundMp3)]);

          const [clickArrayBuffer] = await Promise.all([
            clickResponse.arrayBuffer(),
          ]);

          const [clickAudioBuffer] = await Promise.all([
            audioContextRef.current.decodeAudioData(clickArrayBuffer),
          ]);

          clickAudioBufferRef.current = clickAudioBuffer;

          setIsAudioInitialized(true);
        } catch (error) {}
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    if (
      isAudioInitialized &&
      audioContextRef.current &&
      clickAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  const handleOptionClick = (option) => {
    let newSelectedOptions;
    playSound();
    if (option.id === "all") {
      newSelectedOptions =
        selectedOptions.length === options.length ? [] : options;
    } else {
      const isSelected = selectedOptions.some((item) => item.id === option.id);
      if (isSelected) {
        newSelectedOptions = selectedOptions.filter(
          (item) => item.id !== option.id
        );
      } else {
        newSelectedOptions = [...selectedOptions, option];
      }
    }
    setSelectedOptions(newSelectedOptions);
    onSelect(newSelectedOptions.map((opt) => opt.code));
  };

  const handleRemoveOption = (option) => {
    playSound();
    let newSelectedOptions;
    if (option.id === "all") {
      newSelectedOptions = [];
    } else {
      newSelectedOptions = selectedOptions.filter(
        (item) => item.id !== option.id
      );
    }
    setSelectedOptions(newSelectedOptions);
    onSelect(newSelectedOptions.map((opt) => opt.code));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`addadset custom-select-wrapper ${isOpen ? "open" : ""}`}
      ref={selectRef}
    >
      <div className="custom-select" onClick={toggleSelect}>
        <div className="selected-options w-11/12">
          {selectedOptions.length === options.length ? (
            <span className="selected-option">
              {t("AdOfficePage.AdsetModal.AllRegionsName")}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveOption({ id: "all" });
                }}
              >
                ×
              </button>
            </span>
          ) : (
            selectedOptions.map((option) => (
              <span key={option.id} className="selected-option">
                {option.code}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveOption(option);
                  }}
                >
                  ×
                </button>
              </span>
            ))
          )}
        </div>
        <div className="w-1/12">
          <img
            src={icon}
            alt="arrow"
            className={`w-3 ${isOpen ? "rotated" : ""}`}
          />
        </div>
      </div>
      <div
        className={`custom-options ${isOpen ? "open" : ""}`}
        ref={optionsRef}
      >
        <div className="options-container">
          {allOptions.map((option) => (
            <div
              key={option.id}
              className={`custom-option ${
                option.id === "all"
                  ? selectedOptions.length === options.length
                    ? "selected"
                    : ""
                  : selectedOptions.some((item) => item.id === option.id)
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              <span>{option.name}</span>
              {(option.id === "all" &&
                selectedOptions.length === options.length) ||
              (option.id !== "all" &&
                selectedOptions.some((item) => item.id === option.id)) ? (
                <span className="checkmark">✓</span>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const AddAdsetModal = ({
  isOpen,
  onClose,
  background,
  iconArrowLeft,
  iconArrow,
  iconCoin,
  regions,
  onAdsetCreated,
}) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [adsetName, setAdsetName] = useState("");
  const [amountPerDay, setAmountPerDay] = useState("");
  const [selectedRegions, setSelectedRegions] = useState([]);
  const { user } = useUser();
  const [days, setDays] = useState(6);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [modalState, setModalState] = useState({
    isOpen: false,
    message: "",
    link: "",
  });
  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setIsClosing(false);
    }
  }, [isOpen]);

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 300);
  };

  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const [clickResponse] = await Promise.all([fetch(clickSoundMp3)]);

          const [clickArrayBuffer] = await Promise.all([
            clickResponse.arrayBuffer(),
          ]);

          const [clickAudioBuffer] = await Promise.all([
            audioContextRef.current.decodeAudioData(clickArrayBuffer),
          ]);

          clickAudioBufferRef.current = clickAudioBuffer;

          setIsAudioInitialized(true);
        } catch (error) {}
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    if (
      isAudioInitialized &&
      audioContextRef.current &&
      clickAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  const handleAdsetNameChange = (e) => {
    setAdsetName(e.target.value);
  };

  const handleAmountPerDayChange = (e) => {
    setAmountPerDay(e.target.value);
  };

  const handleRegionChange = (selectedOptions) => {
    setSelectedRegions(selectedOptions);
  };

  const handleDaysChange = (e) => {
    const value = Number(e.target.value);
    setDays(value);
    updateSliderBackground(value);
  };

  const updateSliderBackground = (value) => {
    if (sliderRef.current) {
      const percentage = ((value - 1) / (31 - 1)) * 100;
      sliderRef.current.style.setProperty(
        "--slider-percentage",
        `${percentage}%`
      );
    }
  };
  const handleInputFocus = () => {
    const menuElement = document.querySelector(".menu");
    if (menuElement) {
      menuElement.classList.remove("menu");
      menuElement.classList.add("menu-hidden");
    }
    const tradeButton = document.querySelector(".trade-button");
    if (tradeButton) {
      tradeButton.classList.remove("trade-button");
      tradeButton.classList.add("trade-button-hidden");
    }
  };

  const handleInputBlur = () => {
    const menuElement = document.querySelector(".menu-hidden");
    if (menuElement) {
      menuElement.classList.remove("menu-hidden");
      menuElement.classList.add("menu");
    }
    const tradeButton = document.querySelector(".trade-button-hidden");
    if (tradeButton) {
      tradeButton.classList.remove("trade-button-hidden");
      tradeButton.classList.add("trade-button");
    }
  };

  useEffect(() => {
    updateSliderBackground(days);
  }, [days]);

  const getDayText = (number) => {
    return t("AdOfficePage.DayText", { count: number });
  };

  const handleCloseButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    playSound();
    console.log("Close button clicked");
    closeModal();
  };

  const clearFields = () => {
    setAdsetName("");
    setAmountPerDay("");
    setSelectedRegions([]);
    setDays(6);
  };

  const updateModalState = (message, isOpen = true, link = "") => {
    setModalState({ isOpen, message, link });
  };

  const createAdset = async () => {
    playSound();

    const data = {
      name: adsetName,
      advertiserId: user.user_id,
      relevantRegions: selectedRegions,
      displayDuration: days,
      gTokensPerDay: parseFloat(amountPerDay),
    };

    try {
      const response = await axios.post(API_URLS.CREATE_ADSET, data);

      if (response.data.ok === true) {
        clearFields();
        closeModal();
        onAdsetCreated();
      }
    } catch (error) {
      let message = "";
      if (
        error.response.data.message ===
        "not enough G-tokens to create the adset"
      ) {
        const gTokensNeeded = error.response.data.g_tokens_needed;
        message =
          t("AdOfficePage.AdsetModal.ErrorNotEnoughTokens") + gTokensNeeded;
      } else {
        message = t("AdOfficePage.AdsetModal.UnexpectedError");
      }
      updateModalState(message, true, "");
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      playSound();
      clearFields();
      closeModal();
    }
  };

  if (!isOpen && !isActive) return null;

  return (
    <div
      className={`add-adset-modal-overlay ${isClosing ? "closing" : "active"}`}
      onClick={handleOverlayClick}
    >
      <div
        className={`add-adset-modal-container ${
          isClosing ? "closing" : "active"
        }`}
        style={{ "--modal-background": `url(${background})` }}
        ref={modalRef}
      >
        <TradeWarningModal
          key={modalState.isOpen ? "open" : "closed"}
          modalState={modalState}
          background={background}
          onClose={() => updateModalState("", false)}
        />

        <div className="add-adset-close-modal-container">
          <button
            className="level-modal-close-button"
            onClick={handleCloseButtonClick}
          >
            <img src={iconArrowLeft} alt="<" />
            <p className="level-modal-close-text ml-2">
              {t("AdOfficePage.ModalBackButtonText")}
            </p>
          </button>
        </div>
        <div className="w-full flex justify-center items-center">
          <p className="modal-title">{t("AdOfficePage.AdsetModal.Title")}</p>
        </div>
        <div className="w-full flex flex-col justify-between items-center">
          <div className="w-full flex flex-col justify-center items-center">
            <p className="add-adset-modal-text">
              {t("AdOfficePage.AdsetModal.AdsetName")}
            </p>
            <div className="modal-data-input-container">
              <input
                type="text"
                value={adsetName}
                onChange={handleAdsetNameChange}
                className="modal-data-text-input"
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                placeholder="Name"
              />
            </div>
          </div>
          {/* CAN BE ADD SOON */}
          {/* <div className="w-full flex flex-col justify-center items-center">
            <p className="add-adset-modal-text">
              {t("AdOfficePage.AdsetModal.ClickGoal")}
            </p>
            <div className="modal-data-input-container">
              <input
                type="text"
                inputMode="decimal"
                pattern="[0-9]*"
                min={1}
                value={clicksGoal}
                onChange={handleClicksGoalChange}
                className="modal-data-text-input"
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                placeholder="Clicks count"
              />
            </div>
          </div> */}

          <div className="w-full flex flex-col justify-center items-center">
            <p className="add-adset-modal-text">
              {t("AdOfficePage.AdsetModal.RegionText")}
            </p>
            <div className="modal-region-data-input-container">
              <CustomRegionSelect
                options={regions}
                onSelect={handleRegionChange}
                icon={iconArrow}
              />
            </div>
          </div>

          <div className="w-full flex flex-col justify-center items-center">
            <p className="add-adset-modal-text">
              {t("AdOfficePage.AdsetModal.AmountPerDay")}
            </p>
            <div className="modal-data-input-container">
              <div className="add-adset-amount-item-icon-container">
                <img
                  src={iconCoin}
                  alt="coin"
                  className="add-adset-amount-icon-gtoken"
                />
                <p className="token">Token</p>
              </div>
              <input
                type="text"
                min={1}
                value={amountPerDay}
                inputMode="decimal"
                pattern="[0-9]*"
                onChange={handleAmountPerDayChange}
                className="add-adset-amount-input"
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                placeholder="Amount"
              />
            </div>
          </div>
          <div className="w-full flex flex-col justify-center items-center mt-2">
            <p className="add-adset-modal-text">
              {t("AdOfficePage.AdsetModal.Duration")}
            </p>
            <div className="day-range-slider">
              <div className="slider-labels flex justify-between">
                <span className="days-max-min">
                  1 {t("AdOfficePage.DayText_one")}
                </span>
                <span className="days-value">
                  {days} {getDayText(days)}
                </span>
                <span className="days-max-min">
                  31 {t("AdOfficePage.DayText_one")}
                </span>
              </div>
              <div className="days-slider-container">
                <input
                  type="range"
                  min="1"
                  max="31"
                  value={days}
                  className="slider"
                  ref={sliderRef}
                  onChange={handleDaysChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center mt-3 mb-6">
          <button className="save-adset-modal-button" onClick={createAdset}>
            {t("AdOfficePage.AdsetModal.SaveBtn")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAdsetModal;
