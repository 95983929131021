//LoadingScreen.js
import React from 'react';
import ProgressBar from '../components/ProgressBar';
import logo from '../assets/images/loadingScreen/Subtract.webp'
import backgroundImage from '../assets/images/loadingScreen/backgr.webp';
import '../assets/styles/LoadingPage.css'

import { useTranslation } from 'react-i18next';

const LoadingScreen = React.memo(({ progress }) => {
  const { t } = useTranslation();
  return (
    <div className="progressPage" style={{backgroundImage: `url(${backgroundImage})`}}>
      <img className='logo_pr' src={logo}></img>
      <p className='progress_title'>Play, win, earn!</p>
      <p style={{marginBottom: "27px"}} className='progress_title'>Играй, выигрывай, зарабатывай!</p>
      <ProgressBar progress={progress} />
    </div>
  );
});

export default LoadingScreen;




// // LoadingScreen.js
// import React, { useState, useEffect } from 'react';
// import ProgressBar from '../components/ProgressBar';
// import logo from '../assets/images/loadingScreen/Subtract.webp';
// import backgroundImage from '../assets/images/loadingScreen/backgr.webp';
// import '../assets/styles/LoadingPage.css';

// const LoadingScreen = React.memo(({ progress }) => {
//   const textArray = [
//     'Играй, выигрывай, зарабатывай!',
//     'Play, win, earn!',
//   ];

//   // Set the initial text to the first item in the textArray
//   const [currentText, setCurrentText] = useState(textArray[0]);

//   useEffect(() => {
//     let textIndex = 0;
//     const changeText = () => {
//       textIndex = (textIndex + 1) % textArray.length; // Loop through array
//       setCurrentText(textArray[textIndex]);
//     };

//     // Change text every 2 seconds
//     const textChangeInterval = setInterval(changeText, 2000);

//     return () => clearInterval(textChangeInterval); // Cleanup on unmount
//   }, [textArray]);

//   return (
//     <div className="progressPage" style={{ backgroundImage: `url(${backgroundImage})` }}>
//       <img className='logo_pr' src={logo} alt="logo"></img>
//       <p className='progress_title'>{currentText}</p>
//       <ProgressBar progress={progress} />
//     </div>
//   );
// });

// export default LoadingScreen;
