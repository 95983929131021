import { useEffect, useCallback, memo, useRef } from "react";
import { useUser } from "../contexts/UserContext";

const TelegramInitializer = memo(({ onSuccess, onError }) => {
  const { updateUser } = useUser();
  const isInitialized = useRef(false);

  const lockOrientation = useCallback(() => {
    const root = document.documentElement;
    if (window.screen.orientation) {
      if (window.screen.orientation.type.includes("portrait")) {
        root.style.setProperty("--app-height", `${window.innerHeight}px`);
      } else {
        root.style.setProperty("--app-height", `${window.innerWidth}px`);
        root.style.setProperty("--app-width", `${window.innerHeight}px`);
      }
    } else {
      if (window.innerHeight > window.innerWidth) {
        root.style.setProperty("--app-height", `${window.innerHeight}px`);
      } else {
        root.style.setProperty("--app-height", `${window.innerWidth}px`);
        root.style.setProperty("--app-width", `${window.innerHeight}px`);
      }
    }
  }, []);

  const initializeTelegram = useCallback(async () => {
    if (isInitialized.current) {
      return;
    }
    isInitialized.current = true;

    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      lockOrientation();

      const tgUser = window.Telegram.WebApp.initDataUnsafe.user;
      if (tgUser && tgUser.id) {
        updateUser((prevUser) => ({
          ...prevUser,
          tgInfo: {
            id: tgUser.id,
            first_name: tgUser.first_name,
            last_name: tgUser.last_name,
            username: tgUser.username,
            language: tgUser.language_code,
          },
        }));
        updateUser((prevUser) => {
          if (!prevUser || !prevUser.lang_code) {
            return {
              ...prevUser,
              lang_code: tgUser.language_code || "en",
            };
          }
          return prevUser;
        });
        onSuccess(tgUser.id);
      } else {
        onError();
      }
    } else {
      onError();
    }
  }, [updateUser, onSuccess, onError, lockOrientation]);

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      initializeTelegram();
    } else {
      const script = document.createElement("script");
      script.src = "https://telegram.org/js/telegram-web-app.js";
      script.async = true;
      script.onload = () => {
        initializeTelegram();
      };
      document.body.appendChild(script);

      return () => {
        if (script.parentNode) {
          document.body.removeChild(script);
        }
      };
    }
  }, [initializeTelegram]);

  useEffect(() => {
    window.addEventListener("resize", lockOrientation);
    window.addEventListener("orientationchange", lockOrientation);

    return () => {
      window.removeEventListener("resize", lockOrientation);
      window.removeEventListener("orientationchange", lockOrientation);
    };
  }, [lockOrientation]);

  return null;
});

export default TelegramInitializer;
