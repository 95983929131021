import React, { useEffect, useRef, useState } from "react";

const AnimatedFairy = ({ gifSrc, onFairyClick }) => {
  const fairyRef = useRef(null);
  const [isAnimating, setIsAnimating] = useState(true);
  const lastDirectionRef = useRef(null);

  const handleClick = () => {
    setIsAnimating(false);
    if (onFairyClick) {
      onFairyClick();
    }
  };

  useEffect(() => {
    const FLIGHT_AREA = {
      xMin: -50,
      xMax: window.innerWidth + 50,
      yMin: window.innerHeight * 0.35,
      yMax: window.innerHeight * 0.7,
    };

    const FAIRY_SPEED = 0.2;
    let animationFrameId = null;

    const generateFlight = () => {
      const fromLeft =
        lastDirectionRef.current === "left"
          ? false
          : lastDirectionRef.current === "right"
          ? true
          : Math.random() < 0.5;
      lastDirectionRef.current = fromLeft ? "left" : "right";

      const startX = fromLeft ? FLIGHT_AREA.xMin : FLIGHT_AREA.xMax;
      const startY =
        Math.random() * (FLIGHT_AREA.yMax - FLIGHT_AREA.yMin) +
        FLIGHT_AREA.yMin;

      const endX = fromLeft ? FLIGHT_AREA.xMax : FLIGHT_AREA.xMin;
      let endY;

      if (Math.random() < 0.7) {
        endY =
          startY < (FLIGHT_AREA.yMin + FLIGHT_AREA.yMax) / 2
            ? Math.random() *
                (FLIGHT_AREA.yMax - (FLIGHT_AREA.yMin + FLIGHT_AREA.yMax) / 2) +
              (FLIGHT_AREA.yMin + FLIGHT_AREA.yMax) / 2
            : Math.random() *
                ((FLIGHT_AREA.yMin + FLIGHT_AREA.yMax) / 2 - FLIGHT_AREA.yMin) +
              FLIGHT_AREA.yMin;
      } else {
        endY =
          Math.random() * (FLIGHT_AREA.yMax - FLIGHT_AREA.yMin) +
          FLIGHT_AREA.yMin;
      }

      const distance = Math.sqrt(
        Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2)
      );
      const duration = distance / FAIRY_SPEED;

      return { startX, endX, startY, endY, fromLeft, duration };
    };

    let currentFlight = generateFlight();
    let startTime = performance.now();

    const animate = (timestamp) => {
      if (!isAnimating) return;

      const elapsedTime = timestamp - startTime;

      if (elapsedTime < currentFlight.duration) {
        const flightProgress = elapsedTime / currentFlight.duration;

        const { startX, endX, startY, endY, fromLeft } = currentFlight;

        const x = startX + (endX - startX) * flightProgress;
        const y = startY + (endY - startY) * flightProgress;

        if (fairyRef.current) {
          fairyRef.current.style.transform = `translate(${x}px, ${y}px) scaleX(${
            fromLeft ? 1 : -1
          })`;
          fairyRef.current.style.opacity =
            x >= -50 &&
            x <= window.innerWidth + 50 &&
            y >= FLIGHT_AREA.yMin &&
            y <= FLIGHT_AREA.yMax
              ? 1
              : 0;
        }
      } else {
        currentFlight = generateFlight();
        startTime = performance.now();
      }

      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [isAnimating]);

  return (
    <img
      ref={fairyRef}
      src={gifSrc}
      alt="Animated Fairy"
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "50px",
        height: "auto",
        zIndex: 1000,
        pointerEvents: "auto",
        cursor: "pointer",
        opacity: 0,
        transition: "opacity 0.2s ease-in-out",
      }}
      onClick={handleClick}
    />
  );
};

export default AnimatedFairy;
