import React, { useState, useEffect } from 'react';
import jailIcon from '../../assets/images/prison.webp'; // Assuming you have a jail icon

const JailScene = ({ isBlocked, blockedUntil }) => {
  const [remainingTime, setRemainingTime] = useState(0);
  const [isVisible, setIsVisible] = useState(false); // To control fade-in animation

  const formatTime = (ms) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    return `${hours.toString().padStart(2, "0")}:${(minutes % 60)
      .toString()
      .padStart(2, "0")}:${(seconds % 60).toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    console.log("========");
    console.log("isBlocked: ", isBlocked);
    console.log("blockedUntil: ", blockedUntil);
    console.log("========");
    
    let intervalId;

    // Convert blockedUntil (ISO string) to a Date object
    const blockedUntilDate = new Date(blockedUntil);

    if (isBlocked === true && !isNaN(blockedUntilDate.getTime())) {
      intervalId = setInterval(() => {
        const now = new Date();
        const timeLeft = blockedUntilDate.getTime() - now.getTime();

        if (timeLeft <= 0) {
          clearInterval(intervalId);
        } else {
          setRemainingTime(timeLeft);
          setIsVisible(true); // Make time visible with fade-in effect
        }
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isBlocked, blockedUntil]);

  return (
    isBlocked ? (
      <div className={`jail-msg-section ${isVisible ? 'visible' : ''}`} style={{ color: 'white', padding: '20px' }}>
        <div className='jail-msg-container'>
          <div className='jail-msg-logo-con'>
            <img src={jailIcon} alt="Prison" className="jail-msg-logo" />
          </div>
          <div className={`jail-msg-time ${isVisible ? 'visible' : ''}`}>
            {remainingTime ? formatTime(remainingTime) : "00:00:00"}
          </div>
        </div>
      </div>
    ) : null
  );
};

export default JailScene;
