import React, { useState, useEffect, useCallback, useRef } from "react";
import LoadingDots from "./LoadingDots";

const NumericInput = ({
  value,
  onChange,
  className,
  onFocus,
  onBlur,
  step = 1,
  min = 0,
  max,
  showArrows = true,
  readOnly = false,
  isLoading = false,
  showLoadingAnimation = true,
}) => {
  const [displayValue, setDisplayValue] = useState(() => {
    return value !== undefined && value !== null ? value.toString() : "";
  });
  const lastInteractionTime = useRef(0);
  const interactionTimeoutRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (
      value !== undefined &&
      value !== null &&
      value.toString() !== displayValue
    ) {
      setDisplayValue(value.toString());
    }
  }, [value, displayValue]);

  const handleChange = useCallback(
    (e) => {
      if (readOnly) return;
      let newValue = e.target.value;

      if (newValue === "" || /^\d*\.?\d*$/.test(newValue)) {
        setDisplayValue(newValue);

        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue) && parsedValue >= 0) {
          onChange(parsedValue);
        } else if (newValue === "" || newValue === ".") {
          onChange("");
        }
      }
    },
    [readOnly, onChange]
  );

  const updateValue = useCallback(
    (newValue) => {
      if (readOnly) return;
      newValue = parseFloat(newValue);
      if (isNaN(newValue) || newValue < min) {
        newValue = min;
      }
      if (max !== undefined) {
        newValue = Math.min(newValue, max);
      }
      const formattedValue = newValue.toString();
      setDisplayValue(formattedValue);
      onChange(newValue);
    },
    [readOnly, min, max, onChange]
  );

  const handleInteraction = useCallback(
    (operation) => (e) => {
      e.preventDefault();
      e.stopPropagation();

      const now = new Date().getTime();
      if (now - lastInteractionTime.current > 100 && !readOnly) {
        const currentValue = parseFloat(displayValue) || 0;
        updateValue(currentValue + (operation === "increment" ? step : -step));
        lastInteractionTime.current = now;

        if (interactionTimeoutRef.current) {
          clearTimeout(interactionTimeoutRef.current);
        }

        interactionTimeoutRef.current = setTimeout(() => {
          lastInteractionTime.current = 0;
        }, 500);
      }
    },
    [readOnly, displayValue, step, updateValue]
  );

  useEffect(() => {
    return () => {
      if (interactionTimeoutRef.current) {
        clearTimeout(interactionTimeoutRef.current);
      }
    };
  }, []);

  const handleFocus = useCallback(
    (e) => {
      if (onFocus) {
        onFocus(e);
      }
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);
    },
    [onFocus]
  );

  return (
    <div className="numeric-input-container">
      <input
        ref={inputRef}
        type="number"
        inputMode="decimal"
        step="any"
        min={min}
        max={max}
        value={isLoading && showLoadingAnimation ? "" : displayValue}
        onChange={handleChange}
        onBlur={onBlur}
        className={`numeric-input ${
          isLoading && showLoadingAnimation ? "loading" : ""
        } ${className || ""}`}
        onFocus={handleFocus}
        readOnly={readOnly || (isLoading && showLoadingAnimation)}
      />
      {isLoading && showLoadingAnimation && (
        <div className="loading-overlay">
          <LoadingDots />
        </div>
      )}
      {showArrows && (
        <div className="numeric-input-arrows">
          <button
            type="button"
            onTouchStart={handleInteraction("increment")}
            onMouseDown={handleInteraction("increment")}
            className={`numeric-input-arrow up ${
              readOnly || isLoading ? "readonly" : ""
            }`}
            aria-label="Increase"
            disabled={readOnly || isLoading}
          >
            ▲
          </button>
          <button
            type="button"
            onTouchStart={handleInteraction("decrement")}
            onMouseDown={handleInteraction("decrement")}
            className={`numeric-input-arrow down ${
              readOnly || isLoading ? "readonly" : ""
            }`}
            aria-label="Decrease"
            disabled={readOnly || isLoading}
          >
            ▼
          </button>
        </div>
      )}
    </div>
  );
};

export default NumericInput;
