import React, { useState, useEffect } from 'react';
import './AnimatedText.css';


const AnimatedText = () => {
    const [text, setText] = useState("Initial Text");
    const [animationState, setAnimationState] = useState('');

    const changeText = () => {
        setAnimationState('fade-out');
        setTimeout(() => {
            setText(prev => prev === "Initial Text" ? "Changed Text" : "Initial Text");
            setAnimationState('fade-in');
        }, 300);
    };

    return (
        <div>
            <div className={`animated-content ${animationState}`}>
                <h1>{text}</h1>
            </div>
            <button onClick={changeText}>Change Text</button>
        </div>
    );
};

export default AnimatedText;