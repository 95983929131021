import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import UpgradeGameRenderFix from './UpgradeGameRenderFix';
import '../../assets/styles/upgrade/UpgradeGame.css'
import '../../assets/styles/upgrade/upgradeIviteModal.css'
import { ReactComponent as IconDolar } from "../../assets/icons/dolar.svg";
import { useUser } from '../../contexts/UserContext';
import { API_URLS } from '../../config/config';
import UpgradeGameModalGlobal from './UpgradeGameModalGlobal';
import { useTranslation } from 'react-i18next';



import { useAudio } from "../../contexts/AudioContext";
import { useSound } from '../../utils/soundUtils'; // Импортируем наш хук

import defaultButtonClick from '../../assets/sounds/menu-button-click.mp3';
import levelCompleted from '../../assets/sounds/upgrade/LevelCompleted.mp3';



const UpgradeGameFix = ({ gameData, rankData, userData, preloadedData, globalRefreshData, isUpdating }) => {
    const [getGameData, setGameData] = useState(gameData);
    const [getUserData, setUserData] = useState(userData);
    const [displayedBalance, setDisplayedBalance] = useState(userData?.gold_balance || 0);
    const [isOpenGlobalModal, setIsOpenGlobalModal] = useState(false);
    const [modalStatus, setModalStatus] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);


    //звук
    const { generalVolume, effectsVolume } = useAudio();
    const { initializeAudioContext, loadSound, playSound } = useSound({ generalVolume, effectsVolume });
    const audioInitializedRef = useRef(false);

    useEffect(() => {
        const handleInteraction = async () => {
            if (!audioInitializedRef.current) {
                await initializeAudioContext();
                await loadSound(defaultButtonClick, 'clickDefault');
                await loadSound(levelCompleted, 'levelCompleted');
                audioInitializedRef.current = true;
            }
        };

        document.addEventListener('touchstart', handleInteraction, { once: true });
        document.addEventListener('mousedown', handleInteraction, { once: true });

        return () => {
            document.removeEventListener('touchstart', handleInteraction);
            document.removeEventListener('mousedown', handleInteraction);
        };
    }, [initializeAudioContext, loadSound]);


    const { t } = useTranslation();
    // Этот useEffect сработает при любом изменении gameData или userData
    useEffect(() => {
        if (gameData && userData) {
            console.log("Reloading after level update...");
            setGameData(gameData); // Обновляем состояние gameData
            setUserData(userData); // Обновляем состояние userData
        }
    }, [gameData, userData]);  // следим за изменениями данных игры и пользователя


    useEffect(() => {
        console.log('===================================');
        console.log('gameData', gameData.data);
        console.log('rankData', rankData.rank);
        console.log('userData', userData);
        console.log('===================================');

        if (gameData && gameData.data.stages && gameData.data.stages.length > 0) {
            setGameData(gameData);

        }
        if (userData) {
            setUserData(userData);
            setDisplayedBalance(userData.gold_balance || 0);

        }
    }, [gameData, userData]);

    const handleRefreshComplete = useCallback(async () => {
        setIsRefreshing(true);
        await globalRefreshData();
        setIsRefreshing(false);
        setTimeout(() => {
            setIsOpenGlobalModal(false);
        }, 500);
    }, [globalRefreshData]);

    const handleglobalRefreshData = useCallback(async () => {
        setIsRefreshing(true);
        await globalRefreshData();
        setIsRefreshing(false);
    }, [globalRefreshData]);

    const claimLvl = useCallback(async () => {
        playSound('clickDefault');
        if (!userData || !userData.user_id) {
            console.error('User data is not available');
            return;
        }

        try {
            console.log("--------------userData.user_id: ", userData.user_id);
            const requestData = { userId: userData.user_id };
            // const endpoint = gameData.data.stages && gameData.data.stages.length > 1 ? API_URLS.NEXT_STAGE : API_URLS.NEXT_RANK;
            // const response = await axios.post(endpoint, requestData, {
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Accept': 'application/json',
            //         'ngrok-skip-browser-warning': 'true'
            //     }

            // });
            const response = await axios.post(API_URLS.NEXT_STAGE, requestData);
            handleResponse(response.data);
        } catch (error) {
            handleError(error);
        }
    }, [gameData, userData]);

    const handleResponse = useCallback((data) => {
        if (data.result === "moved to next rank") {
            console.log("Переход на следующий уровень");
            playSound('levelCompleted');
            setModalStatus("NextRank");
            setIsOpenGlobalModal(true);
        } else if (data.result === "moved to next stage") {
            console.log("Переход на следующий этап");
            setModalStatus("NextStage");
            playSound('levelCompleted');
            setIsOpenGlobalModal(true);
        } else {
            console.log("Неизвестная ошибка:", data.result);
        }
    }, []);

    const handleError = useCallback((error) => {
        console.error('Error claiming next level:', error);
        if (error.response && error.response.status === 403 && error.response.data) {
            switch (error.response.data.result) {
                case "You must be at last stage to move on the next rank":
                    setModalStatus("ErrorNextRank");
                    break;
                case "You don't have enough money to move on the next rank":
                    setModalStatus("NoMoneyBuyRank");
                    break;
                case "You don't have key to go next stage":
                    setModalStatus("ErrorNextStage");
                    break;
                case "You can't buy tasks while prisoning":
                    setModalStatus("PrisonError");
                    break;
                default:
                    setModalStatus("Error");
            }
            setIsOpenGlobalModal(true);
        }
    }, []);



    const renderContent = () => {
        return (
            <>
                <div className="upgradeGame-section">
                    <div className="upgradeGame-section-title">
                        <p>{t("UpgradePage.Page.Title")}</p>
                    </div>
                    <UpgradeGameRenderFix
                        gameData={getGameData}
                        rankData={rankData}
                        userData={getUserData}
                        globalRefreshData={globalRefreshData}
                        preloadedData={preloadedData}
                    />
                    <div className='upgrade-section-btn-and-money'>
                        <div className='section_money'>
                        </div>
                        <div className='section-btn'>
                            <div className='mny-con'>
                                <div className='mny-con-dolar'>
                                    <IconDolar className="mny-dolar" />
                                </div>
                                <p className='mny-price'>{displayedBalance}</p>
                            </div>
                            {/* <button onClick={claimLvl} onTouchStart={claimLvl}>{t("UpgradePage.Page.NextRank")}</button> */}
                            <button onPointerDown={claimLvl}>{t("UpgradePage.Page.NextRank")}</button>
                        </div>
                    </div>
                </div>
                {isOpenGlobalModal && (
                    <UpgradeGameModalGlobal
                        rankData={rankData}
                        userData={getUserData}
                        statusCustom={modalStatus}
                        onClose={handleRefreshComplete}
                        onRefreshGlobal={globalRefreshData}
                    />
                )}
            </>
        );
    };
    return (
        <div className={`upgradeGame-container ${isUpdating || isRefreshing ? 'updating-Con' : ''}`} style={{ height: "100%", width: "100%" }}>
            {renderContent()}
            {/* {(isUpdating || isRefreshing) && (
                <div className="overlay">
                    <div className="spinner-custom"></div>
                </div>
            )} */}
        </div>
    );
}

export default UpgradeGameFix;