import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAudio } from "../../contexts/AudioContext";
import "../../assets/styles/games/partnersModal.css";
import clickSoundMp3 from "../../assets/sounds/menu-button-click.mp3";
import CryptoPocket from "../../assets/images/Crypto/pocket.webp";
import CryptoBinance from "../../assets/images/Crypto/binance.webp";
import CryptoBit from "../../assets/images/Crypto/bybit.webp";
import CryptoOkx from "../../assets/images/Crypto/okx.webp";
const PartnersModal = ({
  iconArrowLeft,
  background,
  isOpen,
  onClose,
  exchangeLinks,
  isLinksLoading,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const modalRef = useRef(null);
  const { t } = useTranslation();
  const { effectsVolume, generalVolume } = useAudio();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setIsActive(true);
      setIsClosing(false);
    } else {
      setIsClosing(true);
      const timer = setTimeout(() => {
        setIsActive(false);
        setIsClosing(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    const initializeAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const response = await fetch(clickSoundMp3);
          const arrayBuffer = await response.arrayBuffer();
          clickAudioBufferRef.current =
            await audioContextRef.current.decodeAudioData(arrayBuffer);

          setIsAudioInitialized(true);
        } catch (error) {}
      }
    };

    initializeAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    if (
      isAudioInitialized &&
      audioContextRef.current &&
      clickAudioBufferRef.current
    ) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 300);
  };

  const handleLinkClick = (e, link) => {
    e.stopPropagation();
    playSound();
    window.open(link, "_blank", "noopener,noreferrer");
  };

  const handleCloseButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    playSound();
    closeModal();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      playSound();
      closeModal();
    }
  };

  const renderExchangeLinks = () => {
    if (isLinksLoading) {
      return <p>{t("GamePage.Game4.LoadingLinks")}</p>;
    }

    const hasLinks = Object.values(exchangeLinks).some((link) => link);

    if (!hasLinks) {
      return <p>{t("GamePage.Game4.NoLinksAvailable")}</p>;
    }

    return (
      <div className="flex w-full flex-col justify-between items-center h-full pb-2">
        {exchangeLinks.pocketoption && (
          <div
            className="img-container"
            onClick={(e) => handleLinkClick(e, exchangeLinks.pocketoption)}
          >
            <img
              src={CryptoPocket}
              alt="CryptoPocket"
              className="partner-icon-large"
            />
          </div>
        )}
        {exchangeLinks.binance && (
          <div
            className="img-container"
            onClick={(e) => handleLinkClick(e, exchangeLinks.binance)}
          >
            <img
              src={CryptoBinance}
              alt="CryptoBinance"
              className="partner-icon"
            />
          </div>
        )}
        {exchangeLinks.okx && (
          <div
            className="img-container"
            onClick={(e) => handleLinkClick(e, exchangeLinks.okx)}
          >
            <img src={CryptoOkx} alt="CryptoOkx" className="partner-icon" />
          </div>
        )}
        {exchangeLinks.bybit && (
          <div
            className="img-container"
            onClick={(e) => handleLinkClick(e, exchangeLinks.bybit)}
          >
            <img src={CryptoBit} alt="CryptoBit" className="partner-icon" />
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`partners-modal-overlay ${isOpen ? "active" : ""} ${
        isClosing ? "closing" : ""
      }`}
      onClick={handleOverlayClick}
    >
      <div
        className={`partners-modal-container ${isOpen ? "active" : ""} ${
          isClosing ? "closing" : ""
        }`}
        ref={modalRef}
        style={{ "--modal-background": `url(${background})` }}
      >
        <div className="mt-1 close-modal-container">
          <button
            className="level-modal-close-button"
            onClick={handleCloseButtonClick}
          >
            <img src={iconArrowLeft} alt="<" />
            <p className="level-modal-close-text">
              {t("AdOfficePage.ModalBackButtonText")}
            </p>
          </button>
        </div>

        <div className="game-playwith">
          <p className="game-playwith-text">
            {t("GamePage.Game4.PartnersModalFirst")}
          </p>
          {renderExchangeLinks()}
          <p className="game-playwith-text">
            {t("GamePage.Game4.PartnersModalSecond")}
          </p>
          <p className="game-playwith-text">
            {t("GamePage.Game4.PartnersModalThird")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PartnersModal;
