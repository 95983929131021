

import React, { useState, useEffect, useMemo } from 'react';
import { useUser } from '../../contexts/UserContext';
import gnom_bad from '../../assets/images/upgrade/gnom_bad.png'
import ArrowLeft from '../../assets/images/upgrade/Arrow 1.png'
import { useTranslation } from 'react-i18next';

const UpgradeGameRoadModal = ({ roadId, price, status, closeModal, lvlId, rankData, userData }) => {
    
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const { t } = useTranslation();
    const [backgroundImage, setBackgroundImage] = useState(null);

    useEffect(() => {
        try {
            const bgImage = getBackgroundImage(rankData.rank.id, userData.stage.id);
            setBackgroundImage(bgImage);
        } catch {

        }
    }, [])

    const getBackgroundImage = (rankId, stageId) => {
        return require(`../../assets/images/upgrade/GameBackground/${rankId}_${stageId}.webp`);
    };

    const backgroundStyle = {
        backgroundImage: `url(${getBackgroundImage(rankData.rank.id, userData.stage.id)})`,
    };

    useEffect(() => {
        if (backgroundImage) {
          const upgradeSection = document.querySelector('.modal-task-section-road');
          if (upgradeSection) {
            upgradeSection.style.setProperty('--background-image-road-modal', `url(${backgroundImage})`);
          }
        }
      }, [backgroundImage]);

    useEffect(() => {
        console.log("---lvlId ", lvlId);
        setTimeout(() => {
            setIsOpen(true);
        }, 500);

    }, []);

    const getIcon = (lvlId, roadId ) => {
        try {
            console.log(`Trying to load icon with id: ${roadId} and lvlId: ${lvlId}`);
            return require(`../../assets/images/upgrade/Road/${lvlId}/${roadId}.png`);
        } catch (error) {
            console.error(`Failed to load icon for id: ${roadId}, lvlId: ${lvlId}. Error:`, error);
            return null;
        }
    }
    
    
    const renderRewardIcon = (roadId, lvlId, status) => {
        let icon;
        console.log("lvlId", lvlId);
        if(status === "Win") {
            icon = getIcon(lvlId, roadId);
            return (
                <div className='modal-task-icon-con-nextRankStage'>
                    <div className="image-container">
                    {icon ? <img src={icon} alt="Bad Gnom" /> : <span>No icon available</span>}
                    </div>
                </div>
            );
            
        }
        else if(status === "Louse") {
            return (
                <div className='modal-task-icon-con-bad-gnom'>
                    <img src={gnom_bad} alt="Bad Gnom" />
                </div>
            );
        }
        else if(status === "NoMoney") {
            return (
                <div className='modal-task-icon-con-bad-gnom'>
                    <img src={gnom_bad} alt="Bad Gnom" />
                </div>
            );
        }
        else {

        }
    }
    const getTitle = (status) => {
        const titles = {
            'NoMoney': t('UpgradePage.RoadModal.NoMoney.Title'),
            'Louse': t('UpgradePage.RoadModal.Louse.Title'),
            'Win': t('UpgradePage.RoadModal.Win.Title'),
        };
        return <p className='modal-task-title'>{titles[status] || ''}</p>;
    }
    const getTitleTwo = (status) => {
        const titles = {
            'NoMoney': t('UpgradePage.RoadModal.NoMoney.TitleTwo'),
            'Louse': t('UpgradePage.RoadModal.Louse.TitleTwo'),
            'Win': t('UpgradePage.RoadModal.Win.TitleTwo', { idLvl: lvlId }),
        };
        return <p className='modal-task-title-two'>{titles[status] || ''}</p>;
    }
    const getDescription = (status, price) => {
        const descriptions = {
            'NoMoney': t('UpgradePage.UpgradeModal.NoMoney.Description', { IdDsc: price }),
            'Louse': t('UpgradePage.RoadModal.Louse.Description'),
            'Win': t('UpgradePage.RoadModal.Win.Description'),
        };
        return <p className='modal-task-description'>{descriptions[status] || ''}</p>;
    }
    const getBtnText = (status) => {
        const btnText = {
            'NoMoney': t('UpgradePage.UpgradeModal.NoMoney.ButtonText'),
            'Louse': t('UpgradePage.RoadModal.Louse.ButtonText'),
            'Win': t('UpgradePage.RoadModal.Win.ButtonText'),
        };
        return btnText[status]
    }

    const handleClose = () => {
        console.log("Closing modal and refreshing data");
        setIsOpen(false);
        setTimeout(() => {
            closeModal();
            
        }, 600);
    };

    const buyButton = () => {

    }

    

    return (
        <div className={`modal-task-section-bck ${isOpen ? 'open' : ''}`}>
            <div className={`modal-task-section modal-task-section-road ${isOpen ? 'open' : ''}`}>
                <div className='modal-task-container' >
                    <div className='modal-task-icon'>
                    {renderRewardIcon(roadId, lvlId, status)}
                    </div>
                    {getTitle(status)}
                    {getTitleTwo(status)}

                    {getDescription(status, price)}

                    <button className={`modal-task-buy-button`}
                        onClick={handleClose}>
                        {getBtnText(status)}
                    </button>
                    <div className='modal-task-back-btn-section'>
                        <img src={ArrowLeft} alt='<' />
                        <button onClick={handleClose}>{t("UpgradePage.RoadModal.btnBack")}</button>
                    </div>
                </div>
            </div>
        </div>
    );


}




export default UpgradeGameRoadModal;