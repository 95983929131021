import { useEffect, useRef } from 'react';
import { useAudio } from '../../contexts/AudioContext';

const GamesAudioManager = ({ selectedLevel }) => {
  const { changeBackgroundMusic, revertToDefaultMusic, isGameMusic } = useAudio();
  const prevLevelRef = useRef();

  useEffect(() => {
    const gameMusics = {
      1: require('../../assets/sounds/game1-back.mp3'),
      2: require('../../assets/sounds/game2-back.mp3'),
      3: require('../../assets/sounds/game3-back.mp3'),
      4: require('../../assets/sounds/game4-back.mp3'),
      5: require('../../assets/sounds/game5-back.mp3'),
    };

    const handleMusicChange = async () => {
      if (selectedLevel !== prevLevelRef.current) {
        if (selectedLevel && gameMusics[selectedLevel]) {
          await changeBackgroundMusic(gameMusics[selectedLevel], true);
        } else if (!isGameMusic) {
          await revertToDefaultMusic();
        }
        prevLevelRef.current = selectedLevel;
      }
    };

    handleMusicChange();

    return () => {
      if (isGameMusic) {
        revertToDefaultMusic();
      }
    };
  }, [selectedLevel, changeBackgroundMusic, revertToDefaultMusic, isGameMusic]);

  return null;
};

export default GamesAudioManager;