import React, { useEffect } from "react";
import "../assets/styles/copmonents/Modal/CustomKeyboard.css";

const Keyboard = ({ onKeyPress, onClose, isVisible }) => {
  useEffect(() => {
    const menuElement = document.querySelector('.menu-container');
    if (menuElement) {
      menuElement.style.opacity = isVisible ? '0' : '1';
      menuElement.style.pointerEvents = isVisible ? 'none' : 'auto';
      menuElement.style.transition = 'opacity 0.5s ease';
    }
  }, [isVisible]);

  return (
    <div className={`keyboard ${isVisible ? "show" : ""}`}>
      <div className="keyboard-up">
        {/* <span className="keyboard-quantity"></span> */}
        <button
          type="button"
          className="keyboard-close"
          onClick={onClose}
          onTouchStart={onClose}
        >
          ✖
        </button>
      </div>
      <div className="keyboard-row">
        {["1", "2", "3"].map((key) => (
          <button
            type="button"
            key={key}
            onPointerDown={() => onKeyPress(key)}
            className="keyboard-key"
          >
            {key}
          </button>
        ))}
      </div>
      <div className="keyboard-row">
        {["4", "5", "6"].map((key) => (
          <button
            type="button"
            key={key}
            onPointerDown={() => onKeyPress(key)}
            className="keyboard-key"
          >
            {key}
          </button>
        ))}
      </div>
      <div className="keyboard-row">
        {["7", "8", "9"].map((key) => (
          <button
            type="button"
            key={key}
            onPointerDown={() => onKeyPress(key)}
            className="keyboard-key"
          >
            {key}
          </button>
        ))}
      </div>
      <div className="keyboard-row">
        <button
          type="button"
          onPointerDown={() => onKeyPress("0")}
          className="keyboard-key"
        >
          0
        </button>
        <button
          type="button"
          onPointerDown={() => onKeyPress(",")}
          className="keyboard-key key-diya"
        >
          ,
        </button>
        <button
          type="button"
          onPointerDown={() => onKeyPress("delete")}
          className="keyboard-key key-diya"
        >
          ⌫
        </button>
      </div>
    </div>
  );
};

export default Keyboard;
