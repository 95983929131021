// soundUtils.js
import { useRef, useState, useCallback } from 'react';

export const useSound = (defaultVolume = { generalVolume: 100, effectsVolume: 100 }) => {
  const audioContextRef = useRef(null);
  const audioBuffers = useRef({}); // Хранилище для разных буферов звуков
  const [isInitialized, setIsInitialized] = useState(false);

  const initializeAudioContext = useCallback(() => {
    if (!audioContextRef.current) {
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      audioContextRef.current = new AudioContext();
      setIsInitialized(true);
    }
  }, []);

  const loadSound = useCallback(async (soundFile, soundKey) => {
    if (!audioContextRef.current) initializeAudioContext();
    if (!audioBuffers.current[soundKey]) {
      const response = await fetch(soundFile);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
      audioBuffers.current[soundKey] = audioBuffer;
    }
  }, [initializeAudioContext]);

  const playSound = useCallback((soundKey, volume = defaultVolume) => {
    if (isInitialized && audioContextRef.current && audioBuffers.current[soundKey]) {
      if (audioContextRef.current.state === 'suspended') {
        audioContextRef.current.resume();
      }

      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBuffers.current[soundKey];

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (volume.effectsVolume / 100) * (volume.generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isInitialized, defaultVolume]);

  return { initializeAudioContext, loadSound, playSound };
};
