// import { useEffect, useRef } from 'react';
// import { useAudio } from '../../contexts/AudioContext';

// const GamesAudioManager = ({ selectedLevel }) => {
//   const { changeBackgroundMusic, revertToDefaultMusic, isGameMusic } = useAudio();
//   const prevLevelRef = useRef();

//   useEffect(() => {
//     const gameMusics = {
//       1: require('../../assets/sounds/game1-back.mp3'),
//       2: require('../../assets/sounds/game2-back.mp3'),
//       3: require('../../assets/sounds/game3-back.mp3'),
//       4: require('../../assets/sounds/game4-back.mp3'),
//       5: require('../../assets/sounds/game5-back.mp3'),
//     };

//     const handleMusicChange = async () => {
//       if (selectedLevel !== prevLevelRef.current) {
//         if (selectedLevel && gameMusics[selectedLevel]) {
//           await changeBackgroundMusic(gameMusics[selectedLevel], true);
//         } else if (!isGameMusic) {
//           await revertToDefaultMusic();
//         }
//         prevLevelRef.current = selectedLevel;
//       }
//     };

//     handleMusicChange();

//     return () => {
//       if (isGameMusic) {
//         revertToDefaultMusic();
//       }
//     };
//   }, [selectedLevel, changeBackgroundMusic, revertToDefaultMusic, isGameMusic]);

//   return null;
// };

// export default GamesAudioManager;



import { useEffect, useRef } from 'react';
import { useAudio } from '../../contexts/AudioContext';

// Прелоад аудіо файлів
const gameMusics = {
  1: require('../../assets/sounds/game1-back.mp3'),
  2: require('../../assets/sounds/game2-back.mp3'),
  3: require('../../assets/sounds/game3-back.mp3'),
  4: require('../../assets/sounds/game4-back.mp3'),
  5: require('../../assets/sounds/game5-back.mp3'),
};

// Кешування аудіо буферів
const audioBuffers = {};

const GamesAudioManager = ({ selectedLevel }) => {
  const { 
    changeBackgroundMusic, 
    revertToDefaultMusic, 
    isGameMusic 
  } = useAudio();
  
  const prevLevelRef = useRef();
  const changeTimeoutRef = useRef();
  const isMountedRef = useRef(true);

  // Функція для прелоадингу аудіо
  const preloadAudio = async (level) => {
    if (!audioBuffers[level] && gameMusics[level]) {
      try {
        const response = await fetch(gameMusics[level]);
        const arrayBuffer = await response.arrayBuffer();
        audioBuffers[level] = arrayBuffer;
      } catch (error) {
        console.error('Error preloading audio:', error);
      }
    }
  };

  // Прелоад найближчих рівнів
  useEffect(() => {
    const preloadNearbyLevels = async () => {
      if (selectedLevel) {
        // Прелоад поточного рівня
        await preloadAudio(selectedLevel);
        
        // Прелоад сусідніх рівнів
        if (selectedLevel > 1) await preloadAudio(selectedLevel - 1);
        if (selectedLevel < 5) await preloadAudio(selectedLevel + 1);
      }
    };

    preloadNearbyLevels();
  }, [selectedLevel]);

  useEffect(() => {
    isMountedRef.current = true;

    const handleMusicChange = async () => {
      // Очищаємо попередній таймаут якщо він є
      if (changeTimeoutRef.current) {
        clearTimeout(changeTimeoutRef.current);
      }

      // Перевіряємо чи потрібно змінювати музику
      if (selectedLevel !== prevLevelRef.current) {
        // Якщо є музика для вибраного рівня
        if (selectedLevel && gameMusics[selectedLevel]) {
          try {
            // Спочатку зупиняємо поточну музику
            if (isGameMusic) {
              await revertToDefaultMusic();
            }

            // Даємо невелику паузу перед зміною музики
            changeTimeoutRef.current = setTimeout(async () => {
              if (isMountedRef.current) {
                // Використовуємо прелоадений буфер якщо він є
                const musicSource = audioBuffers[selectedLevel] || gameMusics[selectedLevel];
                await changeBackgroundMusic(musicSource, true);
                prevLevelRef.current = selectedLevel;
              }
            }, 100);
          } catch (error) {
            console.error('Error changing music:', error);
            revertToDefaultMusic();
          }
        } else if (!isGameMusic) {
          await revertToDefaultMusic();
        }
        prevLevelRef.current = selectedLevel;
      }
    };

    handleMusicChange();

    return () => {
      isMountedRef.current = false;
      if (changeTimeoutRef.current) {
        clearTimeout(changeTimeoutRef.current);
      }
      if (isGameMusic) {
        revertToDefaultMusic();
      }
    };
  }, [selectedLevel, changeBackgroundMusic, revertToDefaultMusic, isGameMusic]);

  return null;
};

export default GamesAudioManager;