// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jail-msg-section {
    position: absolute;
    margin-top: 70px;
    margin-bottom: 86px;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 156px);
    z-index: 5;

    
    transition: opacity 0.1s ease-in-out;

    /* background-color: rgba(199, 0, 0, 0.774); */
}

.jail-msg-section .jail-msg-container {
    padding-top: 75px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.jail-msg-section .jail-msg-container .jail-msg-logo-con {
    height: 210px;
    width: 210px;
    /* margin-bottom: 10px; */
}

.jail-msg-section .jail-msg-container .jail-msg-logo-con .jail-msg-logo{
height: 100%;
width: 100%;

}

.jail-msg-section .jail-msg-container .jail-msg-time{
    font-family: Oswald;
    font-size: 5vh;
    font-weight: 500;
    text-shadow: 2px 1px 1px #000000;
    color: #FFFFFF;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.jail-msg-section.visible {
    opacity: 1 !important;
}

.jail-msg-time.visible {
    opacity: 1 !important;
  }`, "",{"version":3,"sources":["webpack://./src/assets/styles/upgrade/jail-msg.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,MAAM;IACN,OAAO;IACP,WAAW;IACX,0BAA0B;IAC1B,UAAU;;;IAGV,oCAAoC;;IAEpC,8CAA8C;AAClD;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;AACA,YAAY;AACZ,WAAW;;AAEX;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,gBAAgB;IAChB,gCAAgC;IAChC,cAAc;IACd,UAAU;IACV,oCAAoC;AACxC;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;EACvB","sourcesContent":[".jail-msg-section {\r\n    position: absolute;\r\n    margin-top: 70px;\r\n    margin-bottom: 86px;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: calc(100% - 156px);\r\n    z-index: 5;\r\n\r\n    \r\n    transition: opacity 0.1s ease-in-out;\r\n\r\n    /* background-color: rgba(199, 0, 0, 0.774); */\r\n}\r\n\r\n.jail-msg-section .jail-msg-container {\r\n    padding-top: 75px;\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.jail-msg-section .jail-msg-container .jail-msg-logo-con {\r\n    height: 210px;\r\n    width: 210px;\r\n    /* margin-bottom: 10px; */\r\n}\r\n\r\n.jail-msg-section .jail-msg-container .jail-msg-logo-con .jail-msg-logo{\r\nheight: 100%;\r\nwidth: 100%;\r\n\r\n}\r\n\r\n.jail-msg-section .jail-msg-container .jail-msg-time{\r\n    font-family: Oswald;\r\n    font-size: 5vh;\r\n    font-weight: 500;\r\n    text-shadow: 2px 1px 1px #000000;\r\n    color: #FFFFFF;\r\n    opacity: 0;\r\n    transition: opacity 0.5s ease-in-out;\r\n}\r\n\r\n.jail-msg-section.visible {\r\n    opacity: 1 !important;\r\n}\r\n\r\n.jail-msg-time.visible {\r\n    opacity: 1 !important;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
