import { useEffect, memo } from 'react';
import { useUser } from '../contexts/UserContext';
import { useReward } from '../contexts/RewardContext';
import axios from 'axios';
import { API_URLS } from '../config/config';

const BackendInitializer = memo(({ userId, onError, onComplete }) => {
  const { updateUser } = useUser();
  const { setLastVisitTime } = useReward();

  useEffect(() => {
    let isMounted = true;

    const fetchUserData = async () => {
      try {
        const response = await axios.get(API_URLS.GET_USER_INFO, {
          params: { userId },
          headers: {
            'Accept': 'application/json',
            'ngrok-skip-browser-warning': 'true'
          }
        });
        if (isMounted) {
          if (typeof response.data === 'object' && response.data.info) {
            const userInfo = { ...response.data.info, user_id: userId };
            updateUser(userInfo);

            if (userInfo.last_visited) {
              setLastVisitTime(new Date(userInfo.last_visited));
            }

            await GetRegion(userInfo);

            onComplete();
          } else {
            throw new Error("Unexpected response structure from backend");
          }
        }
      } catch (error) {
        if (isMounted) {
          onError();
        }
      }
    };

    const GetRegion = async (userInfo) => {
      try {
        const locationResponse = await axios.get('https://ipapi.co/json/');
        const actualRegion = locationResponse.data.country_code.toLowerCase();

        if (!userInfo.region && actualRegion) {
          const saveRegionResponse = await axios.post(API_URLS.SAVE_USER_GEO, {
            userId: userInfo.user_id,
            countryCode: actualRegion
          }, {
            headers: {
              'Accept': 'application/json',
              'ngrok-skip-browser-warning': 'true'
            }
          });

          if (saveRegionResponse.data.result === 'ok') {
            updateUser({ ...userInfo, region: actualRegion });
          }
        }
      } catch (error) {
      }
    };

    fetchUserData();

    return () => {
      isMounted = false;
    };
  }, [userId, onError, onComplete, updateUser, setLastVisitTime]);

  return null;
});

export default BackendInitializer;