// UpgradeContext.js
import React, { createContext, useContext, useReducer, useCallback } from 'react';
import axios from 'axios';
import { API_URLS } from '../config/config';

// Начальное состояние для контекста
const initialState = {
  firstStart: true,
  stageInfo: null,
  rankInfo: null,
  userInfo: null,
  loading: false,
  error: null,
  isBlocked: null, // Состояние для блокировки пользователя
  scrollPosition: null, // Сохраненная позиция прокрутки
};

// Определяем действия
const SET_STAGE_INFO = 'SET_STAGE_INFO';
const SET_FIRST_START = 'SET_FIRST_START';
const SET_RANK_INFO = 'SET_RANK_INFO';
const SET_USER_INFO = 'SET_USER_INFO'; // Действие для сохранения информации о пользователе
const SET_LOADING = 'SET_LOADING';
const SET_ERROR = 'SET_ERROR';
const SET_BLOCKED_STATUS = 'SET_BLOCKED_STATUS'; // Действие для обновления блокировки
const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION'; // Действие для сохранения позиции прокрутки

// Редюсер для управления состоянием
const upgradeReducer = (state, action) => {
  switch (action.type) {
    case SET_FIRST_START:
      return { ...state, firstStart: action.payload };
    case SET_STAGE_INFO:
      return { ...state, stageInfo: action.payload };
    case SET_RANK_INFO:
      return { ...state, rankInfo: action.payload };
    case SET_USER_INFO:
      return { ...state, userInfo: action.payload };
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case SET_BLOCKED_STATUS:
      return { ...state, isBlocked: action.payload }; // Обновляем состояние блокировки
    case SET_SCROLL_POSITION:
      return { ...state, scrollPosition: action.payload }; // Сохраняем позицию прокрутки
    default:
      return state;
  }
};

// Создаем контекст
const UpgradeContext = createContext();

// Провайдер, который будет оборачивать ваши компоненты
export const UpgradeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(upgradeReducer, initialState);

  // Устанавливаем флаг firstStart в false после первого входа
  const setFirstStart = useCallback(() => {
    dispatch({ type: SET_FIRST_START, payload: false });
  }, []);

  // Устанавливаем состояние загрузки
  const setLoading = useCallback((isLoading) => {
    dispatch({ type: SET_LOADING, payload: isLoading });
  }, []);

  // Устанавливаем ошибку
  const setError = useCallback((error) => {
    dispatch({ type: SET_ERROR, payload: error });
  }, []);

  // Сохраняем позицию прокрутки
  const setScrollPosition = useCallback((position) => {
    dispatch({ type: SET_SCROLL_POSITION, payload: position });
  }, []);

  // Функция для получения текущего времени
  const getCurrentTimeFormatted = () => {
    const now = new Date();
    return now.toISOString();
  };

  // Проверяем, заблокирован ли пользователь
  const getActualJailStatus = (blockedUntil) => {
    const currentTime = getCurrentTimeFormatted();
    return currentTime < blockedUntil;
  };

  // Функция для получения данных пользователя
  const fetchUserInfo = useCallback(async (userId) => {
    setLoading(true);
    try {
      const response = await axios.get(API_URLS.GET_USER_INFO, {
        params: { userId },
        headers: { 
          'Accept': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        }
      });
  
      if (response && typeof response.data === 'object' && response.data.info) {
        const data = response.data.info;
  
        // Извлекаем нужные поля из data.info
        const { gold_balance, g_token, is_blocked, stage, user_road_setup: { road_setup }, user_id, blocked_until } = data;
  
        // Создаем новый объект с нужными полями
        const filteredUserInfo = {
          gold_balance,
          g_token,
          is_blocked,
          blocked_until,
          stage,
          road_setup,
          user_id
        };
  
        console.log("Filtered User Info:", filteredUserInfo);  // Логируем извлеченные данные
        
        // Диспатчим только нужные данные
        dispatch({ type: SET_USER_INFO, payload: filteredUserInfo });
  
        // Проверяем статус блокировки
        const isBlocked = getActualJailStatus(data.blocked_until);
        dispatch({ type: SET_BLOCKED_STATUS, payload: isBlocked });
      } else {
        console.error("Unexpected response structure:", response.data);  // Логируем неожиданную структуру ответа
        throw new Error("Unexpected response structure from backend");
      }
      
    } catch (error) {
      console.error("--Error fetching user info:", error);  // Логируем ошибку запроса
      setError('Ошибка получения данных пользователя');
    } finally {
      setLoading(false);
      console.log("===- UpgradeContext fetchUserInfo: good -===");  // Логируем завершение запроса
    }
  }, [setLoading, setError]);

  // Функция для получения информации об уровне
  const fetchStageInfo = useCallback(async (userId) => {
    setLoading(true);
    
    try {
      const response = await axios({
        method: 'POST',
        url: `${API_URLS.GET_USER_CURRENT_STAGE_INFO}`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        },
        data: { userId: userId }  // Логируем данные, которые отправляем
      });
      // В Axios данные находятся в response.data
      const data = response.data;  // Логируем момент получения данных
      console.log("Данные о стадии:", data);
  
      if (data.stages) {
        console.log("Обнаружены stages:", data.stages);  // Логируем если нашли поле stages
        dispatch({ type: SET_STAGE_INFO, payload: { type: 1, data } });
      } else if (data.user_road_setup) {
        console.log("Обнаружен user_road_setup:", data.user_road_setup);  // Логируем если нашли поле user_road_setup
        dispatch({ type: SET_STAGE_INFO, payload: { type: 2, data } });
      } else {
        console.error("Неожиданная структура данных:", data);  // Логируем если структура данных не совпадает с ожидаемой
        throw new Error('Unexpected data format');
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const data = error.response.data;  // Логируем если нашли поле user_road_setup
        
        dispatch({ type: SET_STAGE_INFO, payload: { type: 2, data } });
      }
      else {
        console.error("Ошибка при получении данных об уровне:", error);  // Логируем ошибку если запрос не удался
        setError('Ошибка получения данных уровня');
      }
      
    } finally {
      setLoading(false);
      console.log("Завершение работы fetchStageInfo");  // Логируем завершение функции
    }
  }, [setLoading, setError]);

  // Функция для получения информации о ранге
  const fetchRankInfo = useCallback(async (userId) => {
    setLoading(true);
  
    try {
      const response = await axios({
        method: 'POST',
        url: `${API_URLS.GET_USER_CURRENT_RANK_INFO}`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'ngrok-skip-browser-warning': 'true'
        },
        data: { userId: userId }  // Логируем данные, которые отправляем
      });
  
      
      const data = response.data;  // В Axios данные находятся в response.data
      console.log("Полученные данные о ранге:", data);  // Логируем данные
  
      dispatch({ type: SET_RANK_INFO, payload: data });
    } catch (error) {
      console.error("Ошибка при получении данных о ранге:", error);  // Логируем ошибку если запрос не удался
      setError('Ошибка получения данных ранга');
    } finally {
      setLoading(false);
      console.log("Завершение работы fetchRankInfo");  // Логируем завершение функции
    }
  }, [setLoading, setError]);

  // Функция для обновления всех данных
  const updateAllData = useCallback(async (userId) => {
    console.log("updateAllData called with userId:", userId);  // Логируем userId
    await Promise.all([fetchStageInfo(userId), fetchRankInfo(userId), fetchUserInfo(userId)]);  // Передаем userId в fetchUserInfo
  }, [fetchStageInfo, fetchRankInfo, fetchUserInfo]);

  // Экспортируем контекст и утилиты
  return (
    <UpgradeContext.Provider
      value={{
        firstStart: state.firstStart,
        setFirstStart,
        stageInfo: state.stageInfo,
        rankInfo: state.rankInfo,
        userInfo: state.userInfo,
        loading: state.loading,
        error: state.error,
        isBlocked: state.isBlocked,
        scrollPosition: state.scrollPosition,
        setScrollPosition,
        updateAllData, // Экспортируем функцию обновления данных
      }}
    >
      {children}
    </UpgradeContext.Provider>
  );
};

// Хук для использования контекста
export const useUpgrade = () => {
  const context = useContext(UpgradeContext);
  if (!context) {
    throw new Error('useUpgrade должен использоваться внутри UpgradeProvider');
  }
  return context;
};

// Экспорт дополнительных утилит
export const getBackgroundImage = (lvlId, lvlStageId) => {
  try {
    return require(`../assets/images/upgrade/GameBackground/${lvlId}_${lvlStageId}.webp`);
  } catch (error) {
    console.error(`Ошибка загрузки фона для stage ${lvlId} и rank ${lvlStageId}:`, error);
    return '';
  }
};

export const getActualPickaxe = (lvlId, stageInstrumentId, customIdPickaxe) => {
  try {
    if (customIdPickaxe > '1000') {
      return require(`../assets/images/upgrade/pickaxe/pickaxe_${lvlId}_${customIdPickaxe}.webp`);
    } else {
      return require(`../assets/images/upgrade/pickaxe/pickaxe_${lvlId}_${stageInstrumentId}.webp`);
    }
  } catch (error) {

    console.log("КІРКИ ІКОНКИ ПОМИЛКУ ВИДАЄ", error);
    return '';
  }
  
};

export const getActualDrink = (lvlId, stageDrinkId, customIdDrink) => {
  try {
    if (customIdDrink > '1000') {
      return require(`../assets/images/upgrade/energy/energy_${lvlId}_${customIdDrink}.webp`);
    } else {
      return require(`../assets/images/upgrade/energy/energy_${lvlId}_${stageDrinkId}.webp`);
    }
  } catch (error) {
    console.log("ЕНЕРГІЯ ІКОНКИ ПОМИЛКУ ВИДАЄ", error);
    return '';
    
  }
};
