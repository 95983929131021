import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { FAIRY_GIFT_SHOW_INTERVAL } from "../config/config";

const RewardContext = createContext();

export const useReward = () => useContext(RewardContext);

export const RewardProvider = ({ children }) => {
  const [nextRewardTime, setNextRewardTime] = useState(null);
  const [adType, setAdType] = useState("fairy");
  const [rewardIntervalMinutes, setRewardIntervalMinutes] = useState(
    FAIRY_GIFT_SHOW_INTERVAL / 60
  ); // Convert to minutes
  const [absenceThresholdMinutes, setAbsenceThresholdMinutes] = useState(5);
  const [lastVisitTime, setLastVisitTime] = useState(null);

  const calculateNextRewardTime = useCallback(() => {
    if (!lastVisitTime) {
      return;
    }

    const now = Date.now();
    const timeSinceLastVisit = now - lastVisitTime.getTime();

    let initialDelay;
    if (timeSinceLastVisit > absenceThresholdMinutes * 60 * 1000) {
      initialDelay = (rewardIntervalMinutes * 60 * 1000) / 2;
    } else {
      initialDelay = rewardIntervalMinutes * 60 * 1000;
    }

    const newNextRewardTime = now + initialDelay;
    setNextRewardTime(newNextRewardTime);
  }, [lastVisitTime, rewardIntervalMinutes, absenceThresholdMinutes]);

  useEffect(() => {
    if (lastVisitTime) {
      calculateNextRewardTime();
    }
  }, [lastVisitTime, calculateNextRewardTime]);

  useEffect(() => {
    const fetchIntervals = async () => {
      try {
        const rewardResponse = await new Promise((resolve) =>
          setTimeout(
            () => resolve({ data: FAIRY_GIFT_SHOW_INTERVAL / 60 }),
            1000
          )
        );
        const absenceResponse = await new Promise((resolve) =>
          setTimeout(() => resolve({ data: 5 }), 1000)
        );

        setRewardIntervalMinutes(rewardResponse.data);
        setAbsenceThresholdMinutes(absenceResponse.data);
      } catch (error) {}
    };

    fetchIntervals();
  }, []);

  const toggleRewardType = () => {
    setAdType((prevType) => (prevType === "fairy" ? "gift" : "fairy"));
  };

  const value = {
    nextRewardTime,
    setNextRewardTime,
    adType,
    toggleRewardType,
    rewardIntervalMinutes,
    absenceThresholdMinutes,
    lastVisitTime,
    setLastVisitTime,
  };

  return (
    <RewardContext.Provider value={value}>{children}</RewardContext.Provider>
  );
};
