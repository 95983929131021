import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";

// Приклад ваших контекстів та i18n (адаптуйте під себе)
import { useTranslation } from "react-i18next";
import { useUser } from "../contexts/UserContext";
import { useAudio } from "../contexts/AudioContext";
import { API_URLS } from "../config/config";

// Ігри, модалка, аудіоменеджер (якщо у вас є)
import CoreGameOne from "./games/core_game1";
import CoreGameTwo from "./games/core_game2";
import CoreGameThree from "./games/core_game3";
import CoreGameFour from "./games/core_game4";
import CoreGameFive from "./games/core_game5";
import GameHelpModal from "../components/games/GameHelpModal";
import GamesAudioManager from "../components/games/GamesAudioMeneger";

// Звук кліку
import clickSoundMp3 from "../assets/sounds/menu-button-click.mp3";

// CSS (де є стилі .custom-slider-window, .card-item тощо)
import "../assets/styles/games.css";

const Games = ({ preloadedData }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { effectsVolume, generalVolume } = useAudio();

  // ================== Дані про ігри ==================
  const [gamesData, setGamesData] = useState([]);

  // ================== Вибір поточної гри/рівня ==================
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [currentGame, setCurrentGame] = useState(null);

  // ================== Модалка з правилами ==================
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  // ================== Аудіо для кліку ==================
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const audioContextRef = useRef(null);
  const clickAudioBufferRef = useRef(null);

  // Ініціалізація аудіо (кліковий звук) одноразово
  useEffect(() => {
    const initAudio = async () => {
      if (!isAudioInitialized) {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          audioContextRef.current = new AudioContext();

          const response = await fetch(clickSoundMp3);
          const arrayBuffer = await response.arrayBuffer();
          clickAudioBufferRef.current = await audioContextRef.current.decodeAudioData(arrayBuffer);

          setIsAudioInitialized(true);
        } catch (error) {
          console.error("Audio init error:", error);
        }
      }
    };
    initAudio();
  }, [isAudioInitialized]);

  const playSound = useCallback(() => {
    if (isAudioInitialized && audioContextRef.current && clickAudioBufferRef.current) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = clickAudioBufferRef.current;

      const gainNode = audioContextRef.current.createGain();
      gainNode.gain.value = (effectsVolume / 100) * (generalVolume / 100);

      source.connect(gainNode);
      gainNode.connect(audioContextRef.current.destination);

      source.start(0);
    }
  }, [isAudioInitialized, effectsVolume, generalVolume]);

  // ================== Завантаження ігор з бекенду ==================
  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.post(API_URLS.GET_EXECUTE_GAMELIST, {
          tg_user_id: user.user_id,
        });
        if (response.data?.levels) {
          const sorted = response.data.levels.sort((a, b) => a.level - b.level);
          setGamesData(sorted);
        }
      } catch (err) {
        console.error("Error fetching game list:", err);
      }
    };
    fetchGames();
  }, [user.user_id]);

  // ================== Обробка кліку по картці ==================
  const handleGameClick = (level, game) => {
    playSound();
    setSelectedLevel(level);
    setCurrentGame(game);
  };

  // ================== Рендер потрібного компонента гри ==================
  const renderGameElement = () => {
    switch (selectedLevel) {
      case 1: return <CoreGameOne />;
      case 2: return <CoreGameTwo />;
      case 3: return <CoreGameThree />;
      case 4: return <CoreGameFour />;
      case 5: return <CoreGameFive />;
      default: return null;
    }
  };

  // ================== Модалка (правила гри) ==================
  const openHelpModal = () => {
    playSound();
    setIsHelpModalOpen(true);
  };
  const closeHelpModal = () => {
    setIsHelpModalOpen(false);
  };
  const getGameRules = (level) => t(`GamePage.Game${level}.GameRules`, "");
  const getLevelImage = (level) => t(`LevelsPage.Level${level}Image`, "");

  // ================== Кастомний слайдер (3 картки, 10px відступ) ==================

  // 1) Зберігаємо ref на контейнер, щоб виміряти ширину
  const containerRef = useRef(null);

  // 2) Ширина картки в px (без урахування margin), обчислюється
  const [cardWidth, setCardWidth] = useState(0);

  // 3) Задаємо кількість карток на екрані та відступ
  const visibleCards = 3;
  const spacing = 10; // “жорсткий” відступ (px)

  // 4) Стан свайпу
  const [startX, setStartX] = useState(null);
  const [deltaX, setDeltaX] = useState(0);
  const swipeThreshold = 50;

  // 5) Обчислюємо максимальний індекс “прокрутки”
  const maxSlide = Math.max(0, gamesData.length - visibleCards);

  // 6) При mount/resize вимірюємо контейнер та рахуємо (containerWidth - 40) / 3
  //    бо хочемо (leftMargin=10 + 2 внутрішні gap=20 + rightMargin=10) = 40px усього
  useEffect(() => {
    function handleResize() {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        // Тут – головне місце
        // console.log("containerWidth =>", containerWidth);

        // Наприклад, ви віднімаєте 40px (якщо логіка така: 10px ліворуч, 2 проміжки, 10px праворуч):
        const totalSpacing = 40;
        const computedCardWidth = (containerWidth - totalSpacing) / visibleCards;

        // ЛОГ – перевірити, скільки виходить cardWidth
        // console.log("computedCardWidth =>", computedCardWidth);

        setCardWidth(computedCardWidth);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // викликати при першому рендері
    return () => window.removeEventListener("resize", handleResize);
  }, [visibleCards]);

  // 7) Свайпи: touchstart/move/end
  const handleTouchStart = (e) => {
    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    setStartX(clientX);
    setDeltaX(0);
  };
  const handleTouchMove = (e) => {
    if (startX === null) return;
    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    setDeltaX(clientX - startX);
  };
  const handleTouchEnd = () => {
    if (startX === null) return;
    // deltaX > 0 => свайп вправо => зменшуємо currentSlide
    if (deltaX > swipeThreshold) {
      setCurrentSlide((prev) => Math.max(prev - 1, 0));
    }
    // deltaX < 0 => свайп вліво => збільшуємо
    else if (deltaX < -swipeThreshold) {
      setCurrentSlide((prev) => Math.min(prev + 1, maxSlide));
    }
    setStartX(null);
    setDeltaX(0);
  };

  // 8) Стан “поточний слайд”
  const [currentSlide, setCurrentSlide] = useState(0);

  // 9) Крок зсуву = ширина картки + 10px (адже в кінці кожної картки ми робимо margin-right=10, окрім останньої)
  //   Також перша картка матиме margin-left=10
  const stepPx = cardWidth + spacing;

  // Фактичний зсув у px:
  const translateX = -(currentSlide * (cardWidth + spacing));
//   console.log("translateX =>", translateX); // щоб бачити, куди зсув

  return (
    <div
      id="games"
      style={{ backgroundImage: `url(${preloadedData.background})` }}
    >
      {/* Якщо обрано якийсь рівень - вмикаємо аудіо для цього рівня */}
      {selectedLevel !== null && <GamesAudioManager selectedLevel={selectedLevel} />}

      <div className="gameMainCont">
        <p className="title-game">{t("GamePage.MainPage")}</p>

        {selectedLevel && (
          <button className="custom-button-game" onClick={openHelpModal}>
            <p className="btn-text">{t("GamePage.RulesButton")}</p>
          </button>
        )}
      </div>

      {/* ============ Кастомний слайдер ============ */}
      <div
        ref={containerRef}
        className="custom-slider-window"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        // За бажанням, щоб протестувати drag на десктопі: 
        // onMouseDown={handleTouchStart} onMouseMove={handleTouchMove} ...
      >
        <div
          className="custom-slider-track"
          style={{
            transition: startX === null ? "transform 0.3s ease" : "none",
            transform: `translateX(${translateX}px)`,
          }}
        >
          {gamesData.length > 0 ? (
            gamesData.map((game, index) => {
              // Для розрахунку стилів margin:
              // - Перший елемент => margin-left=10px
              // - Усі (окрім останнього) => margin-right=10px
              // - Останній => margin-right=10px також (для симетрії), 
              //   але якщо хочете, можна 0px. Тоді totalSpacing=30. 
              const isFirst = (index === 0);
              const isLast = (index === gamesData.length - 1);
            //   console.log(`Card ${index} width =>`, cardWidth, "px");

              const cardMarginLeft = isFirst ? 10 : 0;
              // Якщо хочемо завжди 10px справа — робимо так:
              const cardMarginRight = 10;

              // Якщо карток менше 3, і це остання — теж можемо дати 10 
              // (щоб все виглядало симетрично)

              return (
                <div
                  key={index}
                  className="card-item"
                  style={{
                    minWidth: `${cardWidth}px`,
                    marginLeft: `${cardMarginLeft}px`,
                    marginRight: `${cardMarginRight}px`,
                  }}
                  onClick={() => handleGameClick(game.level, game)}
                >
                  <div className="text-btn-selector">
                    <p className="text-btn-maintext">{game.name}</p>
                    <p className="text-btn-editor">
                      {t("GamePage.Level")} {game.level}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <div>No games available</div>
          )}
        </div>
      </div>
      {/* ============ Кінець слайдера ============ */}

      <div className="gameCore">
        {renderGameElement()}
      </div>

      {/* Модалка з правилами */}
      <GameHelpModal
        isOpen={isHelpModalOpen}
        onClose={closeHelpModal}
        levelImageUrl={currentGame ? getLevelImage(currentGame.level) : ""}
        gameName={currentGame?.name}
        gameLevel={currentGame?.level}
        gameRules={currentGame ? getGameRules(currentGame.level) : ""}
      />
    </div>
  );
};

export default Games;
