import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import listva_png from '../assets/images/pickGender/listva.png';
import bck from '../assets/images/welcome-background.webp';
import '../assets/styles/welcomePage.css';

const WelcomePage = ({ preloadedData, onContinue }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleContinue = () => {
    // navigate('/pick-gender');
    console.log("Continue button clicked");
    onContinue();
  };

  return (
    <div className="welcome-page-section" style={{ backgroundImage: `url(${bck})` }}>
      <div className="content">
        <h1 className="welcome-page-title">{t('welcomePage.title')}</h1>
        <div className="dsc-container">
          <p>{t('welcomePage.msg1')}</p>
          <p>{t('welcomePage.msg2')}</p>
          
          
       
        </div>
      </div>
      <button className="continue-button" onClick={handleContinue}>
        {t('BtnText.WelcomPageBtn')}
      </button>
      <img src={listva_png} alt="листва" className="listva_btm" />
    </div>
  );
};

export default WelcomePage;
