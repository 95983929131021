// import React, { useState, useEffect  } from "react";
// import "../../assets/styles/copmonents/Modal/CustomKeyboard.css";

// const CustomKeyboard = () => {
//   const [inputValue, setInputValue] = useState("");
//   const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

//   const handleKeyPress = (key) => {
//     if (key === "delete") {
//       setInputValue(inputValue.slice(0, -1));
//     } else {
//       setInputValue(inputValue + key);
//     }
//   };

//   React.useEffect(() => {
//     const menuElement = document.querySelector('.menu-container');
//     if (menuElement) {
//       menuElement.style.opacity = isKeyboardVisible ? '0' : '1';
//       menuElement.style.pointerEvents = isKeyboardVisible ? 'none' : 'auto';
//       menuElement.style.transition = 'opacity 0.5s ease';
//     }
//   }, [isKeyboardVisible]);

//   return (
//     <div className="custom-keyboard-container">
//       <input
//         type="text"
//         value={inputValue}
//         readOnly
//         onFocus={() => setIsKeyboardVisible(true)}
//         className="custom-input"
//       />
//       <div className={`keyboard ${isKeyboardVisible ? "show" : ""}`}>
//         <button 
//           className="keyboard-close"
//           onClick={() => setIsKeyboardVisible(false)}
//         >
//           ✖
//         </button>
//         <div className="keyboard-row">
//           {["1", "2", "3"].map((key) => (
//             <button
//               key={key}
//               onClick={() => handleKeyPress(key)}
//               className="keyboard-key"
//             >
//               {key}
//             </button>
//           ))}
//         </div>
//         <div className="keyboard-row">
//           {["4", "5", "6"].map((key) => (
//             <button
//               key={key}
//               onClick={() => handleKeyPress(key)}
//               className="keyboard-key"
//             >
//               {key}
//             </button>
//           ))}
//         </div>
//         <div className="keyboard-row">
//           {["7", "8", "9"].map((key) => (
//             <button
//               key={key}
//               onClick={() => handleKeyPress(key)}
//               className="keyboard-key"
//             >
//               {key}
//             </button>
//           ))}
//         </div>
//         <div className="keyboard-row">
//           <button
//             onClick={() => handleKeyPress("0")}
//             className="keyboard-key"
//           >
//             0
//           </button>
//           <button
//             onClick={() => handleKeyPress(",")}
//             className="keyboard-key"
//           >
//             ,
//           </button>
//           <button
//             onClick={() => handleKeyPress("delete")}
//             className="keyboard-key"
//           >
//             ⌫
//           </button>
//         </div>
//       </div>
//     </div>
//   );
  
// };

// export default CustomKeyboard;



// import React, { useState } from "react";
// import Keyboard from "../../components/Keyboard";
// import "../../assets/styles/copmonents/Modal/CustomKeyboard.css";

// const CustomInput = () => {
//   const [inputValue, setInputValue] = useState("");
//   const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

//   const handleKeyPress = (key) => {
//     if (key === "delete") {
//       setInputValue(inputValue.slice(0, -1));
//     } else {
//       setInputValue(inputValue + key);
//     }
//   };

//   return (
//     <div className="custom-keyboard-container">
//       <input
//         type="text"
//         value={inputValue}
//         readOnly
//         onFocus={() => setIsKeyboardVisible(true)}
//         className="custom-input"
//       />
//       <Keyboard
//         onKeyPress={handleKeyPress}
//         onClose={() => setIsKeyboardVisible(false)}
//         isVisible={isKeyboardVisible}
//       />
//     </div>
//   );
// };

// export default CustomInput;
