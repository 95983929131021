// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.game-buttom-list-five{
    gap: 3vw;
    width: 100%;
    /* padding-left: 3vw;
    padding-right: 3vw; */
    display: flex;
    /* position: relative; */
    /* height: 95%; */
    flex-direction: row;
    justify-content: space-between;
    bottom: 10px;
    /* bottom: 25vw;
    left: 0;
    right: 0; */
}

`, "",{"version":3,"sources":["webpack://./src/assets/styles/games/game_core5.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,WAAW;IACX;yBACqB;IACrB,aAAa;IACb,wBAAwB;IACxB,iBAAiB;IACjB,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ;;eAEW;AACf","sourcesContent":[".game-buttom-list-five{\r\n    gap: 3vw;\r\n    width: 100%;\r\n    /* padding-left: 3vw;\r\n    padding-right: 3vw; */\r\n    display: flex;\r\n    /* position: relative; */\r\n    /* height: 95%; */\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    bottom: 10px;\r\n    /* bottom: 25vw;\r\n    left: 0;\r\n    right: 0; */\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
