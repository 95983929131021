import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../contexts/UserContext';
import bck from '../../assets/images/upgrade/inviteBck.png'
import ArrowLeft from '../../assets/images/upgrade/Arrow 1.png'




const UpgradeInviteModal = ({ closeIviteModal }) => {
    const [isOpen, setIsOpen] = useState(false);

    const { t } = useTranslation();
    useEffect(() => {
        setTimeout(() => {
            setIsOpen(true);
        }, 500);

    }, []);

    const handleClose = async () => {
        setTimeout(() => {
            setIsOpen(false);
            closeIviteModal();
        }, 500);
    };
    const backgroundStyle = {
        backgroundImage: `url(${bck})`,
    };

    return (
        <div className={`modal-invite-section ${isOpen ? 'openIviteModal' : ''}`} >
            <div className={`modal-invite-container ${isOpen ? 'openIviteModal' : ''}`}>
                <div className='modal-invite-card' style={backgroundStyle}>
                    <p className='modal-invite-title'>{t("UpgradePage.InviteText.Title")}</p>
                    <p className='modal-invite-description'>{t("UpgradePage.InviteText.dsc1")}</p>
                    <p className='modal-invite-description'>{t("UpgradePage.InviteText.dsc2")}
                    </p>
                    <button style={{color: 'white'}} className='modal-invite-btn' onClick={handleClose}>{t("UpgradePage.InviteText.button")}</button>
                    <div style={{position: 'absolute'}} className='modal-task-back-btn-section'>
                        <img src={ArrowLeft} alt='<' />
                        <button onClick={handleClose}>{t("LevelsPage.CloseModalText")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpgradeInviteModal;
