import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import axios from 'axios';
import { API_URLS } from '../config/config';
import Female_png from '../assets/images/pickGender/Female.png';
import Male_png from '../assets/images/pickGender/male.png';
import Ramka from '../assets/images/pickGender/ramka.png';
import listva_png from '../assets/images/pickGender/listva.png';
import bck from '../assets/images/pickGender/gender_bck.webp';
import '../assets/styles/pickGender.css'

import { useTranslation } from 'react-i18next';

const PickGender = () => {
  const { t } = useTranslation();
  const [selectedGender, setSelectedGender] = useState('1');
  const { user, updateUser } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.is_picked_gender) {
      navigate('/');
    }
  }, [user, navigate]);

  const genders = [
    { id: '1', name: 'Female', image: Female_png },
    { id: '0', name: 'Male', image: Male_png }
  ];

  const handleGenderSelect = useCallback((gender) => {
    setSelectedGender(gender);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (selectedGender && user && user.user_id) {
      try {
        console.log('Sending request to:', API_URLS.PICK_GENDER);
        const response = await axios.post(API_URLS.PICK_GENDER, {
          userId: user.user_id,
          gender: parseInt(selectedGender)
        }, {
          headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'ngrok-skip-browser-warning': 'true'
          }
        });

        console.log('Server response:', response.data);

        if (response.data && response.status === 200) {
          await updateUser(response.data);
          console.log('User context updated successfully with server data');
          navigate('/');
        } else {
          throw new Error("Unexpected response from server");
        }
      } catch (error) {
        console.error('Error updating user gender:', error);
        console.error('Error details:', error.response ? error.response.data : 'No response data');
        // Здесь можно добавить обработку ошибки, например, показать сообщение пользователю
      }
    }
  }, [selectedGender, user, updateUser, navigate]);

  const getGenderClass = useCallback((genderId) => {
    if (selectedGender === '1') {
      return genderId === '1' ? 'female_select' : 'male_Unselect';
    } else if (selectedGender === '0') {
      return genderId === '0' ? 'male_select' : 'female_Unselect';
    }
    return '';
  }, [selectedGender]);

  if (!user) {
    return <div>Loading user data...</div>;
  }

  return (
    <div className="pick-gender-section" style={{backgroundImage: `url(${bck})`}}>
      <div className='title-container'>
        {/* <img className='img1' alt='ramka' src={Ramka} />
        <p style={{textTransform: "uppercase"}}>{t('pickGenderPage.title')}</p>
        <img className='img2' alt='ramka' src={Ramka} /> */}
        <div className="ramka-wrapper">
          <img className='img1' alt='ramka' src={Ramka} />
        </div>
        <p style={{textTransform: "uppercase"}}>{t('pickGenderPage.title')}</p>
        <div className="ramka-wrapper">
          <img className='img2' alt='ramka' src={Ramka} />
        </div>
      </div>
      <div className="content">
        <div className="genders">
          {genders.map(gender => (
            <img  
              key={gender.id}
              src={gender.image}
              alt={gender.name}
              className={`character ${selectedGender === gender.id ? 'selected' : ''} ${getGenderClass(gender.id)}`}
              onClick={() => handleGenderSelect(gender.id)}
            />
          ))}
        </div>
      </div>
      
      <button 
        className="continue-button" 
        onClick={handleSubmit} 
        disabled={!selectedGender}
      >
        {t('pickGenderPage.btn')}
      </button>
      <img className='listva_btm' src={listva_png} alt="Listva" />
    </div>
  );
};

export default PickGender;