import { useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "../contexts/UserContext";
import { useLanguageInit } from "../contexts/LanguageInitContext";

const useSetLanguage = () => {
  const { i18n } = useTranslation();
  const { user, updateUser } = useUser();
  const { setIsLanguageInitialized } = useLanguageInit();

  const availableLanguages = useMemo(() => {
    return (
      i18n.options.supportedLngs || Object.keys(i18n.options.resources || {})
    );
  }, [i18n]);

  const setLanguage = useCallback(
    (lang) => {
      if (availableLanguages.includes(lang)) {
        i18n.changeLanguage(lang).then(() => {
          if (user && user.lang_code !== lang) {
            updateUser({ ...user, lang_code: lang });
          }
          setIsLanguageInitialized(true);
        });
      } else {
        const fallbackLang = i18n.options.fallbackLng || "en";
        i18n.changeLanguage(fallbackLang).then(() => {
          setIsLanguageInitialized(true);
        });
      }
    },
    [i18n, user, updateUser, setIsLanguageInitialized, availableLanguages]
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.lang_code && availableLanguages.includes(user.lang_code)) {
      setLanguage(user.lang_code);
    } else {
      const fallbackLang = i18n.options.fallbackLng || "en";
      setLanguage(fallbackLang);
    }
  }, [user, setLanguage, availableLanguages, i18n]);

  return setLanguage;
};

export default useSetLanguage;
