import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../contexts/UserContext';
import { getBackgroundImage } from '../../contexts/UpgradeContext';
import gnom_bad from '../../assets/images/upgrade/gnom_bad.png';
import ArrowLeft from '../../assets/images/upgrade/Arrow 1.png';

const UpgradeGameModalGlobal = ({ statusCustom, gameData, rankData, userData, onClose, onRefreshGlobal }) => {
    const { user, updateUser } = useUser();
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState(null);



    useEffect(() => {
        console.log("=-=-=-=-=-=-");
        console.log("=-= rankData: ", rankData);
        console.log("=-= userData: ", userData);
        console.log("=-=-=-=-=-=-");

        if(statusCustom === "NextRank") {
            let stageId = userData.stage.id + 1;
            const bgImage = getBackgroundImage(rankData.rank.next_rank, stageId);
            setBackgroundImage(bgImage);
        }
        else if(statusCustom === "NextStage") {
            let stageId = userData.stage.id + 1;
            const bgImage = getBackgroundImage(rankData.rank.id, stageId);
            setBackgroundImage(bgImage);
        }

        else {
          const bgImage = getBackgroundImage(rankData.rank.id, userData.stage.id);
          setBackgroundImage(bgImage);
        }
      }, []);
    

    useEffect(() => {
        if (backgroundImage) {
          const upgradeSection = document.querySelector('.modal-task-section-global');
          if (upgradeSection) {
            upgradeSection.style.setProperty('--background-image-global-modal', `url(${backgroundImage})`);
          }
        }
      }, [backgroundImage]);


    

    useEffect(() => {
        console.log('statusCustom----, ', statusCustom);
        setTimeout(() => {
            setIsOpen(!!statusCustom);
        }, statusCustom ? 500 : 0);
    }, [statusCustom]);

    const handleClose = async () => {
        setIsOpen(false);  // Сначала закрываем модальное окно
    
        // Затем через 500 мс обновляем данные и вызываем onClose
        setTimeout(async () => {
            await onRefreshGlobal();  // Обновляем данные с задержкой
            onClose();  // Завершаем закрытие модального окна
        }, 500); // Задержка в 500 мс
    };
    

    const getImagePath = (rankId, stageId) => {
        try {
            // return require(`../../assets/images/upgrade/Road/${rankId}/${stageId}.png`);
            return require(`../../assets/images/upgrade/GameBackground/${rankId}_${stageId}.webp`);
        } catch (err) {
            console.error('Failed to load image:', err);
            return null;
        }
    };

    const modalIcon = () => {
        if (statusCustom === 'NoMoney' || statusCustom === 'Error' || statusCustom === 'NoMoneyBuyRank' || statusCustom === 'ErrorNextStage') {
            return (
                <div className='modal-task-icon-con-bad-gnom'>
                    <img src={gnom_bad} alt="Bad Gnom" />
                </div>
            );
        } else if (statusCustom === 'NextRank') {
            const levelNumber = user.rank.next_rank;
            const stageNumber = user.stage.id + 1;
            const imagePath = getImagePath(user.rank.next_rank, stageNumber);
            const txt = 'lvl';
            return (
                <div className='modal-task-icon-con-nextRankStage'>
                    <div className="image-container">
                        {imagePath && <img src={imagePath} alt="" />}
                        <p className='num-lvl'>{txt} {levelNumber}</p>
                    </div>
                </div>
            );
        } else if (statusCustom === 'NextStage') {
            const stageId = user.stage.id + 1;
            const imagePath = getImagePath(user.rank.id, stageId);
            const levelNumber = stageId;
            const txt = 'stage';
            return (
                <div className='modal-task-icon-con-nextRankStage'>
                    <div className="image-container">
                        {imagePath && <img src={imagePath} alt="" />}
                        <p className='num-lvl'>{txt} {levelNumber}</p>
                    </div>
                </div>
            );
        }

        return null;
    };

    const modalTitle = (statusCustom) => {
        const titles = {
            'Error': t('UpgradePage.UpgradeModal.Error.Title'),
            'NoMoney': t('UpgradePage.UpgradeModal.NoMoney.Title'),
            'NoMoneyBuyRank': t('UpgradePage.UpgradeModal.NoMoneyBuyRank.Title'),
            'NextRank': t('UpgradePage.UpgradeModal.NextRank.Title'),
            'NextStage': t('UpgradePage.UpgradeModal.NextStage.Title'),
            'ErrorNextStage': t('UpgradePage.UpgradeModal.ErrorNextStage.Title'),
            'PrisonError': t('UpgradePage.UpgradeModal.PrisonError.Title'),
        };
        return <p className='modal-task-title'>{titles[statusCustom] || ''}</p>;
    };

    const modalButtonText = () => {
        const buttonTexts = {
            'Error': t('UpgradePage.UpgradeModal.Error.ButtonText'),
            'NoMoney': t('UpgradePage.UpgradeModal.NoMoney.ButtonText'),
            'NoMoneyBuyRank': t('UpgradePage.UpgradeModal.NoMoneyBuyRank.ButtonText'),
            'NextRank': t('UpgradePage.UpgradeModal.NextRank.ButtonText'),
            'NextStage': t('UpgradePage.UpgradeModal.NextStage.ButtonText'),
            'ErrorNextStage': t('UpgradePage.UpgradeModal.ErrorNextStage.ButtonText'),
            'PrisonError': t('UpgradePage.UpgradeModal.PrisonError.ButtonText'),
        };
        return buttonTexts[statusCustom] || t('UpgradeModal.DefaultButtonText');
    };

    const modalDescription = () => {
        const buttonTexts = {
            'Error': t('UpgradePage.UpgradeModal.Error.Description'),
            'NoMoney': t('UpgradePage.UpgradeModal.NoMoney.Description', { IdDsc: user.rank.gold_required }),
            'NoMoneyBuyRank': t('UpgradePage.UpgradeModal.NoMoneyBuyRank.Description', { IdDsc: user.rank.gold_required }),
            'NextRank': t('UpgradePage.UpgradeModal.NextRank.Description'),
            'NextStage': t('UpgradePage.UpgradeModal.NextStage.Description'),
            'ErrorNextStage': t('UpgradePage.UpgradeModal.ErrorNextStage.Description'),
            'PrisonError': t('UpgradePage.UpgradeModal.PrisonError.Description'),
        };
        return buttonTexts[statusCustom] || t('UpgradeModal.DefaultDescription');
    }


   


    return (
        <div className={`modal-task-section-bck ${isOpen ? 'open' : ''}`}>
            <div className={`modal-task-section modal-task-section-global ${isOpen ? 'open' : ''}`}>
                <div className='modal-task-container'>
                    {modalIcon()}
                    
                    {modalTitle(statusCustom)}
                    
                    <p className='modal-task-description'>
                        {modalDescription()}
                    </p>
                    
                    <button className="modal-task-buy-button" onClick={handleClose}>
                        {modalButtonText()}
                    </button>
                    <div className='modal-task-back-btn-section'>
                        <img src={ArrowLeft} alt='<' />
                        <button onClick={handleClose}>{modalButtonText}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpgradeGameModalGlobal;
